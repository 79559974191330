import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { i18n } from '../../i18n';
import { GROUP_TAB_NAME, PAGE_TAB_NAME_LIST, PAGE_TAB_NAME_NEW, PREFERENCE_TAB } from '../../constant';
import { preferenceGroupSaveService } from '../../services/preferenceGroupSaveService';

const PreferenceGroupSaveButton = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const saveNewPreferenceGroup = () => {
        preferenceGroupSaveService.savePreferenceGroup();
        navigate(`/${PREFERENCE_TAB}/${GROUP_TAB_NAME}/${PAGE_TAB_NAME_LIST}`);
    };

    return (
        <button type="button" className='block__button button preference__form-button' onClick={saveNewPreferenceGroup}>
            <span className="button__content">
                {id !== PAGE_TAB_NAME_NEW ? i18n.get('app.page.group.edit') : i18n.get('app.page.group.new')}
            </span>
        </button>
    );
};

export default PreferenceGroupSaveButton;
