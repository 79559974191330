import React, { useEffect, useState } from 'react';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { SORTING_DIRECTION_ASC, SORTING_DIRECTION_DESC } from '../../constant';
import { SortIcon } from '../../appearence/icons/sort_icon';

const PreferenceGroupsActionsSortIcon = () => {
    const [direction, setDirection] = useState('');
    const changeSortingDirection = () => {
        preferencesGroupsStorage.changeSortingDirection(
            preferencesGroupsStorage.getSortingDirection() === SORTING_DIRECTION_ASC ? 
                SORTING_DIRECTION_DESC 
                : 
                SORTING_DIRECTION_ASC);
    };

    const changeDirectionOfSorting = () => {
        setDirection(preferencesGroupsStorage.getSortingDirection());
    };

    useEffect(() => {
        preferencesGroupsStorage.registerFiltersChangingObserver(changeDirectionOfSorting);
        return () => preferencesGroupsStorage.unRegisterFiltersChangingObserver(changeDirectionOfSorting);
    }, []);

    return (
        <li onClick={changeSortingDirection} key={'phraseSorting'}>
            <SortIcon nameOfClass={`preference__groups-icon 
                ${direction === SORTING_DIRECTION_ASC ? 'preference__groups-icon_rotate' : ''}`
            }/>
        </li>
    );
};

export default PreferenceGroupsActionsSortIcon;
