import React, { useState, useEffect, useRef, useCallback } from 'react';
import UpdateSeveralPropertiesIcon from '../productsTab/products/UpdateSeveralPropertiesIcon';
import { MOUSE_DOWN_EVENT } from '../../constant';
import ExcludeFormHandler from './ExcludeFormHandler';
import { i18n } from '../../i18n';
import AddNewFormButton from './AddNewFormButton';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import CreateNewFormPopup from '../productsTab/forms/CreateNewFormPopup';
import SelectWithTokensActiveItem from '../sharedComponents/SelectWithTokensActiveItem';
import SelectWithTokensInput from '../sharedComponents/SelectWithTokensInput';
import SelectWithTokensSelectList from '../sharedComponents/SelectWithTokensSelectList';

const checkSelectedForms = (selectWithTokensManager) => {
    return selectWithTokensManager.getCurrentItems() && selectWithTokensManager.getCurrentItems().length;
};

const FormsHandler = ({ selectWithTokensManager }) => {
    const [selectListIsOpen, setSelectListIsOpen] = useState(false);
    const wrapperRef = useRef();

    const openFormsList = () => {
        setSelectListIsOpen(true);
    };

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSelectListIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        };
    }, []);

    return (
        <>
            <label className="block__text product__form-label">
                <UpdateSeveralPropertiesIcon />
                <span className="product__form-label_text">{selectWithTokensManager.getLabelName()}</span>
                <div ref={wrapperRef}>
                    <div className="product__forms" onClick={openFormsList} >
                        {checkSelectedForms(selectWithTokensManager) ? 
                            <ul className={`product__forms-list ${selectWithTokensManager.getCurrentItems().length === 0 ? 'product__forms-list_full-width' : ''}`}>
                                {selectWithTokensManager.getCurrentItems().map((it, index) => (
                                    <React.Fragment key={it.id + 'formHandler' + index}>
                                        <SelectWithTokensActiveItem 
                                            selectItem={{...it, included: it.included !== undefined ? it.included : true}} 
                                            removeCallback={selectWithTokensManager}
                                        />
                                    </React.Fragment>
                                ))}
                                    
                            </ul>
                            :
                            null}
                        {selectListIsOpen || selectWithTokensManager.getCurrentItems().length === 0 ? 
                            <SelectWithTokensInput selectWithTokensManager={selectWithTokensManager} />
                            :
                            null}
                        <ChevronIcon
                            nameOfClass={`product__forms-icon ${selectListIsOpen ? 'product__forms-icon_active' : ''}`} />
                    </div>
                    {selectListIsOpen ? 
                        <div className="product__forms-dropdown product__select-dropdown">
                            <SelectWithTokensSelectList selectWithTokensManager={selectWithTokensManager} include={true}/>
                            <AddNewFormButton selectWithTokensManager={selectWithTokensManager}/>
                            {selectWithTokensManager.hasExcludedItemsBlock() ?
                                <ExcludeFormHandler selectWithTokensManager={selectWithTokensManager} />
                                :
                                null}
                        </div>
                        :
                        null}
                </div>
            </label>
            <CreateNewFormPopup />
        </>
    );
};

export default FormsHandler;
