import React, { useState } from 'react';
import { i18n } from '../../i18n';
import ListItemTitleContent from './ListItemTitleContent';
import LocaleSwitcher from './LocaleSwitcher';
import ListItemWarningIcon from './ListItemWarningIcon';

const ListItemTitle = ({itemCode, itemService}) => {
    const [productLocale, setProductLocale] = useState(i18n.APP_LOCALE);

    const changeProductLocale = (newValue) => {
        setProductLocale(newValue);
    };

    return (
        <div key={itemCode} className="item__list-name" >
            <ListItemTitleContent localeCode={productLocale} itemCode={itemCode} itemService={itemService} />
            <ListItemWarningIcon itemCode={itemCode} itemService={itemService} />
            <div className="locale__select">
                <LocaleSwitcher localeCode={productLocale} notifyListener={changeProductLocale}/>
            </div>
        </div>
    );
};

export default ListItemTitle;
