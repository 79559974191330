import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageTitleAsBreadCrumbs from '../../sharedComponents/PageTitleAsBreadCrumbs';
import { productSaveService } from '../../../services/productSaveService';
import ItemName from '../../sharedComponents/ItemName';
import { productsService } from '../../../services/productsService';
import UpdateSeveralPropertiesPopup from './UpdateSeveralPropertiesPopup';
import FormsHandler from '../../formStructure/FormsHandler';
import { formsProductService } from '../../../services/formsProductService';
import { formsStorage } from '../../../services/formsStorage';
import LastUpdateTime from '../../sharedComponents/LastUpdateTime';
import NonEditableItemCode from '../../sharedComponents/NonEditableItemCode';
import { propertiesStorage } from '../../../services/propertiesStorage';
import NavigationTabs from '../../sharedComponents/NavigationTabs';
import ProductPropertiesListHandler from './ProductPropertiesListHandler';
import { productTabNavigationService } from '../../../services/productTabNavigationService';
import { SMALL_WEB_SCREEN_WIDTH } from '../../../constant';
import { propertyValuesSourcesStorage } from '../../../services/propertyValuesSourcesStorage';
import DeleteProductPropertyPopup from './DeleteProductPropertyPopup';
import ItemProductCategory from '../../sharedComponents/ItemProductCategory';

const EditProductTab = () => {
    const [activeItem, setActiveItem] = useState({});
    const { id } = useParams();

    const updateActiveItem = () => {
        setActiveItem({...productSaveService.getProduct()});
    };

    useEffect(() => {
        if(id) {
            productSaveService.loadProduct(id, updateActiveItem);
            productSaveService.registerItemUpdateObserver(updateActiveItem);
            formsStorage.loadItems(() => null, SMALL_WEB_SCREEN_WIDTH + 1, '?valid=true');
            propertiesStorage.loadItems(() => null, SMALL_WEB_SCREEN_WIDTH + 1, '?valid=true');
            propertyValuesSourcesStorage.loadItems(() => null);
        }
        return () => {
            productSaveService.unRegisterItemUpdateObserver(updateActiveItem);
            formsStorage.cleanStorage();
            propertiesStorage.cleanStorage();
        };
    }, [id]);

    return (
        activeItem.id &&
            <>
                <PageTitleAsBreadCrumbs />
                <article className="product__article product__article-add">
                    <LastUpdateTime itemSaveService={productSaveService} itemCode={activeItem.productCode}/>
                    <form className="product__form">
                        <ItemName itemCode={activeItem.productCode} itemService={productsService} itemSaveService={productSaveService}/>
                        <NonEditableItemCode itemCode={activeItem.productCode} />
                        <ItemProductCategory itemsStorageService={productSaveService} />
                        <FormsHandler selectWithTokensManager={formsProductService} />
                    </form>
                    <NavigationTabs navigationService={productTabNavigationService} />
                    <ProductPropertiesListHandler />
                </article>
                <UpdateSeveralPropertiesPopup />
                <DeleteProductPropertyPopup />
            </> 
    );
};

export default EditProductTab;
