import React, { useRef, useEffect, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { checkIsOverflowed } from '../../hooks/useOverflowed';
import HintItem from './HintItem';

const ListItemTitleContent = ({localeCode, itemCode, itemService}) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef();
    const width = useWindowDimensions();
    useEffect(() => {
        setIsOverflowed(checkIsOverflowed(ref.current));
    }, [width]);
    return (
        <h2 
            key={itemCode + localeCode} 
            className="main__subtitle product__title-locale" 
            ref={ref} 
            onMouseOver={() => setIsHovered(true)} 
            onMouseOut={() => setIsHovered(false)}
        >
            {itemService.getLocalization(localeCode, itemCode, true)}
            {isOverflowed && isHovered ? <HintItem message={itemService.getLocalization(localeCode, itemCode, true)} /> : null}
        </h2>
    );
};

export default ListItemTitleContent;
