import React from 'react';
import { i18n } from '../../i18n';
import SelectWithoutTokens from './SelectWithoutTokens';
import { createCategoriesList } from '../helper';

const getProductCategoryTitle = (formGroup) => {
    if(formGroup) {
        return i18n.get(`product.scheme.formGroup.${formGroup}`);
    } else {
        return '';
    }
};

const ItemProductCategory = ({itemsStorageService}) => {
    const changeProductCategory = (form) => {
        itemsStorageService.updateProductCategory(form.id);
    };

    return (
        <label className="block__content product__form-label product__form-item" key={itemsStorageService.getProductCategory() + 'label'}>
            <span className="block__text product__form-label_text">{itemsStorageService.getLabelValue()}</span>
            <SelectWithoutTokens listOfOptions={createCategoriesList(itemsStorageService.needToAddAny())} handleChangeValue={changeProductCategory}
                titleValue={getProductCategoryTitle(itemsStorageService.getProductCategory())}/>
        </label>
    );
};

export default ItemProductCategory;
