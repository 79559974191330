import React from 'react';
import PreferencesListActionsEditIcon from './PreferencesListActionsEditIcon';
import PreferencesListActionsDeleteIcon from './PreferencesListActionsDeleteIcon';

const PreferencesListActions = () => {
    return (
        <ul className='preference__groups-icons'>
            <PreferencesListActionsEditIcon />
            <PreferencesListActionsDeleteIcon />
        </ul>
    );
};

export default PreferencesListActions;
