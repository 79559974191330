import { i18n } from "../i18n";

export const recipesService = {
    getLocalization(localeCode, code, withCodeReplace) {
        if(withCodeReplace) {
            return i18n.get(`recipe.item.type.${code}`, localeCode);
        } else {
            return i18n.is_exists(`recipe.item.type.${code}`, localeCode) ? i18n.get(`recipe.item.type.${code}`, localeCode) : '';
        }
    },
    getPlaceholder() {
        return i18n.get("app.page.recipe.name");
    },
};
