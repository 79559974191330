import React from 'react';

const Checkbox = ({value}) => (
    <label className='block__content checkbox__container checkbox__property'>
        <input className="checkbox__item" type="checkbox" defaultChecked={value} onClick={(e) => e.stopPropagation()}/>
        <span className="checkbox__checkmark"></span>
    </label>
);

export default Checkbox;
