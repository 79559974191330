import React, { useState, useEffect } from 'react';
import { propertySaveService } from '../../../services/propertySaveService';
import { KCAL_UNIT_NAME, MCG_UNIT_NAME } from '../products/ProductPropertyNumberValue';
import { i18n } from '../../../i18n';
import SelectWithoutTokens from '../../sharedComponents/SelectWithoutTokens';

const makePropertyUnitsList = () => {
    return [
        {
            id: MCG_UNIT_NAME,
            name: i18n.get('app.page.product.productUnitFullMcg'),
        },
        {
            id: KCAL_UNIT_NAME,
            name: i18n.get('app.page.product.productUnitKcalTitle'),
        },
    ];
};

const getPropertyNumberUnitsTitle = (numberUnits) => {
    if(numberUnits) {
        return makePropertyUnitsList().find(it => it.id === numberUnits).name;
    } else {
        return '';
    }
};

const PropertyItemNumberUnits = () => {
    const [numberUnits, setNumberUnits] = useState(propertySaveService.getProperty().numberUnits);

    const changePropertyUnits = (property) => {
        propertySaveService.updatePropertyValue('numberUnits', property.id);
    };

    const changePropertyState = () => {
        setNumberUnits(propertySaveService.getProperty().numberUnits);
    };

    useEffect(() => {
        propertySaveService.registerItemUpdateObserver(changePropertyState);
        return () => propertySaveService.unRegisterItemUpdateObserver(changePropertyState);
    }, []);

    return (
        <>
            <label className="block__text product__form-label" key={numberUnits + 'label'}>
                <span className="product__form-label_text">{i18n.get('app.page.product.propertyItem.numberUnits')}</span>
                <SelectWithoutTokens listOfOptions={makePropertyUnitsList()} handleChangeValue={changePropertyUnits}
                    titleValue={getPropertyNumberUnitsTitle(numberUnits)}/>
            </label>
        </>
    );
};

export default PropertyItemNumberUnits;
