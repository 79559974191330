import React, { useState, useEffect } from 'react';
import { popupOpenService } from '../../sharedComponents/popupOpenService';
import Modal from '../../Modal';
import { POPUP_KEY_DELETE_PRODUCT_PROPERTY, PROPERTY_TAB_NAME } from '../../../constant';
import { MODAL_SMALL_SIZE } from '../../helper';
import DeleteModalContent from '../DeleteModalContent';
import { propertiesStorage } from '../../../services/propertiesStorage';
import { propertiesService } from '../../../services/propertiesService';
import { i18n } from '../../../i18n';
import { productSaveService } from '../../../services/productSaveService';

const DeleteProductPropertyPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPropertyId, setCurrentPropertyId] = useState('');

    const handleOpenModal = (propertyId) => {
        setIsOpen(true);
        setCurrentPropertyId(propertyId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_PRODUCT_PROPERTY, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_PRODUCT_PROPERTY, handleOpenModal);
    },[]);

    const deleteProductProperty = () => {        
        setIsOpen(null);
        setCurrentPropertyId('');
        productSaveService.deleteProductProperty(currentPropertyId);
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deleteProductProperty} tab={PROPERTY_TAB_NAME}
                isForceDelete={false}
                itemTitle={propertiesService.getLocalization(i18n.APP_LOCALE, propertiesStorage.getById(currentPropertyId).propertyName)} />}  
            handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeleteProductPropertyPopup;
