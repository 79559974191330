import React, { useRef, useEffect, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { checkIsOverflowed } from '../../hooks/useOverflowed';
import HintItem from './HintItem';

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const ItemNameContent = ({itemCode, localeCode, itemSaveService, itemService}) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef();
    const width = useWindowDimensions();
    useEffect(() => {
        setIsOverflowed(checkIsOverflowed(ref.current));
    }, [width]);
    return (
        <React.Fragment key={localeCode}>
            <input className="main__subtitle product__form-input product__form-title" 
                ref={ref}
                placeholder={capitalize(itemService.getPlaceholder())}
                defaultValue={itemService.getLocalization(localeCode, itemCode, false)}
                onMouseOver={() => setIsHovered(true)} 
                onMouseOut={() => setIsHovered(false)}
                onChange={(e) => itemSaveService.updateItemLocalization(localeCode, e.target.value)}
            />
            {isOverflowed && isHovered ? <HintItem message={itemService.getLocalization(localeCode, itemCode, false)} /> : null}
        </React.Fragment>
    );
};

export default ItemNameContent;
