import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import { MOUSE_DOWN_EVENT } from '../../constant';
import SelectedItem from './SelectedItem';
import SelectList from './SelectList';

const SelectHandler = ({selectedValue, optionsList, sendSelectedValue, parentElement = null}) => {
    const wrapperRef = useRef(null);
    const [optionListIsOpen, setOptionListIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(selectedValue);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOptionListIsOpen(false);
        }
        if(parentElement) {
            if((event.target === parentElement.current) || Array.from(parentElement.current.children).find(element => element === event.target)) {
                setOptionListIsOpen(!optionListIsOpen);
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        };
    }, []);

    const openListWithVariants = () => {
        setOptionListIsOpen(true);
    };

    const selectAnItem = (e, option) => {
        e.stopPropagation();
        e.preventDefault();
        setOptionListIsOpen(false);
        setSelectedItem(option.name);
        sendSelectedValue(option.id);
    };

    const closeOptionsList = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(prev => !prev);
    };

    return (
        <div className={`block__content select__choice select__choice_full-width ${selectedItem ? 'select__choice_active' : ''} `}
            onClick={openListWithVariants} ref={wrapperRef}>
            <SelectedItem selectedValue={selectedItem}/>
            {optionListIsOpen && optionsList.length ? 
                <SelectList optionsList={optionsList} selectAnItem={selectAnItem} />
                :
                null}
            <ChevronIcon handleClick={closeOptionsList}
                nameOfClass={`select__choice-icon ${optionListIsOpen ? 'select__choice-icon_active' : ''} select__choice-icon_full-width`} />
        </div>
    );
};

export default SelectHandler;
