import React from 'react';
import { MenuLogoIcon } from '../appearence/icons/small_logo_icon';
import { BurgerIcon } from '../appearence/icons/burger_icon';

const Header = ({setMenuIsOpen, menuIsOpen}) => {
    return (
        <header className="header">
            <MenuLogoIcon nameOfClass="header__logo"/>
            <BurgerIcon nameOfClass="header__menu" handleClick={setMenuIsOpen} value={!menuIsOpen}/>
        </header>
    );
};

export default Header;
