import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { popupOpenService } from "./popupOpenService";
import { i18n } from '../../i18n';
import { ConeIcon } from '../../appearence/icons/cone_icon';
import { RestoreIcon } from './RestoreIcon';

const RestoreItemIcon = ({ itemId, popupKey }) => {
    const [hintIsOpen, setHintIsOpen] = useState(false);
    const { tab } = useParams();

    const openRestorePopup = () => {
        popupOpenService.callToOpen(popupKey, itemId);
    };

    const openHint = () => {
        setHintIsOpen(true);
    };

    return (
        <div key={itemId + 'deleteIcon'} onClick={openRestorePopup} onMouseOver={openHint} onMouseOut={() => setHintIsOpen(false)}>
            {hintIsOpen ? 
                <p className="product__list-icon_hint">
                    <ConeIcon nameOfClass="product__list-icon_cone"/>
                    {i18n.get(`app.page.${tab}.hint`)}
                </p> 
                : 
                null}
            <RestoreIcon nameOfClass="product__list-icon" />
        </div>
    );
};

export default RestoreItemIcon;
