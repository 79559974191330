import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import { PREFERENCE_TYPE_CEREAL_MIX, PREFERENCE_TYPE_PRODUCT, PREFERENCE_TYPE_PROPERTY, PREFERENCE_TYPE_RECIPE, PRODUCT_FORM_GRAIN, errorCallback, makeUrl, successCallbackEmpty } from "../constant";
import { i18n } from "../i18n";
import { PHRASE_URL_PART, PREFERENCE_URL, deleteFetchRequest, getFetchRequest, postFetchRequest, putFetchRequest } from "../store/requests";
import { productsStorage } from "./productsStorage";
import { propertiesService } from "./propertiesService";
import { propertiesStorage } from "./propertiesStorage";

const DEFAULT_PREFERENCE = {groups: [], isInternal: true};

const createPhraseRequest = (preferenceId, phraseContent, callback) => {
    const preparedPhrase = {
        locale: i18n.APP_LOCALE,
        content: phraseContent.content,
        isWelcome: phraseContent.isWelcome,
    };
    putFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, PHRASE_URL_PART]), preparedPhrase, callback, errorCallback);
};

const transformGroupsList = (groupsList) => {
    const result = [];
    groupsList.forEach(it => result.push({groupId: it.id}));
    return result;
};

const isGrain = (product) => product.productForms.findIndex(it => it.formGroup === PRODUCT_FORM_GRAIN) > -1;

const prepareProductsListForCerealMix = (productsList) => {
    return productsList.filter(it => isGrain(it));
};

const preparePropertiesList = () => {
    const result = [];
    propertiesStorage.getItems(true).forEach(it => result.push({
        id: it.propertyName,
        name: propertiesService.getLocalization(i18n.APP_LOCALE, it.propertyName, false),
    }));
    return result;
};

export const createPossibleItemCodeValues = (productsList, preferenceType) => {
    if(preferenceType === PREFERENCE_TYPE_PRODUCT) {
        return productsList;
    } else if (preferenceType === PREFERENCE_TYPE_CEREAL_MIX) {
        return prepareProductsListForCerealMix(productsList);
    } else if (preferenceType === PREFERENCE_TYPE_RECIPE) {
        return [
            {
                id: 'snack',
                name: i18n.get('recipe.item.type.snack'),
            },
            {
                id: 'kasha',
                name: i18n.get('recipe.item.type.kasha'),
            },
            {
                id: 'bowl',
                name: i18n.get('recipe.item.type.bowl'),
            },
            {
                id: 'soup',
                name: i18n.get('recipe.item.type.soup'),
            },
            {
                id: 'bread',
                name: i18n.get('recipe.item.type.bread'),
            },
            {
                id: 'smoothies',
                name: i18n.get('recipe.item.type.smoothies'),
            },
            {
                id: 'granola',
                name: i18n.get('recipe.item.type.granola'),
            },
        ];
    } else if(preferenceType === PREFERENCE_TYPE_PROPERTY) {
        return preparePropertiesList();
    } else {
        return [];
    }
};

export const preferenceSaveService = {
    currentPreference: {...DEFAULT_PREFERENCE},
    preferenceUpdateObservers: [],
    lastPreferenceUpdateTime: null,
    preferenceId: '',
    howOften: '',
    phrasesList: [{id: '', content: '', locale: i18n.APP_LOCALE, isWelcome: false}],
    loadPreference() {
        try {
            if(this.preferenceId) {
                getFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/']), (response) => {
                    this.currentPreference = {...response, groups: response.groups ? response.groups : []};
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback);
                getFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, '?onlyGenerated=false']), (response) => {
                    if(response.length) {
                        this.phrasesList = [...response, ...this.phrasesList];
                    }
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    getPreference() {
        return this.currentPreference;
    },
    updatePreferenceProperty(propertyKey, value) {
        try {
            this.currentPreference[propertyKey] = value;  
            this.lastPreferenceUpdateTime = Date.now(); 
            if(propertyKey === 'preferenceType') {
                this.currentPreference.preferenceItemCodes = [];
            }
            if(this.preferenceId) {
                this.savePreference();
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    savePreference(callback) {
        try {
            const preparedBody = {
                ...this.currentPreference,
                preferenceFrequency: this.howOften.preferenceFrequency,
                frequencyPeriod: this.howOften.frequencyPeriod ? this.howOften.frequencyPeriod : '0',
                groups: Array.isArray(this.currentPreference.groups) ? this.currentPreference.groups : [this.currentPreference.groups],
            };
            if(this.preferenceId) {
                postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/']), preparedBody, successCallbackEmpty, errorCallback, true);
            } else {
                postFetchRequest(makeUrl([PREFERENCE_URL]), preparedBody, (response) => {
                    for(let phrase of this.phrasesList) {
                        phrase.content !== '' && createPhraseRequest(response.id, phrase, () => this.cleanService());
                    }
                    postFetchRequest(makeUrl([PREFERENCE_URL, response.id, '/group/']), transformGroupsList(preparedBody.groups), () => {
                        this.lastPreferenceUpdateTime = Date.now();
                        this.cleanService();
                        callback && callback();
                    }, errorCallback, true);
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    getHowOften() {
        return this.howOften;
    },
    updateHowOften(frequencyType, frequencyPeriod) {
        this.howOften = {
            preferenceFrequency: frequencyType,
            frequencyPeriod,
        };
        if(this.preferenceId) {
            this.savePreference();
        }
        this.lastPreferenceUpdateTime = Date.now();
        this.notifyPreferenceUpdateObservers();
    },
    updatePreferencePropertyGroup(group) {
        const index = this.currentPreference.groups.findIndex(it => it.id === group.id);
        if(index >= 0) {
            this.currentPreference.groups = this.currentPreference.groups.filter(it => it.id !== group.id);
            if(this.preferenceId) {
                deleteFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/group/']), () => {
                    this.lastPreferenceUpdateTime = Date.now();
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback, [{groupId: group.id}]);
            }
        } else {
            this.currentPreference.groups.push(group);
            if(this.preferenceId) {
                postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, '/group/']), [{groupId: group.id}], () => {
                    this.lastPreferenceUpdateTime = Date.now();
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback, true);
            }
        }
    },
    getPhrases() {
        return this.phrasesList;
    },
    updatePreferencePhrases(value, index) {
        if (this.phrasesList.length === index) this.phrasesList.push({id: '', content: '', locale: i18n.APP_LOCALE, isWelcome: false});
        this.phrasesList[index].content = value;
        if(this.preferenceId) {
            updateValueSevice.updateItemValue(() => {
                if(!this.phrasesList[index].id) {
                    createPhraseRequest(this.preferenceId, this.phrasesList[index], (response) => {
                        this.phrasesList[index] = response;
                        this.lastPreferenceUpdateTime = Date.now();
                        this.notifyPreferenceUpdateObservers();
                    }, errorCallback);
                } else {
                    postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, this.phrasesList[index].id, '/']), 
                        this.phrasesList[index], () => {
                            this.lastPreferenceUpdateTime = Date.now();
                            this.notifyPreferenceUpdateObservers();
                        }, errorCallback, true);
                }
            });
        }
    },
    deletePhrase(index) {
        this.phrasesList = this.phrasesList.filter((it, ind) => ind !== index);
        if(this.preferenceId && this.phrasesList[index].id) {
            deleteFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, this.phrasesList[index].id, '/']), () => {
                this.lastPreferenceUpdateTime = Date.now();
                this.notifyPreferenceUpdateObservers();
            }, errorCallback);
        } else {
            this.lastPreferenceUpdateTime = Date.now();
            this.notifyPreferenceUpdateObservers();
        }
    },
    registerItemUpdateObserver(observer) {
        this.preferenceUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.preferenceUpdateObservers = this.preferenceUpdateObservers.filter(preferenceUpdateFunction => preferenceUpdateFunction !== observer);
    },
    notifyPreferenceUpdateObservers() {
        this.preferenceUpdateObservers.forEach(preferenceUpdateObserver => preferenceUpdateObserver());
    },
    getDateLastUpdate() {
        return this.lastPreferenceUpdateTime;
    },
    cleanService() {
        this.currentPreference = {...DEFAULT_PREFERENCE};
        this.phrasesList = [{id: '', content: '', locale: i18n.APP_LOCALE, isWelcome: false}];
        this.howOften = '';
    },
    getPossibleCodeValues(preferenceType = null) {
        if(this.itemCode) {
            return createPossibleItemCodeValues(productsStorage.getItems(true), this.currentPreference.preferenceType).filter(it => it.name.includes(this.itemCode));
        }
        return createPossibleItemCodeValues(productsStorage.getItems(true), this.currentPreference.preferenceType ? this.currentPreference.preferenceType : preferenceType);
    },
    updatePreferencePropertyValue(key, value) {
        this.currentPreference.preferenceItemCodes[0][key] = value;
        this.lastPreferenceUpdateTime = Date.now(); 
        if(this.preferenceId) {
            this.savePreference();
        }
        this.notifyPreferenceUpdateObservers();
    },
    getProperty() {
        return this.currentPreference.preferenceItemCodes[0];
    },
    updateProductProperty(propertyId, fieldName, value) {
        this.updatePreferencePropertyValue(fieldName, value);
    },
    updatePropertyValueField(propertyValue) {
        try {
            if(!this.getProperty().value) {
                this.getProperty.value = propertyValue;
                this.updatePreferencePropertyValue('value', propertyValue);
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    updateValueLocalization(locale, value, code) {
        try {
            i18n.update(`product.scheme.property.value.${code}`, value, locale);
            updateValueSevice.updateLocaleValue(locale, `product.scheme.property.value.${code}`, () => {});
        } catch(err) {
            console.log(err);
        }
    },
    needToAddNewPhrase() {
        return true;
    },
};

export const initializePreferenceSaveService = (preferenceId) => {
    preferenceSaveService.preferenceId = preferenceId;
    preferenceSaveService.loadPreference();
};
