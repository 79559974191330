import React, { useState } from 'react';

const checkVoteValue = (value) => (+value < 11 && +value > -1) || value === '';

const PhrasesListItemNumberField = ({ preferencePhraseId, ratingValue, phrasesListManager }) => {
    const [vote, setVote] = useState(ratingValue);
    
    const changePhraseVote = (e) => {
        if(checkVoteValue(e.target.value)) {
            setVote(e.target.value);
            if(e.target.value !== '') {
                phrasesListManager.updatePreferenceVote(preferencePhraseId, e.target.value);
            }
        }
    };
    
    return (
        <>
            <input className="block__content preference__phrases-input" value={vote} onChange={changePhraseVote}/>
        </>
    );
};

export default PhrasesListItemNumberField;
