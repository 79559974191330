import React from 'react';
import { i18n } from '../../i18n';
import { PREFERENCE_GROUP_ACTIVITY_PERIOD_ALWAYS, PREFERENCE_GROUP_ACTIVITY_PERIOD_SELECTED } from '../../constant';
import PreferenceGroupActivityPeriodType from './PreferenceGroupActivityPeriodType';
import PreferenceGroupActivityPeriodsList from './PreferenceGroupActivityPeriodsList';

const PreferenceGroupActivityPeriods = () => {
    return (
        <div>
            <label className="block__text product__form-label product__form-item">
                <span className="product__form-label_text">{i18n.get('app.page.preference.groups.activityRange')}</span>
                <PreferenceGroupActivityPeriodType periodTypeName={PREFERENCE_GROUP_ACTIVITY_PERIOD_ALWAYS} />
                <PreferenceGroupActivityPeriodType periodTypeName={PREFERENCE_GROUP_ACTIVITY_PERIOD_SELECTED} />
            </label>
            <PreferenceGroupActivityPeriodsList />
        </div>
    );
};

export default PreferenceGroupActivityPeriods;
