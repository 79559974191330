import React from 'react';

const ContentTitle = ({ title }) => {
    return (
        <h2 className='main__subtitle'>
            {title}
        </h2>
    );
};

export default ContentTitle;
