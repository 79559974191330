import { DEFAULT_PHRASES_COUNT, errorCallback, makeUrl, successCallbackEmpty } from "../constant";
import { i18n } from "../i18n";
import { PHRASE_URL_PART, PREFERENCE_URL, getFetchRequest, postFetchRequest } from "../store/requests";

export const BASED_ON_PREFERENCE = 'preference';
export const BASED_ON_PHRASE = 'phrase';

const createQueryParams = (lastPhraseId, offset, isWelcome, searchValue, sortingDirection) => {
    let result = '/?';
    offset ? result += `count=${offset}` : '';
    offset && lastPhraseId ? result += '&' : '';
    lastPhraseId ? result += `lastId=${lastPhraseId}` : '';
    sortingDirection ? result += `&sorting=vote:${sortingDirection},dateCreated:desc` : result += '&sorting=dateCreated:desc';
    isWelcome ? result += '&welcome=true' : '';
    searchValue ? result += `&phraseContent=${searchValue}` : '';
    return result;
};

export const preferencesPhrasesStorage = {
    basedOn: BASED_ON_PHRASE,
    preferencesPhrases: [],
    preferenceId: '',
    selectedItems: [],
    sortingDirection: '',
    observersList: [],
    isWelcome: false,
    searchValue: '',
    possibleFilters: [],
    filtersList: [],
    loadNextPhrases(lastPhraseId, offset, callback) {
        try {
            const preparedRequestPath = makeUrl([
                PREFERENCE_URL, 
                this.basedOn === BASED_ON_PHRASE ? i18n.APP_LOCALE : this.preferenceId , 
                this.basedOn === BASED_ON_PHRASE ? 
                    createQueryParams(lastPhraseId, offset, this.isWelcome, this.searchValue, this.sortingDirection) 
                    : 
                    PHRASE_URL_PART,
            ]);
            getFetchRequest(preparedRequestPath, (response) => {
                this.preferencesPhrases = lastPhraseId ? [...this.preferencesPhrases, ...response] : response;
                callback(response);
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    addPreferencePossiblePhrases(phrasesList) {
        this.preferencesPhrases = phrasesList;
    },
    getPhrases() {
        return this.preferencesPhrases;
    },
    getSelectedList() {
        return this.selectedItems;
    },
    changeSelectedPhrasesList(phraseId, cleanAll) {
        if(phraseId) {
            if(this.selectedItems.find(it => it.id === phraseId)) {
                this.selectedItems = this.selectedItems.filter(it => it.id !== phraseId);
            } else {
                this.selectedItems.push(this.preferencesPhrases.find(it => it.id === phraseId));
            }
        } else {
            if((this.preferencesPhrases.length === this.selectedItems.length && this.selectedItems.length !== 0) || cleanAll) {
                this.selectedItems = []; 
            } else {
                this.selectedItems = this.preferencesPhrases;
            }
        }
        this.notifyFiltersChangingObservers();
    },
    getSortingDirection() {
        return this.sortingDirection;
    },
    changeSortingDirection(sortingDirection) {
        this.sortingDirection = sortingDirection;
        this.loadNextPhrases('', DEFAULT_PHRASES_COUNT, () => {});
    },
    cleanStorage() {
        this.basedOn = BASED_ON_PHRASE;
        this.preferencesPhrases = [];
        this.preferenceId = '';
        this.selectedItems = [];
        this.sortingDirection = '';
        this.isWelcome = false;
        this.searchValue = '';
        this.possibleFilters = [];
        this.filtersList = [];
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getById(phraseId) {
        return this.preferencesPhrases.find(it => it.id === phraseId);
    },
    changeFiltersList(filterKey, filterValue) {
        this[filterKey] = filterValue;
        this.loadNextPhrases('', DEFAULT_PHRASES_COUNT, () => {});
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.loadNextPhrases('', DEFAULT_PHRASES_COUNT, () => {});
    },
    getSearchString() {
        return this.searchValue;
    },
    getPossibleFilters() {
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    updatePreferenceIsWelcome(isWelcome) {
        this.selectedItems.forEach(it => {
            it.isWelcome = isWelcome;
            postFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, it.id, '/']), 
                {isWelcome}, successCallbackEmpty, errorCallback, true);
            this.preferencesPhrases.forEach(phrase => it.id === phrase.id ? it.isWelcome = isWelcome : null);
        });
        this.notifyFiltersChangingObservers();
    },

    updatePreferenceVote(phraseId, value) {
        const currentPhrase = this.preferencesPhrases.find(it => it.id === phraseId);
        currentPhrase.vote = value;
        postFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, phraseId, '/']), {vote: value}, 
            successCallbackEmpty, errorCallback, true);
        this.notifyFiltersChangingObservers();
    },
};
