import React from 'react';

const CustomCheckbox = ({checked, handleChange, values, roles = null}) => {
    const preparedData = values ? Object.values(values) : null;
    return (
        <label className={`block__content checkbox__container ${roles ? "checkbox__container-roles" : ""}`}>
            <input className="checkbox__item" type="checkbox" checked={checked} onChange={values ? () => handleChange(...preparedData) : handleChange}/>
            <span className="checkbox__checkmark"></span>
        </label>
    );
};

export default CustomCheckbox;
