import React from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';

const createListOfOptions = () => {
    return [
        {
            id: 'morning',
            name: i18n.get('preference.scheme.when.morning'),
        },
        {
            id: 'after-morning',
            name: i18n.get('preference.scheme.when.after-morning'),
        },
        {
            id: 'afternoon',
            name: i18n.get('preference.scheme.when.afternoon'),
        },
        {
            id: 'before-evening',
            name: i18n.get('preference.scheme.when.before-evening'),
        },
        {
            id: 'evening',
            name: i18n.get('preference.scheme.when.evening'),
        },
        {
            id: 'night',
            name: i18n.get('preference.scheme.when.night'),
        },
        {
            id: 'no-matter',
            name: i18n.get('preference.scheme.when.no-matter'),
        },
    ];
};

const findPreferenceTitle = (itemSaveService) => {
    if(itemSaveService.getPreference().when) {
        return i18n.get(`preference.scheme.when.${itemSaveService.getPreference().when}`);
    } else {
        return '';
    }
};

const PreferenceStructureWhen = ({ itemSaveService }) => {
    const updatePreferenceValue = (value) => {
        itemSaveService.updatePreferenceProperty('when', value);
    };

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.when')}</span>
            <SelectHandler 
                selectedValue={findPreferenceTitle(itemSaveService)} 
                optionsList={createListOfOptions()} 
                sendSelectedValue={updatePreferenceValue}
            /> 
        </label> 
    );
};

export default PreferenceStructureWhen;
