import React from 'react';
import PreferenceGroupActivityPeriodsItemLabel from './PreferenceGroupActivityPeriodsItemLabel';
import { DATE_START_LABEL, DATE_STOP_LABEL } from '../../constant';

const PreferenceGroupActivityPeriodsItem = ({ currentPeriodIndex }) => {
    return (
        <div className="product__form-block">
            <PreferenceGroupActivityPeriodsItemLabel currentPeriodIndex={currentPeriodIndex} labelType={DATE_START_LABEL}/>
            <PreferenceGroupActivityPeriodsItemLabel currentPeriodIndex={currentPeriodIndex} labelType={DATE_STOP_LABEL}/>
        </div>
    );
};

export default PreferenceGroupActivityPeriodsItem;
