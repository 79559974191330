import React, { useState, useEffect } from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { i18n } from '../../i18n';
import { LOCALE_EN, SORTING_DIRECTION_ASC, SORTING_DIRECTION_DESC } from '../../constant';
import GeneratePreferencePhrasesIcon from './GeneratePreferencePhrasesIcon';
import { SortIcon } from '../../appearence/icons/sort_icon';
import { TrashIcon } from '../../appearence/icons/trash_icon';
import { PenIcon } from '../../appearence/icons/pen_icon';
import { preferencePhraseItemService } from '../../services/preferencePhraseService';

const getSelectedListCount = () => {
    return preferencePhraseItemService.getSelectedList().length;
};

export const getPreferencePhraseForm = (count) => {
    if(i18n.APP_LOCALE === LOCALE_EN) {
        return count > 0 ? 'phrases' : 'phrase';
    } else {
        if (count > 10 && [11, 12, 13, 14].includes(count % 100)) return 'фраз';
        let last_num = count % 10;
        if (last_num == 1) return 'фраза';
        if ([2, 3, 4].includes(last_num)) return 'фразы';
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'фраз';
    }
};

const createCountPhrase = (count) => {
    return `${getSelectedListCount() === 0 ? i18n.get('app.page.preference.total') : i18n.get('app.page.preference.selected')} 
        ${count} ${getPreferencePhraseForm(count)}`;
};

const PreferencesPhrasesSelectBlock = ({preferenceId, isLoading, setIsLoading}) => {
    const [count, setCount] = useState(0);
    const [sortingDirection, setSortingDirection] = useState(SORTING_DIRECTION_DESC);

    const getSelectedValues = () => {
        if(getSelectedListCount() === 0) {
            setCount(preferencePhraseItemService.getItems().length);
        } else {
            setCount(getSelectedListCount());
        }
    };

    useEffect(() => {
        preferencePhraseItemService.registerFiltersChangingObserver(getSelectedValues);
        return () => preferencePhraseItemService.unRegisterFiltersChangingObserver(getSelectedValues);
    }, []);

    const changeSortingDirection = () => {
        setSortingDirection(prev => prev === SORTING_DIRECTION_ASC ? SORTING_DIRECTION_DESC : SORTING_DIRECTION_ASC);
        preferencePhraseItemService.changeSortingDirection(sortingDirection === SORTING_DIRECTION_ASC ? SORTING_DIRECTION_DESC : SORTING_DIRECTION_ASC);
    };

    return (
        <div className="preference__selected">
            <CustomCheckbox checked={getSelectedListCount() > 0} 
                handleChange={() => preferencePhraseItemService.changeSelectedPreferencePhrases()}/>
            <p className="block__content preference__selected-count">{createCountPhrase(count)}</p>
            <div className="preference__button-group">
                <GeneratePreferencePhrasesIcon preferenceId={preferenceId} isLoading={isLoading} setIsLoading={setIsLoading} />
                <span onClick={changeSortingDirection}>
                    <SortIcon nameOfClass={`preference__groups-icon ${sortingDirection === SORTING_DIRECTION_ASC ? 'preference__groups-icon_rotate' : ''}`} />
                </span>
                <span><PenIcon nameOfClass={`preference__groups-icon`}/></span>
                <span><TrashIcon nameOfClass={`preference__groups-icon`}/></span>
            </div>
        </div>
    );
};

export default PreferencesPhrasesSelectBlock;
