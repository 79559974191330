import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { i18n } from '../../../i18n';
import AddPropertyPopup from './AddPropertyPopup';
import AddNewPropertyButton from './AddNewPropertyButton';
import ProductPropertyItem from './ProductPropertyItem';
import { productSaveService } from '../../../services/productSaveService';
import { ADD_PRODUCT_PHYSICAL_PROPERTIES, sortByDate } from '../../helper';
import { ErrorHandler } from '../../ErrorHandler';
import { PROPERTY_TYPE_BOOLEAN } from '../../../constant';
import ProductPropertiesSearchInput from './ProductPropertiesSearchInput';
import { propertiesService } from '../../../services/propertiesService';

const createKeyForElement = (element) => {
    return element.id + element.categoryName + element.propertyName;
};

const filterBySearchValue = (property, searchPropertyValue) => {
    return propertiesService.getLocalization(i18n.APP_LOCALE, property.propertyName, true).toLowerCase()
        .includes(searchPropertyValue.toLowerCase());
};

const filterBySearchValueIsExists = (propertiesList, searchValue) => {
    return propertiesList.filter(it => filterBySearchValue(it, searchValue)).length > 0;
};

const ProductPropertiesListHandler = () => {
    const [searchPropertyValue, setSearchPropertyValue] = useState('');
    const [propertiesList, setPropertiesList] = useState([]);
    const [searchParams] = useSearchParams();
    const productTab = searchParams.get('productTab');

    const changeProperties = () => {
        setPropertiesList(Object.values(productSaveService.getProduct().properties)
            .filter(it => it.categoryName === searchParams.get('productTab'))
            .sort(sortByDate));
    };

    useEffect(() => {
        productSaveService.registerItemUpdateObserver(changeProperties);
        changeProperties(productTab);
        return () => productSaveService.unRegisterItemUpdateObserver(changeProperties);
    }, [productTab]);

    const changeSearchValue = (value) => {
        setSearchPropertyValue(value);
    };

    return (
        <>
            <div className="product__tab-property">
                <AddNewPropertyButton />
                <ProductPropertiesSearchInput changeSearchValue={changeSearchValue} />
            </div>
            <ul className={`product__add-list ${productTab === ADD_PRODUCT_PHYSICAL_PROPERTIES ? 'product__add-list_wrap' : ''}`}>
                {propertiesList && propertiesList.length && filterBySearchValueIsExists(propertiesList, searchPropertyValue) ? 
                    <>
                        {propertiesList.filter(it => it.propertyType === PROPERTY_TYPE_BOOLEAN && filterBySearchValue(it, searchPropertyValue)).length ?
                            <li className="product__add-item">
                                <ul className="product__form-list">
                                    {propertiesList.filter(it => it.propertyType === PROPERTY_TYPE_BOOLEAN).map(item => (
                                        <li key={createKeyForElement(item)}>
                                            <ProductPropertyItem propertyId={item.id} />
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            :
                            null
                        }
                        {propertiesList.filter(it => it.propertyType !== PROPERTY_TYPE_BOOLEAN && filterBySearchValue(it, searchPropertyValue)).map(item => (
                            <li key={createKeyForElement(item)} className="product__add-item">
                                <ProductPropertyItem propertyId={item.id}/>
                            </li>
                        ))}
                    </>
                    :
                    searchPropertyValue ? <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')} /> : null}
            </ul>
            <AddPropertyPopup />
        </>
    );
};

export default ProductPropertiesListHandler;
