import React, { useState, useEffect } from 'react';
import { useCalendar } from './useCalendar';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import { ONE_DAY } from '../../constant';

const checkDateIsEqual = (date1, date2) =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

const checkIsToday = (date) => {
    const today = new Date();
    return checkDateIsEqual(today, date);
};

export const createDate = (params) => {
    const locale = params?.locale ?? 'ru-RU';
    const d = params?.date ?? new Date();
    const dayNumber = d.getDate();
    const day = d.toLocaleDateString(locale, { weekday: 'long' });
    const dayNumberInWeek = d.getDay() + 1;
    const dayShort = d.toLocaleDateString(locale, { weekday: 'short' });
    const year = d.getFullYear();
    const yearShort = d.toLocaleDateString(locale, { year: '2-digit' });
    const month = d.toLocaleDateString(locale, { month: 'long' });
    const monthShort = d.toLocaleDateString(locale, { month: 'short' });
    const monthNumber = d.getMonth() + 1;
    const monthIndex = d.getMonth();
    const timestamp = d.getTime();
    const week = getWeekNumber(d);

    return {
        date: d,
        dayNumber,
        day,
        dayNumberInWeek,
        dayShort,
        year,
        yearShort,
        month,
        monthShort,
        monthNumber,
        monthIndex,
        timestamp,
        week,
    };
};

export const createMonth = (params) => {
    const date = params?.date ?? new Date();
    const locale = params?.locale ?? 'default';

    const d = createDate({ date, locale });
    const { month: monthName, year, monthNumber, monthIndex } = d;

    const getDay = (dayNumber) =>
        createDate({ date: new Date(year, monthIndex, dayNumber), locale });

    const createMonthDays = () => {
        const days = [];

        for (let i = 0; i <= getMonthNumberOfDays(monthIndex, year) - 1; i += 1) {
            days[i] = getDay(i + 1);
        }

        return days;
    };

    return {
        getDay,
        monthName,
        monthIndex,
        monthNumber,
        year,
        createMonthDays,
    };
};

export const createYear = (params) => {
    const locale = params?.locale ?? 'default';

    const monthCount = 12;
    const today = createDate();

    const year = params?.year ?? today.year;
    const monthNumber = params?.monthNumber ?? today.monthNumber;

    const month = createMonth({ date: new Date(year, monthNumber - 1), locale });

    const getMonthDays = (monthIndex) =>
        createMonth({ date: new Date(year, monthIndex), locale }).createMonthDays();

    const createYearMonthes = () => {
        const monthes = [];

        for (let i = 0; i <= monthCount - 1; i += 1) {
            monthes[i] = getMonthDays(i);
        }

        return monthes;
    };

    return {
        createYearMonthes,
        month,
        year,
    };
};

export const getMonthNumberOfDays = (
    monthIndex,
    yearNumber = new Date().getFullYear(),
) => new Date(yearNumber, monthIndex + 1, 0).getDate();

export const getMonthesNames = (locale = 'ru-RU') => {
    const monthesNames = [];

    for(let i = 12; i > 0; i--) {
        monthesNames.push({
            month: 'month',
            monthShort: 'monthShort',
            monthIndex: 'monthIndex',
            date: 'date',
        });
    }

    const d = new Date();

    monthesNames.forEach((_, i) => {
        const { month, monthIndex, monthShort, date } = createDate({
            locale,
            date: new Date(d.getFullYear(), d.getMonth() + i, 1),
        });

        monthesNames[monthIndex] = { month, monthIndex, monthShort, date };
    });

    return monthesNames;
};


export const getWeekDaysNames = (firstWeekDay = 4, locale = 'ru-RU') => {
    const weekDaysNames = [];

    for(let i = 7; i > 0; i--) {
        weekDaysNames.push({
            day: ['day'],
            dayShort: ['dayShort'],
        });
    }

    const date = new Date();

    weekDaysNames.forEach((_, i) => {
        const { day, dayNumberInWeek, dayShort } = createDate({
            locale,
            date: new Date(date.getFullYear(), date.getMonth(), date.getDate() + i),
        });

        weekDaysNames[dayNumberInWeek - 1] = { day, dayShort };
    });

    return [...weekDaysNames.slice(firstWeekDay - 1), ...weekDaysNames.slice(0, firstWeekDay - 1)];
};

export const getWeekNumber = (date) => {
    const firstDayOfTheYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date.getTime() - firstDayOfTheYear.getTime()) / 86400000;

    return Math.ceil((pastDaysOfYear + firstDayOfTheYear.getDay() + 1) / 7);
};

const findDayBetweenStartAndDay = (day, startDate, endDate) => {
    let result = false;
    if(checkDateIsEqual(day.date, startDate)) {
        result = true;
    } 
    if(endDate && checkDateIsEqual(day.date, endDate)) {
        result = true;
    }
    
    if(day.date > startDate && day.date < endDate) {
        result = true;
    }
    return result;
};

const Calendar = ({
    locale = 'ru-RU',
    selectedDate: date,
    currentDate,
    setCurrentDate,
    firstWeekDayNumber = 2,
}) => {
    const { functions, state } = useCalendar({
        locale,
        selectedDate: date,
        firstWeekDayNumber,
    });
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if(currentDate.startDate && !checkDateIsEqual(new Date(currentDate.startDate), state.selectedDay.date)) {
            const date = new Date(currentDate.startDate);
            const preparedData = createDate({ date, locale });
            functions.setSelectedDay(preparedData);
        }
        if(!currentDate.startDate) {
            setCurrentDate({...currentDate, startDate: state.selectedDay.timestamp });
        }
        if(currentDate.endDate) {
            const date = new Date(currentDate.endDate);
            const preparedData = createDate({ date, locale });
            functions.setEndDay(preparedData);
        }
    },[]);

    useEffect(() => {
        if(firstRender) {
            setFirstRender(false);
        } else {
            if(!currentDate.startDate || currentDate.startDate > state.selectedDay.timestamp) {
                setCurrentDate({...currentDate, startDate: state.selectedDay.timestamp, id: 'today',
                    endDate:currentDate.endDate ? currentDate.endDate : state.selectedDay.timestamp + ONE_DAY - 60 * 1000, 
                });
            } else if(currentDate.startDate === state.selectedDay.timestamp) {
                setCurrentDate({...currentDate, startDate: '', endDate:''});
            } else {
                setCurrentDate({...currentDate, endDate: state.selectedDay.timestamp, 
                    id:(state.selectedDay.timestamp - currentDate.startDate) / ONE_DAY > 2 ? 'week' : 'today'});
                functions.setEndDay(state.selectedDay);
            }
        }
    }, [state.selectedDay]);

    return (
        <>
            <div className='calendar__header'>                
                <div aria-hidden onClick={() => functions.onClickArrow('left')}>
                    <ChevronIcon nameOfClass='calendar__header-arrow_left'/>
                </div>
                <h2 className="block__content calendar__title">
                    {state.monthesNames[state.selectedMonth.monthIndex].month} {state.selectedYear}
                </h2>
                <div aria-hidden onClick={() => functions.onClickArrow('right')}>
                    <ChevronIcon nameOfClass='calendar__header-arrow_right'/>
                </div>
            </div>
            <div className='calendar__body'>
                <div className='block__text calendar__days'>
                    {state.calendarDays.map((day) => {
                        const isToday = checkIsToday(day.date);
                        const isSelectedDay = findDayBetweenStartAndDay(day, new Date(currentDate.startDate), new Date(currentDate.endDate));
                        const isAdditionalDay = day.monthIndex !== state.selectedMonth.monthIndex;
                        return (
                            <div 
                                key={`${day.dayNumber}-${day.monthIndex}`}
                                aria-hidden
                                onClick={() => {
                                    functions.setSelectedDay(day);
                                }}
                                className={[
                                    'calendar__day',
                                    isToday ? 'calendar__today' : '',
                                    isSelectedDay ? 'calendar__selected' : '',
                                    isAdditionalDay ? 'calendar__additional' : '',
                                ].join(' ')}
                            >
                                {day.dayNumber}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Calendar;
