import React from 'react';
import { CheckIconThin } from '../../appearence/icons/check_icon_thin';

const Checkbox = ({ checked, changeCheckboxValue }) => {
    return (
        <label className="checkbox__label">
            <input type="checkbox" className={`checkbox__input ${checked ? 'checkbox__input_checked' : ''}`} 
                checked={checked} onChange={(e) => changeCheckboxValue(e.target.checked)}/>
            <CheckIconThin nameOfClass={`checkbox__icon ${checked ? 'checkbox__icon_checked' : ''}`} />
        </label>
    );
};

export default Checkbox;
