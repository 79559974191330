import React, { useEffect } from 'react';
import { ERROR_WRONG_PHONE_TYPE, NON_DIGITS_REG_EXP } from '../../constant';
import { DangerIcon } from '../../appearence/icons/danger_icon';
import { i18n } from '../../i18n';

const PhoneInput = ({ phone, setPhoneInput, error }) => {
    const changePhoneAppearence = (event, cleanAll) => {
        if(cleanAll) {
            document.getElementsByClassName('login__input-phone')[0].value = '';
            return;
        }
        if (!(event.key == 'ArrowLeft' || event.key == 'ArrowRight' || event.key == 'Backspace' || event.key == 'Tab')) { 
            event.preventDefault();
        }
        if(event.key == 'Backspace') {
            setPhoneInput(event.target.value.slice(0, -1).replace(NON_DIGITS_REG_EXP, ''));
        } 
        if(isFinite(+event.key)) {
            setPhoneInput((event.target.value + event.key).replace(NON_DIGITS_REG_EXP, ''));
        }
        const mask = '(111) 111 11 11';
        if (/[0-9\+\ \(\)]/.test(event.key)) {
            let currentString = event.target.value;
            const currentLength = currentString.length;
            if (/[0-9]/.test(event.key)) {
                if (mask[currentLength] == '1') {
                    event.target.value = currentString + event.key;
                } else {
                    for (var i = currentLength; i < mask.length; i++) {
                        if (mask[i] == '1') {
                            event.target.value = currentString + event.key;
                            break;
                        }
                        currentString += mask[i];
                    }
                }
            }
        }
    };

    useEffect(() => {
        if(phone === '') {
            changePhoneAppearence({}, true);
        }
    }, [phone]);

    return (
        <label className="block__text login__label login__label-phone">
            <p className="login__label-text">{i18n.get("public.app.page.login.phone")}</p>
            <input className="block__content login__input login__input-code" value='+7' disabled={true} onChange={() => null}/>
            <input className={`block__content login__input login__input-phone ${error.type === ERROR_WRONG_PHONE_TYPE ? 'login__input_error' : ''}`}
                onKeyDown={changePhoneAppearence} 
                placeholder='(___) ___ __ __' 
                defaultValue={setPhoneInput ? '' : phone} 
                disabled={setPhoneInput ? false : true} />
            {error.type === ERROR_WRONG_PHONE_TYPE ? <DangerIcon nameOfClass="login__icon login__icon-danger"/> : ''}
        </label>
    );
};

export default PhoneInput;
