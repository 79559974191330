import React, { useEffect, useRef, useState } from 'react';
import PreferencePhrasesListContent from './PreferencePhrasesListContent';
import { preferencePhraseItemService } from '../../services/preferencePhraseService';
import { SMALL_PART_PHRASES_COUNT } from '../../constant';

const getPhrasesLength = () => preferencePhraseItemService.getItems().length;

const PreferencePhrasesList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [phrasesListIsChanged, setPhrasesListIsChanged] = useState(null);
    const ref = useRef();
    const scrollPhrasesList = () => {
        const rect = ref.current.getBoundingClientRect();
        if(rect.top < window.innerHeight && !isLoading) {
            setIsLoading(true);
            preferencePhraseItemService.loadItems(preferencePhraseItemService.getItems()[getPhrasesLength() - 1].id, SMALL_PART_PHRASES_COUNT, 
                () => setIsLoading(false));
        }
    };

    const changePhrases = () => {
        setPhrasesListIsChanged(!phrasesListIsChanged);
    };

    useEffect(() => {
        preferencePhraseItemService.registerFiltersChangingObserver(changePhrases);
        return () => {
            preferencePhraseItemService.unRegisterFiltersChangingObserver(changePhrases);
            preferencePhraseItemService.changeSelectedPreferencePhrases('', true);
        };
    }, []);

    return (
        <ul className="preference__list" onScroll={scrollPhrasesList}>
            {preferencePhraseItemService.getItems().map((phrase, index) => (
                <li key={phrase.id} className="block__content preference__phrases-item" 
                    ref={index === getPhrasesLength() - 1 ? ref : null}>
                    <PreferencePhrasesListContent phrase={phrase} />
                </li>
            ))}
        </ul>
    );
};

export default PreferencePhrasesList;
