import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { i18n } from '../../../i18n';
import { SearchIcon } from '../../../appearence/icons/search_icon';

const ProductPropertiesSearchInput = ({ changeSearchValue }) => {
    const [searchPropertyValue, setSearchPropertyValue] = useState('');
    const [searchParams] = useSearchParams();
    const productTab = searchParams.get('productTab');

    const changeSearchInputValue = (e) => {
        changeSearchValue(e.target.value);
        setSearchPropertyValue(e.target.value);
    };

    useEffect(() => {
        setSearchPropertyValue('');
        changeSearchValue('');
    }, [productTab]);

    return (
        <label className="block__content product__label product__search-label product__tab-property-label">
            <SearchIcon nameOfClass="product__input-icon product__input-icon_property" />
            <input className="product__input product__search-input" placeholder={i18n.get("app.page.product.productFormTab.searchProperties")} 
                value={searchPropertyValue || ''} onChange={changeSearchInputValue} />
        </label>
    );
};

export default ProductPropertiesSearchInput;
