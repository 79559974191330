import React, { useState } from 'react';
import LocaleSwitcher from './LocaleSwitcher';
import { LOCALE_RU } from '../../constant';
import ItemNameContent from './ItemNameContent';

const ItemName = ({itemCode, itemSaveService, itemService}) => {
    const [currentLocaleValue, setCurrentLocaleValue] = useState(LOCALE_RU);
    
    const updateLocaleValue = (newLocaleValue) => {
        setCurrentLocaleValue(newLocaleValue);
    };

    return (
        <label className="main__subtitle product__form-label product__form_lowercase item__name">
            <div className="locale__select locale__select_add">
                <LocaleSwitcher localeCode={currentLocaleValue} notifyListener={updateLocaleValue}/>
            </div>
            <ItemNameContent itemCode={itemCode} 
                localeCode={currentLocaleValue} 
                itemSaveService={itemSaveService} 
                itemService={itemService}
            />
        </label>
    );
};

export default ItemName;
