import React from 'react';
import { EditUserIcon } from '../../appearence/icons/edit_user_icon';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_ASSIGN_USER } from '../../constant';
import { preferencesStorage } from '../../services/preferencesStorage';

const PreferencesListItemUsers = ({ preferenceId }) => {
    const openAssignPreferenceToUserPopup = () => {
        preferencesStorage.changeSelectedPreferencesList('', true);
        preferencesStorage.changeSelectedPreferencesList(preferenceId);
        popupOpenService.callToOpen(POPUP_KEY_ASSIGN_USER);
    };

    return (
        <p className="preference__item-count" onClick={openAssignPreferenceToUserPopup}>
            <EditUserIcon nameOfClass="preference__list-icon preference__item-icon" />
            0
        </p>
    );
};

export default PreferencesListItemUsers;
