import React, { useState, useEffect } from 'react';
import { SAFARI_BROWSER, YANDEX_BROWSER } from '../../constant';
import { i18n } from '../../i18n';
import { ERROR_TYPE, loginManager } from './loginManager';
import PasswordMessage from './PasswordMessage';

const checkIsYandexOrSafariBrowser = () => {
    return window.navigator.userAgent.includes(YANDEX_BROWSER) || window.navigator.userAgent.includes(SAFARI_BROWSER);
};

const PasswordField = ({label, passwordType}) => {
    const [error, setError] = useState({type: '', message: '', key: ''});
    const [passwordIsShown, setPasswordIsShown] = useState(false);
    const [password, setPassword] = useState('');

    const changeMessage = () => {
        setError(loginManager.getError());
    };

    const changePasswordField = (e) => {
        setPassword(e.target.value);
        loginManager.updatePasswordValue(passwordType, e.target.value);
    };

    const changePasswordVisibility = () => {
        setPasswordIsShown(!passwordIsShown);
    };
    
    useEffect(() => {
        loginManager.registerLoginUpdateObserver(changeMessage);
        changeMessage();
        return () => {
            loginManager.unRegisterLoginUpdateObserver(changeMessage);
        };
    }, []);

    return (
        <label className="block__text password__label" 
            autoComplete="off"
            key={label}
            name={label}>
            {label}
            {checkIsYandexOrSafariBrowser() ?
                <input type="password" autoComplete="off" style={{display: 'none'}} />
                :
                null}
            <input 
                type={!passwordIsShown && password ? "password" : "text"} 
                value={password} onChange={changePasswordField} 
                autoComplete="off"
                className={`block__content password__input 
                    ${error.key.includes(passwordType) && error.type === ERROR_TYPE ? 'password__input_error' : ''}`} />
            {password ? 
                <span className="block__bullet password__text" onClick={changePasswordVisibility} >
                    {passwordIsShown ? i18n.get('public.app.page.login.hide') : i18n.get('public.app.page.login.show')}
                </span>
                :
                null}
            <PasswordMessage passwordType={passwordType} />
        </label>
    );
};

export default PasswordField;
