import React, { useState, useEffect } from 'react';
import { FAT_SOLUBLE_VITAMIN_TYPE, WATER_SOLUBLE_VITAMIN_TYPE, propertySaveService } from '../../../services/propertySaveService';
import { i18n } from '../../../i18n';
import RadioLabel from '../../RadioLabel';
import Switch from '../../sharedComponents/Switch';
import PropertyItemVitaminTypeError from './PropertyItemVitaminTypeError';
import { ADD_PRODUCT_MICRO_ELEMENTS } from '../../helper';

const createVitaminTypeLabelName = (vitaminType) => {
    return i18n.get(`app.page.product.propertyItem.${vitaminType}`);
};

const PropertyItemVitaminSlider = () => {
    const [isVitamin, setIsVitamin] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isWaterSoluble, setIsWaterSoluble] = useState(false);

    const changePropertyData = () => {
        setIsVitamin(!isVitamin);
        propertySaveService.updatePropertyValue('isVitamin', !isVitamin);
    };

    const changePropertyIsVitaminState = (value, type) => {
        setIsWaterSoluble(type === WATER_SOLUBLE_VITAMIN_TYPE ? value : !value);
        propertySaveService.changeVitaminType(type === WATER_SOLUBLE_VITAMIN_TYPE ? value : !value);
    };

    const openVitaminsType = () => {
        const property = propertySaveService.getProperty();
        setIsVitamin(property.isVitamin);
        setIsVisible(property.categoryName === ADD_PRODUCT_MICRO_ELEMENTS);
        setIsWaterSoluble(property.isWaterSoluble);
    };

    useEffect(() => {
        propertySaveService.registerItemUpdateObserver(openVitaminsType);
        return () => propertySaveService.unRegisterItemUpdateObserver(openVitaminsType);
    }, []);

    return (
        isVisible ? 
            <>
                <label className="block__text product__form-label_inline">
                    <Switch value={isVitamin} changeIsVitaminValue={changePropertyData}/>
                    <span className="product__form-label_text">{i18n.get('app.page.product.propertyItem.vitamin')}</span>
                </label>
                {isVitamin ? 
                    <label className="block__text product__form-label product__form-item">
                        <span className="product__form-label_text">{i18n.get('app.page.product.propertyItem.type')}</span>
                        <RadioLabel 
                            value={isWaterSoluble === true} 
                            type={WATER_SOLUBLE_VITAMIN_TYPE}
                            title={createVitaminTypeLabelName(WATER_SOLUBLE_VITAMIN_TYPE)} 
                            changeValue={changePropertyIsVitaminState}
                        />
                        <RadioLabel 
                            value={isWaterSoluble === false} 
                            type={FAT_SOLUBLE_VITAMIN_TYPE}
                            title={createVitaminTypeLabelName(FAT_SOLUBLE_VITAMIN_TYPE)} 
                            changeValue={changePropertyIsVitaminState}
                        />
                    </label>
                    :
                    null}
                {isVitamin && propertySaveService.getProperty().isWaterSoluble === undefined ?
                    <PropertyItemVitaminTypeError />
                    :
                    null}
            </>
            :
            null
    );
};

export default PropertyItemVitaminSlider;
