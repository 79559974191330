import React, { useState } from "react";
import Header from "./Header";
import MainPage from "./MainPage";
import { TABLET_WIDTH } from "../constant";
import useWindowDimensions from "../hooks/useWindowDimension";

const WithWrapper = (Component, setAccessToken) => {
    const WrappedComponent = (props) => {
        const [menuIsOpen, setMenuIsOpen] = useState(false);
        const { width } = useWindowDimensions();

        return (
            <>
                {width <= TABLET_WIDTH ? <Header setMenuIsOpen={setMenuIsOpen} menuIsOpen={menuIsOpen}/> : null}
                <main className="main">
                    {(width <= TABLET_WIDTH && menuIsOpen) || width > TABLET_WIDTH ? 
                        <MainPage menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} setAccessToken={setAccessToken}/>
                        :
                        null}
                    <Component {...props}/>
                </main>
            </>
        );
    };
    return WrappedComponent;
};

export default WithWrapper;
