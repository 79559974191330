import React, { useState, useEffect } from 'react';
import SearchInput from './SearchInput';
import OpenFilterButton from './OpenFilterButton';
import FiltersListDropdown from './FiltersListDropdown';
import { filtersListIsOpenStateObject } from './FiltersListIsOpenStateObject';

const SearchItemBlock = ({ itemsStorageService, placeholder }) => {
    const [filtersListIsOpen, setFiltersListIsOpen] = useState(false);

    const changeFiltersListState = (value) => {
        setFiltersListIsOpen(value);
    };

    useEffect(() => {
        filtersListIsOpenStateObject.registerFiltersListObserver(changeFiltersListState);
        return () => filtersListIsOpenStateObject.unRegisterFiltersListObserver(changeFiltersListState);
    }, []);

    return (
        <>
            <article className={`product__article product__article-list product__search ${filtersListIsOpen ? 'product__article-list_active' : ''}`}>
                <SearchInput itemsStorageService={itemsStorageService} placeholder={placeholder}/>
                <OpenFilterButton itemsStorageService={itemsStorageService} />
            </article>
            <FiltersListDropdown itemsStorageService={itemsStorageService} />
        </>
    );
};

export default SearchItemBlock;
