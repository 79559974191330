import { i18n } from "../i18n";
import { preferenceSaveService } from "./preferenceSaveService";
import { preferencesGroupsService } from "./preferencesGroupsService";
import { preferencesGroupsStorage } from "./preferencesGroupsStorage";

const transformSelectValuesList = (groupsList) => {
    let resultArray = [];
    if(groupsList && groupsList.length) {
        groupsList.forEach(it => resultArray.push({itemId: it.id, 
            itemTitle: preferencesGroupsService.getLocalization(i18n.APP_LOCALE, it.name, false), included: true}));
    }
    return resultArray;
};

export class preferenceGroupSelectService {
    observersList = [];
    filter = '';
    possibleItems = [];
    selectedItems = transformSelectValuesList(preferenceSaveService.getPreference().groups);

    getPossibleItems() {
        this.possibleItems = transformSelectValuesList(preferencesGroupsStorage.getItems())
            .filter(it => it.itemTitle.toLowerCase().includes(this.filter.toLowerCase()));
        return this.possibleItems;
    }

    getCurrentItems() {
        try {
            return this.selectedItems;
        } catch (err) {
            return [];
        }
    }

    deleteItem(itemId) { 
        this.selectedItems = this.selectedItems.filter(it => it.itemId !== itemId);
        preferenceSaveService.updatePreferencePropertyGroup(preferencesGroupsStorage.getById(itemId));
        this.notifyItemsChangingObservers();
    }

    addItem(itemId) {
        this.selectedItems.push(this.possibleItems.find(it => it.itemId === itemId));
        preferenceSaveService.updatePreferencePropertyGroup(preferencesGroupsStorage.getById(itemId));
        this.notifyItemsChangingObservers();
    }

    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    }

    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    }

    hasExcludedItemsBlock() {
        return false;
    }

    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    }

    getSearchString() {
        return this.filter;
    }

    getPopupKey() {
        return '';
    }

    getButtonTitle() {
        return '';
    }
}

export const createPreferenceSelectGroupService = () => {
    return new preferenceGroupSelectService();
};
