import React from 'react';
import { PlusIcon } from '../../appearence/icons/plus_icon';
import { popupOpenService } from '../../services/popupOpenService';

const AddNewFormButton = ({ selectWithTokensManager }) => {
    const handleAddNewSource = () => {
        popupOpenService.callToOpen(selectWithTokensManager.getPopupKey());
    };

    return (
        <div className="block__text product__forms-add" onClick={handleAddNewSource}>
            <span className="product__forms-content">{selectWithTokensManager.getButtonTitle()}</span>
            <PlusIcon nameOfClass="product__forms-plus" />
        </div>
    );
};

export default AddNewFormButton;
