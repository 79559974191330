import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorHandler } from '../ErrorHandler';
import { i18n } from '../../i18n';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import PreferenceGroupsListItem from './PreferenceGroupsListItem';

const PreferenceGroupsList = () => {
    const [searchParams] = useSearchParams();
    const [preferenceGroupsListIsChanged, setPreferenceGroupsListIsChanged] = useState(null);

    const changePreferenceGroups = () => {
        setPreferenceGroupsListIsChanged(prev => !prev);
    };

    useEffect(() => {
        preferencesGroupsStorage.loadItems(() => {});
        preferencesGroupsStorage.getInitialFilters(searchParams);
        preferencesGroupsStorage.registerFiltersChangingObserver(changePreferenceGroups);
        return () => {
            preferencesGroupsStorage.unRegisterFiltersChangingObserver(changePreferenceGroups);
            preferencesGroupsStorage.changeSelectedPreferenceGroups('', true);
        };
    }, []);
    return (
        preferencesGroupsStorage.getItems().length && preferenceGroupsListIsChanged !== null ?   
            <>  
                <ul className="preference__list">
                    {preferencesGroupsStorage.getItems().map((group, index) => (
                        <React.Fragment key={group.id + index}>
                            <PreferenceGroupsListItem group={group}/>
                        </React.Fragment>
                    ))}
                </ul>
            </>  
            :
            <ErrorHandler isSmall={true} titleValue={i18n.get('app.page.profile.emptyData')} />
    );
};

export default PreferenceGroupsList;
