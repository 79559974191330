import React, { useEffect, useState, useRef } from 'react';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';
import PhrasesListItem from './PhrasesListItem';
import PreferencesListSelectBlock from './PreferencesListSelectBlock';
import { DEFAULT_PHRASES_COUNT, SMALL_PART_PHRASES_COUNT } from '../../constant';
import DeletePhrasePopup from './DeletePhrasePopup';

const getPhrasesLength = () => preferencesPhrasesStorage.getPhrases().length;

const PhrasesList = () => {
    const [phrasesListIsChanged, setPhrasesListIsChanged] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const ref = useRef();

    const changePhrases = () => {
        setPhrasesListIsChanged(prev => !prev);
    };

    const scrollPhrasesList = () => {
        const rect = ref.current.getBoundingClientRect();
        if(rect.top < window.innerHeight && !isLoading) {
            setIsLoading(true);
            preferencesPhrasesStorage.loadNextPhrases(preferencesPhrasesStorage.getPhrases()[getPhrasesLength() - 1].id, SMALL_PART_PHRASES_COUNT, 
                () => setIsLoading(false));
        }
    };

    useEffect(() => {
        preferencesPhrasesStorage.loadNextPhrases('', DEFAULT_PHRASES_COUNT, changePhrases);
        preferencesPhrasesStorage.registerFiltersChangingObserver(changePhrases);
        return () => {
            preferencesPhrasesStorage.unRegisterFiltersChangingObserver(changePhrases);
            preferencesPhrasesStorage.changeSelectedPhrasesList('', true);
        };
    }, []);

    return (
        <>
            <DeletePhrasePopup notifyPhrasesList={changePhrases} />
            <ul className="preference__list" onScroll={scrollPhrasesList}>
                {preferencesPhrasesStorage.getPhrases().map((phrase, index) => (
                    <li key={phrase.id} 
                        className="block__content preference__phrases-item" 
                        ref={index === preferencesPhrasesStorage.getPhrases().length - 1 ? ref : null}>
                        <PhrasesListItem preferencePhraseId={phrase.id}/>
                    </li>
                ))}
            </ul> 
            {preferencesPhrasesStorage.getSelectedList().length && phrasesListIsChanged !== null ? 
                <PreferencesListSelectBlock phrasesListManager={preferencesPhrasesStorage} /> 
                :
                null}
        </>
    );
};

export default PhrasesList;
