import React, { useEffect, useState } from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { i18n } from '../../i18n';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import AssignPreferenceGroupsListItem from './AssignPreferenceGroupsListItem';

const getCheckedValue = () => {
    return preferencesGroupsStorage.getSelectedList().length === preferencesGroupsStorage.getItems().length && 
        preferencesGroupsStorage.getSelectedList().length !== 0;
};

const AssignPreferenceGroupsList = () => {
    const [groupsListIsChanged, setGroupsListIsChanged] = useState(false);

    const changeGroupsList = () => {
        setGroupsListIsChanged(prev => !prev);
    };

    useEffect(() => {
        preferencesGroupsStorage.registerFiltersChangingObserver(changeGroupsList);
        return () => preferencesGroupsStorage.unRegisterFiltersChangingObserver(changeGroupsList);
    }, []);
    return (
        <div className="scroll preference__modal-wrapper">
            <table className="block__table preference__modal">
                <thead>
                    <tr className="preference__modal-row preference__modal-row_header">
                        <th className="preference__modal-item preference__modal-header preference__modal-header_small">
                            <CustomCheckbox 
                                checked={getCheckedValue()} 
                                handleChange={() => preferencesGroupsStorage.changeSelectedPreferenceGroups()} 
                            />
                        </th>
                        <th className="preference__modal-item preference__modal-header">{i18n.get('app.page.preference.groupItemName')}</th>
                    </tr>
                </thead>
                <tbody>
                    {preferencesGroupsStorage.getItems().map(it => (
                        <React.Fragment key={it.id + it.name + groupsListIsChanged}>
                            <AssignPreferenceGroupsListItem groupId={it.id} />
                        </React.Fragment>
                    ))}

                </tbody>
            </table>
        </div> 
    );
};

export default AssignPreferenceGroupsList;
