import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChangeIcon } from '../../../appearence/icons/change_icon';
import { MOUSE_DOWN_EVENT } from '../../../constant';
import UpdateSeveralPropertiesActionsList from './UpdateSeveralPropertiesActionsList';

const UpdateSeveralPropertiesIcon = ({propertyId}) => {
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const wrapperRef = useRef();

    const closeDropdown = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setDropdownIsOpen(false);
        }
    }, [dropdownIsOpen]);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, closeDropdown);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, closeDropdown);
        };
    }, [closeDropdown]);

    const showDropdown = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDropdownIsOpen(!dropdownIsOpen);
    };

    return (
        <div className="property__update-wrapper" ref={wrapperRef}>
            <p onClick={showDropdown}>
                <ChangeIcon nameOfClass="property__update-icon"/>
            </p>
            {dropdownIsOpen ? <UpdateSeveralPropertiesActionsList propertyId={propertyId}/> : null}
        </div>
    );
};

export default UpdateSeveralPropertiesIcon;
