import { errorCallback, makeUrl, successCallbackEmpty } from "../constant";
import { PHRASE_URL_PART, PREFERENCE_URL, getFetchRequest, postFetchRequest } from "../store/requests";

const createQueryParams = (sortingDirection, count, lastId) => {
    let result = '?sorting=dateCreated:desc';
    if(sortingDirection) {
        result = `?sorting=vote:${sortingDirection},dateCreated:desc`;
    }
    if(count) {
        result += `&count=${count}`;
    }
    if(lastId) {
        result += `&lastId=${lastId}`;
    }
    return result;
}; 

class PreferencePhraseService {
    phrasesList = [];
    preferenceId = '';
    observersList = [];
    selectedItems = [];
    sortingDirection = '';

    constructor (preferenceId) {
        this.preferenceId = preferenceId;
    }

    loadItems(lastId, count, callback) {
        if(this.preferenceId) {
            getFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, createQueryParams(this.sortingDirection, count, lastId)]),
                (response) => {
                    this.phrasesList = lastId ? [...this.phrasesList, ...response] : response;
                    callback && callback(response);
                    this.notifyObservers();
                }, errorCallback);
        }
    }

    getItems() {
        return this.phrasesList ? this.phrasesList : [];
    }

    getSelectedList() {
        return this.selectedItems;
    }

    changeSelectedPreferencePhrases(phraseId, cleanAll) {
        if(phraseId) {
            if(this.selectedItems.find(it => it.id === phraseId)) {
                this.selectedItems = this.selectedItems.filter(it => it.id !== phraseId);
            } else {
                this.selectedItems.push(this.phrasesList.find(it => it.id === phraseId));
            }
        } else {
            if((this.phrasesList.length === this.selectedItems.length && this.selectedItems.length !== 0) || cleanAll) {
                this.selectedItems = []; 
            } else {
                this.selectedItems = this.phrasesList;
            }
        }
        this.notifyObservers();
    }

    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    }

    notifyObservers() {
        this.observersList.forEach(observer => observer());
    }

    getById(phraseId) {
        return this.phrasesList.find(it => it.id === phraseId);
    }

    updatePreferenceIsWelcome(isWelcome) {
        this.selectedItems.forEach(it => {
            it.isWelcome = isWelcome;
            postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, it.id, '/']), 
                {isWelcome}, successCallbackEmpty, errorCallback, true);
            this.phrasesList.forEach(phrase => it.id === phrase.id ? it.isWelcome = isWelcome : null);
        });
        this.notifyObservers();
    }

    updatePreferenceVote(phraseId, value) {
        const currentPhrase = this.phrasesList.find(it => it.id === phraseId);
        currentPhrase.vote = value;
        postFetchRequest(makeUrl([PREFERENCE_URL, this.preferenceId, PHRASE_URL_PART, phraseId, '/']), {vote: value}, 
            successCallbackEmpty, errorCallback, true);
        this.notifyObservers();
    }

    changeSortingDirection(sortingDirection) {
        this.sortingDirection = sortingDirection;
        this.loadItems();
    }
}

export let preferencePhraseItemService = new PreferencePhraseService();

export const createPreferencePhraseService = (preferenceId) => {
    preferencePhraseItemService.phrasesList = [];
    preferencePhraseItemService.selectedItems = [];
    preferencePhraseItemService.preferenceId = preferenceId;
    preferencePhraseItemService.sortingDirection = '';
};
