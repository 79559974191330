import { sortByDate } from "../components/helper";
import { FILTER_TYPE_CHECKBOX, errorCallback, makeUrl, successCallbackEmpty } from "../constant";
import { i18n } from "../i18n";
import { PHRASE_URL_PART, PREFERENCE_URL, getFetchRequest, postFetchRequest } from "../store/requests";

const createListOfFilters = () => {
    return [
        {
            filterTitle: i18n.get('preference.scheme.include.true'),
            filterName: 'include.true',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'include',
        },
        {
            filterTitle: i18n.get('preference.scheme.include.false'),
            filterName: 'include.false',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'include',
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.product'),
            filterName: 'preferenceType.product',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'preferenceType',
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.recipe'),
            filterName: 'preferenceType.recipe',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'preferenceType',
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.property'),
            filterName: 'preferenceType.property',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'preferenceType',
        },
        {
            filterTitle: i18n.get('preference.scheme.groups.base'),
            filterName: 'groups.base',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'group',
        },
        {
            filterTitle: i18n.get('preference.scheme.groups.season'),
            filterName: 'groups.season',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'group',
        },
        {
            filterTitle: i18n.get('preference.scheme.groups.collection'),
            filterName: 'groups.collection',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'group',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.morning'),
            filterName: 'when.morning',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.after-morning'),
            filterName: 'when.after-morning',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.afternoon'),
            filterName: 'when.afternoon',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.before-evening'),
            filterName: 'when.before-evening',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.evening'),
            filterName: 'when.evening',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.night'),
            filterName: 'when.night',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.when.no-matter'),
            filterName: 'when.no-matter',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'when',
        },
        {
            filterTitle: i18n.get('preference.scheme.isInternal.true'),
            filterName: 'isInternal.true',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'isInternal',
        },
        {
            filterTitle: i18n.get('preference.scheme.isInternal.false'),
            filterName: 'isInternal.false',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'isInternal',
        },
    ];
};

const findSearchValueInProperty = (property, titleFilter) => {
    if(i18n.get(`preference.scheme.preferenceType.${property.preferenceType}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    if(i18n.get(`recipe.scheme.recipeType.${property.preferenceItemCode}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    if(i18n.get(`preference.scheme.when.${property.when}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    return false;
};

export const preferencesStorage = {
    preferencesList: [],
    filtersList: [],
    searchValue: '',
    observersList: [],
    possibleFilters: [],
    selectedItems: [],
    loadItems(callback) {
        try {
            getFetchRequest(makeUrl([PREFERENCE_URL]), (response) => {
                this.preferencesList = response.sort(sortByDate);
                callback && callback();
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    getItems() {
        let filteredList = [...this.preferencesList];
        if(this.searchValue) {
            filteredList = this.preferencesList.filter(it => findSearchValueInProperty(it, this.searchValue));
        }
        if(this.filtersList.length) {
            filteredList = this.preferencesList.filter(it => 
                this.filtersList.findIndex(filter => String(it[filter.filterName.split('.')[0]]) == filter.filterName.split('.')[1]) >= 0);
        }
        return filteredList;
    },
    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value, key) => {
            const currentFilter = this.possibleFilters.find(it => it.filterName === key);
            if(currentFilter.filterType === FILTER_TYPE_CHECKBOX && value === 'true') {
                currentFilter.value = value;
                filtersList.push(currentFilter);
            }
        });
        this.filtersList = filtersList;
    },
    getPossibleFilters() {
        this.possibleFilters = createListOfFilters();
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    getFilter(filterName) {
        return this.possibleFilters.find(it => it.filterName === filterName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedKeys) {
        const currentFilter = this.filtersList.find(it => it.filterName === filterName);
        if(currentFilter) {
            currentFilter.value = selectedKeys;
        } else {
            const possibleFilter = this.possibleFilters.find(it => it.filterName === filterName);
            possibleFilter.value = selectedKeys;
            this.filtersList.push(possibleFilter);
        }
        this.filtersList = this.filtersList.filter(it => 
            it.filterType !== FILTER_TYPE_CHECKBOX || (it.filterType === FILTER_TYPE_CHECKBOX && it.value == true));
    },
    setNumberFilterValue() {
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.possibleFilters.forEach((it, index) => {
            if(it.filterType === FILTER_TYPE_CHECKBOX) {
                this.possibleFilters[index].value = false;
            }
        });
        this.notifyFiltersChangingObservers();
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getSelectedPreferencesList() {
        return this.selectedItems;
    },
    changeSelectedPreferencesList(preferenceId, cleanAll) {
        if(preferenceId) {
            if(this.selectedItems.length && this.selectedItems.find(it => it.id === preferenceId)) {
                this.selectedItems = this.selectedItems.filter(it => it.id !== preferenceId);
            } else {
                if(this.preferencesList.find(it => it.id === preferenceId)) {
                    this.selectedItems.push(this.preferencesList.find(it => it.id === preferenceId));
                } else {
                    this.selectedItems.push(this.getPreferencePhraseById(preferenceId));
                }
            }
        } else {
            if((this.preferencesList.length === this.selectedItems.length && this.selectedItems.length !== 0) || cleanAll) {
                this.selectedItems = []; 
            } else {
                this.selectedItems = this.preferencesList;
            }
        }
        this.notifyFiltersChangingObservers();
    },
    generatePreferencePhrases(preferenceId) {
        postFetchRequest(makeUrl([PREFERENCE_URL,preferenceId, PHRASE_URL_PART]), null, successCallbackEmpty, errorCallback);
    },
    getPreferencePhrases(preferenceId, isRecreate) {
        const preference = this.preferencesList.find(it => it.id === preferenceId);
        if(preference.phrase && !isRecreate) {
            return preference.phrase;
        } else {
            getFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, PHRASE_URL_PART]), (response) => {
                preference.phrase = response;
                this.notifyFiltersChangingObservers();
            }, errorCallback);
            return [];
        }
    },
    getPreferencePhrasesCount(preferenceId, callback) {
        getFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, PHRASE_URL_PART, '?onlyCount=true']), (response) => {
            this.preferencesList.forEach(it => it.id === preferenceId ? it.count = response.count : null);
            callback(response);
        }, errorCallback);
    },
    getById(preferenceId) {
        return this.preferencesList.find(it => it.id === preferenceId);
    },
    clearService() {
        this.preferencesList = [];
        this.notifyFiltersChangingObservers();
    },
    reloadPagesNumber() {
    },
    changeDeletedItemsView() {
    },
    changeValidItemsView() {
    },
};
