import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MODAL_SMALL_SIZE } from '../helper';
import { POPUP_KEY_ADD_INGREDIENT_TO_RECIPE, PREFERENCE_TYPE_CEREAL_MIX, PREFERENCE_TYPE_PRODUCT, RECIPE_INGREDIENT_TYPE_PRODUCT } from '../../constant';
import { i18n } from '../../i18n';
import IngredientHandler from './IngredientHandler';
import IngredientTypeSwitcher from './IngredientTypeSwitcher';
import Modal from '../Modal';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { recipeAddIngredientService } from '../../services/recipeAddIngredientsService';
import { productsStorage } from '../../services/productsStorage';
import { recipeSaveService } from '../../services/recipeSaveService';
import { preferenceCerealMixSelectService, prepareProductCodes } from '../../services/preferenceCerealMixSelectService';

const AddIngredientModalContent = ({handleClick, handleClose, changeIngredientType}) => {
    return (
        <div className="recipe__modal">
            <h2 className="modal__title modal__title_align block__title-h3 recipe__ingredient-title ">{i18n.get('app.page.recipe.modal.addIngredient.title')}</h2>
            <IngredientTypeSwitcher changeIngredientType={changeIngredientType}/>
            <label className="block__text recipe__form-label recipe__form-modal" >
                <span className="product__form-label_text">
                    {recipeAddIngredientService.getIngredientType() === PREFERENCE_TYPE_CEREAL_MIX ? 
                        i18n.get('preference.scheme.preferenceType.cereal-mix')
                        : 
                        i18n.get('app.page.recipe.product')}
                </span>
                <IngredientHandler selectWithTokensManager={recipeAddIngredientService} /> 
            </label>
            <div className="block__button modal__button-group">
                <button className="block__button button button_outline" onClick={handleClose}>
                    <span className="button__content">{i18n.get('app.page.recipe.modal.cancel')}</span>
                </button>
                <button className={`button ${handleClick ? '' : 'button_disabled'}`}
                    onClick={handleClick}>
                    <span className="button__content">{i18n.get('app.page.recipe.modal.addModal.add')}</span>
                </button>
            </div>
        </div>
    );
};

const AddNewIngredientPopup = () => {
    const [isVisible, setIsVisible] = useState('');
    const [ingredient, setIngredient] = useState({});
    const [searchParams] = useSearchParams();
    const ingredientTab = searchParams.get('ingredientTab');

    const changePopupVisibility = () => {
        setIsVisible(true);
    };

    const closePopup = () => {
        setIsVisible(false);
        recipeAddIngredientService.deleteItem('', true);
        recipeAddIngredientService.changeIngredientType(PREFERENCE_TYPE_PRODUCT);
        recipeAddIngredientService.setSearchFilterValue('');
        preferenceCerealMixSelectService.setSearchFilterValue('');
    };

    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_ADD_INGREDIENT_TO_RECIPE, changePopupVisibility);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_ADD_INGREDIENT_TO_RECIPE, changePopupVisibility);
    }, []);

    const changeIngredientType = (value) => {
        setIngredient({...ingredient, ingredientType: value});
        recipeAddIngredientService.changeIngredientType(value);
    };

    const saveIngredient = () => {
        if(recipeAddIngredientService.getCurrentItems() && recipeAddIngredientService.getCurrentItems().length) {
            if(ingredient.ingredientType === PREFERENCE_TYPE_CEREAL_MIX) {
                const preparedData = {
                    ingredientType: PREFERENCE_TYPE_CEREAL_MIX,
                    ingredientGroup: ingredientTab,
                    ingredientParts: prepareProductCodes(recipeAddIngredientService.getCurrentItems()),
                };
                recipeSaveService.addIngredient(preparedData);
                recipeAddIngredientService.deleteItem('', true);
            } else {
                for(let ingredientData of recipeAddIngredientService.getCurrentItems()) {
                    const preparedData = {
                        ingredientType: ingredient.ingredientType ? ingredient.ingredientType : RECIPE_INGREDIENT_TYPE_PRODUCT,
                        ingredientGroup: ingredientTab,
                        ingredientParts: [{
                            productCode: productsStorage.getById(ingredientData.itemId).productCode,
                            ingredientForm: [],
                        }],
                    };
                    recipeSaveService.addIngredient(preparedData);
                    recipeAddIngredientService.deleteItem('', true);
                }
            }
            setIsVisible(false);
        }
    };

    return (
        isVisible ?
            <Modal handleCloseModal={closePopup} size={MODAL_SMALL_SIZE}
                content={<AddIngredientModalContent handleClose={closePopup} changeIngredientType={changeIngredientType} handleClick={saveIngredient}/>} 
            />
            :
            null
    );
};

export default AddNewIngredientPopup;
