import React, { useState, useEffect } from 'react';
import { POPUP_KEY_DELETE_PREFERENCE_GROUP, errorCallback, makeUrl } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { PREFERENCE_GROUP_URL, deleteFetchRequest } from '../../store/requests';
import { MODAL_SMALL_SIZE } from '../helper';
import Modal from '../Modal';
import { i18n } from '../../i18n';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { preferencesGroupsService } from '../../services/preferencesGroupsService';

const createDeletedItemsName = () => {
    const result = [];
    preferencesGroupsStorage.getSelectedList().forEach(it => result.push(preferencesGroupsService.getLocalization(i18n.APP_LOCALE, it.name, false)));
    return result.join(', ');
};

const DeleteModalContent = ({ handleDelete, handleClose }) => (
    <>
        <h2 className="modal__title modal__title_align block__title-h3 block__title-h3">{i18n.get('app.page.preference.groups.modal.title')}</h2>
        <p className="block__content modal__text modal__text_align">{i18n.get('app.page.preference.groups.modal.content')} {createDeletedItemsName()}</p>
        <div className="block__button modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>{i18n.get('app.page.preference.modal.cancel')}</button>
            <button className="block__button button" onClick={handleDelete}>{i18n.get('app.page.preference.modal.delete')}</button>
        </div>
    </>
);

const DeletePreferenceGroupPopup = ({ notifyPreferencesGroupsList }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_PREFERENCE_GROUP, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_PREFERENCE_GROUP, handleOpenModal);
    },[]);

    const deletePreferenceGroup = () => {        
        setIsOpen(null);
        const selectedList = preferencesGroupsStorage.getSelectedList();
        for(let group of selectedList) {
            deleteFetchRequest(makeUrl([PREFERENCE_GROUP_URL, group.id, '/']), () => notifyPreferencesGroupsList(), errorCallback);
        }  
        preferencesGroupsStorage.changeSelectedPreferenceGroups('', true);
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deletePreferenceGroup} />} 
                handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeletePreferenceGroupPopup;
