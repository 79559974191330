import React from "react";

export const NotificationWarningIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" fill="#D6D6D6" />
        <path d="M-17939 -21973C-17939 -21974.1 -17938.1 -21975 -17937 -21975H36971C36972.1 -21975 36973 -21974.1 36973 -21973V3829C36973 3830.11 36972.1 3831 36971 3831H-17937C-17938.1 3831 -17939 3830.11 -17939 3829V-21973Z" fill="#A2A2A2" />
        <path d="M-6821 -1638C-6821 -1639.1 -6820.1 -1640 -6819 -1640H7242C7243.1 -1640 7244 -1639.1 7244 -1638V2537C7244 2538.1 7243.1 2539 7242 2539H-6819C-6820.1 2539 -6821 2538.1 -6821 2537V-1638Z" fill="white" />
        <path d="M-6819 -1639H7242V-1641H-6819V-1639ZM7243 -1638V2537H7245V-1638H7243ZM7242 2538H-6819V2540H7242V2538ZM-6820 2537V-1638H-6822V2537H-6820ZM-6819 2538C-6819.55 2538 -6820 2537.55 -6820 2537H-6822C-6822 2538.66 -6820.66 2540 -6819 2540V2538ZM7243 2537C7243 2537.55 7242.55 2538 7242 2538V2540C7243.66 2540 7245 2538.66 7245 2537H7243ZM7242 -1639C7242.55 -1639 7243 -1638.55 7243 -1638H7245C7245 -1639.66 7243.66 -1641 7242 -1641V-1639ZM-6819 -1641C-6820.66 -1641 -6822 -1639.66 -6822 -1638H-6820C-6820 -1638.55 -6819.55 -1639 -6819 -1639V-1641Z" fill="black" fillOpacity="0.1" />
        <g clipPath="url(#clip0_1011_12842)">
            <rect width="1920" height="1080" transform="translate(-284 -981)" fill="#FAFAFA" />
            <g filter="url(#filter0_d_1011_12842)">
                <path d="M-12 -615C-12 -630.464 0.536026 -643 16 -643H1568C1583.46 -643 1596 -630.464 1596 -615V30C1596 45.464 1583.46 58 1568 58H16C0.536068 58 -12 45.464 -12 30V-615Z" fill="white" />
            </g>
            <g filter="url(#filter1_d_1011_12842)">
                <path d="M-12 -4C-12 -8.41828 -8.41828 -12 -4 -12H1588C1592.42 -12 1596 -8.41828 1596 -4V50C1596 54.4183 1592.42 58 1588 58H-3.99998C-8.41825 58 -12 54.4183 -12 50V-4Z" fill="white" shapeRendering="crispEdges" />
                <circle cx="10" cy="10" r="10" fill="#F88545" fillOpacity="0.2" />
                <path d="M10 4C8.81331 4 7.65328 4.35189 6.66658 5.01118C5.67989 5.67047 4.91085 6.60754 4.45673 7.7039C4.0026 8.80025 3.88378 10.0067 4.11529 11.1705C4.3468 12.3344 4.91825 13.4035 5.75736 14.2426C6.59648 15.0818 7.66557 15.6532 8.82946 15.8847C9.99335 16.1162 11.1997 15.9974 12.2961 15.5433C13.3925 15.0891 14.3295 14.3201 14.9888 13.3334C15.6481 12.3467 16 11.1867 16 10C15.997 8.40964 15.3638 6.88529 14.2393 5.76073C13.1147 4.63617 11.5904 4.00305 10 4ZM9.53846 7.23077C9.53846 7.10836 9.58709 6.99097 9.67364 6.90441C9.7602 6.81786 9.87759 6.76923 10 6.76923C10.1224 6.76923 10.2398 6.81786 10.3264 6.90441C10.4129 6.99097 10.4615 7.10836 10.4615 7.23077V10.4615C10.4615 10.5839 10.4129 10.7013 10.3264 10.7879C10.2398 10.8744 10.1224 10.9231 10 10.9231C9.87759 10.9231 9.7602 10.8744 9.67364 10.7879C9.58709 10.7013 9.53846 10.5839 9.53846 10.4615V7.23077ZM10 13.2308C9.86308 13.2308 9.72923 13.1902 9.61538 13.1141C9.50153 13.038 9.41279 12.9299 9.36039 12.8034C9.30799 12.6769 9.29428 12.5377 9.321 12.4034C9.34771 12.2691 9.41365 12.1457 9.51047 12.0489C9.60729 11.9521 9.73064 11.8862 9.86494 11.8595C9.99923 11.8327 10.1384 11.8465 10.2649 11.8989C10.3914 11.9513 10.4996 12.04 10.5756 12.1538C10.6517 12.2677 10.6923 12.4015 10.6923 12.5385C10.6923 12.7221 10.6194 12.8982 10.4895 13.028C10.3597 13.1578 10.1836 13.2308 10 13.2308Z" fill="#F88545" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_1011_12842" x="-36" y="-667" width="1656" height="749" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <filter id="filter1_d_1011_12842" x="-36" y="-36" width="1656" height="118" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <clipPath id="clip0_1011_12842">
                <rect width="1920" height="1080" fill="white" transform="translate(-284 -981)" />
            </clipPath>
        </defs>
    </svg>
);
