import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import { MOUSE_DOWN_EVENT, KEY_UP_EVENT } from '../../constant';
import { getUnactiveLocaleValue } from './localeUtil';


const LocaleSwitcher = ({ localeCode, notifyListener }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [locale, setLocale] = useState(localeCode);
    const wrapperRef = useRef(null);

    const handleChangeLocale = () => {
        setIsOpen(false);
        setLocale(getUnactiveLocaleValue(locale));
        notifyListener(getUnactiveLocaleValue(locale));
    };

    const openLocalesList = (e) => {
        e.stopPropagation();
        setIsOpen(true);
    };

    const closeLocalesList = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, [locale]);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, closeLocalesList);
        document.addEventListener(KEY_UP_EVENT, closeLocalesList);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, closeLocalesList);
            document.addEventListener(KEY_UP_EVENT, closeLocalesList);
        };
    }, [closeLocalesList]);

    return (
        <div className="locale__select-wrapper" ref={wrapperRef} onClick={openLocalesList}>
            <p className="block__text locale__item">{locale}</p>
            {isOpen ? 
                <p className="block__text locale__option" onClick={handleChangeLocale}>
                    {getUnactiveLocaleValue(locale)}
                </p>
                :
                null}
            <ChevronIcon nameOfClass="locale__icon" handleClick={setIsOpen} value={!isOpen}/>
        </div>
    );
};

export default LocaleSwitcher;
