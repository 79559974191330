import React from 'react';
import PropertyItemValue from '../properties/PropertyItemValue';

const ProductPropertyInput = ({ propertyId, itemSaveService }) => {
    return (
        <PropertyItemValue itemSaveService={itemSaveService} index={0} propertyId={propertyId}/>
    );
};

export default ProductPropertyInput;
