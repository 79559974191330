import React from 'react';
import { PlusIcon } from '../../appearence/icons/plus_icon';
import { i18n } from '../../i18n';
import { POPUP_KEY_ADD_INGREDIENT_TO_RECIPE } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';

const AddNewIngredientButton = () => {
    const openPopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_ADD_INGREDIENT_TO_RECIPE);
    };

    return (
        <button className="block__button button button_outline recipe__add-product" onClick={openPopup} >
            <span className="button__content">{i18n.get('app.page.recipe.addProduct')}</span>
            <PlusIcon nameOfClass="recipe__add-product-icon" handleClick={() => null}/>
        </button>
    );
};

export default AddNewIngredientButton;
