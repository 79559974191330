import React, { useEffect, useState } from 'react';
import { SORTING_DIRECTION_ASC, SORTING_DIRECTION_DESC } from '../../constant';
import { SortIcon } from '../../appearence/icons/sort_icon';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';

const SortingDirectionIcon = () => {
    const [direction, setDirection] = useState('');
    const changeSortingDirection = () => {
        const direction  = preferencesPhrasesStorage.getSortingDirection() === SORTING_DIRECTION_ASC ? 
            SORTING_DIRECTION_DESC 
            : 
            SORTING_DIRECTION_ASC;
        setDirection(direction);
        preferencesPhrasesStorage.changeSortingDirection(direction);
    };

    const changeDirectionOfSorting = () => {
        setDirection(preferencesPhrasesStorage.getSortingDirection());
    };

    useEffect(() => {
        preferencesPhrasesStorage.registerFiltersChangingObserver(changeDirectionOfSorting);
        return () => preferencesPhrasesStorage.unRegisterFiltersChangingObserver(changeDirectionOfSorting);
    }, []);

    return (
        <span onClick={changeSortingDirection}>
            <SortIcon nameOfClass={`preference__groups-icon preference__phrases-sort
                ${direction === SORTING_DIRECTION_ASC ? 'preference__groups-icon_rotate' : ''}`
            }/>
        </span>
    );
};

export default SortingDirectionIcon;
