import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import { ERROR_TYPE, NEW_PASSWORD_KEY, OLD_PASSWORD_KEY, loginManager } from './loginManager';
import { KEY_UP_EVENT } from '../../constant';

const CAPS_LOCK_KEY = 'CapsLock';

const checkToNeedCapsLockWarning = (e, currentValue) => {
    return e.getModifierState(CAPS_LOCK_KEY) && (e.key !== CAPS_LOCK_KEY || (e.key === CAPS_LOCK_KEY && currentValue === false));
};

const PasswordMessage = ({passwordType}) => {
    const [capslockIsOn, setCapslockIsOn] = useState(false);
    const [error, setError] = useState({type: '', message: '', key:''});

    const handleKeyUp = (e) => {
        setCapslockIsOn(prev => checkToNeedCapsLockWarning(e, prev));
    };

    const changeMessage = () => {
        setError(loginManager.getError());
    };
    
    useEffect(() => {
        document.addEventListener(KEY_UP_EVENT, handleKeyUp);
        loginManager.registerLoginUpdateObserver(changeMessage);
        loginManager.loadError();
        return () => {
            document.removeEventListener(KEY_UP_EVENT, handleKeyUp);
            loginManager.unRegisterLoginUpdateObserver(changeMessage);
        };
    }, []);

    return (
        error.key.includes(passwordType) && error.type === ERROR_TYPE ? 
            <p className="block__bullet password__error">{error.message}</p> 
            :
            capslockIsOn && (passwordType === OLD_PASSWORD_KEY || passwordType === NEW_PASSWORD_KEY) ? 
                <p className="block__bullet password__warning">{i18n.get('public.app.page.login.capsLock')}</p> 
                :
                <p className="block__bullet password__message">{error.key.includes(passwordType) ? error.message : ''}</p> 
    );
};

export default PasswordMessage;
