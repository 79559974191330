import React from 'react';
import { i18n } from '../../../i18n';
import { propertiesService } from '../../../services/propertiesService';
import ListItemTitle from '../../sharedComponents/ListItemTitle';
import { propertyDeleteService } from '../../../services/propertyDeleteService';
import { POPUP_KEY_DELETE_PROPERTY, POPUP_KEY_RESTORE_PROPERTY } from '../../../constant';
import DeleteItemIcon from '../../sharedComponents/DeleteItemIcon';
import RestoreItemIcon from '../../sharedComponents/RestoreItemIcon';
import EditItemIcon from '../../sharedComponents/EditItemIcon';

const PropertyListItem = ({ property }) => {
    return (
        <>
            <ListItemTitle itemCode={property.propertyName} itemService={propertiesService}/>
            <h3 className="main__subtitle product__list-code">{property.propertyName}</h3>
            <h2 className="block__content product__list-title">{i18n.get('app.page.product.propertyItem.propertyType')}</h2>
            <p className="block__content product__list-content">
                {property.propertyType ?
                    i18n.get(propertiesService.getPropertyTypei18nCode(property))
                    :
                    <span className="product__list-content_warning">{i18n.get('app.page.product.notAssigned')}</span>}
            </p>
            <h2 className="block__content product__list-title">{i18n.get('app.page.product.propertyItem.categoryName')} </h2>
            <div className="block__content product__list-content product__list-content_margin">
                {property.categoryName ?
                    i18n.get(propertiesService.getPropertyCategoryi18nCode(property))
                    :
                    <span className="product__list-content_warning">{i18n.get('app.page.product.notAssigned')}</span>}            
                {property.isVitamin ? 
                    <p className="block__content product__list-content">
                        {i18n.get(propertiesService.getPropertyVitaminTypei18nCode(property))}
                    </p>
                    :
                    null}
            </div>
            <div className="product__list-icons product__list-icons_property">
                {property.isDeleted ?
                    <RestoreItemIcon itemId={property.id} popupKey={POPUP_KEY_RESTORE_PROPERTY} />
                    :
                    <EditItemIcon pageUrl={`/product/property/${property.id}/`} />}
                <DeleteItemIcon 
                    itemId={property.id} 
                    itemDeleteService={propertyDeleteService} 
                    popupKey={POPUP_KEY_DELETE_PROPERTY} 
                />
            </div>
        </>
    );
};

export default PropertyListItem;
