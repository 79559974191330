import React from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { userPreferencesService } from '../../services/userPreferencesService';

const AssignPreferenceUsersListItem = ({ userName, userEmail, userId }) => {
    const changeSelectedUsersList = () => {
        userPreferencesService.changeSelectedUsersList(userId);
    };

    return (
        <tr className="preference__users-row">
            <td className="preference__users-item preference__users-item_small" onClick={changeSelectedUsersList}>
                <CustomCheckbox 
                    checked={userPreferencesService.getSelectedUsersList().find(it => it.id === userId) !== undefined} 
                    handleChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }} />
            </td>
            <td className="preference__users-item">{userName}</td>
            <td className="preference__users-item">{userEmail}</td>
        </tr>
    );
};

export default AssignPreferenceUsersListItem;
