import { GROUP_TAB_NAME, ITEM_TAB_NAME, MEAL_TAB_NAME, PAGE_TAB_NAME_LIST, PHRASE_TAB_NAME, PREFERENCE_TAB } from '../constant';
import { i18n } from '../i18n';

const checkActiveRoute = (activeRoute, pageUrl) => {
    let result = false;
    if(activeRoute === pageUrl) {
        result = true;
    }
    if(activeRoute === `/${PREFERENCE_TAB}/${PHRASE_TAB_NAME}/${PAGE_TAB_NAME_LIST}/` && pageUrl === `/${PREFERENCE_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}/`) {
        result = true;
    }
    return result;
};

const createPreferencesTabs = (activeRoute) => {
    return [
        {
            pageUrl: `/${PREFERENCE_TAB}/${GROUP_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
            title: i18n.get('app.page.preference.group'),
            isActive: checkActiveRoute(activeRoute, `/${PREFERENCE_TAB}/${GROUP_TAB_NAME}/${PAGE_TAB_NAME_LIST}`),
        },
        {
            pageUrl: `/${PREFERENCE_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
            title: i18n.get('app.page.preference.item'),
            isActive: checkActiveRoute(activeRoute, `/${PREFERENCE_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`),
        },
        {
            pageUrl: `/${PREFERENCE_TAB}/${MEAL_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
            title: i18n.get('app.page.preference.meal'),
            isActive: checkActiveRoute(activeRoute, `/${PREFERENCE_TAB}/${MEAL_TAB_NAME}/${PAGE_TAB_NAME_LIST}`),
        },
    ];
};

export const preferenceNavigationService = {
    observersList: [],
    getTabs(activeRoute) {
        return createPreferencesTabs(activeRoute);
    },
    getTabName() {
        return PREFERENCE_TAB;
    },
    registerTabsUpdateObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterTabsUpdateObserver(observer) {
        this.observersList = this.observersList.filter(observerFunction => observerFunction !== observer);
    },
    notifyObservers() {
        this.observersList.forEach(observer => observer());
    },
};
