import { POPUP_KEY_CREATE_FORM, PRODUCT_CATEGORY_ANY } from "../constant";
import { i18n } from "../i18n";
import { formsService } from "./formsService";
import { formsStorage } from "./formsStorage";
import { productsStorage } from "./productsStorage";
import { recipeSaveService } from "./recipeSaveService";

const findProductByProductCode = (productCode) => {
    return productsStorage.getItems(true).find(it => it.productCode === productCode) || {};
};

const filterFormsList = (form, productCode) => {
    return (findProductByProductCode(productCode).productCategory && form.formGroup === findProductByProductCode(productCode).productCategory) || 
        form.formGroup === PRODUCT_CATEGORY_ANY ||
        findProductByProductCode(productCode).productCategory === null;
};


const transformFormsList = (formsList, productCode, withFilter) => {
    let resultArray = [];
    withFilter ? formsList = formsList.filter(it => filterFormsList(it, productCode)) : null;
    formsList.forEach(it => resultArray.push({itemId: it.id, itemTitle: formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true), 
        included: true, code: it.productForm}));
    return resultArray;
};

export class formsIngredientService  {
    observersList = [];
    filter = '';
    possibleForms = [];
    selectedForms = [];
    productCode = '';
    ingredientId = '';

    constructor (productCode, ingredientId) {
        this.productCode = productCode;
        this.ingredientId = ingredientId;
    }

    getPossibleItems() {
        this.possibleForms = transformFormsList(formsStorage.getItems(true)
            .filter(it => formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true).toLowerCase().includes(this.filter)), this.productCode, true);
        return this.possibleForms;
    }

    getCurrentItems() {
        if(this.possibleForms.length === 0) {
            this.possibleForms = transformFormsList(formsStorage.getItems(true)
                .filter(it => formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true).toLowerCase().includes(this.filter)), this.productCode, false);
        }
        this.selectedForms = this.possibleForms.filter(it =>
            recipeSaveService.getRecipe().ingredient
                .find(ingredient => ingredient.id === this.ingredientId)?.ingredientParts[0]?.ingredientForm?.includes(it.code),
        );
        return this.selectedForms;
    }

    deleteItem(formId) {        
        recipeSaveService.deleteIngredientForm(this.ingredientId, this.possibleForms.find(it => it.itemId === formId).code);
        this.selectedForms = this.selectedForms.filter(it => it.itemId !== formId);
        this.notifyItemsChangingObservers();
    }

    addItem(formId) {
        recipeSaveService.addIngredientForm(this.ingredientId, this.possibleForms.find(it => it.itemId === formId).code);
        this.selectedForms.push(this.possibleForms.find(it => it.itemId === formId));
        this.notifyItemsChangingObservers();
    }

    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    }

    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    }

    hasExcludedItemsBlock() {
        return false;
    }

    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    }

    getSearchString() {
        return this.filter;
    }

    getPopupKey() {
        return POPUP_KEY_CREATE_FORM;
    }

    getButtonTitle() {
        return i18n.get("app.page.product.addForm");
    }

    getLabelName() {
        return i18n.get('app.page.recipe.ingredientForm');
    }
}

export const createFormsIngredientService = (productCode, ingredientId) => {
    return new formsIngredientService(productCode, ingredientId);
};
