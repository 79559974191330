import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { i18n } from '../../i18n/index';
import PageTitleItem from './PageTitleItem';
import { ITEM_TAB_NAME, PAGE_TAB_NAME_NEW, PAGE_TAB_NAME_LIST } from '../../constant';

const TITLE_CREATE_NAME_KEY = 'title';
const EDIT_CREATE_NAME_KEY = 'edit';

const getCurrentPageName = (url) => {
    return url.split('/')[1];
};

const createPageTitle = (pageName, tab, key) => {
    return i18n.get(`app.page.${(tab === ITEM_TAB_NAME || !tab) ? pageName : tab}.${key}`);
};

const PageTitleAsBreadCrumbs = () => {
    const location = useLocation();
    const { tab, id } = useParams();

    return (
        <header className="item__header">
            <PageTitleItem 
                pageUrl={`/${getCurrentPageName(location.pathname)}/${tab}/${PAGE_TAB_NAME_LIST}/`} 
                title={createPageTitle(getCurrentPageName(location.pathname), tab, TITLE_CREATE_NAME_KEY)}
            />
            <PageTitleItem 
                pageUrl={`/${getCurrentPageName(location.pathname)}/${tab}/${id !== PAGE_TAB_NAME_NEW ? id : PAGE_TAB_NAME_NEW}/`} 
                title={createPageTitle(getCurrentPageName(location.pathname), tab, id !== PAGE_TAB_NAME_NEW ? EDIT_CREATE_NAME_KEY : PAGE_TAB_NAME_NEW)} 
                isActive={true}
            />
        </header>
    );
};

export default PageTitleAsBreadCrumbs;
