import React, { useEffect, useState } from 'react';
import { i18n } from '../../i18n';
import { ONE_HOUR_NUMBER, ONE_MINUTE_NUMBER } from '../../constant';

const LastUpdateTime = ({ itemSaveService, itemCode }) => {
    const [currentUpdateTime, setCurrentUpdateTime] = useState({value: 0, format: 'min'});
    const [lastUpdate, setLastUpdate] = useState(null);

    useEffect(() => {
        let interval;
        if(lastUpdate) {
            setCurrentUpdateTime({value: 0, format: 'min'});
            interval = setInterval(() => {
                const value = Math.round((Date.now() - itemSaveService.getDateLastUpdate()) / ONE_MINUTE_NUMBER);
                if(value > ONE_HOUR_NUMBER * 3) {
                    setCurrentUpdateTime({value: null, format: 'min'});
                    clearInterval(interval);
                } else {
                    setCurrentUpdateTime({value: value > ONE_HOUR_NUMBER ? Math.round(value / ONE_HOUR_NUMBER) : value, 
                        format: value > ONE_HOUR_NUMBER ? 'hour' : 'min'});
                }
            }, ONE_MINUTE_NUMBER);
        }
        return () => {
            clearInterval(interval);
        };

    },[lastUpdate]);

    const changeLastUpdate = () => {
        setLastUpdate(itemSaveService.getDateLastUpdate());
    };

    useEffect(() => {
        itemSaveService.registerItemUpdateObserver(changeLastUpdate);
        return () => itemSaveService.unRegisterItemUpdateObserver(changeLastUpdate);
    }, []);

    return (
        itemSaveService.getDateLastUpdate() && currentUpdateTime.value !== null ? 
            <p className="block__text product__timer">
                {i18n.get('app.page.product.updated')} {currentUpdateTime.value + ' '} 
                {i18n.get(`app.page.product.${currentUpdateTime.format}`)} {i18n.get('app.page.product.ago')}
            </p>
            :
            null
    );
};

export default LastUpdateTime;
