import React, { useState, useEffect } from 'react';
import { i18n } from '../../../i18n';
import { LOCALE_EN, LOCALE_RU } from '../../../constant';
import LocaleSwitcher from '../../sharedComponents/LocaleSwitcher';
import { v4 as uuid } from 'uuid';
import { propertiesService } from '../../../services/propertiesService';
import { MinusIcon } from '../../../appearence/icons/minus_icon';

const createValueCode = () => {
    return uuid().slice(0, 4) + uuid().slice(0, 4);
};

const PropertyItemValue = ({ itemSaveService, propertyId, index }) => {
    const [fieldValue, setFieldValue] = useState({[LOCALE_RU]: '', [LOCALE_EN]: ''});
    const [selectedLocale, setSelectedLocale] = useState(LOCALE_RU);

    const changeSelectValues = (e) => {
        setFieldValue({...fieldValue, [selectedLocale]: e.target.value});
        itemSaveService.updateValueLocalization(selectedLocale, e.target.value, itemSaveService.getProperty()?.selectValues ? 
            itemSaveService.getProperty().selectValues[index]
            : 
            itemSaveService.getProperty(propertyId).value);
    };

    const changePropertyData = () => {
        if(itemSaveService.getProperty(propertyId).selectValues || itemSaveService.getProperty(propertyId).value) {
            const valueKeyItem = itemSaveService.getProperty(propertyId).selectValues ? 
                itemSaveService.getProperty().selectValues[index]
                : 
                itemSaveService.getProperty(propertyId).value;
            setFieldValue({
                [LOCALE_EN]: propertiesService.getPropertyValueLocalization(valueKeyItem, LOCALE_EN), 
                [LOCALE_RU]: propertiesService.getPropertyValueLocalization(valueKeyItem, LOCALE_RU),
            });
        } else {
            itemSaveService.updatePropertyValueField(createValueCode(), propertyId);
        }
    };

    const changeLocaleValue = (localeValue) => {
        setSelectedLocale(localeValue);
    };

    useEffect(() => {
        itemSaveService.registerItemUpdateObserver(changePropertyData);
        changePropertyData();
        return () => itemSaveService.unRegisterItemUpdateObserver(changePropertyData);
    }, []);

    const createNewValue = () => {
        if(itemSaveService.getProperty() && 
            itemSaveService.getProperty().selectValues && 
            itemSaveService.getProperty().selectValues.length - 1 === index && 
            !propertyId) {
            itemSaveService.updatePropertyValueField(createValueCode());
        }
    };

    return (
        <label className={`block__text product__form-label product__form-value ${propertyId ? '' : 'product__form-value_property'}`}>
            {propertyId ? 
                '' 
                : 
                <span>{i18n.get('product.scheme.property.code.valueOfData')}</span>}
            <input className={`product__form-input ${fieldValue[LOCALE_EN] && fieldValue[LOCALE_RU] ? 'product__form-input_full-filled' : ''}`} 
                placeholder={i18n.get('app.page.product.propertyItem.selectValues')}
                value={fieldValue[selectedLocale] || ''} 
                onBlur={createNewValue}
                onChange={changeSelectValues}/>
            <LocaleSwitcher localeCode={selectedLocale} notifyListener={changeLocaleValue}/>
            {index !== 0 ? 
                <span onClick={() => itemSaveService.deleteSelectValue(itemSaveService.getProperty().selectValues[index])}>
                    <MinusIcon nameOfClass="product__value-icon"/>
                </span> 
                : 
                null}
        </label>
    );
};

export default PropertyItemValue;
