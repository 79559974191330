import React, { useEffect } from 'react';
import { Pagination } from '../../Pagination';
import DeletePropertyPopup from './DeletePropertyPopup';
import { propertiesStorage } from '../../../services/propertiesStorage';
import CreateNewPropertyButton from './CreateNewPropertyButton';
import SearchItemBlock from '../../sharedComponents/SearchItemBlock';
import PropertiesList from './PropertiesList';
import CreateNewPropertyPopup from './CreateNewPropertyPopup';
import { i18n } from '../../../i18n';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import RestoreItemPopup from '../../sharedComponents/RestoreItemPopup';
import { propertyRestoreService } from '../../../services/propertyRestoreService';

const PropertyListTab = () => {
    const { width } = useWindowDimensions();
    const notifyPropertiesList = () => {
        propertiesStorage.loadItems(() => {}, width);
    };

    useEffect(() => {
        return () => {
            propertiesStorage.setPageNumber(1);
        };
    }, []);

    return (
        <>
            <SearchItemBlock itemsStorageService={propertiesStorage} placeholder={i18n.get('app.page.product.propertySearch')}/>
            <CreateNewPropertyButton />
            <PropertiesList />
            <Pagination itemsStorageService={propertiesStorage} />
            <DeletePropertyPopup notifyPropertiesList={notifyPropertiesList}/>
            <CreateNewPropertyPopup />
            <RestoreItemPopup itemRestoreService={propertyRestoreService} notifyItemsList={notifyPropertiesList} />
        </>
    );
};

export default PropertyListTab;
