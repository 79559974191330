import React, { useEffect } from 'react';
import { ChangeIcon } from '../../appearence/icons/change_icon';
import { preferencesStorage } from '../../services/preferencesStorage';
import { registerObserver, unRegisterObserver } from '../sharedComponents/WebSocketConnectionService';
import { PREFERENCE_URL, getFetchRequest } from '../../store/requests';
import { PHRASE_STATUS_CREATED, PHRASE_STATUS_IN_PROGRESS, makeUrl } from '../../constant';
import { preferencePhraseItemService } from '../../services/preferencePhraseService';

const checkResponseStatus = (response) => {
    return response.phrases && (response.phrases === PHRASE_STATUS_CREATED || response.phrases === PHRASE_STATUS_IN_PROGRESS);
};

const GeneratePreferencePhrasesIcon = ({ preferenceId, isLoading, setIsLoading }) => {
    const generatePreferencePhrases = () => {
        setIsLoading(true);
        preferencesStorage.generatePreferencePhrases(preferenceId, setIsLoading);
    };

    const getPhrasesValue = () => {
        getFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, '/status/']), (response) => {
            if(checkResponseStatus(response)) {
                setIsLoading(true);
            } else {
                setIsLoading(false);
            }
        }, () => setIsLoading(true));
    };

    const changeIsLoadingStatus = () => {
        setIsLoading(false);
        preferencesStorage.getPreferencePhrasesCount(preferenceId, () => preferencesStorage.notifyFiltersChangingObservers());
        preferencePhraseItemService.loadItems();
    };

    useEffect(() => {
        registerObserver(changeIsLoadingStatus);
        getPhrasesValue();
        return () => {
            unRegisterObserver(changeIsLoadingStatus);
        };
    }, []);

    return (
        <span onClick={generatePreferencePhrases}>
            {isLoading ?
                <img className="preference__phrases-icon preference__phrases-loader" 
                    src="/static/images/loader_picture.jpg" alt="loader"/>
                :
                <ChangeIcon nameOfClass="preference__phrases-icon" />}
        </span>
    );
};

export default GeneratePreferencePhrasesIcon;
