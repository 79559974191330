import React from 'react';
import { ingredientTabNavigationService } from '../../services/recipeIngredientTabNavigationService';
import NavigationTabs from '../sharedComponents/NavigationTabs';
import RecipeIngredientGroupsTabContent from './RecipeIngredientGroupsTabContent';

const RecipeIngredientGroupsTabs = () => {
    return (
        <>
            <NavigationTabs navigationService={ingredientTabNavigationService} />
            <RecipeIngredientGroupsTabContent />
        </>
    );
};

export default RecipeIngredientGroupsTabs;
