import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PAGE_TAB_NAME_NEW } from '../../constant';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import LastUpdateTime from '../sharedComponents/LastUpdateTime';
import SaveNewPreferenceButton from './SaveNewPreferenceButton';
import { initializePreferenceSaveService, preferenceSaveService } from '../../services/preferenceSaveService';
import PreferenceStructureIncluded from './PreferenceStructureIncluded';
import PreferenceStructureType from './PreferenceStructureType';
import PreferenceStructureItemCode from './PreferenceStructureItemCode';
import PreferenceStructureWhen from './PreferenceStructureWhen';
import PreferenceStructureItemHowOften from './PreferenceStructureItemHowOften';
import PreferenceStructureIsInternal from './PreferenceStructureIsInternal';
import PreferenceStructureGroup from './PreferenceStructureGroup';
import PreferenceStructurePhrase from './PreferenceStructurePhrase';
import { createPreferenceSelectGroupService } from '../../services/preferenceGroupSelectService';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { productsStorage } from '../../services/productsStorage';
import { propertiesStorage } from '../../services/propertiesStorage';

const PreferenceItemEdit = () => {
    const { id } = useParams();
    const [preferenceIsReady, setPreferenceIsReady] = useState(false);

    const changePreferenceReadyState = () => {
        if(id === PAGE_TAB_NAME_NEW) {
            setPreferenceIsReady(true);
        } else {
            setPreferenceIsReady(preferenceSaveService.getPreference().include !== undefined);
        }
    };

    useEffect(() => {
        if(id !== PAGE_TAB_NAME_NEW) {
            initializePreferenceSaveService(id);
        } 
        propertiesStorage.loadItems(() => {});
        productsStorage.loadItems(() => {});
        preferencesGroupsStorage.loadItems();
        preferenceSaveService.registerItemUpdateObserver(changePreferenceReadyState);
        return () => {
            preferenceSaveService.unRegisterItemUpdateObserver(changePreferenceReadyState);
            preferenceSaveService.cleanService();
        };
    }, [id]);

    return (
        <>
            <PageTitleAsBreadCrumbs />
            <article className="preference__article preference__article_full-width preference__article-add">
                <form className="preference__form" key={preferenceIsReady}>
                    {id === PAGE_TAB_NAME_NEW ?
                        <SaveNewPreferenceButton />
                        :
                        <LastUpdateTime itemSaveService={preferenceSaveService} />}
                    <div className="preference__form-column">
                        <PreferenceStructureIncluded itemSaveService={preferenceSaveService}  />
                        <PreferenceStructureType itemSaveService={preferenceSaveService}  />
                        <PreferenceStructureItemCode itemSaveService={preferenceSaveService}  />
                        <PreferenceStructureWhen itemSaveService={preferenceSaveService} />
                        
                    </div>
                    <div className="preference__form-column">
                        <PreferenceStructureItemHowOften itemSaveService={preferenceSaveService}  />
                        <PreferenceStructureIsInternal />
                        <PreferenceStructureGroup selectWithTokensManager={createPreferenceSelectGroupService()}/>
                        <PreferenceStructurePhrase itemSaveService={preferenceSaveService} />
                    </div>
                </form>
            </article>
        </>
    );
};

export default PreferenceItemEdit;
