import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { ITEM_TAB_NAME, PREFERENCE_TAB, PAGE_TAB_NAME_LIST, PRODUCT_TAB, PROFILE_TAB, RECIPE_TAB, USERS_TAB, errorCallback, makeUrl} from "../constant";
import { UserIcon } from '../appearence/icons/user_icon';
import { MenuLogoIcon } from '../appearence/icons/small_logo_icon';
import { ProfileIcon } from '../appearence/icons/profile_icon';
import { LemonIcon } from '../appearence/icons/lemon_icon';
import { BookIcon } from '../appearence/icons/book_icon';
import { MugIcon } from '../appearence/icons/mug_icon';
import { i18n } from '../i18n';
import { LOGOUT_REQUEST, postFetchRequest } from '../store/requests';
import { LogoutIcon } from '../appearence/icons/logout_icon';
import Modal from './Modal';
import { MODAL_SMALL_SIZE } from './helper';
import CommitInfo from './sharedComponents/CommitInfo';

const ExitModalContent = ({handleClose, handleLogout}) => (
    <>
        <h2 className="modal__title modal__logout-title block__title-h3">{i18n.get('public.app.page.login.modal.title')}</h2>
        <p className="block__content modal__text modal__logout-text">{i18n.get('public.app.page.login.modal.content')}</p>
        <div className="block__button modal__button-group modal__logout-buttons">
            <button className="block__button button button_outline modal__logout-button_outline" onClick={() => handleClose(false)}>{i18n.get('public.app.page.login.modal.cancel')}</button>
            <button className="block__button button modal__logout-button" onClick={handleLogout}>{i18n.get('public.app.page.login.modal.exit')}</button>
        </div>
    </>
);

const MAIN_MENU_LIST = [
    {
        title: PROFILE_TAB,
        link: PROFILE_TAB,
        icon: ProfileIcon,
    },
    {
        title: USERS_TAB,
        link: USERS_TAB,
        icon: UserIcon,
    },
    {
        title: PRODUCT_TAB,
        link: `${PRODUCT_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
        icon: LemonIcon,
    },
    {
        title: PREFERENCE_TAB,
        link: `${PREFERENCE_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
        icon: MugIcon,
    },
    {
        title: RECIPE_TAB,
        link: `${RECIPE_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
        icon: BookIcon,
    },

];

const MainPage = ({menuIsOpen, setMenuIsOpen, setAccessToken}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [exitModalIsOpen, setExitModalIsOpen] = useState(false);
    const [appLocale, setAppLocale] = useState(i18n.APP_LOCALE);

    const handleLogout = () => {
        postFetchRequest(makeUrl([LOGOUT_REQUEST]), null, () => {
            setAccessToken(false);
            navigate('/login');
        }, errorCallback, true);
    };

    const changeAppLocale = () => {
        setAppLocale(i18n.APP_LOCALE);
    };

    useEffect(() => {
        i18n.registerChangingObserver(changeAppLocale);
        return () => i18n.unRegisterChangingObserver(changeAppLocale);
    }, []);

    return (
        <>
            {menuIsOpen ? <div className="menu__wrapper" onClick={() => setMenuIsOpen(false)}></div> : null}
            {exitModalIsOpen ? 
                <Modal content={<ExitModalContent handleClose={setExitModalIsOpen} handleLogout={handleLogout} />} 
                    handleCloseModal={setExitModalIsOpen} size={MODAL_SMALL_SIZE}/>
                :
                null}
            <nav className="menu">
                <div className="menu__content">
                    <div className="menu__logo-group">
                        <MenuLogoIcon nameOfClass="menu__logo_small"/>
                        <CommitInfo />
                    </div>
                    <ul className="menu__list" key={appLocale}>
                        {MAIN_MENU_LIST.map(it => (
                            <li key={it.title} className="menu__item navigation-link">
                                <Link className="menu__item-link" to={'/' + it.link}>
                                    {<it.icon nameOfClass={location.pathname.includes(it.title) ? 'menu__icon menu__icon_active' : 'menu__icon'}/>}
                                    <h2 className={`block__content menu__title ${location.pathname.includes(it.title) ? 'menu__title_active' : ''}`}>{i18n.get(`app.page.${it.title}.title`)}</h2>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <ul className="menu__list menu__list_bottom">
                        <li className="menu__item menu__item-logout navigation-link" onClick={() => setExitModalIsOpen(true)}>
                            <Link className="menu__item-link" to={'#'}>
                                <LogoutIcon nameOfClass='menu__icon'/>
                                <h2 className='block__button menu__title'>{i18n.get("public.app.page.login.exit")}</h2>
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default MainPage;
