import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../../i18n/index';
import { PAGE_TAB_NAME_LIST, RECIPE_TAB } from '../../constant';
import { ErrorHandler } from '../ErrorHandler';
import RecipesListTab from './RecipesListTab';
import RecipeEditTab from './RecipeEditTab';

const RecipePage = () => {
    const [activeRoute, setActiveRoute] = useState('');
    const {tab, id} = useParams();

    useEffect(() => {
        setActiveRoute(`/${RECIPE_TAB}/${tab}/${id}/`);
    }, [tab, id]);

    try {
        if(!activeRoute) return null;
        return (
            id === PAGE_TAB_NAME_LIST ?
                <RecipesListTab />
                :
                <RecipeEditTab /> 
        );
    } catch (err) {
        console.log(err);
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;
    }
};

export default RecipePage;
