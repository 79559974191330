import React from "react";

export const NotificationInfoIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" fill="#D6D6D6" />
        <path d="M-13800 -22000C-13800 -22001.1 -13799.1 -22002 -13798 -22002H41110C41111.1 -22002 41112 -22001.1 41112 -22000V3802C41112 3803.11 41111.1 3804 41110 3804H-13798C-13799.1 3804 -13800 3803.11 -13800 3802V-22000Z" fill="#A2A2A2" />
        <path d="M-2682 -1665C-2682 -1666.1 -2681.1 -1667 -2680 -1667H11381C11382.1 -1667 11383 -1666.1 11383 -1665V2510C11383 2511.1 11382.1 2512 11381 2512H-2680C-2681.1 2512 -2682 2511.1 -2682 2510V-1665Z" fill="white" />
        <path d="M-2680 -1666H11381V-1668H-2680V-1666ZM11382 -1665V2510H11384V-1665H11382ZM11381 2511H-2680V2513H11381V2511ZM-2681 2510V-1665H-2683V2510H-2681ZM-2680 2511C-2680.55 2511 -2681 2510.55 -2681 2510H-2683C-2683 2511.66 -2681.66 2513 -2680 2513V2511ZM11382 2510C11382 2510.55 11381.6 2511 11381 2511V2513C11382.7 2513 11384 2511.66 11384 2510H11382ZM11381 -1666C11381.6 -1666 11382 -1665.55 11382 -1665H11384C11384 -1666.66 11382.7 -1668 11381 -1668V-1666ZM-2680 -1668C-2681.66 -1668 -2683 -1666.66 -2683 -1665H-2681C-2681 -1665.55 -2680.55 -1666 -2680 -1666V-1668Z" fill="black" fillOpacity="0.1" />
        <g clipPath="url(#clip0_1011_12842)">
            <rect width="1920" height="1080" transform="translate(-284 -1008)" fill="#FAFAFA" />
            <g filter="url(#filter0_d_1011_12842)">
                <path d="M-12 -642C-12 -657.464 0.536026 -670 16 -670H1568C1583.46 -670 1596 -657.464 1596 -642V4C1596 19.464 1583.46 32 1568 32H16C0.536068 32 -12 19.464 -12 4V-642Z" fill="white" />
            </g>
            <g filter="url(#filter1_d_1011_12842)">
                <path d="M-12 -4C-12 -8.41828 -8.41828 -12 -4 -12H1588C1592.42 -12 1596 -8.41828 1596 -4V24C1596 28.4183 1592.42 32 1588 32H-3.99998C-8.41825 32 -12 28.4183 -12 24V-4Z" fill="white" shapeRendering="crispEdges" />
                <circle cx="10" cy="10" r="10" fill="#3795DE" fillOpacity="0.22" />
                <path d="M10 4C8.81331 4 7.65328 4.35189 6.66658 5.01118C5.67989 5.67047 4.91085 6.60754 4.45673 7.7039C4.0026 8.80025 3.88378 10.0067 4.11529 11.1705C4.3468 12.3344 4.91825 13.4035 5.75736 14.2426C6.59648 15.0818 7.66557 15.6532 8.82946 15.8847C9.99335 16.1162 11.1997 15.9974 12.2961 15.5433C13.3925 15.0891 14.3295 14.3201 14.9888 13.3334C15.6481 12.3467 16 11.1867 16 10C15.997 8.40964 15.3638 6.88529 14.2393 5.76073C13.1147 4.63617 11.5904 4.00305 10 4ZM9.88462 6.76923C10.0215 6.76923 10.1554 6.80983 10.2692 6.88591C10.3831 6.96198 10.4718 7.0701 10.5242 7.1966C10.5766 7.32311 10.5903 7.46231 10.5636 7.5966C10.5369 7.7309 10.471 7.85425 10.3742 7.95107C10.2773 8.04789 10.154 8.11383 10.0197 8.14054C9.88538 8.16726 9.74618 8.15355 9.61968 8.10115C9.49318 8.04875 9.38506 7.96001 9.30898 7.84616C9.23291 7.73231 9.19231 7.59846 9.19231 7.46154C9.19231 7.27793 9.26525 7.10184 9.39508 6.972C9.52491 6.84217 9.70101 6.76923 9.88462 6.76923ZM10.4615 13.2308H10C9.87759 13.2308 9.7602 13.1821 9.67364 13.0956C9.58709 13.009 9.53846 12.8916 9.53846 12.7692V10C9.41606 10 9.29866 9.95137 9.21211 9.86482C9.12555 9.77826 9.07692 9.66087 9.07692 9.53846C9.07692 9.41605 9.12555 9.29866 9.21211 9.2121C9.29866 9.12555 9.41606 9.07692 9.53846 9.07692H10C10.1224 9.07692 10.2398 9.12555 10.3264 9.2121C10.4129 9.29866 10.4615 9.41605 10.4615 9.53846V12.3077C10.5839 12.3077 10.7013 12.3563 10.7879 12.4429C10.8745 12.5294 10.9231 12.6468 10.9231 12.7692C10.9231 12.8916 10.8745 13.009 10.7879 13.0956C10.7013 13.1821 10.5839 13.2308 10.4615 13.2308Z" fill="#3795DE" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_1011_12842" x="-36" y="-694" width="1656" height="750" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <filter id="filter1_d_1011_12842" x="-36" y="-36" width="1656" height="92" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <clipPath id="clip0_1011_12842">
                <rect width="1920" height="1080" fill="white" transform="translate(-284 -1008)" />
            </clipPath>
        </defs>
    </svg>
);
