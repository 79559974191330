import { i18n } from "../i18n";

const propertiesService = {
    getPropertyNamei18nCode(propertyData) {
        return `product.scheme.property.code.${propertyData.propertyName}`;
    },
    getPropertyTypei18nCode(propertyData) {
        if(propertyData.propertyType) return `product.scheme.property.propertyType.${propertyData.propertyType}`;
        return 'app.page.product.notAssigned';
    },
    getPropertyCategoryi18nCode(propertyData) {
        if(propertyData.categoryName) return `product.scheme.property.categoryName.${propertyData.categoryName}`;
        return 'app.page.product.notAssigned';
    },
    getPropertyVitaminTypei18nCode(propertyData) {
        return `product.scheme.property.vitamin.${propertyData.isWaterSoluble ? 'waterSoluble' : 'fatSoluble'}`;
    },
    getLocalization(localeCode, code, withCodeReplace) {
        if(withCodeReplace) {
            return i18n.get(`product.scheme.property.code.${code}`, localeCode);
        } else {
            return i18n.is_exists(`product.scheme.property.code.${code}`, localeCode) ? i18n.get(`product.scheme.property.code.${code}`, localeCode) : '';
        }
    },
    getPlaceholder() {
        return i18n.get('app.page.product.propertyItem.propertyName');
    },
    getPropertyValueLocalization(value, localeCode) {
        return i18n.is_exists(`product.scheme.property.value.${value}`, localeCode) ? i18n.get(`product.scheme.property.value.${value}`, localeCode) : '';
    },
};

export {propertiesService};
