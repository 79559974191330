import React from 'react';
import { i18n } from '../../i18n';
import SelectHandler from '../sharedComponents/SelectHandler';

const createListOfOptions = () => {
    return [
        {
            name: i18n.get('preference.scheme.include.true'),
            id: true,
        },
        {
            name: i18n.get('preference.scheme.include.false'),
            id: false,
        },
    ];
};

const findPreferenceTitle = (itemSaveService) => {
    if(itemSaveService.getPreference()?.include !== undefined) {
        return i18n.get(`preference.scheme.include.${itemSaveService.getPreference().include}`);
    } else {
        return '';
    }
};

const PreferenceStructureIncluded = ({ itemSaveService }) => {
    const updatePreferenceValue = (value) => {
        itemSaveService.updatePreferenceProperty('include', value);
    };

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.include')}</span>
            <SelectHandler 
                selectedValue={findPreferenceTitle(itemSaveService)} 
                optionsList={createListOfOptions()} 
                sendSelectedValue={updatePreferenceValue}
            /> 
        </label> 
    );
};

export default PreferenceStructureIncluded;
