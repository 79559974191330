import React, { useEffect, useState } from 'react';
import { NOTIFICATION_TYPE_INFO, NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_WARNING } from "../../constant";
import { NotificationInfoIcon } from "../../appearence/icons/notification_info_icon";
import { NotificationSuccessIcon } from "../../appearence/icons/notification_success_icon";
import { NotificationWarningIcon } from "../../appearence/icons/notification_warning_icon";
import { CloseIcon } from '../../appearence/icons/close_icon';
import { i18n } from '../../i18n';

const CLOSE_TOAST_MESSAGE_TIME = 10000;

const NOTIFICATION_TYPE_ICONS = {
    [NOTIFICATION_TYPE_INFO]: <NotificationInfoIcon nameOfClass="notification__toast-icon"/>,
    [NOTIFICATION_TYPE_SUCCESS]: <NotificationSuccessIcon nameOfClass="notification__toast-icon"/>,
    [NOTIFICATION_TYPE_WARNING]: <NotificationWarningIcon nameOfClass="notification__toast-icon"/>,
};

const NotificationToast = ({ message, link, type }) => {
    const [isVisible, setIsVisible] = useState(true);
    let timeout;

    useEffect(() => {
        timeout = setTimeout(() => {
            setIsVisible(false);
        }, CLOSE_TOAST_MESSAGE_TIME);
        return () => clearTimeout(timeout);
    },[]);
    return (
        isVisible ? 
            <section className="notification__toast">
                <div className="notification__toast-block">
                    {NOTIFICATION_TYPE_ICONS[type]}
                    <p className="block__bullet notification__toast-content">{message}</p>
                </div>
                <span onClick={() => setIsVisible(false)}>
                    <CloseIcon nameOfClass="notification__toast-icon_small" />
                </span>
                {link ? <a href={link} className="block__bullet notification__toast-link">{i18n.get('app.page.user.more')}</a> : null}
            </section>
            :
            null
    );
};

export default NotificationToast;
