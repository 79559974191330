import React, { useEffect } from 'react';
import { Pagination } from '../../Pagination';
import DeleteFormPopup from './DeleteFormPopup';
import CreateNewFormPopup from './CreateNewFormPopup';
import SearchItemBlock from '../../sharedComponents/SearchItemBlock';
import CreateNewFormButton from './CreateNewFormButton';
import FormsList from './FormsList';
import { formsStorage } from '../../../services/formsStorage';
import { i18n } from '../../../i18n';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import RestoreItemPopup from '../../sharedComponents/RestoreItemPopup';
import { formRestoreService } from '../../../services/formRestoreService';

const FormListTab = () => {
    const { width } = useWindowDimensions();
    const notifyFormsList = () => {
        formsStorage.loadItems(() => {}, width);
    };

    useEffect(() => {
        return () => {
            formsStorage.setPageNumber(1);
        };
    }, []);

    return (
        <>
            <SearchItemBlock itemsStorageService={formsStorage} placeholder={i18n.get('app.page.product.formSearch')}/>
            <CreateNewFormButton />
            <FormsList />
            <Pagination itemsStorageService={formsStorage} />
            <DeleteFormPopup notifyFormsList={notifyFormsList}/>
            <CreateNewFormPopup />
            <RestoreItemPopup itemRestoreService={formRestoreService} notifyItemsList={notifyFormsList} />
        </>
    );
};

export default FormListTab;
