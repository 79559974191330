import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';

const FiltersListItemMaxAndMin = ({ itemsStorageService, filterName }) => {
    const [inputValue, setInputValue] = useState({min: '', max: ''});

    const changeFilterValue = (e, filterKey) => {
        itemsStorageService.setNumberFilterValue(filterName, filterKey, e.target.value);
        setInputValue({...inputValue, [filterKey]: e.target.value});
    };

    const getCurrentFilterValue = () => {
        setInputValue({...itemsStorageService.getFilter(filterName)});
    };

    useEffect(() => {
        itemsStorageService.registerFiltersChangingObserver(getCurrentFilterValue);
        setInputValue(itemsStorageService.getFilter(filterName));
        return () => itemsStorageService.unRegisterFiltersChangingObserver(getCurrentFilterValue);
    }, []);

    return (
        <div key={filterName} className="block__content product__dropdown-label product__dropdown-number">
            <label className="product__dropdown-text">
                {i18n.get('product.scheme.property.code.from')}
                <input type="number"
                    placeholder="0"
                    className="product__dropdown-input"
                    onChange={(e) => changeFilterValue(e, 'min')}
                    value={inputValue.min || ''}
                />
            </label>
            <label className="product__dropdown-text">
                {i18n.get('product.scheme.property.code.to')}
                <input type="number"
                    placeholder="300000"
                    className="product__dropdown-input"
                    onChange={(e) => changeFilterValue(e, 'max')}
                    value={inputValue.max || ''}
                />
            </label>
        </div>
    );
};

export default FiltersListItemMaxAndMin;
