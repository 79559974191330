import React, { useEffect, useState } from 'react';
import AssignPreferenceUsersListItem from './AssignPreferenceUsersListItem';
import { userPreferencesService } from '../../services/userPreferencesService';
import CustomCheckbox from '../CustomCheckbox';
import { i18n } from '../../i18n';

const getCheckedValue = () => {
    return userPreferencesService.getSelectedUsersList().length === userPreferencesService.getItems().length && 
        userPreferencesService.getSelectedUsersList().length !== 0;
};

const AssignPreferenceUsersList = () => {
    const [usersListIsChanged, setUsersListIsChanged] = useState(false);

    const changeUsersList = () => {
        setUsersListIsChanged(prev => !prev);
    };

    useEffect(() => {
        userPreferencesService.registerFiltersChangingObserver(changeUsersList);
        return () => userPreferencesService.unRegisterFiltersChangingObserver(changeUsersList);
    }, []);
    return (
        <div className="scroll preference__users-wrapper">
            <table className="block__table preference__users">
                <thead>
                    <tr className="preference__users-row">
                        <th className="preference__users-item preference__users-header preference__users-header_small">
                            <CustomCheckbox 
                                checked={getCheckedValue()} 
                                handleChange={() => userPreferencesService.changeSelectedUsersList()} 
                            />
                        </th>
                        <th className="preference__users-item preference__users-header">{i18n.get('app.page.preference.userName')}</th>
                        <th className="preference__users-item preference__users-header">{i18n.get('app.page.preference.userPhone')}</th>
                    </tr>
                </thead>
                <tbody>
                    {userPreferencesService.getItems().map(it => (
                        <React.Fragment key={it.id + it.name + usersListIsChanged}>
                            <AssignPreferenceUsersListItem userName={it.name} userEmail={it.phone ? it.phone : it.email} userId={it.id}/>
                        </React.Fragment>
                    ))}

                </tbody>
            </table>
        </div> 
    );
};

export default AssignPreferenceUsersList;
