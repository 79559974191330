import React from 'react';
import { GroupIcon } from '../../appearence/icons/group_icon';
import { preferencesStorage } from '../../services/preferencesStorage';
import { POPUP_KEY_ASSIGN_GROUP } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';

const PreferencesListItemGroups = ({ preferenceId }) => {
    const openAssignGroupPopup = () => {
        preferencesStorage.changeSelectedPreferencesList('', true);
        preferencesStorage.changeSelectedPreferencesList(preferenceId);
        popupOpenService.callToOpen(POPUP_KEY_ASSIGN_GROUP);
    };

    return (
        <p className="preference__item-count" onClick={openAssignGroupPopup}>
            <GroupIcon nameOfClass="preference__list-icon preference__item-icon" />
            {preferencesStorage.getById(preferenceId).groups.length}
        </p>
    );
};

export default PreferencesListItemGroups;
