import React, { useState, useEffect } from 'react';
import { preferencesStorage } from '../../services/preferencesStorage';
import CustomCheckbox from '../CustomCheckbox';
import { i18n } from '../../i18n';
import { LOCALE_EN } from '../../constant';
import PreferencesListSelectAssignGroup from './PreferencesListSelectAssignGroup';
import PreferencesListSelectAssignUser from './PreferenceSelectAssignUser';
import PreferencesListSelectIsDefault from './PreferencesListSelectIsDefault';
import PreferencesListSelectDelete from './PreferencesListSelectDelete';
import { getPreferencePhraseForm } from './PreferencePhrasesSelectBlock';

const getSelectedListCount = (isPhrase, phrasesListManager) => {
    return isPhrase ? 
        phrasesListManager.getSelectedList().length 
        : 
        preferencesStorage.getSelectedPreferencesList().length;
};

const getPreferenceForm = (count) => {
    if(i18n.APP_LOCALE === LOCALE_EN) {
        return count > 0 ? 'preferences' : 'preference';
    } else {
        if (count > 10 && [11, 12, 13, 14].includes(count % 100)) return 'предпочтений';
        let last_num = count % 10;
        if (last_num == 1) return 'предпочтение';
        if ([2, 3, 4].includes(last_num)) return 'предпочтения';
        if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'предпочтений';
    }
};

const createCountPhrase = (count, isPhrase, phrasesListManager) => {
    return `${getSelectedListCount(isPhrase, phrasesListManager) === 0 ? i18n.get('app.page.preference.total') : i18n.get('app.page.preference.selected')} 
        ${count} ${isPhrase ? getPreferencePhraseForm(count) : getPreferenceForm(count)}`;
};

const PreferencesListSelectBlock = ({ phrasesListManager }) => {
    const [count, setCount] = useState(0);
    const [phrasesIsSelected, setPhrasesIsSelected] = useState(false);

    const getSelectedValues = () => {
        if(phrasesListManager.getSelectedList().length > 0) {
            setPhrasesIsSelected(true);
            setCount(phrasesListManager.getSelectedList().length);
        } else {
            setPhrasesIsSelected(false);
            if(getSelectedListCount() === 0) {
                setCount(preferencesStorage.getItems().length);
            } else {
                setCount(getSelectedListCount());
            }
        }
    };

    useEffect(() => {
        preferencesStorage.registerFiltersChangingObserver(getSelectedValues);
        phrasesListManager.registerFiltersChangingObserver(getSelectedValues);
        getSelectedValues();
        return () => {
            preferencesStorage.unRegisterFiltersChangingObserver(getSelectedValues);
            phrasesListManager.unRegisterFiltersChangingObserver(getSelectedValues);
        };
    }, []);

    return (
        <div className="preference__selected">
            <CustomCheckbox checked={getSelectedListCount(phrasesIsSelected, phrasesListManager) > 0} 
                handleChange={() => preferencesStorage.changeSelectedPreferencesList()}/>
            <p className="block__content preference__selected-count">
                {createCountPhrase(count, phrasesIsSelected, phrasesListManager)}
            </p>
            <div className="preference__button-group">
                {phrasesIsSelected ?
                    <>
                        <PreferencesListSelectIsDefault phrasesListManager={phrasesListManager} />
                        <PreferencesListSelectDelete phrasesListManager={phrasesListManager} />
                    </>
                    :
                    <>
                        <PreferencesListSelectAssignGroup />
                        <PreferencesListSelectAssignUser />
                    </>
                }
            </div>
        </div>
    );
};

export default PreferencesListSelectBlock;
