export const recipeDeleteService = {
    getWhereItemIsUsed() {
        return [];
    },
    getTranslationCode() {
        return '';
    },
    checkIsDeletedItem() {
        return false;
    },
};
