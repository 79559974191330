import React from "react";

const RadioLabel = ({ value, title, changeValue, type }) => {
    return (
        <div key={value} className={`block__content radio__container ${value === true ? 'radio__container_active' : ''}`} 
            onClick={() => changeValue(!value, type)}>
            <p className={`radio__round ${value === true ? 'radio__round_active' : ''}`}></p>
            <span className="radio__content">{title}</span>
        </div>
    );
};
export default RadioLabel;
