import React, { useState, useEffect } from 'react';
import { i18n } from '../i18n';
import { ERROR_TYPE_PASSWORD, KEY_UP_EVENT, SAFARI_BROWSER, YANDEX_BROWSER } from '../constant';

const CAPS_LOCK_KEY = 'CapsLock';

const checkIsYandexOrSafariBrowser = () => {
    return window.navigator.userAgent.includes(YANDEX_BROWSER) || window.navigator.userAgent.includes(SAFARI_BROWSER);
};

const PasswordField = ({password, setPassword, error, label}) => {
    const [capslockIsOn, setCapslockIsOn] = useState(false);
    const [passwordIsShown, setPasswordIsShown] = useState(false);

    const handleKeyUp = (e) => {
        if(e.getModifierState(CAPS_LOCK_KEY) && e.key === CAPS_LOCK_KEY) {
            setCapslockIsOn(prev => !prev);
        } else if(e.key === CAPS_LOCK_KEY) {
            setCapslockIsOn(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener(KEY_UP_EVENT, handleKeyUp);
        return () => {
            document.removeEventListener(KEY_UP_EVENT, handleKeyUp);
        };
    }, []);

    return (
        <>
            <label className="block__text password__label" 
                autoComplete="off"
                key={label}
                name={label}>
                {label}
                {checkIsYandexOrSafariBrowser() ?
                    <input type="password" autoComplete="off" style={{display: 'none'}} />
                    :
                    null}
                <input 
                    type={!passwordIsShown && password ? "password" : "text"} 
                    value={password} onChange={setPassword} 
                    autoComplete="off"
                    className={`block__content password__input ${error.type === ERROR_TYPE_PASSWORD ? 'password__input_error' : ''}`} />
                {password ? 
                    <span className="block__bullet password__text" onClick={() => setPasswordIsShown(!passwordIsShown)} >
                        {passwordIsShown ? i18n.get('public.app.page.login.hide') : i18n.get('public.app.page.login.show')}
                    </span>
                    :
                    null}
            </label>
            {capslockIsOn ? <p className="block__bullet password__warning">{i18n.get('public.app.page.login.capsLock')}</p> : null}
        </>
    );

};

export default PasswordField;
