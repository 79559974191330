import React from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../i18n';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { TABLET_WIDTH } from '../../constant';

const createPreferenceButtonName = (width) => {
    return width > TABLET_WIDTH ? i18n.get('app.page.preference.addPreference') : i18n.get('app.page.preference.addPreference').split(' ')[0];
};

const CreateNewPreferenceButton = () => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    return (
        <button className='block__button button button_outline preference__button'
            onClick={() => navigate('/preference/item/new')}>
            <span className="button__content">{createPreferenceButtonName(width)}</span>
        </button>
    );
};

export default CreateNewPreferenceButton;
