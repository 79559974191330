import React, { useState } from 'react';
import Checkbox from '../sharedComponents/Checkbox';
import { i18n } from '../../i18n/index';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';

const ShowWelcomePhrasesSwitcher = () => { 
    const [checked, setChecked] = useState(false);

    const changeShowWelcomePhrases = (value) => {
        setChecked(value);
        preferencesPhrasesStorage.changeFiltersList('isWelcome', value);
    };

    return (
        <label className="product__deleted-label" key={checked + 'phraseCheckbox'} onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            changeShowWelcomePhrases(!checked);
        }}>
            <Checkbox checked={checked} changeCheckboxValue={() => {}}/>
            <span className="block__text product__deleted-text">{i18n.get('app.page.preference.showIsWelcome')}</span>
        </label>
    );
};

export default ShowWelcomePhrasesSwitcher;
