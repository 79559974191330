import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PenIcon } from '../../appearence/icons/pen_icon';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';
import { PREFERENCE_TAB } from '../../constant';
import { PHRASE_URL_PART } from '../../store/requests';

const EditPhraseIcon = () => {
    const [isVisible, setIsVisible] = useState();
    const navigate = useNavigate();

    const changeIconVisibility = () => {
        const selectedPhrases = preferencesPhrasesStorage.getSelectedList();
        setIsVisible(selectedPhrases.length === 1 && selectedPhrases[0].isGenerated === false);
    };

    const openEditPhrasePage = () => {
        navigate(`/${PREFERENCE_TAB}${PHRASE_URL_PART}${preferencesPhrasesStorage.getSelectedList()[0].id}/`);
    };

    useEffect(() => {
        preferencesPhrasesStorage.registerFiltersChangingObserver(changeIconVisibility);
        return () => preferencesPhrasesStorage.unRegisterFiltersChangingObserver(changeIconVisibility);
    }, []);
    return (
        isVisible ?
            <PenIcon nameOfClass="preference__phrases-icon preference__phrases-edit" handleClick={openEditPhrasePage} />
            :
            null
    );
};

export default EditPhraseIcon;
