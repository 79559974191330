import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import { ADD_PRODUCT_MICRO_ELEMENTS, checkItemLocaleCode } from "../components/helper";
import { LOCALE_RU, PROPERTY_TYPE_SELECT, PROPERTY_TYPE_STRING, errorCallback, makeUrl, successCallbackEmpty } from "../constant";
import { i18n } from "../i18n";
import { PROPERTIES_URL, getFetchRequest, postFetchRequest } from "../store/requests";
import { propertiesService } from "./propertiesService";

export const FAT_SOLUBLE_VITAMIN_TYPE = 'fatSoluble';
export const WATER_SOLUBLE_VITAMIN_TYPE = 'waterSoluble';

const checkNeedToAddIsVitamin = (property) => property.isVitamin !== undefined && property.categoryName === ADD_PRODUCT_MICRO_ELEMENTS;

const preparePropertyItem = (property) => {
    const result = {
        categoryName: property.categoryName,
        description: property.description,
        propertyName: property.propertyName,
        propertyType: property.propertyType,
        numberUnits: property.numberUnits,
    };
    if(checkNeedToAddIsVitamin(property)) {
        result.isVitamin = property.isVitamin;
        result.isWaterSoluble = property.isWaterSoluble;
    }
    if(property.userDisplayGroup) {
        result.userDisplayGroup = property.userDisplayGroup;
    }
    if((property.propertyType === PROPERTY_TYPE_SELECT || property.propertyType === PROPERTY_TYPE_STRING) && property.selectValues) {
        result.selectValues = JSON.stringify(property.selectValues);
    } else {
        result.selectValues = null;
    }
    return result;
};

export const findCodeValue = (value, key, array) => {
    let result = '';
    array.forEach(it => it[key] === value ? result = it.id : null);
    return result;
};

export const createUserPropertyType = () => {
    return [
        {
            name: i18n.get('app.page.product.property.vitaminMineral'),
            id: 'vitamin_mineral',
        },
        {
            name: i18n.get('app.page.product.property.calories'),
            id: 'calorie_content',
        },
        {
            name: i18n.get('app.page.product.property.pfcPriority'),
            id: 'proteins_fats_carbohydrates',
        },
        {
            name: i18n.get('app.page.product.property.colorDiet'),
            id: 'color',
        },
    ];
};

export const propertySaveService = {
    currentProperty: {},
    lastPropertyUpdateTime: null,
    propertyUpdateObserver: [],
    loadProperty(propertyId, callback) {
        try {
            getFetchRequest(makeUrl([PROPERTIES_URL, propertyId, '/']), (response) => {
                this.currentProperty = {...response, locale: LOCALE_RU, 
                    locales: [{[LOCALE_RU]: i18n.get(`product.scheme.property.code.${response.propertyName}`, LOCALE_RU)}],
                    selectValues: response.selectValues ? JSON.parse(response.selectValues) : null};
                callback(response);
                this.notifyPropertyUpdateObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    getProperty() {
        return this.currentProperty;
    },
    getDateLastUpdate() {
        return this.lastPropertyUpdateTime;
    },
    registerItemUpdateObserver(observer) {
        this.propertyUpdateObserver.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.propertyUpdateObserver = this.propertyUpdateObserver.filter(propertyUpdateFunction => propertyUpdateFunction !== observer);
    },
    notifyPropertyUpdateObservers() {
        this.propertyUpdateObserver.forEach(propertyUpdateFunction => propertyUpdateFunction(this.currentProperty));
    },
    updateItemLocalization(locale, value) {
        try {
            i18n.update(propertiesService.getPropertyNamei18nCode(this.currentProperty), value, locale);
            if (this.currentProperty.locales && this.currentProperty.locales.length) {
                const index = this.currentProperty.locales.findIndex(it => it.localeCode === locale);
                index >= 0 ?
                    this.currentProperty.locales[index].localeValue = value
                    :
                    this.currentProperty.locales.push({localeCode: locale, localeValue:value});
            } else {
                this.currentProperty.locales.push({localeCode: locale, localeValue: value});
            }
            if(checkItemLocaleCode(this.currentProperty.propertyName)) { 
                updateValueSevice.updateLocaleValue(locale, propertiesService.getPropertyNamei18nCode(this.currentProperty), () => {
                    this.lastPropertyUpdateTime = Date.now();
                    this.notifyPropertyUpdateObservers();
                });
            }
        } catch(err) {
            console.log(err);
        }
    },
    updateValueLocalization(locale, value, code) {
        try {
            i18n.update(`product.scheme.property.value.${code}`, value, locale);
            if(checkItemLocaleCode(code)) {
                updateValueSevice.updateLocaleValue(locale, `product.scheme.property.value.${code}`, () => {});
            }
        } catch(err) {
            console.log(err);
        }
    },

    updatePropertyValue(filedName, value) {
        try {
            this.currentProperty[filedName] = value;
            this.notifyPropertyUpdateObservers();
            updateValueSevice.updateItemValue(() => {
                this.lastPropertyUpdateTime = Date.now();
                this.updateProperty();
            });
        } catch(err) {
            console.log(err);
        }
    },
    updateProperty() {
        try {
            postFetchRequest(makeUrl([PROPERTIES_URL, this.currentProperty.id, '/']), preparePropertyItem(this.currentProperty), successCallbackEmpty, errorCallback, true);
        } catch(err) {
            console.log(err);
        }
    },
    changeVitaminType(value) {
        try {
            this.currentProperty.isWaterSoluble = value;
            this.lastPropertyUpdateTime = Date.now();
            this.notifyPropertyUpdateObservers();
            this.updateProperty();
        } catch(err) {
            console.log(err);
        }
    },
    updatePropertyValueField(value) {
        try {
            if(this.currentProperty.selectValues) {
                this.currentProperty.selectValues.push(value);
            } else {
                this.currentProperty.selectValues = [value];
            }
            this.updateProperty();
            this.notifyPropertyUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    deleteSelectValue(value) {
        this.currentProperty.selectValues = this.currentProperty.selectValues.filter(it => it !== value);
        this.notifyPropertyUpdateObservers();
        this.updateProperty();
    },
};
