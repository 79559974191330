import { broadcastChannelService } from "../components/sharedComponents/broadcastChannelService";
import { checkItemLocaleCode } from "../components/helper";
import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import { LOCALE_RU, errorCallback, makeUrl } from "../constant";
import { i18n } from "../i18n";
import { FORMS_URL, getFetchRequest, postFetchRequest } from "../store/requests";

const prepareFormData = (form) => {
    const result = {};
    if(form.formGroup) {
        result.formGroup = form.formGroup;
    }
    if(form.formType) {
        result.formType = form.formType;
    }
    return result;
};

export const findCodeValue = (value, key, array) => {
    let result = '';
    array.forEach(it => it[key] === value ? result = it.id : null);
    return result;
};

export const formSaveService = {
    currentForm: {},
    lastFormUpdateTime: null,
    formUpdateObservers: [],
    loadForm(formId, callback) {
        try {
            getFetchRequest(makeUrl([FORMS_URL, formId, '/']), (response) => {
                this.currentForm = {...response, locale: LOCALE_RU, locales: [{[LOCALE_RU]: i18n.get(`product.scheme.form.code.${response.productForm}`, LOCALE_RU)}]};
                this.notifyFormUpdateObservers();
                callback(response);
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    getForm() {
        return this.currentForm;
    },
    getDateLastUpdate() {
        return this.lastFormUpdateTime;
    },
    registerItemUpdateObserver(observer) {
        this.formUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.formUpdateObservers = this.formUpdateObservers.filter(formUpdateFunction => formUpdateFunction !== observer);
    },
    notifyFormUpdateObservers() {
        this.formUpdateObservers.forEach(formUpdateFunction => formUpdateFunction(this.currentForm));
    },
    updateItemLocalization(locale, value) {
        try {
            i18n.update(`product.scheme.form.code.${this.currentForm.productForm}`, value, locale);
            if (this.currentForm.locales && this.currentForm.locales.length) {
                const index = this.currentForm.locales.findIndex(it => it.localeCode === locale);
                index >= 0 ?
                    this.currentForm.locales[index].localeValue = value
                    :
                    this.currentForm.locales.push({localeCode: locale, localeValue:value});
            } else {
                this.currentForm.locales.push({localeCode: locale, localeValue: value});
            }
            if(checkItemLocaleCode(this.currentForm.productForm)) {
                updateValueSevice.updateLocaleValue(locale, `product.scheme.form.code.${this.currentForm.productForm}`, () => {
                    this.lastFormUpdateTime = Date.now();
                    this.notifyFormUpdateObservers();
                });
            }
        } catch(err) {
            console.log(err);
        }
    },
    updateFormValue(filedName, value) {
        try {
            this.currentForm[filedName] = value;
            this.lastFormUpdateTime = Date.now();
            this.notifyFormUpdateObservers();
            this.updateForm();            
        } catch(err) {
            console.log(err);
        }
    },
    updateForm() {
        try {
            postFetchRequest(makeUrl([FORMS_URL, this.currentForm.id, '/']), prepareFormData(this.currentForm), () => {
                broadcastChannelService.sendFormUpdateMessage();
            }, errorCallback, true);
        } catch(err) {
            console.log(err);
        }
    },
    getLabelValue() {
        return i18n.get('app.page.product.addFormItem.formGroup');
    },
    getProductCategory() {
        return this.currentForm.formGroup;
    },
    updateProductCategory(value) {
        this.currentForm['formGroup'] = value;
        this.lastFormUpdateTime = Date.now();
        this.notifyFormUpdateObservers();
        this.updateForm();    
    },
    needToAddAny() {
        return true;
    },
};
