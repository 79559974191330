import React from 'react';
import { StarIcon } from '../../appearence/icons/star_icon';
import { i18n } from '../../i18n';

const PreferencesListSelectIsDefault = ({ phrasesListManager }) => {
    return (
        <button className="button preference__button_outline" onClick={() => phrasesListManager.updatePreferenceIsWelcome(true)}>
            <StarIcon nameOfClass="preference__button-icon"/>
            <span className="block__content preference__button-content">{i18n.get('app.page.preference.addInIsWelcome')}</span>
        </button>
    );
};

export default PreferencesListSelectIsDefault;
