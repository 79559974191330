import { errorCallback, makeUrl } from "../constant";
import { PROPERTIES_URL, getFetchRequest } from "../store/requests";
import { propertiesStorage } from "./propertiesStorage";

export const propertyDeleteService = {
    getWhereItemIsUsed(itemId, callback) {
        getFetchRequest(makeUrl([PROPERTIES_URL, itemId, '/used/']), callback, errorCallback);
    },
    getTranslationCode() {
        return 'app.page.property.hint';
    },
    checkIsDeletedItem(itemId) {
        return propertiesStorage.getById(itemId).isDeleted;
    },
};
