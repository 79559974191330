import React, { useState, useEffect } from 'react';
import SelectWithTokensSelectListItem from '../sharedComponents/SelectWithTokensSelectListItem';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';
import { i18n } from '../../i18n';

const CreateCerealMixBlock = ({ closeCallback, selectManager }) => {
    const [possibleListLength, setPossibleListLength] = useState();
    const addNewCerealMix = () => {
        preferenceCerealMixSelectService.addNewCerealMix(selectManager);
        closeCallback(prev => !prev);
    };
    
    const changePossibleList = () => {
        setPossibleListLength(selectManager.getPossibleItems().length);
    };

    useEffect(() => {
        selectManager.registerItemsChangingObserver(changePossibleList);
        changePossibleList();
        return () => {
            selectManager.unRegisterItemsChangingObserver(changePossibleList);
        };
    }, []);

    return (
        <ul>
            <div className="scroll product__forms-container">  
                {possibleListLength > 0 ?
                    selectManager.getPossibleItems().map((it) => (
                        <React.Fragment key={it.itemId}>
                            <SelectWithTokensSelectListItem selectWithTokensManager={selectManager}
                                selectItem={{...it, included: true}} />
                        </React.Fragment>
                    ))
                    :
                    null}
            </div>
            <div className="block__text preference__mix" onClick={() => closeCallback(false)}>
                <span className="preference__mix-button" onClick={() => closeCallback(false)}>{i18n.get("app.page.product.close")}</span>
                <span className="preference__mix-button" onClick={addNewCerealMix}>{i18n.get("app.page.product.add")}</span>
            </div>
        </ul>
    );
};

export default CreateCerealMixBlock;
