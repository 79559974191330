import React from "react";

export const StarIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="19" />
        <path d="M-30120 -2725C-30120 -2726.1 -30119.1 -2727 -30118 -2727H24790C24791.1 -2727 24792 -2726.1 24792 -2725V23077C24792 23078.1 24791.1 23079 24790 23079H-30118C-30119.1 23079 -30120 23078.1 -30120 23077V-2725Z" fill="#A2A2A2" />
        <path d="M-4108 -1404C-4108 -1405.1 -4107.1 -1406 -4106 -1406H9955C9956.1 -1406 9957 -1405.1 9957 -1404V2771C9957 2772.1 9956.1 2773 9955 2773H-4106C-4107.1 2773 -4108 2772.1 -4108 2771V-1404Z" fill="white" />
        <path d="M-4106 -1405H9955V-1407H-4106V-1405ZM9956 -1404V2771H9958V-1404H9956ZM9955 2772H-4106V2774H9955V2772ZM-4107 2771V-1404H-4109V2771H-4107ZM-4106 2772C-4106.55 2772 -4107 2771.55 -4107 2771H-4109C-4109 2772.66 -4107.66 2774 -4106 2774V2772ZM9956 2771C9956 2771.55 9955.55 2772 9955 2772V2774C9956.66 2774 9958 2772.66 9958 2771H9956ZM9955 -1405C9955.55 -1405 9956 -1404.55 9956 -1404H9958C9958 -1405.66 9956.66 -1407 9955 -1407V-1405ZM-4106 -1407C-4107.66 -1407 -4109 -1405.66 -4109 -1404H-4107C-4107 -1404.55 -4106.55 -1405 -4106 -1405V-1407Z" fillOpacity="0.1" />
        <g clipPath="url(#clip0_1011_12842)">
            <rect width="1920" height="1080" transform="translate(-1756 -792)" fill="#FAFAFA" />
            <g filter="url(#filter0_d_1011_12842)">
                <path d="M-1484 -426C-1484 -441.464 -1471.46 -454 -1456 -454H96C111.464 -454 124 -441.464 124 -426V190C124 205.464 111.464 218 96 218H-1456C-1471.46 218 -1484 205.464 -1484 190V-426Z" fill="white" />
                <g clipPath="url(#clip1_1011_12842)">
                    <g filter="url(#filter1_i_1011_12842)">
                        <g clipPath="url(#clip2_1011_12842)">
                            <path d="M-1444 -294H84V186C84 197.046 75.0457 206 64 206H-1424C-1435.05 206 -1444 197.046 -1444 186V-294Z" fill="#FFFDFC" />
                            <mask id="path-7-inside-1_1011_12842" fill="white">
                                <path d="M-1424 -22H64V42H-1424V-22Z" />
                            </mask>
                            <path d="M64 41H-1424V43H64V41Z" fill="#D2D2D2" mask="url(#path-7-inside-1_1011_12842)" />
                            <path d="M19.4998 8.69553L15.4796 12.1117L16.7044 17.2205C16.772 17.4978 16.7546 17.7887 16.6544 18.0563C16.5542 18.3239 16.3757 18.5562 16.1415 18.724C15.9073 18.8918 15.6279 18.9874 15.3385 18.9988C15.0491 19.0103 14.7628 18.937 14.5156 18.7883L10.0041 16.0539L5.48985 18.7883C5.24277 18.9361 4.95678 19.0087 4.66789 18.9969C4.37901 18.985 4.10014 18.8892 3.86641 18.7216C3.63268 18.554 3.45454 18.322 3.35442 18.0549C3.2543 17.7878 3.23667 17.4975 3.30376 17.2205L4.53305 12.1117L0.512852 8.69553C0.294241 8.50947 0.136137 8.26411 0.0582862 7.99008C-0.0195648 7.71606 -0.013713 7.42553 0.0751108 7.15476C0.163935 6.884 0.331794 6.645 0.557725 6.46762C0.783657 6.29024 1.05765 6.18234 1.34548 6.15739L6.6164 5.73862L8.64973 0.892845C8.75979 0.628753 8.94711 0.402855 9.18787 0.243872C9.42864 0.0848881 9.71197 0 10.0019 0C10.2917 0 10.5751 0.0848881 10.8158 0.243872C11.0566 0.402855 11.2439 0.628753 11.354 0.892845L13.3864 5.73862L18.6573 6.15739C18.9457 6.18141 19.2205 6.28871 19.4473 6.46585C19.674 6.64298 19.8427 6.88207 19.932 7.15317C20.0214 7.42426 20.0276 7.7153 19.9497 7.98982C19.8719 8.26434 19.7135 8.51014 19.4944 8.69641L19.4998 8.69553Z" fill="#264948" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
        <defs>
            <filter id="filter0_d_1011_12842" x="-1508" y="-478" width="1656" height="720" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <filter id="filter1_i_1011_12842" x="-1444" y="-304" width="1528" height="510" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="-10" />
                <feGaussianBlur stdDeviation="11" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.204167 0 0 0 0 0.204167 0 0 0 0 0.204167 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1011_12842" />
            </filter>
            <clipPath id="clip0_1011_12842">
                <rect width="1920" height="1080" fill="white" transform="translate(-1756 -792)" />
            </clipPath>
            <clipPath id="clip1_1011_12842">
                <rect width="1528" height="546" fill="white" transform="translate(-1444 -414)" />
            </clipPath>
            <clipPath id="clip2_1011_12842">
                <path d="M-1444 -294H84V186C84 197.046 75.0457 206 64 206H-1424C-1435.05 206 -1444 197.046 -1444 186V-294Z" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
