import { errorCallback, makeUrl } from "../constant";
import { FORMS_URL, getFetchRequest } from "../store/requests";
import { formsStorage } from "./formsStorage";

export const formDeleteService = {
    getWhereItemIsUsed(itemId, callback) {
        getFetchRequest(makeUrl([FORMS_URL, itemId, '/used/']), callback, errorCallback);
    },
    getTranslationCode() {
        return 'app.page.form.hint';
    },
    checkIsDeletedItem(itemId) {
        return formsStorage.getById(itemId).isDeleted;
    },
};
