import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n/index';
import { FilterIcon } from '../../appearence/icons/filter_icon';
import OpenFilterButtonIcon from './OpenFilterButtonIcon';
import { filtersListIsOpenStateObject } from './FiltersListIsOpenStateObject';

const OpenFilterButton = ({ itemsStorageService }) => {
    const [buttonIsActive, setButtonIsActive] = useState(false);

    const changeButtonState = (value) => {
        setButtonIsActive(value);
    };

    useEffect(() => {
        filtersListIsOpenStateObject.registerFiltersListObserver(changeButtonState);
        return () => filtersListIsOpenStateObject.unRegisterFiltersListObserver(changeButtonState);
    }, []);
    return (
        <button className={`product__search-button ${buttonIsActive ? 'product__search-button_active' : ''}`} 
            onClick={() => filtersListIsOpenStateObject.callToOpen(!buttonIsActive)}>
            <FilterIcon nameOfClass="product__search-icon" />
            <span className="block__button product__search-text">{i18n.get("app.page.product.filter")}</span>
            <OpenFilterButtonIcon itemsStorageService={itemsStorageService}/>
        </button>
    );
};

export default OpenFilterButton;
