export const filtersListIsOpenStateObject = {
    observers: [],
    callToOpen(popupParams) {
        try {
            this.observers.forEach(observer => observer(popupParams));
        } catch(err) {
            console.log(err);
        }
    },

    registerFiltersListObserver(openStateFunction) {
        this.observers.push(openStateFunction);
    },

    unRegisterFiltersListObserver(openStateFunction) {
        try {
            this.observers = this.observers.filter(it => it !== openStateFunction);
        } catch(err) {
            console.log(err);
        }
    },
};
