import React, { useState } from 'react';
import { preferenceGroupSaveService } from '../../services/preferenceGroupSaveService';
import { i18n } from '../../i18n';
import Switch from '../sharedComponents/Switch';

const IsAutoAssignedSwitcher = () => {
    const [isAutoAssigned, setIsAutoAssigned] = useState(preferenceGroupSaveService.getPreferenceGroup().isAutoAssigned);

    const switchAutoAssigned = () => {
        preferenceGroupSaveService.updatePreferenceGroupAutoAssigned(!isAutoAssigned);
        setIsAutoAssigned(prev => !prev);
    };

    return (
        <label className="block__text preference__group-label">
            <Switch value={isAutoAssigned} changeIsVitaminValue={switchAutoAssigned}/>
            <span className="button__content">{i18n.get('app.page.preference.groups.isAutoAssigned')}</span>
        </label>
    );
};

export default IsAutoAssignedSwitcher;
