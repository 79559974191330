import React, { useState, useEffect, useRef, useCallback } from 'react';
import { i18n } from '../../../i18n';
import { CheckIcon } from '../../../appearence/icons/check_icon';
import { DIGITS_REG_EXP, LOCALE_RU, MOUSE_DOWN_EVENT } from '../../../constant';
import { findLogicUnits } from '../../helper';

export const MCG_UNIT_NAME = 'mcg';
export const MG_UNIT_NAME = 'mg';
export const G_UNIT_NAME = 'g';
export const KCAL_UNIT_NAME = 'kcal';

const checkValueWithDot = (value, localeDecimalSymbol) => 
    value && (value.slice(-1) === localeDecimalSymbol || 
        value[0] === localeDecimalSymbol || (value[0] === '0' && value.includes(localeDecimalSymbol)));

export const createProductUnitObject = () => { 
    return {
        [MCG_UNIT_NAME]: {
            key: MCG_UNIT_NAME,
            value: 1,
            title: i18n.get('app.page.product.productUnitMcgTitle'),
        },
        [MG_UNIT_NAME]: {
            key: MG_UNIT_NAME,
            value: 1000,
            title: i18n.get('app.page.product.productUnitMgTitle'),
        },
        [G_UNIT_NAME]: {
            key: G_UNIT_NAME,
            value: 1000000,
            title: i18n.get('app.page.product.productUnitGTitle'),
        },
    };
};

const UnitItem = ({unitValue, setUnitValue}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleChangeUnitValue = (e, option) => {
        e.stopPropagation();
        setUnitValue(option);
        setMenuIsOpen(false);
    };

    return (
        <div className="block__content product__unit">
            <span className="product__unit-text" onClick={() => unitValue !== KCAL_UNIT_NAME ? setMenuIsOpen(true) : null}>{unitValue}</span>
            {menuIsOpen ?
                <ul className="product__unit-list">
                    {Object.values(createProductUnitObject()).map(it => (
                        <li key={it.key} className={`product__unit-item ${unitValue === it.key ? 'product__unit-item_active' : ''}`}
                            onClick={(e) => handleChangeUnitValue(e, it.key)}>
                            {unitValue === it.key ? <CheckIcon nameOfClass="product__unit-icon" /> : null}
                            {it.title}
                        </li>
                    ))}
                </ul>
                :
                null}
        </div>
    );
};

const createFormattedString = (value, unitValue, localeDecimalSymbol) => {
    let result = value;
    if(checkValueWithDot(value, localeDecimalSymbol)) {
        return result;
    }
    if(value !== null && unitValue === MG_UNIT_NAME) {
        result = (+value / 1000).toString().replace('.', localeDecimalSymbol);
    }
    if(value !== null && unitValue === G_UNIT_NAME) {
        result = (+value / 1000000).toString().replace('.', localeDecimalSymbol);
    }
    return result;
};

const transformValueForUpdate = (value, unitValue, localeDecimalSymbol) => {
    let result = value;
    if(checkValueWithDot(value, localeDecimalSymbol)) {
        return result;
    }
    if(value !== null && unitValue === MG_UNIT_NAME) {
        result = Math.round((+value.replace(localeDecimalSymbol, '.') * 1000)).toString();
    }
    if(value !== null && unitValue === G_UNIT_NAME) {
        result = Math.round((+value.replace(localeDecimalSymbol, '.') * 1000000)).toString();
    }
    return result;
};

const createLabelName = (fieldName) => {
    return fieldName.substring(0, 3);
};

const findDefaultUnitValue = (propertyId, itemSaveService, value) => {
    if(itemSaveService.getProperty(propertyId).numberUnits === KCAL_UNIT_NAME) {
        return KCAL_UNIT_NAME;
    } else {
        return findLogicUnits(value);
    }
};

const ProductPropertyNumberValue = ({propertyId, fieldName, fieldValue, itemSaveService}) => {
    const [showChangeUnitMenu, setShowChangeUnitMenu] = useState(false);
    const [unitValue, setUnitValue] = useState(findDefaultUnitValue(propertyId, itemSaveService, fieldValue));
    const [currentDisplayUnit, setCurrentDisplayUnit] = useState(fieldValue);
    const [localeDecimalSymbol] = useState(i18n.APP_LOCALE === LOCALE_RU ? ',' : '.');
    const wrapperRef = useRef(null);

    const loseUnitFocus = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShowChangeUnitMenu(false);
        }
    }, [unitValue]);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, loseUnitFocus);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, loseUnitFocus);
        };
    }, [loseUnitFocus]);

    const changeVisibleInput = (e) => {
        if(e.target.value.match(DIGITS_REG_EXP) || e.target.value === '') {
            changePropertyValue(transformValueForUpdate(e.target.value, unitValue, localeDecimalSymbol));
        }
    };

    const changePropertyValue = (value) => {
        setCurrentDisplayUnit(value);
        if(!checkValueWithDot(value, localeDecimalSymbol)) {
            itemSaveService.updateProductProperty(propertyId, fieldName, value);
        }
    };

    return (
        <label key={propertyId + fieldName} ref={wrapperRef} className="block__text product__form-label product__add-label">
            <span className="product__form-number product__form-label_text">{createLabelName(fieldName)} {i18n.get('product.scheme.property.code.valueOfData')}</span>
            <input className={`product__form-input product__add-input ${showChangeUnitMenu ? 'product__add-number' : ''}`} 
                value={createFormattedString(currentDisplayUnit, unitValue, localeDecimalSymbol) || ''} 
                onChange={changeVisibleInput} name={propertyId}
                onFocus={() => setShowChangeUnitMenu(true)} 
                onMouseOver={() => setShowChangeUnitMenu(true)}
            />
            {showChangeUnitMenu ?
                <UnitItem unitValue={unitValue} setUnitValue={setUnitValue}/>
                :
                null
            }
        </label>
    );
};

export default ProductPropertyNumberValue;
