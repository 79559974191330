import React, { useState, useEffect, useRef } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { checkIsOverflowed } from '../../hooks/useOverflowed';
import HintItem from './HintItem';

const HINT_ITEM_GAP = 10;

const findUsedItemInList = (listOfItems, itemId) => {
    return listOfItems.find(it => it.itemId === itemId);
};

const SelectWithTokensSelectListItem = ({ selectItem, selectWithTokensManager }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef();
    const width = useWindowDimensions();
    
    useEffect(() => {
        setIsOverflowed(checkIsOverflowed(ref.current));
    }, [width]);

    const selectListItem = (e) => {
        e.stopPropagation();
        e.preventDefault();
        selectWithTokensManager.addItem(selectItem.itemId, selectItem.included);
    };

    useEffect(() => {
        const hint = document.getElementsByClassName('item__hint-container')[0];
        if(hint && hint.style) {
            hint.style.position = 'fixed';
            hint.style.left = ref.current.getBoundingClientRect().left;
            hint.style.top = ref.current.getBoundingClientRect().top - hint.offsetHeight - HINT_ITEM_GAP;
            hint.style.height = 'max-content';
        }
    }, [isHovered]);

    return (
        <>
            <li key={selectItem.itemId + 'selected' + selectItem.itemId} 
                onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
                className={`block__content product__forms-option 
                    ${findUsedItemInList(selectWithTokensManager.getCurrentItems(), selectItem.itemId) ? 'product__forms-option_active' : ''}`} 
                onClick={(e) => findUsedItemInList(selectWithTokensManager.getCurrentItems(), selectItem.itemId) ? null : selectListItem(e, selectItem.itemId)}>
                <span className="product__forms-content" ref={ref}>{selectItem.itemTitle}</span>
                
            </li>
            {isOverflowed && isHovered ? <HintItem message={selectItem.itemTitle} /> : null}
        </>
    );
};

export default SelectWithTokensSelectListItem;
