import React from 'react';
import { UserIcon } from '../../appearence/icons/user_icon';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_ASSIGN_USER } from '../../constant';
import { i18n } from '../../i18n';

const PreferencesListSelectAssignUser = () => {
    const openAssignUserPopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_ASSIGN_USER);
    };

    return (
        <button type="button" className="button preference__button_outline" onClick={openAssignUserPopup}>
            <UserIcon nameOfClass="preference__button-icon"/>
            <span className="block__content preference__button-content">{i18n.get('app.page.preference.assignUser')}</span>
        </button>
    );
};

export default PreferencesListSelectAssignUser;
