import React, { useState, useEffect } from 'react';
import { preferenceGroupSaveService } from '../../services/preferenceGroupSaveService';
import PreferenceGroupActivityPeriodsItem from './PreferenceGroupActivityPeriodsItem';

const PreferenceGroupActivityPeriodsList = () => {
    const [periodsListIsChanged, setPeriodsListIsChanged] = useState(0);

    const changePreferenceActivityPeriods = () => {
        if(preferenceGroupSaveService.getPreferenceGroup().activityPeriods) {
            setPeriodsListIsChanged(preferenceGroupSaveService.getPreferenceGroup().activityPeriods.length);
        }
    };

    useEffect(() => {
        preferenceGroupSaveService.registerPreferenceGroupUpdateObserver(changePreferenceActivityPeriods);
        return () => preferenceGroupSaveService.unRegisterPreferenceGroupUpdateObserver(changePreferenceActivityPeriods);
    }, []);
    return (
        <>
            <ul className="preference__period-list" key={periodsListIsChanged + 'listGroup'}>
                {preferenceGroupSaveService.getPreferenceGroup().activityPeriods 
                    && preferenceGroupSaveService.getPreferenceGroup().isPermanent === false ?
                    preferenceGroupSaveService.getPreferenceGroup().activityPeriods.map((it, index) => (
                        <li className="preference__period-item" key={index + it.dateStart + it.dateStop}>
                            <PreferenceGroupActivityPeriodsItem currentPeriodIndex={index} />
                        </li>
                    ))
                    :
                    null} 
            </ul>
        </>
    );
};

export default PreferenceGroupActivityPeriodsList;
