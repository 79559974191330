import React from 'react';
import { i18n } from '../../../i18n';
import { formSaveService } from '../../../services/formSaveService';
import SelectWithoutTokens from '../../sharedComponents/SelectWithoutTokens';

const makeFormType = () => {
    return [
        {
            id: 'productPart',
            name: i18n.get(`product.scheme.formType.productPart`),
        },
        {
            id: 'heatTreatment',
            name: i18n.get(`product.scheme.formType.heatTreatment`),
        },
    ];
};

const getFormTitle = (formType) => {
    if(formType) {
        return i18n.get(`product.scheme.formType.${formType}`);
    } else {
        return '';
    }
};

const FormItemType = () => {
    const changeFormType = (form) => {
        formSaveService.updateFormValue('formType', form.id);
    };

    return (
        <label className="block__content product__form-label product__form-item" key={formSaveService.getForm().formType + 'label'}>
            <span className="block__text product__form-label_text">{i18n.get('app.page.product.addFormItem.formType')}</span>
            <SelectWithoutTokens listOfOptions={makeFormType()} handleChangeValue={changeFormType}
                titleValue={getFormTitle(formSaveService.getForm().formType)}/>
        </label>
    );
};

export default FormItemType;
