import { formsStorage } from "../../services/formsStorage";
import { i18n } from "../../i18n";

const UPDATE_LOCALE_CHANNEL = 'locales-channel';
const UPDATE_FORM_CHANNEL = 'form-update-channel';

export const broadcastChannelService = {
    broadcastUpdateLocaleChannel: null,
    broadcastUpdateFormChannel: null,
    createLocaleConnection() {
        this.broadcastUpdateLocaleChannel = new BroadcastChannel(UPDATE_LOCALE_CHANNEL);
        this.broadcastUpdateLocaleChannel.onmessage = (event) => {
            i18n.create(event.data.body, event.data.locale);
        };
    },
    getBroadcastLocaleChannel() {
        return this.broadcastUpdateLocaleChannel;
    },
    sendLocaleMessage(body, locale) {
        this.broadcastUpdateLocaleChannel.postMessage({body: {[locale]: body}, locale});
    },
    createFormUpdateConnection() {
        this.broadcastUpdateFormChannel = new BroadcastChannel(UPDATE_FORM_CHANNEL);
        this.broadcastUpdateFormChannel.onmessage = () => {
            formsStorage.loadItems(() => {});
        };
    },
    getBroadcastFormChannel() {
        return this.broadcastUpdateFormChannel;
    },
    sendFormUpdateMessage() {
        this.broadcastUpdateFormChannel.postMessage({body: null});
    },

};
