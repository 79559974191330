import React from 'react';
import { popupOpenService } from '../../../services/popupOpenService';
import { POPUP_KEY_CREATE_PROPERTY } from '../../../constant';
import { i18n } from '../../../i18n';

const CreateNewPropertyButton = () => {
    const openPopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_CREATE_PROPERTY, null);
    };

    return (
        <button className='block__button button button_outline product__button' 
            onClick={openPopup}>
            <span className="button__content">{i18n.get('app.page.product.addProperty')}</span>
        </button>
    );
};

export default CreateNewPropertyButton;
