import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PenIcon } from '../../appearence/icons/pen_icon';
import { preferencesStorage } from '../../services/preferencesStorage';

const PreferencesListActionsEditIcon = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();

    const changeIsDisabled = () => {
        setIsDisabled(preferencesStorage.getSelectedPreferencesList().length !== 1);
    };

    useEffect(() => {
        preferencesStorage.registerFiltersChangingObserver(changeIsDisabled);
        return () => preferencesStorage.unRegisterFiltersChangingObserver(changeIsDisabled);
    }, []);

    const navigateOnEditPage = () => {
        if(!isDisabled) {
            navigate(`/preference/item/${preferencesStorage.getSelectedPreferencesList()[0].id}`);
        }
    };

    return (
        <li onClick={navigateOnEditPage}>
            <PenIcon handleClick={() => {}} nameOfClass={`preference__groups-icon
                ${!isDisabled ? '' : 'preference__groups-icon_disabled'}`
            } />
        </li>
    );
};

export default PreferencesListActionsEditIcon;
