import React from 'react';

export const CommitIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6667 11.4999C13.6667 10.4874 12.8459 9.66659 11.8333 9.66659C10.8208 9.66659 10 10.4874 10 11.4999C10 12.5124 10.8208 13.3333 11.8333 13.3333C12.8459 13.3333 13.6667 12.5124 13.6667 11.4999Z" stroke="#264948" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.832 17V13.3333M11.832 9.66667V6" stroke="#264948" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 17L22.3987 11.9174C22.722 11.6131 22.715 11.0972 22.3837 10.8017L17 6" stroke="#264948" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M7 6L1.60129 11.0826C1.27804 11.3869 1.28498 11.9028 1.61631 12.1983L7 17" stroke="#264948" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
);
