import React, { useEffect, useState } from 'react';
import { POPUP_KEY_ASSIGN_GROUP, errorCallback, makeUrl } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { preferencesStorage } from '../../services/preferencesStorage';
import { PREFERENCE_URL, deleteFetchRequest, postFetchRequest } from '../../store/requests';
import Modal from '../Modal';
import { MODAL_SMALL_SIZE } from '../helper';
import { i18n } from '../../i18n';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import AssignPreferenceGroupsList from './AssignPreferenceGroupsList';

const AssignGroupModalContent = ({ handleClose, assignPreferences }) => {
    return (
        <>
            <h2 className="block__title-h3 preference__group-subtitle">
                {i18n.get('app.page.preference.preferenceAssignGroup')}
            </h2>
            <AssignPreferenceGroupsList />
            <div className="block__button modal__button-group">
                <button className="block__button button preference__users-button" onClick={handleClose}>
                    <span className="button__content">{i18n.get('app.page.preference.modal.cancel')}</span>
                </button>
                <button className="block__button button button_outline preference__users-button" onClick={assignPreferences}>
                    <span className="button__content">{i18n.get('app.page.preference.modal.assign')}</span>
                </button>
            </div>
        </>
    );
};

const prepareGroupsBody = () => {
    const preparedList = [];
    preferencesGroupsStorage.getSelectedList().forEach(it => preparedList.push({groupId: it.id}));
    return preparedList;
};

const AssignGroupToPreferencePopup = ({ notifyPreferencesList }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
        if(preferencesStorage.getSelectedPreferencesList().length === 1) {
            preferencesStorage.getSelectedPreferencesList()[0].groups.forEach(it => preferencesGroupsStorage.changeSelectedPreferenceGroups(it.id));
        }
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_ASSIGN_GROUP, handleOpenModal);
        return () =>{
            popupOpenService.unRegisterPopup(POPUP_KEY_ASSIGN_GROUP, handleOpenModal);
            preferencesGroupsStorage.changeSelectedPreferenceGroups('', true);
        };
    },[]);

    const closePopup = () => {
        setIsOpen(false);
        preferencesGroupsStorage.changeSelectedPreferenceGroups('', true);
    };

    const assignPreferences = () => {        
        if(preferencesStorage.getSelectedPreferencesList().length) {
            for(let preference of preferencesStorage.getSelectedPreferencesList()) {
                if(preferencesStorage.getSelectedPreferencesList().length === 1) {
                    for(let group of preference.groups) {
                        if(!preferencesGroupsStorage.getSelectedList().find(it => it.id === group.id)) {
                            deleteFetchRequest(makeUrl([PREFERENCE_URL, preference.id, '/group/']), () => {}, errorCallback, [{groupId: group.id}]);
                        }
                    }
                }
                postFetchRequest(makeUrl([PREFERENCE_URL, preference.id, '/group/']), prepareGroupsBody(), () => {
                    notifyPreferencesList();
                    closePopup();
                    preferencesGroupsStorage.changeSelectedPreferenceGroups(null, true);
                    preferencesStorage.changeSelectedPreferencesList(null, true);  
                }, errorCallback, true);
            }
        }
    };

    return (
        isOpen ?
            <Modal content={<AssignGroupModalContent handleClose={closePopup} assignPreferences={assignPreferences} />}  
                handleCloseModal={closePopup} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default AssignGroupToPreferencePopup;
