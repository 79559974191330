import React, { useState, useEffect } from 'react';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import { i18n } from '../../i18n';

const ExcludeFormButton = ({ callback, isVisible }) => {
    const [isBlockOpened, setIsBlockOpened] = useState(isVisible);

    const closeItself = (e) => {
        e.preventDefault();
        e.stopPropagation();
        callback(!isVisible);
    };

    useEffect(() => {
        setIsBlockOpened(isVisible);
    }, [isVisible]);

    return (
        <div className="block__text product__forms-exclude" onClick={closeItself}>
            <span className="product__forms-content">{i18n.get("app.page.product.excludeForm")}</span>
            <ChevronIcon
                nameOfClass={`product__forms-icon product__forms-icon_exclude ${isBlockOpened ? 'product__forms-icon_active' : ''}`} />
        </div> 
    );
};

export default ExcludeFormButton;
