import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { preferencesStorage } from '../../services/preferencesStorage';
import PreferencesListItem from './PreferencesListItem';
import { ErrorHandler } from '../ErrorHandler';
import { i18n } from '../../i18n';
import PreferencesListSelectBlock from './PreferencesListSelectBlock';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { preferencePhraseItemService } from '../../services/preferencePhraseService';

const PreferencesList = () => {
    const [searchParams] = useSearchParams();
    const [preferencesListIsChanged, setPreferencesListIsChanged] = useState(false);

    const changePreferences = () => {
        setPreferencesListIsChanged(prev => !prev);
    };

    useEffect(() => {
        preferencesGroupsStorage.loadItems(() => {});
        preferencesStorage.loadItems(() => {});
        preferencesStorage.getInitialFilters(searchParams);
        preferencesStorage.registerFiltersChangingObserver(changePreferences);
        return () => {
            preferencesStorage.unRegisterFiltersChangingObserver(changePreferences);
            preferencesStorage.changeSelectedPreferencesList('', true);
            preferencesStorage.clearService();
        };
    }, []);
    return (
        preferencesStorage.getItems().length && preferencesListIsChanged !== null ?   
            <>  
                <ul className="preference__list">
                    {preferencesStorage.getItems().map((preference, index) => (
                        <React.Fragment key={preference.id + index}>
                            <PreferencesListItem preference={preference}/>
                        </React.Fragment>
                    ))}
                </ul>
                <PreferencesListSelectBlock phrasesListManager={preferencePhraseItemService} />
            </>  
            :
            <ErrorHandler isSmall={true} titleValue={i18n.get('app.page.profile.emptyData')} />
    );
};

export default PreferencesList;
