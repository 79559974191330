import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationTabItem = ({ pageUrl, title, isActive }) => {
    const navigate = useNavigate();

    const routeToThePage = (route) => {
        navigate(route, { replace: true });
    };

    return (
        <h2 key={pageUrl + title}
            className={`main__subtitle navigation__item ${isActive ? 'navigation__item_active' : ''}`} 
            onClick={() => routeToThePage(pageUrl)}>
            {title}
        </h2>
    );
};

export default NavigationTabItem;
