import React, { useEffect, useState } from 'react';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import { propertiesStorage } from '../../services/propertiesStorage';
import ProductPropertyNumberValue from '../productsTab/products/ProductPropertyNumberValue';
import ProductPropertyInput from '../productsTab/products/ProductPropertyInput';
import ProductPropertyBoolean from '../productsTab/products/ProductPropertyBoolean';
import ProductPropertySelect from '../productsTab/products/ProductPropertySelect';
import { createSelectPreferencePropertyService } from '../../services/preferenceSelectService';
import { PROPERTY_TYPE_BOOLEAN, PROPERTY_TYPE_NUMBER, PROPERTY_TYPE_SELECT, PROPERTY_TYPE_STRING } from '../../constant';
import { i18n } from '../../i18n';

const findPropertyByCode = (itemCode) => propertiesStorage.getItems(true).find(it => it.propertyName === itemCode);

const PreferenceStructureValue = () => {
    const [propertiesStorageIsReady, setPropertiesStorageIsReady] = useState(false);
    const findCurrentValueType = () => {
        const property = findPropertyByCode(preferenceSaveService.getProperty().itemCode);
        if(property) {
            switch (property.propertyType) {
            case PROPERTY_TYPE_NUMBER: {
                return (
                    <div className="product__form-block">
                        <ProductPropertyNumberValue 
                            propertyId={null} 
                            fieldName={'minValue'} 
                            fieldValue={preferenceSaveService.getProperty().minValue}
                            itemSaveService={preferenceSaveService} />
                        <ProductPropertyNumberValue 
                            propertyId={null} 
                            fieldName={'maxValue'} 
                            fieldValue={preferenceSaveService.getProperty().maxValue}
                            itemSaveService={preferenceSaveService} />
                    </div>
                );
            }
            case PROPERTY_TYPE_STRING: {
                return <ProductPropertyInput propertyId={null} itemSaveService={preferenceSaveService} />;
            }
            case PROPERTY_TYPE_BOOLEAN: {
                return <ProductPropertyBoolean propertyId={null} itemSaveService={preferenceSaveService} />;
            }
            case PROPERTY_TYPE_SELECT: {
                return <>
                    <span className="preference__form-value">{i18n.get('product.scheme.property.code.valueOfData')}</span>
                    <ProductPropertySelect selectWithTokensManager={createSelectPreferencePropertyService(property.id)}/>
                </>;
            }
            default: {
                return null;
            }}
        }
    };

    const changePropertiesList = () => {
        setPropertiesStorageIsReady(propertiesStorage.getItems(true).length > 0);
    };

    useEffect(() => {
        propertiesStorage.registerFiltersChangingObserver(changePropertiesList);
        return () => propertiesStorage.unRegisterFiltersChangingObserver(changePropertiesList);
    }, []);

    return (
        <React.Fragment key={propertiesStorageIsReady + 'preferencePropertyValue'}>
            {findCurrentValueType()}
        </React.Fragment>
    );
};

export default PreferenceStructureValue;
