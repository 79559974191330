import React, { useEffect, useState } from 'react';
import { preferenceGroupSaveService } from '../../services/preferenceGroupSaveService';
import { PREFERENCE_GROUP_ACTIVITY_PERIOD_ALWAYS } from '../../constant';
import { i18n } from '../../i18n';
import RadioLabel from '../RadioLabel';

const getCurrentPreferenceGroup = (periodTypeName) => {
    if(periodTypeName === PREFERENCE_GROUP_ACTIVITY_PERIOD_ALWAYS) {
        return preferenceGroupSaveService.getPreferenceGroup().isPermanent === true;
    } else {
        return preferenceGroupSaveService.getPreferenceGroup().isPermanent === false;
    }
};

const PreferenceGroupActivityPeriodType = ({ periodTypeName }) => {
    const [activityPeriodValue, setActivityPeriodValue] = useState(getCurrentPreferenceGroup(periodTypeName));

    const changeActivityPeriods = () => {
        setActivityPeriodValue(getCurrentPreferenceGroup(periodTypeName));
    };

    useEffect(() => {
        preferenceGroupSaveService.registerPreferenceGroupUpdateObserver(changeActivityPeriods);
        return () => preferenceGroupSaveService.unRegisterPreferenceGroupUpdateObserver(changeActivityPeriods);
    }, []);

    const changeIsPermanentValue = () => {
        preferenceGroupSaveService.updatePreferenceGroupActivityType(periodTypeName === PREFERENCE_GROUP_ACTIVITY_PERIOD_ALWAYS);
    };

    return (
        <RadioLabel
            value={activityPeriodValue} 
            type={periodTypeName}
            title={i18n.get(`app.page.preference.groups.${periodTypeName}`)} 
            changeValue={changeIsPermanentValue}
        />
    );
};

export default PreferenceGroupActivityPeriodType;
