import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LOCALE_RU } from '../../../constant';
import { propertiesService } from '../../../services/propertiesService';
import ItemName from '../../sharedComponents/ItemName';
import PageTitleAsBreadCrumbs from '../../sharedComponents/PageTitleAsBreadCrumbs';
import { propertySaveService } from '../../../services/propertySaveService';
import PropertyItemDescription from './PropertyItemDescription';
import PropertyItemType from './PropertyItemType';
import PropertyItemCategory from './PropertyItemCategory';
import PropertyItemVitaminSlider from './PropertyItemVitaminSlider';
import NonEditableItemCode from '../../sharedComponents/NonEditableItemCode';
import LastUpdateTime from '../../sharedComponents/LastUpdateTime';
import PropertyItemUserDisplayList from './PropertyItemUserDisplayList';

const EditPropertyTab = () => {
    const [activePropertyItem, setActivePropertyItem] = useState({});
    const { id } = useParams();

    const successGetProperty = (response) => {
        setActivePropertyItem({...response, locales:[], locale:LOCALE_RU});
    };

    useEffect(() => {
        propertySaveService.loadProperty(id, successGetProperty);
    }, [id]);

    return (
        <>
            <PageTitleAsBreadCrumbs />
            <article className="product__article product__article-add">
                <LastUpdateTime itemSaveService={propertySaveService} itemCode={activePropertyItem.propertyName}/>
                <form className="product__form product__form_large">
                    <ItemName itemCode={activePropertyItem.propertyName} itemService={propertiesService} itemSaveService={propertySaveService}/>
                    <NonEditableItemCode itemCode={activePropertyItem.propertyName} />
                    <PropertyItemType />
                    <PropertyItemCategory />
                    <PropertyItemVitaminSlider />
                    <PropertyItemUserDisplayList />
                    <PropertyItemDescription />
                </form>
            </article>
        </> 
    );
};

export default EditPropertyTab;
