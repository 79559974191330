import { formsService } from "./formsService";
import { FILTER_GROUP_NAME_STATUS, FILTER_NAME_IS_DELETED, FILTER_NAME_IS_NOT_VALID, FILTER_TYPE_CHECKBOX, ITEM_COUNT_FULL_WIDTH, ITEM_COUNT_SMALL_WIDTH, errorCallback, makeUrl } from "../constant";
import { FORMS_URL, getFetchRequest } from "../store/requests";
import { i18n } from '../i18n';
import { createFiltersListProductCategories, isSmallWebScreen, sortByDate } from '../components/helper';

const createListOfFilters = () => {
    return [ 
        ...createFiltersListProductCategories(true, 'formGroup'),
        {
            filterTitle: i18n.get('product.scheme.formType.heatTreatment'),
            filterName: 'heatTreatment',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'formType',
        },
        {
            filterTitle: i18n.get('product.scheme.formType.productPart'),
            filterName: 'productPart',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'formType',
        },
        {
            filterTitle: i18n.get('product.scheme.filter.isDeleted'),
            filterName: 'isDeleted',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: FILTER_GROUP_NAME_STATUS,
        },
        {
            filterTitle: i18n.get('product.scheme.filter.isNotValid'),
            filterName: 'isNotValid',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: FILTER_GROUP_NAME_STATUS,
        },
    ];
};

const createQueryParams = (queryParams, showDeletedForms, isNotValid) => {
    return queryParams ? 
        queryParams 
        : 
        `?${showDeletedForms ? 'withDeleted=true&' : ''}${isNotValid ? 'valid=false&' : ''}${showDeletedForms || isNotValid ? 'withStatus=true' : ''}`;
};

const filterBySearchValue = (filteredList, searchValue) => {
    return filteredList.filter(it => i18n.get(formsService.geti18nCode(it)).toLowerCase().includes(searchValue));
};

export const formsStorage = {
    formsList: [],
    filtersList: [],
    searchValue: '',
    possibleFilters: [],
    offset: 8,
    page: 1,
    filter: '',
    maxPages: 1,
    observersList: [],
    showDeletedForms: false,
    showIsNotValid: false,
    loadItems(callback, width, queryParams) {
        try {
            const preperedQueries = createQueryParams(queryParams, this.showDeletedForms, this.showIsNotValid);
            getFetchRequest(makeUrl([FORMS_URL, preperedQueries]), (response) => {
                this.formsList = response.sort(sortByDate);
                this.offset = isSmallWebScreen(width) ? ITEM_COUNT_SMALL_WIDTH : ITEM_COUNT_FULL_WIDTH;
                this.reloadPagesNumber();
                callback && callback();
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    cleanStorage() {
        this.formsList = [];
    },
    
    getItems(loadAllData) {
        let filteredList = [...this.formsList];
        if(this.searchValue) {
            filteredList = filterBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => this.filtersList.findIndex(filter => Object.values(it).includes(filter.filterName) || 
                filter.filterGroupName === FILTER_GROUP_NAME_STATUS) >= 0);
        }
        if(this.showDeletedForms) {
            filteredList = filteredList.filter(it => it.isDeleted);
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
        return loadAllData ? filteredList : filteredList.slice((this.page - 1) * this.offset, this.page * this.offset);
    },

    getMaxCount() {
        return this.maxPages;
    },

    getCurrentPage() {
        return this.page;
    },

    setPageNumber(value) {
        this.page = value;
        this.notifyFiltersChangingObservers();
    },

    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },

    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    
    getById(itemId) {
        return this.formsList.find(it => it.id === itemId);
    },

    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value, key) => {
            const currentFilter = this.possibleFilters.find(it => it.filterName === key);
            if(currentFilter.filterType === FILTER_TYPE_CHECKBOX && value === 'true') {
                currentFilter.value = value;
                filtersList.push(currentFilter);
            }
            if(currentFilter.filterName === FILTER_NAME_IS_DELETED && value === 'true') {
                this.changeDeletedItemsView(true);
            }
            if(currentFilter.filterName === FILTER_NAME_IS_NOT_VALID && value === 'true') {
                this.changeValidItemsView(true);
            }
        });
        this.filtersList = filtersList;
    },
    getPossibleFilters() {
        this.possibleFilters = createListOfFilters();
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    getFilter(filterName) {
        return this.possibleFilters.find(it => it.filterName === filterName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.reloadPagesNumber();
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedKeys) {
        const currentFilter = this.filtersList.find(it => it.filterName === filterName);
        if(currentFilter) {
            currentFilter.value = selectedKeys;
        } else {
            const possibleFilter = this.possibleFilters.find(it => it.filterName === filterName);
            possibleFilter.value = selectedKeys;
            this.filtersList.push(possibleFilter);
        }
        this.filtersList = this.filtersList.filter(it => 
            it.filterType !== FILTER_TYPE_CHECKBOX || (it.filterType === FILTER_TYPE_CHECKBOX && it.value == true));
        this.page = 1;
    },
    setNumberFilterValue() {
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.showDeletedForms = false;
        this.showIsNotValid = false;
        this.possibleFilters.forEach((it, index) => {
            if(it.filterType === FILTER_TYPE_CHECKBOX) {
                this.possibleFilters[index].value = false;
            }
        });
        this.loadItems();
    },

    changeDeletedItemsView(deletedIsVisible) {
        this.showDeletedForms = deletedIsVisible;
        this.loadItems();
    },

    changeValidItemsView(isValid) {
        this.showIsNotValid = isValid;
        this.loadItems();
    },
    reloadPagesNumber() {
        let filteredList = [...this.formsList];
        if(this.searchValue) {
            filteredList = filterBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => this.filtersList.findIndex(filter => Object.values(it).includes(filter.filterName) || 
                filter.filterGroupName === FILTER_GROUP_NAME_STATUS) >= 0);
        }
        if(this.showDeletedForms) {
            filteredList = filteredList.filter(it => it.isDeleted);
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
    },
};
