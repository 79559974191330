import React from "react";

export const NotificationSuccessIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" fill="#D6D6D6" />
        <path d="M-15831 -21973C-15831 -21974.1 -15830.1 -21975 -15829 -21975H39079C39080.1 -21975 39081 -21974.1 39081 -21973V3829C39081 3830.11 39080.1 3831 39079 3831H-15829C-15830.1 3831 -15831 3830.11 -15831 3829V-21973Z" fill="#A2A2A2" />
        <path d="M-4713 -1638C-4713 -1639.1 -4712.1 -1640 -4711 -1640H9350C9351.1 -1640 9352 -1639.1 9352 -1638V2537C9352 2538.1 9351.1 2539 9350 2539H-4711C-4712.1 2539 -4713 2538.1 -4713 2537V-1638Z" fill="white" />
        <path d="M-4711 -1639H9350V-1641H-4711V-1639ZM9351 -1638V2537H9353V-1638H9351ZM9350 2538H-4711V2540H9350V2538ZM-4712 2537V-1638H-4714V2537H-4712ZM-4711 2538C-4711.55 2538 -4712 2537.55 -4712 2537H-4714C-4714 2538.66 -4712.66 2540 -4711 2540V2538ZM9351 2537C9351 2537.55 9350.55 2538 9350 2538V2540C9351.66 2540 9353 2538.66 9353 2537H9351ZM9350 -1639C9350.55 -1639 9351 -1638.55 9351 -1638H9353C9353 -1639.66 9351.66 -1641 9350 -1641V-1639ZM-4711 -1641C-4712.66 -1641 -4714 -1639.66 -4714 -1638H-4712C-4712 -1638.55 -4711.55 -1639 -4711 -1639V-1641Z" fill="black" fillOpacity="0.1" />
        <g clipPath="url(#clip0_1011_12842)">
            <rect width="1920" height="1080" transform="translate(-284 -981)" fill="#FAFAFA" />
            <g filter="url(#filter0_d_1011_12842)">
                <path d="M-12 -615C-12 -630.464 0.536026 -643 16 -643H1568C1583.46 -643 1596 -630.464 1596 -615V30C1596 45.464 1583.46 58 1568 58H16C0.536068 58 -12 45.464 -12 30V-615Z" fill="white" />
            </g>
            <g filter="url(#filter1_d_1011_12842)">
                <path d="M-12 -4C-12 -8.41828 -8.41828 -12 -4 -12H1588C1592.42 -12 1596 -8.41828 1596 -4V50C1596 54.4183 1592.42 58 1588 58H-3.99998C-8.41825 58 -12 54.4183 -12 50V-4Z" fill="white" shapeRendering="crispEdges" />
                <circle cx="10" cy="10" r="10" fill="#E0EBEB" />
                <path d="M10 4C8.81331 4 7.65328 4.35189 6.66658 5.01118C5.67989 5.67047 4.91085 6.60754 4.45673 7.7039C4.0026 8.80025 3.88378 10.0067 4.11529 11.1705C4.3468 12.3344 4.91825 13.4035 5.75736 14.2426C6.59648 15.0818 7.66557 15.6532 8.82946 15.8847C9.99335 16.1162 11.1997 15.9974 12.2961 15.5433C13.3925 15.0891 14.3295 14.3201 14.9888 13.3334C15.6481 12.3467 16 11.1867 16 10C15.997 8.40964 15.3638 6.88529 14.2393 5.76073C13.1147 4.63617 11.5904 4.00305 10 4ZM12.8558 8.95L9.475 12.1808C9.3876 12.263 9.27192 12.3084 9.15193 12.3077C9.09327 12.3085 9.03504 12.2977 8.98058 12.276C8.92612 12.2542 8.87651 12.2218 8.83462 12.1808L7.14423 10.5654C7.09735 10.5245 7.05922 10.4745 7.03213 10.4185C7.00505 10.3625 6.98957 10.3016 6.98663 10.2394C6.98369 10.1773 6.99335 10.1152 7.01502 10.0568C7.0367 9.99851 7.06994 9.94517 7.11275 9.90002C7.15557 9.85487 7.20707 9.81885 7.26416 9.79411C7.32125 9.76937 7.38276 9.75643 7.44498 9.75607C7.5072 9.75571 7.56885 9.76793 7.62622 9.79201C7.6836 9.81608 7.73551 9.8515 7.77885 9.89615L9.15193 11.2058L12.2212 8.28077C12.3109 8.20247 12.4274 8.1619 12.5464 8.16753C12.6654 8.17316 12.7775 8.22455 12.8595 8.31097C12.9414 8.39739 12.9868 8.51214 12.9861 8.63123C12.9854 8.75032 12.9387 8.86454 12.8558 8.95Z" fill="#397271" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_1011_12842" x="-36" y="-667" width="1656" height="749" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <filter id="filter1_d_1011_12842" x="-36" y="-36" width="1656" height="118" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_12842" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1011_12842" result="shape" />
            </filter>
            <clipPath id="clip0_1011_12842">
                <rect width="1920" height="1080" fill="white" transform="translate(-284 -981)" />
            </clipPath>
        </defs>
    </svg>
);
