import React, { useState } from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';
import { 
    FREQUENCY_PERIOD_DAY_AFTER_DAY, 
    FREQUENCY_PERIOD_EVERY_DAY, 
    FREQUENCY_PERIOD_NO_MATTER, 
    FREQUENCY_PERIOD_ONCE_A_PERIOD,
    findFrequencyTitle, 
    findTranslationValue,
} from './PreferencesListItem';

const createListOfOptions = () => {
    return [
        {
            id: FREQUENCY_PERIOD_NO_MATTER,
            name: i18n.get('preference.scheme.frequency.no-matter'),
        },
        {
            id: FREQUENCY_PERIOD_EVERY_DAY,
            name: i18n.get('preference.scheme.frequency.everyDay'),
        },
        {
            id: FREQUENCY_PERIOD_DAY_AFTER_DAY,
            name: i18n.get('preference.scheme.frequency.dayAfterDay'),
        },
        {
            id: FREQUENCY_PERIOD_ONCE_A_PERIOD,
            name: `${i18n.get('preference.scheme.frequency.onceInDays')} N ${findTranslationValue(5)}`,
        },
    ];
};

const createFrequencyTitle = (frequencyType, frequencyPeriod) => {
    if(frequencyType === FREQUENCY_PERIOD_ONCE_A_PERIOD && frequencyPeriod > 2) {
        return `${i18n.get('preference.scheme.frequency.onceInDays')} N ${findTranslationValue(5)}`;
    } else {
        return findFrequencyTitle(frequencyType, frequencyPeriod);
    }
};

const createFrequencyPeriodType = (frequencyType, frequencyPeriod) => {
    if(frequencyType === FREQUENCY_PERIOD_NO_MATTER || frequencyPeriod > 2) {
        return frequencyType;
    }
    if(frequencyType === FREQUENCY_PERIOD_ONCE_A_PERIOD && frequencyPeriod === 1) {
        return FREQUENCY_PERIOD_EVERY_DAY;
    }
    if(frequencyType === FREQUENCY_PERIOD_ONCE_A_PERIOD && frequencyPeriod === 2) {
        return FREQUENCY_PERIOD_DAY_AFTER_DAY;
    }
};

const PreferenceStructureItemHowOften = ({ itemSaveService }) => {
    const [frequencyPeriod, setFrequencyPeriod] = useState(+itemSaveService.getPreference().frequencyPeriod);
    const [frequencyType, setFrequencyType] = useState(createFrequencyPeriodType(itemSaveService.getPreference().preferenceFrequency, 
        +itemSaveService.getPreference().frequencyPeriod));
    const [error, setError] = useState(false);

    const changeFrequencyValue = (e) => {
        setError(false);
        setFrequencyPeriod(e.target.value);
        if(+e.target.value > 0 && +e.target.value < 15) {    
            updatePreferenceValue(frequencyType, +e.target.value);
        } else {
            setError(true);
        }
    };

    const updatePreferenceValue = (value, period = null) => {
        setFrequencyType(value);
        if(value === FREQUENCY_PERIOD_EVERY_DAY) {
            itemSaveService.updateHowOften(FREQUENCY_PERIOD_ONCE_A_PERIOD, 1);
        } else if (value === FREQUENCY_PERIOD_DAY_AFTER_DAY) {
            itemSaveService.updateHowOften(FREQUENCY_PERIOD_ONCE_A_PERIOD, 2);
        } else {
            itemSaveService.updateHowOften(value, period ? period : +frequencyPeriod);
        }
    };

    return (
        <>
            <label className="block__text preference__form-label">
                <span>{i18n.get('app.page.preference.addTitles.howOften')}</span>
                <SelectHandler 
                    selectedValue={itemSaveService.getPreference().preferenceFrequency ? 
                        createFrequencyTitle(itemSaveService.getPreference().preferenceFrequency, 
                            +itemSaveService.getPreference().frequencyPeriod) 
                        : 
                        ''} 
                    optionsList={createListOfOptions()} 
                    sendSelectedValue={updatePreferenceValue}
                /> 
                {frequencyType ===  FREQUENCY_PERIOD_ONCE_A_PERIOD ?
                    <label className="preference__frequency-label">
                        <p className="block__text preference__frequency-title">{i18n.get('app.page.preference.daysCount')}</p>
                        <input className={`block__content preference__form-input ${error ? 'preference__form-input_error' : ''}`} 
                            placeholder={i18n.get('app.page.preference.enterInfo')} 
                            onChange={changeFrequencyValue}
                            value={frequencyPeriod || ''} />
                        {error ? 
                            <p className={`block__bullet alert alert__product`}>
                                {i18n.get('product.scheme.property.code.from')} 1 {i18n.get('product.scheme.property.code.to')} 14
                            </p> 
                            : 
                            null}
                    </label>
                    :
                    null
                }
            </label> 
        </>
    );
};

export default PreferenceStructureItemHowOften;
