import React from 'react';
import ListItemTitle from '../../sharedComponents/ListItemTitle';
import { productsService } from '../../../services/productsService';
import DeleteItemIcon from '../../sharedComponents/DeleteItemIcon';
import { productDeleteService } from '../../../services/productDeleteService';
import { POPUP_KEY_DELETE_PRODUCT, POPUP_KEY_RESTORE_PRODUCT } from '../../../constant';
import { ADD_PRODUCT_MACRO_ELEMENTS } from '../../helper';
import RestoreItemIcon from '../../sharedComponents/RestoreItemIcon';
import EditItemIcon from '../../sharedComponents/EditItemIcon';

const ProductListItem = ({ product }) => {
    return (
        <React.Fragment key={product.id + 'product'}>
            <ListItemTitle itemCode={product.productCode} itemService={productsService}/>
            <p className="main__subtitle product__list-code">{product.productCode}</p>
            <div className="product__list-icons">
                {product.isDeleted ?
                    <RestoreItemIcon itemId={product.id} popupKey={POPUP_KEY_RESTORE_PRODUCT} />
                    :
                    <EditItemIcon pageUrl={`/product/item/${product.id}/?productTab=${ADD_PRODUCT_MACRO_ELEMENTS}`}/>}
                <DeleteItemIcon 
                    itemId={product.id} 
                    itemDeleteService={productDeleteService} 
                    popupKey={POPUP_KEY_DELETE_PRODUCT} />
            </div>
        </React.Fragment>
    );
};

export default ProductListItem;
