import React, { useState, useEffect } from 'react';
import Checkbox from './Checkbox';

const FiltersListItemCheckbox = ({ itemsStorageService, filterName }) => {
    const [inputValue, setInputValue] = useState('');

    const changeFilterValue = (value) => {
        itemsStorageService.setCheckedFilterValues(filterName, value);
        setInputValue(value);
    };

    const getCurrentFilterValue = () => {
        const filter = itemsStorageService.getCurrentFilters().filter(it => it.filterName === filterName)[0];
        setInputValue(filter && (filter.value === true || filter.value === 'true') ? true : false);
    };

    useEffect(() => {
        itemsStorageService.registerFiltersChangingObserver(getCurrentFilterValue);
        getCurrentFilterValue();
        return () => itemsStorageService.unRegisterFiltersChangingObserver(getCurrentFilterValue);
    }, []);

    return (
        <label key={filterName + inputValue} className="block__content product__dropdown-label">
            <Checkbox checked={inputValue} changeCheckboxValue={changeFilterValue} />
            <span className="product__dropdown-subtitle product__dropdown-text">
                {itemsStorageService.getFilter(filterName).filterTitle}
            </span>
        </label>
    );
};

export default FiltersListItemCheckbox;
