import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import SelectWithTokensSelectListItem from './SelectWithTokensSelectListItem';

const SelectWithTokensSelectList = ({ selectWithTokensManager, included }) => {
    const [itemsListIsChanged, setItemsListIsChanged] = useState(false);

    const changeItemsList = () => {
        setItemsListIsChanged(prev => !prev);
    };

    useEffect(() => {
        selectWithTokensManager.registerItemsChangingObserver(changeItemsList);
        return () => selectWithTokensManager.registerItemsChangingObserver(changeItemsList);
    }, []);

    return (
        <ul>
            <div className="scroll product__forms-container">
                {selectWithTokensManager.getPossibleItems().length ? 
                    selectWithTokensManager.getPossibleItems().map(it => (
                        <React.Fragment key={it.itemId + 'possible' + itemsListIsChanged}>
                            <SelectWithTokensSelectListItem selectWithTokensManager={selectWithTokensManager}
                                selectItem={{...it, included}} />
                        </React.Fragment>
                    ))
                    :
                    <li className="block__content product__forms-option">{i18n.get("app.page.product.notFound")}</li>
                }
            </div>
        </ul> 
    );
};

export default SelectWithTokensSelectList;
