import React, { useState, useEffect } from 'react';
import { i18n } from '../../../i18n';
import { propertySaveService } from '../../../services/propertySaveService';
import { propertiesService } from '../../../services/propertiesService';
import { PROPERTY_TYPE_BOOLEAN, PROPERTY_TYPE_NUMBER, PROPERTY_TYPE_SELECT, PROPERTY_TYPE_STRING } from '../../../constant';
import SelectWithoutTokens from '../../sharedComponents/SelectWithoutTokens';
import PropertyItemValue from './PropertyItemValue';
import PropertyItemNumberUnits from './PropertyItemNumberUnits';

const makePropertyTypesList = () => {
    const result = [
        {
            id: PROPERTY_TYPE_NUMBER,
            name: i18n.get(propertiesService.getPropertyTypei18nCode({propertyType: PROPERTY_TYPE_NUMBER})),
        },
        {
            id: PROPERTY_TYPE_SELECT,
            name: i18n.get(propertiesService.getPropertyTypei18nCode({propertyType: PROPERTY_TYPE_SELECT})),
        },
        {
            id: PROPERTY_TYPE_BOOLEAN,
            name: i18n.get(propertiesService.getPropertyTypei18nCode({propertyType: PROPERTY_TYPE_BOOLEAN})),
        },
        {
            id: PROPERTY_TYPE_STRING,
            name: i18n.get(propertiesService.getPropertyTypei18nCode({propertyType: PROPERTY_TYPE_STRING})),
        },
    ];
    return result;
};

const getPropertyTypeTitle = (propertyType) => {
    if(propertyType) {
        return i18n.get(propertiesService.getPropertyTypei18nCode({propertyType}));
    } else {
        return '';
    }
};

const PropertyItemType = () => {
    const [propertyType, setPropertyType] = useState('');
    const [valuesCountIsChanged, setValuesCountIsChanged] = useState(false);

    const changePropertyType = (property) => {
        setPropertyType(property.id);
        propertySaveService.updatePropertyValue('propertyType', property.id);
    };

    const changePropertyState = () => {
        setPropertyType(propertySaveService.getProperty().propertyType);
        setValuesCountIsChanged(prev => !prev);
    };

    useEffect(() => {
        propertySaveService.registerItemUpdateObserver(changePropertyState);
        return () => propertySaveService.unRegisterItemUpdateObserver(changePropertyState);
    }, []);



    return (
        <>
            <label className="block__text product__form-label" key={propertyType + 'label'}>
                <span className="product__form-label_text">{i18n.get('app.page.product.propertyItem.propertyType')}</span>
                <SelectWithoutTokens listOfOptions={makePropertyTypesList()} handleChangeValue={changePropertyType}
                    titleValue={getPropertyTypeTitle(propertyType)}/>
            </label>
            {propertyType === PROPERTY_TYPE_SELECT ?
                propertySaveService.getProperty().selectValues ? 
                    propertySaveService.getProperty().selectValues.map((it, index) => (
                        <React.Fragment key={it + index + valuesCountIsChanged}>
                            <PropertyItemValue itemSaveService={propertySaveService} index={index} />
                        </React.Fragment>
                    ))
                    :
                    <PropertyItemValue itemSaveService={propertySaveService} index={0} />
                :
                null}
            {propertyType === PROPERTY_TYPE_NUMBER ?
                <PropertyItemNumberUnits />
                :
                null}
        </>
    );
};

export default PropertyItemType;
