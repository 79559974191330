import { errorCallback, makeUrl } from "../constant";
import { GET_USER_REQUEST, getFetchRequest } from "../store/requests";

export const userPreferencesService = {
    usersList: [],
    searchValue: '',
    observersList: [],
    selectedItems: [],
    loadItems(callback) {
        try {
            getFetchRequest(makeUrl([GET_USER_REQUEST]), (response) => {
                this.usersList = response;
                callback && callback();
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    getItems() {
        let filteredList = [...this.usersList];
        if(this.searchValue) {
            filteredList = this.usersList.filter(it => it.name.toLowerCase().includes(it, this.searchValue) 
                || it.email.toLowerCase().includes(it, this.searchValue
                || it.phone.toLowerCase().includes(it, this.searchValue)));
        }
        return filteredList;
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getSelectedUsersList() {
        return this.selectedItems;
    },
    changeSelectedUsersList(userId, cleanAll) {
        if(userId) {
            if(this.selectedItems.find(it => it.id === userId)) {
                this.selectedItems = this.selectedItems.filter(it => it.id !== userId);
            } else {
                this.selectedItems.push(this.usersList.find(it => it.id === userId));
            }
        } else {
            if((this.usersList.length === this.selectedItems.length && this.selectedItems.length !== 0) || cleanAll) {
                this.selectedItems = []; 
            } else {
                this.selectedItems = this.usersList;
            }   
        }
        this.notifyFiltersChangingObservers();
    },
};

export const initializeUsersService = () => {
    userPreferencesService.loadItems();
};
