import React from 'react';
import { popupOpenService } from '../../services/popupOpenService';
import { POPUP_KEY_CREATE_RECIPE } from '../../constant';
import { i18n } from '../../i18n';

const CreateNewRecipeButton = () => {
    const openCreateRecipePopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_CREATE_RECIPE);
    };

    return (
        <button className='block__button button button_outline recipe__button' 
            onClick={openCreateRecipePopup}>
            <span className="button__content">{i18n.get('app.page.recipe.addRecipe')}</span>
        </button>
    );
};

export default CreateNewRecipeButton;
