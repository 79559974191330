import React, { useState } from 'react';
import { getPropertyDataById } from '../../../services/productSaveService';
import { propertiesService } from '../../../services/propertiesService';
import { i18n } from '../../../i18n';

const ProductPropertyBoolean = ({ propertyId, itemSaveService }) => {
    const [checkedValue, setCheckedValue] = useState(propertyId ? getPropertyDataById(propertyId).value : itemSaveService.getProperty().value);

    const editFieldValue = (e) => {
        setCheckedValue(e.target.checked);
        itemSaveService.updateProductProperty(propertyId, 'value', e.target.checked.toString());
    };

    return (
        <label className="block__content product__label-checkbox">
            <span className="product__add-checkbox-text">
                {i18n.get(propertiesService.getPropertyNamei18nCode(propertyId ? 
                    getPropertyDataById(propertyId) 
                    : 
                    {propertyName: itemSaveService.getProperty().itemCode}))}
            </span>
            <input className="product__add-checkbox" 
                type="checkbox" value={checkedValue || ''} 
                checked={(checkedValue === 'true' || checkedValue === true) || false} 
                onChange={editFieldValue}/>
        </label>
    );
};

export default ProductPropertyBoolean;
