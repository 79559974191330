import React, { useState, useEffect } from 'react';
import { i18n } from '../../../i18n';
import { propertySaveService } from '../../../services/propertySaveService';

const PropertyItemDescription = () => {
    const [fieldValue, setFieldValue] = useState('');

    const changePropertyDescription = (e) => {
        setFieldValue(e.target.value);
        propertySaveService.updatePropertyValue('description', e.target.value);
    };

    const changePropertyData = () => {
        setFieldValue(propertySaveService.getProperty().description);
    };

    useEffect(() => {
        propertySaveService.registerItemUpdateObserver(changePropertyData);
        return () => propertySaveService.unRegisterItemUpdateObserver(changePropertyData);
    }, []);

    return (
        <label className="block__text product__form-label">
            <span className="product__form-label_text">{i18n.get('app.page.product.propertyItem.description')}</span>
            <input className="product__form-input" placeholder={i18n.get('app.page.product.propertyItem.text')}
                value={fieldValue || ''} 
                onChange={changePropertyDescription}/>
        </label>
    );
};

export default PropertyItemDescription;
