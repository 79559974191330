import React from 'react';
import PreferenceGroupsActionsSortIcon from './PreferenceGroupsActionsSortIcon';
import PreferenceGroupsActionsEditIcon from './PreferenceGroupsActionsEditIcon';
import PreferenceGroupsActionsDeleteIcon from './PreferenceGroupsActionsDeleteIcon';

const PreferenceGroupsActions = () => {
    return (
        <ul className='preference__groups-icons'>
            <PreferenceGroupsActionsSortIcon />
            <PreferenceGroupsActionsEditIcon />
            <PreferenceGroupsActionsDeleteIcon />
        </ul>
    );
};

export default PreferenceGroupsActions;
