import React from 'react';
import { i18n } from '../../i18n';

const NoPhrasesForPreferenceItem = () => {
    return (
        <p className='content__block preference__phrases-empty'>
            {i18n.get('app.page.preference.noPhrases')}
        </p>
    );
};

export default NoPhrasesForPreferenceItem;
