import React from 'react';
import { i18n } from '../../i18n';

const DeleteModalContent = ({ handleDelete, handleClose, tab, itemTitle, isForceDelete }) => (
    <>
        <h2 className="modal__title modal__title_align block__title-h3">{i18n.get(`app.page.product.modal.${tab}.title`)}</h2>
        <p className="block__content modal__text modal__text_align">
            {isForceDelete ? 
                i18n.get(`app.page.product.modal.${tab}.forceDelete.content`) + ` ${itemTitle}?`
                :
                `${i18n.get(`app.page.product.modal.${tab}.content`).slice(0, -1)} ${itemTitle ? itemTitle : ''}?`}
        </p>
        <div className="block__button modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>
                <span className="button__content">{i18n.get(`app.page.product.modal.${tab}.cancel`)}</span>
            </button>
            <button className="block__button button" onClick={handleDelete}>
                <span className="button__content">{i18n.get(`app.page.product.modal.${tab}.delete`)}</span>
            </button>
        </div>
    </>
);

export default DeleteModalContent;
