import React, { useEffect, useState } from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';
import PhrasesListItemNumberField from './PhrasesListItemNumberField';
import { i18n } from '../../i18n';
import { createPreferenceItemCodesValue, findFrequencyTitle } from './PreferencesListItem';
import PhrasesListItemWelcomeIcon from './PhrasesListItemWelcomeIcon';

export const createValuesString = (phrase) => {
    return [
        i18n.get(`preference.scheme.include.${phrase.include}`),
        i18n.get(`preference.scheme.preferenceType.${phrase.preferenceType}`),
        createPreferenceItemCodesValue(phrase),
        i18n.get(`preference.scheme.when.${phrase.when}`),
        findFrequencyTitle(phrase.preferenceFrequency, +phrase.frequencyPeriod),
    ].join(' | ');
};

const PhrasesListItem = ({ preferencePhraseId }) => {
    const [isChecked, setIsChecked] = useState(false);

    const changeSelectedList = () => {
        preferencesPhrasesStorage.changeSelectedPhrasesList(preferencePhraseId);
    };

    const changePhrasesList = () => {
        setIsChecked(preferencesPhrasesStorage.getSelectedList().findIndex(it => it.id === preferencePhraseId) >= 0);
    };

    useEffect(() => {
        preferencesPhrasesStorage.registerFiltersChangingObserver(changePhrasesList);
        return () => {
            preferencesPhrasesStorage.unRegisterFiltersChangingObserver(changePhrasesList);
        };
    }, []);

    return (
        <React.Fragment key={preferencePhraseId + isChecked}>
            <CustomCheckbox checked={isChecked} handleChange={changeSelectedList} />
            <p className="preference__phrases-text">{preferencesPhrasesStorage.getById(preferencePhraseId).content}</p>
            <div className="preference__phrases-data">
                {isChecked ? 
                    <p className="block__bullet preference__phrases-values">
                        {createValuesString(preferencesPhrasesStorage.getById(preferencePhraseId))}
                    </p> 
                    : 
                    null}
                <PhrasesListItemNumberField 
                    preferencePhraseId={preferencePhraseId} 
                    ratingValue={preferencesPhrasesStorage.getById(preferencePhraseId).vote}
                    phrasesListManager={preferencesPhrasesStorage}
                />
            </div>
            <PhrasesListItemWelcomeIcon preferencePhraseId={preferencePhraseId} />
        </React.Fragment>
    );
};

export default PhrasesListItem;
