import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { popupOpenService } from '../../../services/popupOpenService';
import { formCreateServiceItem, initializeFormCreateService } from '../../../services/formCreateService';
import { i18n } from '../../../i18n';
import RestrictedCodeInput from '../RestrictedCodeInput';
import CreateItemPopupSaveButton from '../CreateItemPopupSaveButton';
import { POPUP_KEY_CREATE_FORM } from '../../../constant';
import { keyDownService } from '../../../services/keyDownService';

const CreateNewFormPopup = () => {
    const [isVisible, setIsVisible] = useState('');
    const navigate = useNavigate();

    const changePopupVisibility = () => {
        setIsVisible(true);
    };

    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_CREATE_FORM, changePopupVisibility);
        initializeFormCreateService();
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_CREATE_FORM, changePopupVisibility);
    }, []);

    const redirectToEditPage = (response) => {
        if(response && response.id) {
            navigate(`/product/form/${response.id}/`);
        } else {
            formCreateServiceItem.changeItemCode('');
        }
    };

    const createNewItem = () => {
        if(formCreateServiceItem.checkCodeValidation() === '') {
            formCreateServiceItem.saveNewItem(redirectToEditPage);
        }
    };

    return (
        isVisible ?
            <>
                <div className="modal__wrapper modal__wrapper_active" onClick={() => setIsVisible(false)}></div>  
                <article className="modal modal_small" onKeyDown={(e) => keyDownService.saveNewItem(e, createNewItem)}>
                    <h2 className="modal__title block__title-h3">{i18n.get(`app.page.product.modal.createForm.title`)}</h2>
                    <p className="block__content modal__text product__modal-text">{i18n.get(`app.page.product.modal.createForm.content`)}</p>
                    <label className="block__text product__form-label product__modal-label">
                        <span className="product__form-label_text">{i18n.get("app.page.product.addFormItem.formCode")}</span>
                        <RestrictedCodeInput itemCreateService={formCreateServiceItem} />
                    </label>
                    <div className="block__button modal__button-group">
                        <button className="block__button button button_outline" onClick={() => setIsVisible(false)}>
                            <span className="button__content">{i18n.get(`app.page.product.modal.cancel`)}</span>
                        </button>
                        <CreateItemPopupSaveButton itemCreateService={formCreateServiceItem} successRedirectTo={`/product/form`}/>
                    </div>
                </article>
            </>
            :
            null
    );
};

export default CreateNewFormPopup;
