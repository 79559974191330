import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MOUSE_DOWN_EVENT } from '../../constant';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import SelectWithTokensActiveItem from '../sharedComponents/SelectWithTokensActiveItem';
import SelectWithTokensInput from '../sharedComponents/SelectWithTokensInput';
import SelectWithTokensSelectList from '../sharedComponents/SelectWithTokensSelectList';
import { i18n } from '../../i18n';

const checkSelectedForms = (selectWithTokensManager) => {
    return selectWithTokensManager.getCurrentItems() && selectWithTokensManager.getCurrentItems().length;
};

const PreferenceStructureGroup = ({ selectWithTokensManager }) => {
    const [selectListIsOpen, setSelectListIsOpen] = useState(false);
    const [selectedListLength, setSelectedListLength] = useState();
    const wrapperRef = useRef();

    const openItemsList = () => {
        setSelectListIsOpen(true);
    };

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSelectListIsOpen(false);
        }
    }, []);

    const changeSelected = () => {
        setSelectedListLength(selectWithTokensManager.getCurrentItems().length);
    };

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        selectWithTokensManager.registerItemsChangingObserver(changeSelected);
        changeSelected();
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
            selectWithTokensManager.unRegisterItemsChangingObserver(changeSelected);
        };
    }, []);

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.preferenceLabels')}</span>
            <label className="block__text product__form-label">
                <div ref={wrapperRef}>
                    <div className="product__forms" onClick={openItemsList} >
                        {checkSelectedForms(selectWithTokensManager) && selectedListLength > 0 ? 
                            <ul className={`product__forms-list ${selectWithTokensManager.getCurrentItems().length === 0 ? 'product__forms-list_full-width' : ''}`}>
                                {selectWithTokensManager.getCurrentItems().map((it, index) => (
                                    <React.Fragment key={it.itemId + 'ingredient' + index}>
                                        <SelectWithTokensActiveItem 
                                            selectItem={it}
                                            removeCallback={selectWithTokensManager}
                                        />
                                    </React.Fragment>
                                ))}
                                    
                            </ul>
                            :
                            null}
                        {selectListIsOpen || selectWithTokensManager.getCurrentItems().length === 0 ? 
                            <SelectWithTokensInput selectWithTokensManager={selectWithTokensManager} />
                            :
                            null}
                        <ChevronIcon
                            nameOfClass={`product__forms-icon ${selectListIsOpen ? 'product__forms-icon_active' : ''}`} />
                    </div>
                    {selectListIsOpen ? 
                        <div className="product__forms-dropdown product__select-dropdown">
                            <SelectWithTokensSelectList selectWithTokensManager={selectWithTokensManager} include={true}/>
                        </div>
                        :
                        null}
                </div>
            </label>
        </label>
    );
};

export default PreferenceStructureGroup;
