import React, { useEffect, useState } from 'react';

const OpenFilterButtonIcon = ({ itemsStorageService }) => {
    const [filtersCount, setFiltersCount] = useState(0);

    const changeFiltersCount = () => {
        setFiltersCount(itemsStorageService.getCurrentFilters().length);
    };

    useEffect(() => {
        itemsStorageService.registerFiltersChangingObserver(changeFiltersCount);
        return () => itemsStorageService.registerFiltersChangingObserver(changeFiltersCount);
    }, []);

    return (
        filtersCount > 0 ? <p className="block__bullet filter__count">{filtersCount}</p> : null
    );
};

export default OpenFilterButtonIcon;
