import React from 'react';
import { popupOpenService } from '../../../services/popupOpenService';
import { POPUP_KEY_CREATE_FORM } from '../../../constant';
import { i18n } from '../../../i18n';

const CreateNewFormButton = () => {
    const openPopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_CREATE_FORM, null);
    };

    return (
        <button className='block__button button button_outline product__button' 
            onClick={openPopup}>
            <span className="button__content">{i18n.get('app.page.product.addForm')}</span>
        </button>
    );
};

export default CreateNewFormButton;
