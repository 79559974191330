import React, { useState, useEffect } from 'react';
import { POPUP_KEY_DELETE_PREFERENCE, errorCallback, makeUrl } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { PREFERENCE_URL, deleteFetchRequest } from '../../store/requests';
import { MODAL_SMALL_SIZE } from '../helper';
import Modal from '../Modal';
import { i18n } from '../../i18n';
import { preferencesStorage } from '../../services/preferencesStorage';

const DeleteModalContent = ({ handleDelete, handleClose }) => (
    <>
        <h2 className="modal__title modal__title_align block__title-h3 block__title-h3">{i18n.get('app.page.preference.modal.title')}</h2>
        <p className="block__content modal__text modal__text_align">{i18n.get('app.page.preference.modal.content')}</p>
        <div className="block__button modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>{i18n.get('app.page.preference.modal.cancel')}</button>
            <button className="block__button button" onClick={handleDelete}>{i18n.get('app.page.preference.modal.delete')}</button>
        </div>
    </>
);

const DeletePreferencePopup = ({ notifyPreferencesList }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_PREFERENCE, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_PREFERENCE, handleOpenModal);
    },[]);

    const deletePreference = () => {        
        setIsOpen(null);
        for(let preference of preferencesStorage.getSelectedPreferencesList()) {
            deleteFetchRequest(makeUrl([PREFERENCE_URL, preference.id, '/']), () => notifyPreferencesList(), errorCallback);
        }
        preferencesStorage.changeSelectedPreferencesList('', true);
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deletePreference} />} 
                handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeletePreferencePopup;
