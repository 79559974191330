import React, { useState } from 'react';
import ExcludeFormButton from './ExcludeFormButton';
import ExcludeFormListHandler from './ExcludeFormListHandler';

const ExcludeFormHandler = ({ selectWithTokensManager }) => {
    const [excludeFormIsOpen, setExcludeFormIsOpen] = useState(false);
    return (
        <>
            <ExcludeFormButton callback={setExcludeFormIsOpen} isVisible={excludeFormIsOpen}/>
            <ExcludeFormListHandler 
                selectWithTokensManager={selectWithTokensManager} 
                callback={setExcludeFormIsOpen} 
                isOpened={excludeFormIsOpen}
            />
        </>
    );
};

export default ExcludeFormHandler;
