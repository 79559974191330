import { errorCallback, makeUrl, FILTER_TYPE_SELECT, PRODUCT_COUNT_SMALL_WIDTH, PRODUCT_COUNT_FULL_WIDTH } from "../constant";
import { RECIPES_URL, getFetchRequest } from "../store/requests";
import { i18n } from '../i18n';
import { recipesService } from "./recipesService";
import { isSmallWebScreen, sortByDate } from "../components/helper";
import { productsStorage } from "./productsStorage";
import { productsService } from "./productsService";
import { recipeIngredientService } from "./recipeIngredientsService";

const createVariantsList = (productsList) => {
    let result = [];
    productsList.forEach(it => result.push({id: it.productCode, name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode)}));
    return result;
};

const createListOfFilters = (productsList) => {
    return [ 
        {
            filterTitle: i18n.get('app.page.property.filters.ingredient'),
            filterName: 'ingredient',
            filterType: FILTER_TYPE_SELECT,
            variants: createVariantsList(productsList),
            value: false,
            filterGroupName: 'ingredient',
        },
    ];
};

const filterRecipesListBySearchValue = (recipesList, searchValue) => {
    return recipesList.filter(it => recipesService.getLocalization(i18n.APP_LOCALE, it.recipeType, true).toLowerCase().includes(searchValue));
};

const findIngredientItem = (recipe, filtersList) => {
    let count = 0;
    for(let product of filtersList) {
        const currentProduct = productsStorage.getById(product.id);
        if(currentProduct) {
            recipe.ingredients.find(it => it.ingredientParts.find(ingredient => ingredient.productCode === currentProduct.productCode)) ? 
                count++ 
                : 
                null;
        } else {
            count++;
        }
    }
    return count > 0;
};

export const recipesStorage = {
    recipesList: [],
    filtersList: [],
    searchValue: '',
    observersList: [],
    productsList: [],
    possibleFilters: [],
    page: 1,
    offset: 10,
    maxPages: 1,

    loadItems(callback, width) {
        try {
            getFetchRequest(makeUrl([RECIPES_URL]), (response) => {
                this.recipesList = response.sort(sortByDate);
                this.offset = isSmallWebScreen(width) ? PRODUCT_COUNT_SMALL_WIDTH : PRODUCT_COUNT_FULL_WIDTH;
                for(let i = 0; i < response.length; i++) {
                    getFetchRequest(makeUrl([RECIPES_URL, response[i].id, '/ingredient/']), 
                        (result) => {
                            this.recipesList[i].ingredients = result;
                            if(i === response.length - 1) {
                                callback && callback(response);
                                this.reloadPagesNumber();
                                this.notifyFiltersChangingObservers();
                            }                
                        }, errorCallback);
                }
                
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    getMaxCount() {
        return this.maxPages;
    },

    getCurrentPage() {
        return this.page;
    },

    setPageNumber(value) {
        this.page = value;
        this.notifyFiltersChangingObservers();
    },

    getItems(loadAllData) {
        let filteredList = [...this.recipesList];
        if(this.searchValue) {
            filteredList = filterRecipesListBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => findIngredientItem(it, this.filtersList));
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
        return loadAllData ? filteredList : filteredList.slice((this.page - 1) * this.offset, this.page * this.offset);
    },

    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value) => {
            value.split(',').forEach(it => {
                filtersList.push({value, filterType: FILTER_TYPE_SELECT, id: it});
                recipeIngredientService.addItem(it);
            });
        });
        this.filtersList = filtersList;
        this.notifyFiltersChangingObservers();
    },
    getPossibleFilters() {
        this.possibleFilters = createListOfFilters(productsStorage.getItems(true));
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    getFilter(filterName) {
        return this.possibleFilters.find(it => it.filterName === filterName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.reloadPagesNumber();
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedKeys) {
        this.filtersList = [];
        selectedKeys.split(',').forEach(it => {
            if(it) {
                this.filtersList.push({value: selectedKeys, filterType: FILTER_TYPE_SELECT, id: it});
            }
        });
        this.maxPages = 1;
        this.page = 1;
    },
    setNumberFilterValue() {
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.possibleFilters.forEach((it, index) => {
            if(it.filterType === FILTER_TYPE_SELECT) {
                this.possibleFilters[index].value = false;
            }
        });
        this.maxPages = Math.ceil(this.recipesList.length / this.offset);
        this.notifyFiltersChangingObservers();
        recipeIngredientService.deleteItem('', true);
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    reloadPagesNumber() {
        let filteredList = [...this.recipesList];
        if(this.searchValue) {
            filteredList = filterRecipesListBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => findIngredientItem(it, this.filtersList));
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
    },
    changeDeletedItemsView() {
    },
    changeValidItemsView() {
    },
};
