import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { LogoIcon } from '../appearence/icons/logo_icon';
import { i18n } from '../i18n';
import CommitInfo from './sharedComponents/CommitInfo';
import { keyDownService } from '../services/keyDownService';
import PasswordField from './sharedComponents/PasswordField';
import { NEW_PASSWORD_KEY, REPEAT_PASSWORD_KEY } from './sharedComponents/loginManager';
import { loginManager } from './sharedComponents/loginManager';

const ResetPasswordPage = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');

    const successChangePasswordCallback = () => {
        navigate("/login");
    };

    const handleResetPassword = () => {
        loginManager.sendUpdatePasswordRequest(code, successChangePasswordCallback);
    };

    const changeIsDisabled = () => {
        setIsDisabled(!loginManager.canSendRequest());
    };

    useEffect(() => {
        loginManager.registerLoginUpdateObserver(changeIsDisabled);
        return () => loginManager.unRegisterLoginUpdateObserver(changeIsDisabled);
    }, []);

    return (
        <>
            <header className="login__header">
                <LogoIcon />
                <CommitInfo />
            </header>
            <main className="login" onKeyDown={(e) => keyDownService.saveNewItem(e, handleResetPassword)}>
                <h1 className="login__title block__title-h3">{i18n.get('public.app.page.login.passwordCreating')}</h1>
                <form className="login__form">
                    <PasswordField label={i18n.get('public.app.page.login.password')} passwordType={NEW_PASSWORD_KEY}/>
                    <PasswordField label={i18n.get('public.app.page.login.repeatPassword')} passwordType={REPEAT_PASSWORD_KEY}/>
                    <button type="button" onClick={handleResetPassword}
                        className={`block__button button login__button ${isDisabled ? 'button_disabled' : ''}`}>
                        <span className="button__content">{i18n.get('app.page.product.modal.create')}</span>
                    </button>
                </form>
            </main>
        </>
    );
};

export default ResetPasswordPage;
