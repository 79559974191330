import React, { useState } from 'react';
import { DropdownIcon } from '../../appearence/icons/dropdown_icon';
import FiltersListItemMaxAndMin from './FiltersListItemMaxAndMin';
import FiltersListItemSelect from './FiltersListItemSelect';
import FiltersListItemCheckbox from './FiltersListItemCheckbox';
import { i18n } from '../../i18n/index';
import { FILTER_TYPE_MAX_AND_MIN, FILTER_TYPE_SELECT } from '../../constant';

const FiltersListItem = ({ filterGroupName, filterName, itemsStorageService }) => {
    const [filterGroupIsOpen, setFilterGroupIsOpen] = useState(false);

    const changeFilterItemOpenness = () => {
        setFilterGroupIsOpen(!filterGroupIsOpen);
    };

    return (
        <li key={filterName + filterGroupName} className="product__dropdown-item" >
            {itemsStorageService.getFilter(filterName)?.filterType === FILTER_TYPE_SELECT ?
                <FiltersListItemSelect itemsStorageService={itemsStorageService} filterName={filterName} />
                :
                <>
                    <div className="block__content product__dropdown-title" onClick={changeFilterItemOpenness}>
                        <h3 >{i18n.get(`app.page.product.filters.${filterGroupName}`)}</h3>
                        <DropdownIcon nameOfClass={`product__dropdown-icon ${filterGroupIsOpen ? 'product__dropdown-icon_active' : ''}`} 
                            handleClick={() => null} value={null}/>
                    </div>
                    {filterGroupIsOpen ?
                        <ul className="product__dropdown-content scroll">
                            {itemsStorageService.getCurrentFiltersByGroup(filterGroupName).map(item => (
                                <React.Fragment key={item.filterName + 'key'}>
                                    {item.filterType === FILTER_TYPE_MAX_AND_MIN ?
                                        <FiltersListItemMaxAndMin itemsStorageService={itemsStorageService} filterName={item.filterName} />
                                        :
                                        <FiltersListItemCheckbox itemsStorageService={itemsStorageService} filterName={item.filterName} />}
                                </React.Fragment>
                            ))}
                        </ul>
                        : null}
                </>}
        </li>
    );
};

export default FiltersListItem;
