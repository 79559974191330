import React, { useState, useEffect } from 'react';
import NoPhrasesForPreferenceItem from './NoPhrasesForPreferenceItem';
import GeneratePreferencePhrasesIcon from './GeneratePreferencePhrasesIcon';
import PreferencePhrasesList from './PreferencePhrasesList';
import { TrashIcon } from '../../appearence/icons/trash_icon';
import PreferencesPhrasesSelectBlock from './PreferencePhrasesSelectBlock';
import { createPreferencePhraseService, preferencePhraseItemService } from '../../services/preferencePhraseService';

const PreferencePhrasesHandler = ({ preferenceId }) => {
    const [phrasesListIsChanged, setPhrasesListIsChanged] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const changePhrasesList = () => {
        setPhrasesListIsChanged(prev => !prev);
    };

    useEffect(() => {
        createPreferencePhraseService(preferenceId);
        preferencePhraseItemService.loadItems();
        preferencePhraseItemService.registerFiltersChangingObserver(changePhrasesList);
        return () => {
            preferencePhraseItemService.unRegisterFiltersChangingObserver(changePhrasesList);
        };
    }, []);
    return (
        <div className="preference__phrases">
            {preferencePhraseItemService.getItems().length && phrasesListIsChanged !== null && !isLoading ?
                <PreferencesPhrasesSelectBlock preferenceId={preferenceId} isLoading={isLoading} setIsLoading={setIsLoading}/>
                :
                <div className="preference__phrases-buttons">
                    <GeneratePreferencePhrasesIcon preferenceId={preferenceId} isLoading={isLoading} setIsLoading={setIsLoading}/>
                    <TrashIcon nameOfClass="preference__phrases-icon preference__phrases-icon_disabled" />
                </div>}
            {preferencePhraseItemService.getItems().length && phrasesListIsChanged !== null && !isLoading ?
                <PreferencePhrasesList />
                :
                <NoPhrasesForPreferenceItem />
            }
        </div>
    );
};

export default PreferencePhrasesHandler;
