import React from 'react';
import { i18n } from '../../i18n';
import { TrashIcon } from '../../appearence/icons/trash_icon';

const PreferencesListSelectDelete = ({ phrasesListManager }) => {
    return (
        <button className="button preference__button_outline" onClick={() => phrasesListManager.updatePreferenceIsWelcome(false)}>
            <TrashIcon nameOfClass="preference__button-icon"/>
            <span className="block__content preference__button-content">{i18n.get('app.page.preference.modal.delete')}</span>
        </button>
    );
};

export default PreferencesListSelectDelete;
