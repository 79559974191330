import { FORM_TAB_NAME, ITEM_TAB_NAME, PAGE_TAB_NAME_LIST, PRODUCT_TAB, PROPERTY_TAB_NAME } from '../constant';
import { i18n } from '../i18n';

const checkActiveRoute = (activeRoute, pageUrl) => {
    return activeRoute.includes(pageUrl);
};

const createProductPageTabs = (activeRoute) => {
    return [
        {
            pageUrl: `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
            title: i18n.get(`app.page.product.productTab.product`),
            isActive: checkActiveRoute(activeRoute, `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`),
        },
        {
            pageUrl: `/${PRODUCT_TAB}/${FORM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
            title: i18n.get(`app.page.product.productTab.form`),
            isActive: checkActiveRoute(activeRoute, `/${PRODUCT_TAB}/${FORM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`),
        },
        {
            pageUrl: `/${PRODUCT_TAB}/${PROPERTY_TAB_NAME}/${PAGE_TAB_NAME_LIST}`,
            title: i18n.get(`app.page.product.productTab.property`),
            isActive: checkActiveRoute(activeRoute, `/${PRODUCT_TAB}/${PROPERTY_TAB_NAME}/${PAGE_TAB_NAME_LIST}`),
        },
    ];
};

export const productPageNavigationService = {
    observersList: [],
    getTabs(activeRoute) {
        return createProductPageTabs(activeRoute);
    },
    getTabName() {
        return PRODUCT_TAB;
    },
    registerTabsUpdateObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterTabsUpdateObserver(observer) {
        this.observersList = this.observersList.filter(observerFunction => observerFunction !== observer);
    },
    notifyObservers() {
        this.observersList.forEach(observer => observer());
    },
};
