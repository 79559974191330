import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import ItemName from '../sharedComponents/ItemName';
import { preferencesGroupsService } from '../../services/preferencesGroupsService';
import { initializePreferenceGroupSaveService, preferenceGroupSaveService } from '../../services/preferenceGroupSaveService';
import IsAutoAssignedSwitcher from './IsAutoAssignedSwitcher';
import PreferenceGroupActivityPeriods from './PreferenceGroupActivityPeriods';
import PreferenceGroupSaveButton from './PreferenceGroupSaveButton';
import { PAGE_TAB_NAME_NEW } from '../../constant';

const PreferenceGroupEdit = () => {
    const { id } = useParams();
    const [preferenceIsReady, setPreferenceIsReady] = useState(false);

    const changePreferenceGroupReadyState = () => {
        if(id === PAGE_TAB_NAME_NEW) {
            setPreferenceIsReady(true);
        } else {
            setPreferenceIsReady(preferenceGroupSaveService.getPreferenceGroup().name !== undefined);
        }
    };

    useEffect(() => {
        if(id !== PAGE_TAB_NAME_NEW) {
            initializePreferenceGroupSaveService(id);
        }
        preferenceGroupSaveService.registerPreferenceGroupUpdateObserver(changePreferenceGroupReadyState);
        return () => {
            preferenceGroupSaveService.unRegisterPreferenceGroupUpdateObserver(changePreferenceGroupReadyState);
            preferenceGroupSaveService.clearService();
        };
    }, [id]);
    return (
        <>
            <PageTitleAsBreadCrumbs />
            <article className="product__article product__article-add">
                <form className="product__form product__form_large" key={preferenceIsReady + 'group'}>
                    <PreferenceGroupSaveButton />
                    <ItemName itemCode={preferenceGroupSaveService.getPreferenceGroup().name} itemService={preferencesGroupsService} itemSaveService={preferenceGroupSaveService}/>
                    <IsAutoAssignedSwitcher />
                    <PreferenceGroupActivityPeriods />
                </form>
            </article>
        </>
    );
};

export default PreferenceGroupEdit;
