import React from 'react';
import { PAGINATION_ITEM_TYPE_LAST_PAGE, PAGINATION_ITEM_TYPE_NEXT_PAGE, PAGINATION_ITEM_TYPE_PAGE_NUMBER, PAGINATION_ITEM_TYPE_THREE_DOTS } from '../constant';

const PaginationItem = ({ type, pageNumber, itemsStorageService }) => {
    const changePageCount = (currentPage) => {
        if(currentPage !== itemsStorageService.getCurrentPage()) {
            itemsStorageService.setPageNumber(currentPage);
        }
    };

    switch(type) {
    case PAGINATION_ITEM_TYPE_PAGE_NUMBER:
        return (
            <li key={pageNumber} 
                className={`block__text pagination__number ${pageNumber === 1 ? 'pagination__number_first' : ''}
                    ${pageNumber == itemsStorageService.getCurrentPage() ? 'pagination__number_active' : ''}`} 
                onClick={() => changePageCount(pageNumber)}>
                {pageNumber}
            </li>
        );
    case PAGINATION_ITEM_TYPE_THREE_DOTS:
        return (
            <li key={'dots'} className={`pagination__number`}>...</li>
        );
    case PAGINATION_ITEM_TYPE_LAST_PAGE:
        return (
            <li key={'last'} 
                className={`block__text pagination__number pagination__number_last`} 
                onClick={() => changePageCount(pageNumber)}>{pageNumber}</li>
        );
    case PAGINATION_ITEM_TYPE_NEXT_PAGE:
        return (
            <li key={1} 
                className={`block__text pagination__number pagination__number_first`} 
                onClick={() => changePageCount(pageNumber)}>{pageNumber}</li>
        );
    default:
        return null;
    }
};

export default PaginationItem;
