import React, { useState, useEffect } from 'react';
import { ErrorHandler } from './ErrorHandler';
import { GET_USER_REQUEST, postFetchRequest } from '../store/requests';
import { ACTIVE_MODAL_ITEM_EMAIL, ACTIVE_MODAL_ITEM_PASSWORD, ERROR_TYPE_PASSWORD, LOCALE_EN, LOCALE_RU, TABLET_WIDTH, errorCallback, makeUrl } from '../constant';
import { i18n } from '../i18n';
import Modal from './Modal';
import { MODAL_SMALL_SIZE, MODAL_SUPER_SMALL_SIZE } from './helper';
import PasswordField from './sharedComponents/PasswordField';
import LocaleSwitcher from './sharedComponents/LocaleSwitcher';
import Select from './sharedComponents/Select';
import useWindowDimensions from '../hooks/useWindowDimension';
import { NEW_PASSWORD_KEY, OLD_PASSWORD_KEY, REPEAT_PASSWORD_KEY, loginManager } from './sharedComponents/loginManager';

const handleCreateEditEmailObject = () => {
    return {
        name: ACTIVE_MODAL_ITEM_EMAIL,
        fields: [
            {
                name: i18n.get('app.page.profile.email'),
                value: '',
                type: 'email',
            },
        ],
    };
};

const handleCreateEditPasswordObject = () => {
    return {
        name: ACTIVE_MODAL_ITEM_PASSWORD,
        fields: [
            {
                label: i18n.get('app.page.profile.modal.password.currentPassword'),
                type: OLD_PASSWORD_KEY,
            },
            {
                label: i18n.get('app.page.profile.modal.password.newPassword'),
                type: NEW_PASSWORD_KEY,
            },
            {
                label: i18n.get('app.page.profile.modal.password.repeatNewPassword'),
                type: REPEAT_PASSWORD_KEY,
            },
        ],
    };
};

const ChangeModalContent = ({handleChange, handleClose, activeModalItem, saveChanges, error }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const changeIsDisabled = () => {
        setIsDisabled(!loginManager.canSendRequest());
    };

    useEffect(() => {
        loginManager.registerLoginUpdateObserver(changeIsDisabled);
        return () => loginManager.unRegisterLoginUpdateObserver(changeIsDisabled);
    }, []);
    return (
        <>
            <h2 className="modal__title block__title-h3">{i18n.get(`app.page.profile.modal.${activeModalItem.name}.title`)}</h2>
            <p className="block__content modal__text">{i18n.get(`app.page.profile.modal.${activeModalItem.name}.content`)}</p>
            {activeModalItem.fields.map((it, index) => (
                <React.Fragment key={it.label + it.type}>
                    {it.type !== 'email' ? 
                        <PasswordField label={it.label} passwordType={it.type} />
                        :
                        <label key={it.name} className="block__content profile__label profile__label-modal"
                            value={it.value}
                            name={it.name}
                            onChange={(e) => handleChange(e, index)}>{it.name}
                            <input className="block__content profile__input" />
                        </label>}
                    {index === 2 && error.type === ERROR_TYPE_PASSWORD ? <p className="block__bullet alert profile__alert">{error.message}</p> : ''}
                </React.Fragment>
            ))}
            <div className="block__button modal__button-group">
                <button className="block__button button button_outline" onClick={() => handleClose(null)}>{i18n.get(`app.page.profile.modal.${activeModalItem.name}.cancel`)}</button>
                <button className={`block__button button ${isDisabled ? 'button_disabled' : ''}`} onClick={saveChanges}>
                    {i18n.get(`app.page.profile.modal.${activeModalItem.name}.change`)}
                </button>
            </div>
        </>
    );
};

const ProfilePage = ({setLocaleIsReady, currentUserData}) => {
    const [profileData, setProfileData] = useState(currentUserData);
    const [activeModalItem, setActiveModalItem] = useState(null);
    const [error, setError] = useState({type:'', message: ''});
    const [mobileLocaleList, setMobileLocaleList] = useState({activeList: [],
        fullList: [{name: 'Русский', id: LOCALE_RU, code: LOCALE_RU}, {name:'English', id: LOCALE_EN, code: LOCALE_EN}]});
    const { width } = useWindowDimensions();

    const handleChangeProfileData = (e) => {
        profileData[e.target.name] = e.target.value;
        setProfileData({...profileData});
    };

    const handleChangeUser = (e) => {
        profileData[e.target.name] = e.target.value;
        setProfileData({...profileData});
        const data = {
            name: profileData.name,
            appRoleId: profileData.appRole.id,
        };
        postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id, '/']), data, () => {}, errorCallback, true);
    };

    const handleChangeProfile = (e, index) => {
        setError({type:'', message: ''});
        activeModalItem.fields[index].value = e.target.value;
        setActiveModalItem({...activeModalItem});
    };

    const successUpdateEmailCallback = (response) => {
        if(response.ok) {
            setActiveModalItem(null);
            profileData.isChangedEmail = true;
            profileData.email = activeModalItem.fields[0].value;
            setProfileData({...profileData});
        }
    };

    const successUpdatePasswordCallback = (response) => {
        if(response.ok) {
            setActiveModalItem(null);
        }
    };


    const saveChanges = () => {
        if(activeModalItem.name === ACTIVE_MODAL_ITEM_EMAIL) {
            const data = {
                email: activeModalItem.fields[0].value,
            };
            postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id , `/${ACTIVE_MODAL_ITEM_EMAIL}/`]), data, successUpdateEmailCallback, errorCallback, true);
        } else {
            loginManager.sendChangePasswordRequest(profileData.id, successUpdatePasswordCallback);
        }
    };

    const handleUpdateLocale = (newLocale) => {
        const newProfileData = {...profileData, locale: newLocale };
        i18n.APP_LOCALE = newLocale;
        setProfileData(newProfileData);
        const data = {locale: newLocale};
        i18n.create({[newLocale]: i18n[newLocale]}, newLocale);
        postFetchRequest(makeUrl([GET_USER_REQUEST, profileData.id, '/locale/']), data,
            () => setLocaleIsReady(true), errorCallback, true);
        
    };

    return (
        <section className="workspace workspace__profile">
            {activeModalItem ? 
                <Modal content={
                    <ChangeModalContent handleChange={handleChangeProfile} saveChanges={saveChanges} handleClose={setActiveModalItem} 
                        error={error} activeModalItem={activeModalItem} setActiveModalItem={setActiveModalItem}/>} 
                handleCloseModal={setActiveModalItem} size={activeModalItem.name === ACTIVE_MODAL_ITEM_EMAIL ? MODAL_SMALL_SIZE : MODAL_SUPER_SMALL_SIZE} /> 
                : 
                ''}
            <h1 className="profile__title block__title-h2">
                {i18n.get('app.page.profile.title')}
                {profileData.id && width > TABLET_WIDTH ?
                    <div className="locale__select locale__select-profile">
                        <LocaleSwitcher localeCode={profileData.locale} notifyListener={handleUpdateLocale}/>
                    </div>
                    :
                    null}
            </h1>
            <div className="profile__wrapper">
                <article className="profile__article">
                    <h2 className="profile__subtitle block__title-h3">{i18n.get('app.page.profile.myData')}</h2>
                    <form className="profile__form">
                        <label className="block__text profile__label">{i18n.get('app.page.profile.name')}
                            <input className="block__content profile__input" 
                                value={profileData.name ? profileData.name : ''}
                                name="name"
                                onChange={handleChangeProfileData}/>
                        </label>
                        <label className="block__text profile__label">{i18n.get('app.page.profile.email')}
                            <input className={`block__content profile__input ${profileData.isChangedEmail ? "profile__input_warning" : ""}`} 
                                value={profileData.email ? profileData.email : ''}                        
                                name="email"
                                onChange={handleChangeProfileData}
                                disabled={true}/>
                            {profileData.isChangedEmail ? <p className="alert__warning">{i18n.get('app.page.profile.confirmEmail')}</p> : ''}
                        </label>
                        <p className="block__bullet profile__change" onClick={() => setActiveModalItem(handleCreateEditEmailObject())}>{i18n.get('app.page.profile.changeEmail')}</p>
                        <label className="block__text profile__label">{i18n.get('app.page.profile.password')}
                            <input type="password" className="block__content profile__input" 
                                value={profileData.password ? profileData.password : ''}
                                name="password"
                                placeholder="******"
                                onChange={handleChangeProfileData}
                                disabled={true}/>
                        </label>
                        <p className="block__bullet profile__change" onClick={() => setActiveModalItem(handleCreateEditPasswordObject())}>{i18n.get('app.page.profile.changePassword')}</p>
                        {width <= TABLET_WIDTH ? 
                            <Select titleValue={profileData.locale === 'ru' ? 'Русский' : 'English'} handleChangeValue={handleUpdateLocale} activeOptionList={[]}
                                listOfOptions={mobileLocaleList} setListOfOptions={setMobileLocaleList}/>
                            :
                            null}
                        <button type="button" className="block__button button profile__button" onClick={handleChangeUser}>
                            <span className="button__content">{i18n.get('app.page.profile.change')}</span>
                        </button>
                    </form>
                </article>
                <article className="profile__article profile__article-history">
                    <h2 className="profile__subtitle block__title-h3">{i18n.get('app.page.profile.history')}</h2>
                    <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>
                </article>
            </div>
        </section>
    );
};

export default ProfilePage;
