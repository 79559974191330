import { POPUP_KEY_CREATE_FORM } from "../constant";
import { i18n } from "../i18n";
import { filterFormsList } from "./formsProductService";
import { formsService } from "./formsService";
import { formsStorage } from "./formsStorage";
import { productSaveService } from "./productSaveService";

const transformFormsList = (formsList, withFilter) => {
    let resultArray = [];
    withFilter ? formsList = formsList.filter(it => filterFormsList(it)) : null;
    formsList.forEach(it => resultArray.push({itemId: it.id, itemTitle: formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true), 
        included: it.included === false ? false : true}));
    return resultArray;
};

export class formsPropertyService  {
    observersList = [];
    filter = '';
    possibleForms = transformFormsList(formsStorage.getItems(true), true);
    selectedForms = [];
    propertyId = '';

    constructor (propertyId) {
        this.propertyId = propertyId;
    }

    getPossibleItems() {
        this.possibleForms = transformFormsList(formsStorage.getItems(true)
            .filter(it => formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true).toLowerCase().includes(this.filter)), true);
        return this.possibleForms;
    }

    getCurrentItems() {
        this.selectedForms = transformFormsList(productSaveService.getProduct().properties[this.propertyId].productForms, false);
        return this.selectedForms;
    }

    deleteItem(formId) {        
        productSaveService.deleteProductPropertyForm(this.propertyId, formId);
        this.selectedForms = productSaveService.getProduct().properties[this.propertyId].productForms;
        this.notifyItemsChangingObservers();
    }

    addItem(formId, included) {
        productSaveService.addProductPropertyForm(this.propertyId, formId, included);
        this.selectedForms = productSaveService.getProduct().properties[this.propertyId].productForms;
        this.notifyItemsChangingObservers();
    }

    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    }

    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    }

    hasExcludedItemsBlock() {
        return true;
    }

    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    }

    getSearchString() {
        return this.filter;
    }

    getPopupKey() {
        return POPUP_KEY_CREATE_FORM;
    }

    getButtonTitle() {
        return i18n.get("app.page.product.addForm");
    }

    getLabelName() {
        return i18n.get('app.page.product.productFormName');
    }
}

export const createFormsPropertyService = (propertyId) => {
    return new formsPropertyService(propertyId);
};
