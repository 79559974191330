import { checkItemLocaleCode } from "../components/helper";
import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import { LOCALE_RU, errorCallback, makeUrl, successCallbackEmpty } from "../constant";
import { i18n } from "../i18n";
import { CATEGORY_URL_PART, FORM_URL_PART, PRODUCT_URL, PROPERTY_URL_PART, deleteFetchRequest, getFetchRequest, postFetchRequest } from "../store/requests";
import { formsProductService } from "./formsProductService";
import { formsStorage } from "./formsStorage";
import { productTabNavigationService } from "./productTabNavigationService";

const createObjectFromPropertiesList = (properties) => {
    if(properties && properties.length) {
        const preparedPropertiesObject = {};
        properties.forEach(it => preparedPropertiesObject[it.id] = it);
        return preparedPropertiesObject;
    } else {
        return {};
    }
};

const prepareProductPropertyForms = (formsList) => {
    const preparedArray = [];
    formsList.forEach(it => preparedArray.push({id: it.id, included: it.included !== false ? true : false }));
    return preparedArray;
};

export const getPropertyDataById = (propertyId) => {
    return productSaveService.getProduct().properties[propertyId];
};

const prepareProductProperty = (property) => {
    return {
        value: Array.isArray(property.value) ? JSON.stringify(property.value) : property.value,
        productForms: prepareProductPropertyForms(property.productForms),
        sources: property.sources,
        minValue: property.minValue,
        maxValue: property.maxValue,
    };
};

export const productSaveService = {
    currentProduct: {},
    lastProductUpdateTime: null,
    productUpdateObservers: [],
    loadProduct(productId, callback) {
        try {
            getFetchRequest(makeUrl([PRODUCT_URL, productId, '/?withForms=true&withProperties=true']), (response) => {
                this.currentProduct = {...response, properties: createObjectFromPropertiesList(response.properties), 
                    locale: LOCALE_RU, locales: [{[LOCALE_RU]: i18n.get(`product.item.code.${response.productCode}`, LOCALE_RU)}]};
                this.notifyProductUpdateObservers();
                callback(response);
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    getProduct() {
        if(this.searchValue) {
            const preparedPropertiesList = Object.values(this.currentProduct.properties);
            return {...this.currentProduct, properties: createObjectFromPropertiesList(preparedPropertiesList)};
        }
        return this.currentProduct;
    },
    getDateLastUpdate() {
        return this.lastProductUpdateTime;
    },
    registerItemUpdateObserver(observer) {
        this.productUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.productUpdateObservers = this.productUpdateObservers.filter(productUpdateFunction => productUpdateFunction !== observer);
    },
    notifyProductUpdateObservers() {
        this.productUpdateObservers.forEach(productUpdateFunction => productUpdateFunction(this.currentProduct));
    },
    updateItemLocalization(locale, value) {
        try {
            i18n.update(`product.item.code.${this.currentProduct.productCode}`, value, locale);
            if (this.currentProduct.locales && this.currentProduct.locales.length) {
                const index = this.currentProduct.locales.findIndex(it => it.localeCode === locale);
                index >= 0 ?
                    this.currentProduct.locales[index].localeValue = value
                    :
                    this.currentProduct.locales.push({localeCode: locale, localeValue:value});
            } else {
                this.currentProduct.locales.push({localeCode: locale, localeValue: value});
            }
            if(checkItemLocaleCode(this.currentProduct.productCode)) {
                updateValueSevice.updateLocaleValue(locale, `product.item.code.${this.currentProduct.productCode}`, () => {
                    this.lastProductUpdateTime = Date.now();
                    this.notifyProductUpdateObservers();
                    productTabNavigationService.notifyObservers();
                });
            }
        } catch(err) {
            console.log(err);
        }
    },
    addProductForm(formId) {
        try {
            this.currentProduct.productForms.push(formsStorage.getById(formId));
            postFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, FORM_URL_PART, formId, '/']), 
                null, successCallbackEmpty, errorCallback, true);
            this.lastProductUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            productTabNavigationService.notifyObservers();
        } catch(err) {
            console.log(err);
        }
    },
    deleteProductForm(formId) {
        try {
            this.currentProduct.productForms = this.currentProduct.productForms.filter(it => it.id !== formId);
            deleteFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, FORM_URL_PART, formId, '/']), 
                null, () => null, errorCallback, true);
            this.lastProductUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            productTabNavigationService.notifyObservers();
        } catch(err) {
            console.log(err);
        }
    },
    addProductProperty(propertyCode, categoryCode, propertyId) {
        try {
            const preparedPath = makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART]);
            const preparedProperty = {
                propertyId: propertyId,
                propertyName: propertyCode,
                categoryName: categoryCode,
            };
            postFetchRequest(preparedPath, preparedProperty, (response) => {
                this.currentProduct.properties[response.id] = {...response, dateCreated: new Date(Date.now()).toISOString()};
                this.lastProductUpdateTime = Date.now();
                this.notifyProductUpdateObservers();
                productTabNavigationService.notifyObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    deleteProductProperty(propertyId) {
        try {
            deleteFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART, propertyId, '/']), () => {
                delete this.currentProduct.properties[propertyId];
                this.lastProductUpdateTime = Date.now();
                this.notifyProductUpdateObservers();
                productTabNavigationService.notifyObservers();
            }, errorCallback, true);
        } catch(err) {
            console.log(err);
        }
    },
    updateProductProperty(propertyId, fieldName, fieldValue) {
        try {
            this.currentProduct.properties[propertyId][fieldName] = fieldValue;
            updateValueSevice.updateItemValue(() => {
                postFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART, propertyId, '/']), 
                    prepareProductProperty(this.currentProduct.properties[propertyId]), () => {
                        this.lastProductUpdateTime = Date.now();
                        this.notifyProductUpdateObservers();
                        productTabNavigationService.notifyObservers();
                    }, errorCallback, true);
            });
        } catch(err) {
            console.log(err);
        }
    },
    addProductPropertyForm(propertyId, formId, include) {
        try {
            this.currentProduct.properties[propertyId].productForms.push({...formsStorage.getById(formId), included: include});
            postFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART, propertyId, '/']), 
                prepareProductProperty(this.currentProduct.properties[propertyId]), successCallbackEmpty, errorCallback, true);
            this.lastProductUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            productTabNavigationService.notifyObservers();
        } catch(err) {
            console.log(err);
        }
    },
    deleteProductPropertyForm(propertyId, formId) {
        try {
            this.currentProduct.properties[propertyId].productForms = 
                this.currentProduct.properties[propertyId].productForms.filter(it => it.id !== formId);
            deleteFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART, propertyId, FORM_URL_PART, formId, '/']),
                successCallbackEmpty, errorCallback);
            this.lastProductUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            productTabNavigationService.notifyObservers();
        } catch(err) {
            console.log(err);
        }
    },
    addProductPropertySource(propertyId, sourceId, sourceName) {
        try {
            this.currentProduct.properties[propertyId].sources.push({sourceId, sourceName});
            postFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART, propertyId, '/']), 
                prepareProductProperty(this.currentProduct.properties[propertyId]), successCallbackEmpty, errorCallback, true);
            this.lastProductUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            productTabNavigationService.notifyObservers();
        } catch(err) {
            console.log(err);
        }
    },
    deleteProductPropertySource(propertyId, sourceId) {
        try {
            this.currentProduct.properties[propertyId].sources = 
                this.currentProduct.properties[propertyId].sources.filter(it => it.sourceId !== sourceId);
            postFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, PROPERTY_URL_PART, propertyId, '/']), 
                prepareProductProperty(this.currentProduct.properties[propertyId]), successCallbackEmpty, errorCallback, true);
            this.lastProductUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            productTabNavigationService.notifyObservers();
        } catch(err) {
            console.log(err);
        }
    },

    updateValueLocalization(locale, value, code) {
        try {
            i18n.update(`product.scheme.property.value.${code}`, value, locale);
            if(this.localeTimeout) {
                clearTimeout(this.localeTimeout);
                this.localeTimeout = null;
            }
            if(checkItemLocaleCode(code)) {
                updateValueSevice.updateLocaleValue(locale, `product.scheme.property.value.${code}`, () => {});
            }
        } catch(err) {
            console.log(err);
        }
    },

    updatePropertyValueField(value, propertyId) {
        try {
            if(!this.currentProduct.properties[propertyId].value) {
                this.currentProduct.properties[propertyId].value = value;
                this.updateProductProperty(propertyId, 'value', value);
            }
            this.notifyProductUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },

    getProperty(propertyId) {
        return this.currentProduct.properties[propertyId];
    },
    getLabelValue() {
        return i18n.get('app.page.product.productCategory');
    },
    getProductCategory() {
        return this.currentProduct.productCategory;
    },
    updateProductCategory(value) {
        this.currentProduct['productCategory'] = value;
        postFetchRequest(makeUrl([PRODUCT_URL, this.currentProduct.id, CATEGORY_URL_PART]), {category: value}, () => {
            this.lastFormUpdateTime = Date.now();
            this.notifyProductUpdateObservers();
            formsProductService.notifyItemsChangingObservers();
        }, errorCallback, true);   
    },
    needToAddAny() {
        return false;
    },
};
