import React, { useState, useEffect } from 'react';
import { TrashIcon } from "../../appearence/icons/trash_icon";
import { popupOpenService } from "../../services/popupOpenService";
import { i18n } from '../../i18n';
import { ConeIcon } from '../../appearence/icons/cone_icon';

const DeleteItemIcon = ({ itemId, itemDeleteService, popupKey }) => {
    const [hintIsOpen, setHintIsOpen] = useState(false);
    const [count, setCount] = useState(0);

    const successGetCountCallback = (response) => {
        let countValue = 0;
        response.forEach(it => countValue += it.count);
        setCount(countValue);
    };

    useEffect(() => {
        if(itemDeleteService.checkIsDeletedItem(itemId) !== true) {
            itemDeleteService.getWhereItemIsUsed(itemId, successGetCountCallback);
        }
    }, []);

    const openDeleteFormPopup = () => {
        if(!isDisabled()) {
            popupOpenService.callToOpen(popupKey, itemId);
        }
    };

    const openHint = () => {
        if(isDisabled()) {
            setHintIsOpen(true);
        }
    };

    const isDisabled = () => {
        return count > 0;
    };

    return (
        <div key={itemId + 'deleteIcon'} onClick={openDeleteFormPopup} onMouseOver={openHint} onMouseOut={() => setHintIsOpen(false)}>
            {hintIsOpen ? 
                <p className="product__list-icon_hint">
                    <ConeIcon nameOfClass="product__list-icon_cone"/>
                    {i18n.get(itemDeleteService.getTranslationCode())}
                </p> 
                : 
                null}
            <TrashIcon nameOfClass={`product__list-icon ${isDisabled() ? 'product__list-icon_disabled' : ''}`} />
        </div>
    );
};

export default DeleteItemIcon;
