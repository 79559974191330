import React, { useEffect, useState } from 'react';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_DELETE_PREFERENCE_GROUP } from '../../constant';
import { TrashIcon } from '../../appearence/icons/trash_icon';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';

const PreferenceGroupsActionsDeleteIcon = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const deletePreferenceGroups = () => {
        if(!isDisabled) {
            popupOpenService.callToOpen(POPUP_KEY_DELETE_PREFERENCE_GROUP);
        }
    };

    const changeIsDisabled = () => {
        setIsDisabled(preferencesGroupsStorage.getSelectedList().length === 0);
    };

    useEffect(() => {
        preferencesGroupsStorage.registerFiltersChangingObserver(changeIsDisabled);
        return () => preferencesGroupsStorage.unRegisterFiltersChangingObserver(changeIsDisabled);
    }, []);

    return (
        <li onClick={deletePreferenceGroups}>
            <TrashIcon nameOfClass={`preference__groups-icon ${ !isDisabled ? '' : 'preference__groups-icon_disabled'}`}/>
        </li>
    );
};

export default PreferenceGroupsActionsDeleteIcon;
