import React, { useState, useEffect } from 'react';
import { i18n } from '../../../i18n';
import { createUserPropertyType, propertySaveService } from '../../../services/propertySaveService';
import PropertyItemUserDisplayGroup from './PropertyItemUserDisplayGroup';

const PropertyItemUserDisplayList = () => {
    const [propertyUserTypeIsChanged, setPropertyUserTypeIsChanged] = useState(false);

    const changePropertyUserType = () => {
        setPropertyUserTypeIsChanged(prev => !prev);
    };

    useEffect(() => {
        propertySaveService.registerItemUpdateObserver(changePropertyUserType);
        return () => propertySaveService.unRegisterItemUpdateObserver(changePropertyUserType);
    }, []);
    return (
        <label className="block__text product__form-label product__form-item" key={propertyUserTypeIsChanged + 'propertyUserType'}>
            <span className="product__form-label_text">{i18n.get('app.page.product.property.userPropertyType')}</span>
            {createUserPropertyType().map(it => (
                <React.Fragment key={it.id + it.name}>
                    <PropertyItemUserDisplayGroup userDisplayValue={it.id} />
                </React.Fragment>
            ))}
        </label>
    );
};

export default PropertyItemUserDisplayList;
