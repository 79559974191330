import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { productsStorage } from '../../../services/productsStorage';
import ProductListItem from './ProductListItem';
import { i18n } from '../../../i18n';
import { ErrorHandler } from '../../ErrorHandler';
import useWindowDimensions from '../../../hooks/useWindowDimension';

const ProductList = () => {
    const [productListIsChanged, setProductListIsChanged] = useState([]);
    const { width } = useWindowDimensions();
    const [searchParams] = useSearchParams();

    const changeProductList = () => {
        setProductListIsChanged(prev => !prev);
    };

    const initializeProductsStorage = () => {
        productsStorage.loadItems(changeProductList, width);
    };

    useEffect(() => {
        initializeProductsStorage();
        productsStorage.getInitialFilters(searchParams);
        productsStorage.registerFiltersChangingObserver(changeProductList);
        return () => {
            productsStorage.unRegisterFiltersChangingObserver(changeProductList);
            productsStorage.cleanStorage();
        };
    }, []);

    return (
        <article className="product__article-item">
            {productsStorage.getItems().length ?
                <ul className="product__list product__list_grid">
                    {productsStorage.getItems().map(it => (
                        <li key={it.id + productListIsChanged} 
                            className={`product__list-item ${it.isDeleted ? 'product__list-item_deleted' : ''}`}>
                            <ProductListItem product={it} />
                        </li>
                    ))}
                </ul>
                :
                <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>}
        </article>
    );
};

export default ProductList;
