import React from 'react';
import { ConeIcon } from '../../appearence/icons/cone_icon';

const HintItem = ({ message, title = null }) => {
    return (
        <div className="item__hint-container block__text">
            <ConeIcon nameOfClass="item__hint-cone"/>
            {title ? <p className="block__subtitle item__hint-title">{title}</p> : null}
            {message}
        </div> 
    );
};

export default HintItem;
