import React, { useState, useEffect } from 'react';
import { ChevronIcon } from '../appearence/icons/chevron_icon';
import PaginationItem from './PaginationItem';
import { 
    PAGINATION_ITEM_TYPE_LAST_PAGE, 
    PAGINATION_ITEM_TYPE_NEXT_PAGE, 
    PAGINATION_ITEM_TYPE_PAGE_NUMBER, 
    PAGINATION_ITEM_TYPE_THREE_DOTS, 
} from '../constant';

const PageNumbers = ({ pageMax, currentPage, itemsStorageService }) => {
    const pages = [];
    if (pageMax < 5) {
        for (let i = 1; i < (pageMax + 1); i++) {
            pages.push(
                <React.Fragment key={i + 'key'}>
                    <PaginationItem itemsStorageService={itemsStorageService} pageNumber={i} type={PAGINATION_ITEM_TYPE_PAGE_NUMBER} />
                </React.Fragment>);
        }
    } else {
        if (currentPage > 3) {
            pages.push(
                <React.Fragment key={1}>
                    <PaginationItem itemsStorageService={itemsStorageService} pageNumber={1} type={PAGINATION_ITEM_TYPE_NEXT_PAGE}/>
                </React.Fragment>);
            pages.push(
                <React.Fragment key={'dots-first'}>
                    <PaginationItem itemsStorageService={itemsStorageService} pageNumber={0} type={PAGINATION_ITEM_TYPE_THREE_DOTS}/>
                </React.Fragment>);
        }
        for (let i = 1; i < (pageMax + 1); i++) {
            if (currentPage === 1) {
                i < 4 && pages.push(
                    <React.Fragment key={i}>
                        <PaginationItem itemsStorageService={itemsStorageService} pageNumber={i} type={PAGINATION_ITEM_TYPE_PAGE_NUMBER}/>
                    </React.Fragment>);
            } else if (currentPage === pageMax) {
                i > currentPage - 3 && pages.push(
                    <React.Fragment key={i}>
                        <PaginationItem itemsStorageService={itemsStorageService} pageNumber={i} type={PAGINATION_ITEM_TYPE_PAGE_NUMBER}/>
                    </React.Fragment>);
            } else {
                (i === currentPage - 1 || i === currentPage + 1 || i === currentPage ) 
                    && pages.push(
                        <React.Fragment key={i}>
                            <PaginationItem itemsStorageService={itemsStorageService} pageNumber={i} type={PAGINATION_ITEM_TYPE_PAGE_NUMBER}/>
                        </React.Fragment>);
            }
        }
        if(pageMax > 4 && currentPage < pageMax - 2) {
            pages.push(
                <React.Fragment key={'dots-last'}>
                    <PaginationItem itemsStorageService={itemsStorageService} pageNumber={0} type={PAGINATION_ITEM_TYPE_THREE_DOTS}/>
                </React.Fragment>);
        }
        if (currentPage === 1 || currentPage < pageMax - 1) {
            pages.push(
                <React.Fragment key={pageMax}>
                    <PaginationItem itemsStorageService={itemsStorageService} pageNumber={pageMax} type={PAGINATION_ITEM_TYPE_LAST_PAGE} />
                </React.Fragment>);
        }
    }
    return (
        <ul className={`pagination__list`}>{pages}</ul>
    );
};

export const Pagination = ({ itemsStorageService }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    const changeCurrentPage = (pageNumber) => {
        if(pageNumber !== itemsStorageService.getCurrentPage()) {
            itemsStorageService.setPageNumber(pageNumber);
            setCurrentPage(pageNumber);
        }
    };

    const changePagesCount = () => {
        setCurrentPage(itemsStorageService.getCurrentPage());
        setMaxPages(itemsStorageService.getMaxCount());
    };

    useEffect(() => {
        itemsStorageService.registerFiltersChangingObserver(changePagesCount);
        return () => itemsStorageService.unRegisterFiltersChangingObserver(changePagesCount);
    }, []);

    try{
        return (
            maxPages > 1 ?
                <section className="pagination">
                    <div className='pagination__block'>
                        {currentPage === 1 ? 
                            "" 
                            :
                            <span className="pagination__item" onClick={() => changeCurrentPage(currentPage - 1)}>
                                <ChevronIcon nameOfClass="pagination__icon"/>
                            </span>}
                        <PageNumbers pageMax={maxPages} currentPage={currentPage} itemsStorageService={itemsStorageService} />
                        {currentPage === maxPages ? 
                            "" 
                            : 
                            <span className="pagination__item" onClick={() => changeCurrentPage(currentPage + 1)}>
                                <ChevronIcon nameOfClass="pagination__icon pagination__icon_right"/>
                            </span>}
                    </div>
                </section>
                :
                null
        );
    } catch(err) {
        return null;
    }
};
