import React from 'react';

export const WarningIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="19" height="19" fill="#E0E0E0" />
        <path d="M-828 -1000C-828 -1001.1 -827.105 -1002 -826 -1002H12260C12261.1 -1002 12262 -1001.1 12262 -1000V3220C12262 3221.1 12261.1 3222 12260 3222H-826C-827.105 3222 -828 3221.1 -828 3220V-1000Z" fill="white" />
        <path d="M-826 -1001H12260V-1003H-826V-1001ZM12261 -1000V3220H12263V-1000H12261ZM12260 3221H-826V3223H12260V3221ZM-827 3220V-1000H-829V3220H-827ZM-826 3221C-826.552 3221 -827 3220.55 -827 3220H-829C-829 3221.66 -827.657 3223 -826 3223V3221ZM12261 3220C12261 3220.55 12260.6 3221 12260 3221V3223C12261.7 3223 12263 3221.66 12263 3220H12261ZM12260 -1001C12260.6 -1001 12261 -1000.55 12261 -1000H12263C12263 -1001.66 12261.7 -1003 12260 -1003V-1001ZM-826 -1003C-827.657 -1003 -829 -1001.66 -829 -1000H-827C-827 -1000.55 -826.552 -1001 -826 -1001V-1003Z" fill="black" fillOpacity="0.1" />
        <g clipPath="url(#clip0_404_1900)">
            <rect width="1920" height="1080" transform="translate(-458 -538)" fill="#FAFAFA" />
            <g filter="url(#filter0_d_404_1900)">
                <path d="M-186 13.0266C-186 -2.65491 -186 -10.4956 -182.948 -16.4852C-180.264 -21.7537 -175.98 -26.0372 -170.712 -28.7216C-164.722 -31.7734 -156.881 -31.7734 -141.2 -31.7734H71.6001C87.2815 -31.7734 95.1222 -31.7734 101.112 -28.7216C106.38 -26.0372 110.664 -21.7537 113.348 -16.4852C116.4 -10.4956 116.4 -2.65491 116.4 13.0266V79.4266C116.4 95.108 116.4 102.949 113.348 108.938C110.664 114.207 106.38 118.49 101.112 121.175C95.1222 124.227 87.2815 124.227 71.6 124.227H-141.2C-156.881 124.227 -164.722 124.227 -170.712 121.175C-175.98 118.49 -180.264 114.207 -182.948 108.938C-186 102.949 -186 95.108 -186 79.4266V13.0266Z" fill="white" />
                <path d="M9.39942 0.226562C7.61938 0.226563 5.87933 0.754403 4.39929 1.74334C2.91924 2.73227 1.76569 4.13788 1.0845 5.78241C0.403314 7.42694 0.225084 9.23654 0.572351 10.9824C0.919617 12.7282 1.77678 14.3318 3.03546 15.5905C4.29413 16.8492 5.89778 17.7064 7.6436 18.0536C9.38943 18.4009 11.199 18.2227 12.8436 17.5415C14.4881 16.8603 15.8937 15.7067 16.8826 14.2267C17.8716 12.7466 18.3994 11.0066 18.3994 9.22656C18.3948 6.84102 17.4452 4.55449 15.7583 2.86765C14.0715 1.18082 11.785 0.231137 9.39942 0.226562ZM8.70711 5.07272C8.70711 4.8891 8.78005 4.71301 8.90988 4.58318C9.03971 4.45335 9.2158 4.38041 9.39942 4.38041C9.58303 4.38041 9.75912 4.45335 9.88895 4.58318C10.0188 4.71301 10.0917 4.8891 10.0917 5.07272V9.91887C10.0917 10.1025 10.0188 10.2786 9.88895 10.4084C9.75912 10.5382 9.58303 10.6112 9.39942 10.6112C9.2158 10.6112 9.03971 10.5382 8.90988 10.4084C8.78005 10.2786 8.70711 10.1025 8.70711 9.91887V5.07272ZM9.39942 14.0727C9.19403 14.0727 8.99325 14.0118 8.82248 13.8977C8.6517 13.7836 8.5186 13.6214 8.44 13.4317C8.3614 13.2419 8.34084 13.0331 8.38091 12.8317C8.42098 12.6302 8.51988 12.4452 8.66511 12.2999C8.81034 12.1547 8.99538 12.0558 9.19682 12.0157C9.39826 11.9757 9.60706 11.9962 9.79682 12.0748C9.98657 12.1534 10.1488 12.2865 10.2629 12.4573C10.377 12.6281 10.4379 12.8289 10.4379 13.0343C10.4379 13.3097 10.3285 13.5738 10.1337 13.7686C9.93897 13.9633 9.67483 14.0727 9.39942 14.0727Z" fill="#F88545" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_404_1900" x="-210" y="-55.7734" width="350.4" height="204" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_404_1900" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_404_1900" result="shape" />
            </filter>
            <clipPath id="clip0_404_1900">
                <rect width="1920" height="1080" fill="white" transform="translate(-458 -538)" />
            </clipPath>
        </defs>
    </svg>
);
