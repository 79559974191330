import React from 'react';
import { i18n } from '../../../i18n';

const PropertyItemVitaminTypeError = () => {
    return (
        <p className="error">{i18n.get('app.page.property.vitaminError')}</p>
    );
};

export default PropertyItemVitaminTypeError;
