import React from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../i18n';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { TABLET_WIDTH } from '../../constant';

const createPreferenceGroupButtonName = (width) => {
    return width > TABLET_WIDTH ? i18n.get('app.page.preference.addGroup') : i18n.get('app.page.preference.addGroup').split(' ')[0];
};

const CreateNewGroupButton = () => {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const navigateToPage = () => {
        navigate('/preference/group/new');
    };

    return (
        <button className='block__button button button_outline preference__button'
            onClick={navigateToPage}>
            <span className="button__content">{createPreferenceGroupButtonName(width)}</span>
        </button>
    );
};

export default CreateNewGroupButton;
