import { POPUP_KEY_RESTORE_FORM, errorCallback, makeUrl } from "../constant";
import { i18n } from "../i18n";
import { FORMS_URL, postFetchRequest } from "../store/requests";
import { formsService } from "./formsService";
import { formsStorage } from "./formsStorage";

export const formRestoreService = {
    restoreItem(itemId, notifyItemsList) {
        postFetchRequest(makeUrl([FORMS_URL, itemId, '/?restore=true']), null, notifyItemsList, errorCallback, true);
    },
    getItemTitle(itemId) {
        return formsService.getLocalization(i18n.APP_LOCALE, formsStorage.getById(itemId).productForm);
    },
    getPopupKey() {
        return POPUP_KEY_RESTORE_FORM;
    },
};
