import React, { useState, useEffect } from 'react';
import { i18n } from '../../../i18n';
import { propertySaveService } from '../../../services/propertySaveService';
import { propertiesService } from '../../../services/propertiesService';
import { ADD_PRODUCT_MACRO_ELEMENTS, ADD_PRODUCT_MICRO_ELEMENTS, ADD_PRODUCT_PHYSICAL_PROPERTIES } from '../../helper';
import SelectWithoutTokens from '../../sharedComponents/SelectWithoutTokens';

const makeCategoryNamesList = () => {
    return [
        {
            name: i18n.get(propertiesService.getPropertyCategoryi18nCode({categoryName: ADD_PRODUCT_MACRO_ELEMENTS})),
            id: ADD_PRODUCT_MACRO_ELEMENTS,
        },
        {
            name: i18n.get(propertiesService.getPropertyCategoryi18nCode({categoryName: ADD_PRODUCT_MICRO_ELEMENTS})),
            id: ADD_PRODUCT_MICRO_ELEMENTS,
        },
        {
            name: i18n.get(propertiesService.getPropertyCategoryi18nCode({categoryName: ADD_PRODUCT_PHYSICAL_PROPERTIES})),
            id: ADD_PRODUCT_PHYSICAL_PROPERTIES,
        },
    ];
};

const getPropertyCategoryTitle = (propertyCategory) => {
    if(propertyCategory) {
        return i18n.get(propertiesService.getPropertyCategoryi18nCode({categoryName: propertyCategory}));
    } else {
        return '';
    }
};

const PropertyItemCategory = () => {
    const [propertyCategory, setPropertyCategory] = useState('');

    const changePropertyCategory = (property) => {
        propertySaveService.updatePropertyValue('categoryName', property.id);
    };

    const changePropertyState = () => {
        setPropertyCategory(propertySaveService.getProperty().categoryName);
    };

    useEffect(() => {
        propertySaveService.registerItemUpdateObserver(changePropertyState);
        return () => propertySaveService.unRegisterItemUpdateObserver(changePropertyState);
    }, []);


    return (
        <label className="block__text product__form-label" key={propertyCategory + 'label'}>
            <span className="product__form-label_text">{i18n.get('app.page.product.propertyItem.categoryName')}</span>
            <SelectWithoutTokens listOfOptions={makeCategoryNamesList()} handleChangeValue={changePropertyCategory}
                titleValue={getPropertyCategoryTitle(propertyCategory)}/>
        </label>
    );
};

export default PropertyItemCategory;
