import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CalendarIcon } from '../../appearence/icons/calendar_icon';
import Calendar from '../sharedComponents/CalendarItem';
import { MOUSE_DOWN_EVENT } from '../../constant';
import { preferenceGroupSaveService } from '../../services/preferenceGroupSaveService';

const createValueDate = (date) => {
    return new Date(date).toLocaleString('ru-RU').substring(0, 6);
};

const PreferenceGroupActivityPeriodsCalendarIcon = ({ currentPeriodIndex, labelType }) => {
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const wrapperRef = useRef();

    const closeLocalesList = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setCalendarIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, closeLocalesList);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, closeLocalesList);
        };
    }, [closeLocalesList]);

    const changeSelectedDate = (value) => {
        if(value.id) {
            preferenceGroupSaveService.updatePreferenceGroupActivityPeriod(labelType, createValueDate(value.startDate), currentPeriodIndex);
            setCurrentDate(value.startDate);
        }
    };

    return ( 
        <span onClick={() => setCalendarIsOpen(!calendarIsOpen)} ref={wrapperRef}>
            <CalendarIcon nameOfClass="preference__icon-calendar" />
            {calendarIsOpen ? 
                <div className="calendar" onClick={(e) => e.stopPropagation()}>
                    <Calendar currentDate={currentDate} setCurrentDate={changeSelectedDate}/>
                </div>
                :
                null}
        </span>
    );
};

export default PreferenceGroupActivityPeriodsCalendarIcon;
