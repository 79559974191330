import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { LogoIcon } from '../appearence/icons/logo_icon';
import { ERROR_TYPE_LOGIN, ERROR_TYPE_PASSWORD, makeUrl} from "../constant";
import { CHANGE_PASSWORD_REQUEST, LOGIN_REQUEST, postFetchRequest } from '../store/requests';
import { i18n } from '../i18n';
import PasswordField from './PasswordField';
import { ArrowIcon } from '../appearence/icons/arrow_icon';
import CommitInfo from './sharedComponents/CommitInfo';
import { keyDownService } from '../services/keyDownService';

const LoginPage = ({setAccessToken}) => {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [forgetPasswordFormIsOpen, setForgetPasswordFormIsOpen] = useState(false);
    const [error, setError] = useState({message:'', type:''});
    const [loginButtonIsUnactive, setLoginButtonIsUnactive] = useState(false);

    useEffect(() => {
        setError({message:'', type:''});
    }, [login, password, forgetPasswordFormIsOpen]);

    const succesLoginCallback = (response) => {
        if(response.status === 200) {
            navigate("/");
            setAccessToken(true);
            setLoginButtonIsUnactive(false);
        } else {
            setError({type:ERROR_TYPE_PASSWORD, message:i18n.get("public.app.page.login.signInError")});
            setLoginButtonIsUnactive(false);
        }
    };

    const successChangePasswordCallback = (response) => {
        if(response.status === 200) {
            setLogin('');
            setForgetPasswordFormIsOpen(false);
            setLoginButtonIsUnactive(false);
        } else {
            setError({type:ERROR_TYPE_LOGIN, message:i18n.get("public.app.page.login.changePasswordError")});
            setLoginButtonIsUnactive(false);
        }
    };

    const errorCallback = () => {
        setError({type:ERROR_TYPE_PASSWORD, message:i18n.get("public.app.page.login.signInError")});
        setLoginButtonIsUnactive(false);
    };

    const handleLogin = () => {
        setLoginButtonIsUnactive(true);
        setPassword('');
        if(forgetPasswordFormIsOpen) {
            const data = {
                email: login,
            };
            postFetchRequest(makeUrl([CHANGE_PASSWORD_REQUEST]), data, successChangePasswordCallback, errorCallback, true);
        } else {
            if(login && password) {
                const data = {login, password};
                postFetchRequest(makeUrl([LOGIN_REQUEST]), data, succesLoginCallback, errorCallback, true);
            } else {
                setLoginButtonIsUnactive(false);
            }
        }
    };

    return (
        <>
            <header className="login__header">
                <LogoIcon />
                <CommitInfo />
            </header>
            <main className="login" onKeyDown={(e) => keyDownService.saveNewItem(e, handleLogin)}>
                {forgetPasswordFormIsOpen ? 
                    <ArrowIcon nameOfClass="login__icon-back" handleClick={setForgetPasswordFormIsOpen} value={false} />
                    :
                    null}
                <h1 className="login__title block__title-h3">
                    {!forgetPasswordFormIsOpen ? 
                        i18n.get('public.app.page.login.title')
                        :
                        i18n.get('public.app.page.login.enterEmail')}
                </h1>
                {forgetPasswordFormIsOpen ? 
                    <h2 className="block__content login__subtitle">{i18n.get('public.app.page.login.content')}</h2>
                    :
                    null}
                <form className="login__form" autoComplete="off">
                    <label className="block__text login__label">{i18n.get('public.app.page.login.email')}
                        <input className="block__content login__input" value={login} onChange={(e)=> setLogin(e.target.value.trim())}/>
                    </label>
                    {!forgetPasswordFormIsOpen ? 
                        <PasswordField password={password} setPassword={(e) => setPassword(e.target.value)} error={error}
                            label={i18n.get('public.app.page.login.password')}/>
                        :
                        null}
                    {error.type ? <p className="block__bullet alert">{error.message}</p> : ''}
                    <button type="button" disabled={loginButtonIsUnactive} onClick={() => loginButtonIsUnactive ? null : handleLogin()}
                        className={`block__button button login__button ${(login && password) || (login && forgetPasswordFormIsOpen) ? '' : 'button_disabled'}`}>
                        <span className="button__content">{i18n.get('public.app.page.login.continue')}</span>
                    </button>
                    {!forgetPasswordFormIsOpen ?
                        <p className="block__content login__text" onClick={() => setForgetPasswordFormIsOpen(true)}>
                            {i18n.get('public.app.page.login.forgetPassword')}
                        </p>
                        :
                        null}
                </form>
            </main>
        </>
    );
};

export default LoginPage;
