import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_DELETE_INGREDIENT, PREFERENCE_TYPE_CEREAL_MIX } from '../../constant';
import { productsService } from '../../services/productsService';
import { TrashIcon } from '../../appearence/icons/trash_icon';
import { XIcon } from '../../appearence/icons/x_icon';
import FormsHandler from '../formStructure/FormsHandler';
import { createFormsIngredientService } from '../../services/formsIngredientService';
import { recipeSaveService } from '../../services/recipeSaveService';

const IngredientsListItem = ({ingredient}) => {
    const [recipeIsChanged, setRecipeIsChanged] = useState(null);

    const changeRecipe = () => {
        setRecipeIsChanged(!recipeIsChanged);
    };

    useEffect(() => {
        recipeSaveService.registerItemUpdateObserver(changeRecipe);
        return () => {
            recipeSaveService.unRegisterItemUpdateObserver(changeRecipe);
        };
    }, []);

    return (
        <li className="recipe__add-item" key={ingredient.id + "ingredient"}>
            <h3 className='block__text recipe__add-title' >
                <span className="product__form-label_text">
                    {ingredient.ingredientType === PREFERENCE_TYPE_CEREAL_MIX ? 
                        i18n.get('preference.scheme.preferenceType.cereal-mix') 
                        : 
                        i18n.get('app.page.recipe.product')}
                </span>
                <span onClick={() => popupOpenService.callToOpen(POPUP_KEY_DELETE_INGREDIENT, ingredient.id)}>
                    <TrashIcon nameOfClass={'recipe__ingredient-delete'} handleClick={() => {}} />
                </span>
            </h3>
            {ingredient.ingredientType === PREFERENCE_TYPE_CEREAL_MIX ? 
                <label className="block__text product__form-label">
                    <div>
                        <div className="product__forms">
                            <label className="block__text recipe__form-label recipe__add-label">
                                <ul className="product__forms-list product__forms-list_full-width">
                                    {ingredient.ingredientParts.map(it => (
                                        <li key={it.id + 'selected'} className="product__forms-item">
                                            <span className="block__bullet product__forms-text">
                                                {productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true)}
                                            </span>
                                            <XIcon nameOfClass="product__forms-item-icon" />
                                        </li>
                                    ))}
                                </ul>
                            </label>
                        </div>
                    </div>
                </label>
                :
                <label className="block__text recipe__form-label recipe__add-label">
                    <input className="recipe__form-input recipe__add-input" 
                        disabled={true}
                        value={productsService.getLocalization(i18n.APP_LOCALE, ingredient?.ingredientParts[0]?.productCode, true)} 
                        onChange={() => {}}
                        placeholder={i18n.get('app.page.recipe.productCode')} /> 
                </label>}
            <FormsHandler selectWithTokensManager={createFormsIngredientService(ingredient?.ingredientParts[0]?.productCode, ingredient.id)} />
        </li> 
    );
};

export default IngredientsListItem;
