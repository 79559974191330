import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { popupOpenService } from './popupOpenService';
import { MODAL_SMALL_SIZE } from '../helper';
import Modal from '../Modal';
import { i18n } from '../../i18n';

const RestoreModalContent = ({ restoreItem, handleClose, itemTitle, tab }) => (
    <>
        <h2 className="modal__title modal__title_align block__title-h3 block__title-h3">{i18n.get(`app.page.product.restore.modal.${tab}.title`)}</h2>
        <p className="block__content modal__text modal__text_align">{i18n.get(`app.page.product.restore.modal.${tab}.content`)} {itemTitle}?</p>
        <div className="block__button modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>
                <span className="button__content">{i18n.get('app.page.product.modal.cancel')}</span>
            </button>
            <button className="block__button button" onClick={restoreItem}>
                <span className="button__content">{i18n.get('app.page.product.modal.restore')}</span>
            </button>
        </div>
    </>
);

const RestoreItemPopup = ({ notifyItemsList, itemRestoreService }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { tab } = useParams();
    const [itemId, setItemId] = useState('');

    const handleOpenModal = (itemIdValue) => {
        setIsOpen(true);
        setItemId(itemIdValue);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(itemRestoreService.getPopupKey(), handleOpenModal);
        return () => popupOpenService.unRegisterPopup(itemRestoreService.getPopupKey(), handleOpenModal);
    },[]);

    const restoreItem = () => {        
        setIsOpen(null);
        itemRestoreService.restoreItem(itemId, notifyItemsList);
    };

    return (
        isOpen ?
            <Modal content={<RestoreModalContent handleClose={setIsOpen} restoreItem={restoreItem} 
                itemTitle={itemRestoreService.getItemTitle(itemId)} tab={tab}/>} 
            handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default RestoreItemPopup;
