import React, { useState, useEffect } from 'react';
import { updateSeveralPropertiesService } from '../../../services/updateSeveralPropertiesService';
import Checkbox from '../../Checkbox';
import { i18n } from '../../../i18n';
import { propertiesService } from '../../../services/propertiesService';

const findCurrentCheckboxValue = (propertyItemId, selectedList) => {
    return selectedList.findIndex(it => it === propertyItemId) >= 0;
};

const findCheckedAllValues = (selectedPropertiesList, propertiesList) => 
    selectedPropertiesList.length !== 0 && selectedPropertiesList.length === propertiesList.length;

const UpdateSeveralPropertiesListOfProperties = () => {
    const [propertiesList, setPropertiesList] = useState([]);
    const [selectedPropertiesList, setSelectedPropertiesList] = useState([]);

    const selectPropertyInList = (propertyItemId) => {
        if(propertyItemId) {
            const indexOfProperty = selectedPropertiesList.findIndex(it => it === propertyItemId);
            indexOfProperty === -1 ?
                updateSeveralPropertiesService.addPropertyInPropertiesList(propertyItemId)
                :
                updateSeveralPropertiesService.deletePropertyFromPropertiesList(propertyItemId);
        } else {
            selectedPropertiesList.length === propertiesList.length && (selectedPropertiesList.length || propertiesList.length) ?
                updateSeveralPropertiesService.deletePropertyFromPropertiesList()
                :
                updateSeveralPropertiesService.addPropertyInPropertiesList();
        }
    };

    const changeSelectedList = () => {
        setSelectedPropertiesList([...updateSeveralPropertiesService.getSelectedProperties()]);
    };

    useEffect(() => {
        setPropertiesList(updateSeveralPropertiesService.getProperties());
        updateSeveralPropertiesService.registerObserverDataListsChanged(changeSelectedList);
        return () => updateSeveralPropertiesService.unRegisterObserverDataListsChanged(changeSelectedList);
    }, []);

    return (
        <article className="property__article">
            <h3 className="main__subtitle property__title">{i18n.get('app.page.product.modal.updateProperties.applyTo')}</h3>
            <ul className="scroll property__list">
                <li onClick={() => selectPropertyInList()} className="property__list-item" >
                    <Checkbox key={"all-properties" + selectedPropertiesList.length} value={findCheckedAllValues(selectedPropertiesList, propertiesList)}/>
                    <span className="property__list-text">{i18n.get('app.page.product.modal.updateProperties.allProperties')}</span>
                </li>
                {propertiesList.map(property => (
                    <li key={property.id} 
                        onClick={() => selectPropertyInList(property.id)} className="property__list-item">
                        <Checkbox key={property.id + selectedPropertiesList.length} value={findCurrentCheckboxValue(property.id, selectedPropertiesList)}/>
                        <span className="property__list-text">{i18n.get(propertiesService.getPropertyNamei18nCode(property))}</span>
                    </li>
                ))}
            </ul>
        </article>
    );
};

export default UpdateSeveralPropertiesListOfProperties;
