import React, { useState, useEffect } from 'react';
import { updateSeveralPropertiesService } from '../../../services/updateSeveralPropertiesService';
import Checkbox from '../../Checkbox';
import { i18n } from '../../../i18n';
import { formsService } from '../../../services/formsService';
import { formsStorage } from '../../../services/formsStorage';

const findCurrentCheckboxValue = (formId, selectedList) => {
    return selectedList.findIndex(it => it === formId) >= 0;
};

const findCheckedAllValues = (selectedFormsList, listOfForms) => selectedFormsList.length !== 0 && selectedFormsList.length === listOfForms.length;

const UpdateSeveralPropertiesFormsList = ({propertyId}) => {
    const [listOfForms, setListOfForms] = useState([]);
    const [selectedFormsList, setSelectedFormsList] = useState([]);

    const changeSelectedFormsList = () => {
        setSelectedFormsList([...updateSeveralPropertiesService.getSelectedForms()]);
    };

    const selectFormInList = (formId) => {
        if(formId) {
            const indexOfForm = selectedFormsList.findIndex(it => it === formId);
            indexOfForm === -1 ? 
                updateSeveralPropertiesService.addFormInFormsList(formId)
                :
                updateSeveralPropertiesService.deleteFormFromFormsList(formId);
        } else {
            selectedFormsList.length === listOfForms.length && (selectedFormsList.length || listOfForms.length) ?
                updateSeveralPropertiesService.deleteFormFromFormsList()
                :
                updateSeveralPropertiesService.addFormInFormsList();
        }
    };

    useEffect(() => {
        setListOfForms(updateSeveralPropertiesService.getItems(propertyId));
        updateSeveralPropertiesService.registerObserverDataListsChanged(changeSelectedFormsList);
        return () => updateSeveralPropertiesService.unRegisterObserverDataListsChanged(changeSelectedFormsList);
    }, []);

    return (
        <article className="property__article">
            <h3 className="main__subtitle property__title">{i18n.get('app.page.product.modal.updateProperties.meaning')}</h3>
            <ul className="scroll property__list">
                <li onClick={() => selectFormInList()} className="property__list-item">
                    <Checkbox key={'all-forms' + selectedFormsList.length} value={findCheckedAllValues(selectedFormsList, listOfForms)}/>
                    <span className="property__list-text">{i18n.get('app.page.product.modal.updateProperties.allForms')}</span>
                </li>
                {listOfForms.map(form => (
                    <li key={form.id} 
                        onClick={() => selectFormInList(form.id)} className="property__list-item">
                        <Checkbox key={form.id + selectedFormsList.length} value={findCurrentCheckboxValue(form.id, selectedFormsList)}/>
                        <span className="property__list-text">{i18n.get(formsService.geti18nCode(formsStorage.getById(form.id)))}</span>
                    </li>
                ))}
            </ul>
        </article>
    );
};

export default UpdateSeveralPropertiesFormsList;
