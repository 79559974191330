import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { popupOpenService } from '../../../services/popupOpenService';
import { productCreateServiceItem, initializeProductCreateService } from '../../../services/productCreateService';
import { i18n } from '../../../i18n';
import RestrictedCodeInput from '../RestrictedCodeInput';
import CreateItemPopupSaveButton from '../CreateItemPopupSaveButton';
import { POPUP_KEY_CREATE_PRODUCT } from '../../../constant';
import { ADD_PRODUCT_MACRO_ELEMENTS } from '../../helper';
import { keyDownService } from '../../../services/keyDownService';
import { productsStorage } from '../../../services/productsStorage';
import { productsService } from '../../../services/productsService';
import SelectWithoutTokens from '../../sharedComponents/SelectWithoutTokens';

const createProductsSelectList = () => {
    const result = [];
    productsStorage.getItems(true)
        .forEach(it => result.push({id: it.id, name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true)}));
    return result;
};

const CreateNewProductPopup = () => {
    const [isVisible, setIsVisible] = useState('');
    const navigate = useNavigate();

    const changePopupVisibility = () => {
        setIsVisible(true);
    };

    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_CREATE_PRODUCT, changePopupVisibility);
        initializeProductCreateService();
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_CREATE_PRODUCT, changePopupVisibility);
    }, []);


    const redirectToEditPage = (response) => {
        if(response && response.id) {
            navigate(`/product/item/${response.id}/?productTab=${ADD_PRODUCT_MACRO_ELEMENTS}`);
        } else {
            productCreateServiceItem.changeItemCode('');
        }
    };

    const createNewItem = () => {
        if(productCreateServiceItem.checkCodeValidation() === '') {
            productCreateServiceItem.saveNewItem(redirectToEditPage);
        }
    };

    const changeBasedOnValue = (value) => {
        productCreateServiceItem.changeBasedOn(value.id);
    };

    const closePopup = () => {
        setIsVisible(false);
        productCreateServiceItem.changeBasedOn(null);
    };

    return (
        isVisible ?
            <>
                <div className="modal__wrapper modal__wrapper_active" onClick={closePopup}></div>  
                <article className="modal modal_small modal__create" onKeyDown={(e) => keyDownService.saveNewItem(e, createNewItem)}>
                    <h2 className="modal__title block__title-h3">{i18n.get(`app.page.product.modal.createProduct.title`)}</h2>
                    <p className="block__content modal__text product__modal-text">{i18n.get(`app.page.product.modal.createProduct.content`)}</p>
                    <label className="block__text product__form-label product__modal-label">
                        <span className="product__form-label_text">{i18n.get("app.page.product.addProductItem.productCode")}</span>
                        <RestrictedCodeInput itemCreateService={productCreateServiceItem} />
                    </label>
                    <span className="block__text product__form-based">{i18n.get('app.page.product.basedOn')}</span>
                    <SelectWithoutTokens listOfOptions={createProductsSelectList()} handleChangeValue={changeBasedOnValue} titleValue="" 
                        withCleanIcon={true} />
                    <div className="block__button modal__button-group">
                        <button className="block__button button button_outline" onClick={closePopup}>
                            <span className="button__content">{i18n.get(`app.page.product.modal.cancel`)}</span>
                        </button>
                        <CreateItemPopupSaveButton itemCreateService={productCreateServiceItem} successRedirectTo={`/product/item`}/>
                    </div>
                </article>
            </>
            :
            null
    );
};

export default CreateNewProductPopup;
