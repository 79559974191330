import { LOCALE_EN, LOCALE_RU } from '../../constant';

export const getUnactiveLocaleValue = (currentLocaleValue) => {
    return currentLocaleValue === LOCALE_RU ? LOCALE_EN : LOCALE_RU;
};

export const getlocaleValue = (activeItem) => {
    let result = '';
    activeItem.locales.forEach(it => it.localeCode === activeItem.locale ? result = it.localeValue : null);
    return result;
};

export const createInitialLocalesArray = () => {
    return [{localeCode: LOCALE_EN, localeValue:''}, {localeCode: LOCALE_RU, localeValue:''}];
};
