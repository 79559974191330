import React, { useState, useEffect } from 'react';
import { TrashIcon } from '../../appearence/icons/trash_icon';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';
import { POPUP_KEY_DELETE_PHRASE } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';

const DeletePhraseIcon = () => {
    const [isVisible, setIsVisible] = useState();

    const changeIconVisibility = () => {
        setIsVisible(preferencesPhrasesStorage.getSelectedList().length > 0);
    };

    const openDeletePhrasePopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_DELETE_PHRASE);
    };

    useEffect(() => {
        preferencesPhrasesStorage.registerFiltersChangingObserver(changeIconVisibility);
        return () => preferencesPhrasesStorage.unRegisterFiltersChangingObserver(changeIconVisibility);
    }, []);
    return (
        isVisible ?
            <TrashIcon nameOfClass="preference__phrases-icon preference__phrases-delete" handleClick={openDeletePhrasePopup} />
            :
            null
    );
};

export default DeletePhraseIcon;
