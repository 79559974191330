import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { PAGE_TAB_NAME_LIST } from '../../constant';

const PageTitleItem = ({pageUrl, title, isActive}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const navigateToUrl = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(location.key !== "default") {
            navigate(-1);
        } else if(pageUrl.includes(PAGE_TAB_NAME_LIST)){
            navigate(pageUrl);
        } else {
            return;
        }
    };
    return (
        <Link to={pageUrl} onClick={navigateToUrl}>
            <h3 className={`block__subtitle item__header-breadcrumbs ${!isActive ? '' : 'item__header-breadcrumbs_active'}`}>
                {title}
            </h3>
        </Link>
    );
};

export default PageTitleItem;
