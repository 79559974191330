import React from "react";
const Switch = ({ value, changeIsVitaminValue }) => {
    return (
        <div key={value} className={`switch__container ${value === true || value === 'true' ? 'switch__container_active' : ''}`} 
            onClick={() => changeIsVitaminValue('isVitamin', !value)}>
            <p className={`switch__round ${value === true || value === 'true' ? 'switch__round_active' : ''}`}></p>
        </div>
    );
};
export default Switch;
