import React from 'react';
import { PlusIcon } from '../../../appearence/icons/plus_icon';
import { i18n } from '../../../i18n';
import { popupOpenService } from '../../../services/popupOpenService';
import { POPUP_KEY_ADD_PROPERTY, SMALL_WEB_SCREEN_WIDTH } from '../../../constant';
import { propertiesStorage } from '../../../services/propertiesStorage';

const AddNewPropertyButton = () => {
    const openAddPropertyPopup = () => {
        propertiesStorage.loadItems(() => popupOpenService.callToOpen(POPUP_KEY_ADD_PROPERTY), SMALL_WEB_SCREEN_WIDTH + 1, '?valid=true');
    };

    return (
        <button className="block__button button button_outline product__tab-button" onClick={openAddPropertyPopup}>
            <span className="button__content">{i18n.get("app.page.product.productFormTab.addProperty")}</span>
            <PlusIcon nameOfClass="product__add-plus" handleClick={() => null}/>
        </button>
    );
};

export default AddNewPropertyButton;
