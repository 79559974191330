import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { i18n } from '../../i18n';
import { ITEM_TAB_NAME, PAGE_TAB_NAME_LIST, PREFERENCE_TAB, TABLET_WIDTH } from '../../constant';
import { preferenceSaveService } from '../../services/preferenceSaveService';

const SaveNewPreferenceButton = () => {
    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    const saveNewPreference = () => {
        preferenceSaveService.savePreference(() => navigate(`/${PREFERENCE_TAB}/${ITEM_TAB_NAME}/${PAGE_TAB_NAME_LIST}`));
    };

    return (
        <button type="button" className='block__button button preference__form-button' onClick={saveNewPreference}>
            <span className="button__content">
                {i18n.get(`app.page.preference.${width <= TABLET_WIDTH ? 'addPreferenceSmall' : 'addPreference'}`)}
            </span>
        </button>
    );
};

export default SaveNewPreferenceButton;
