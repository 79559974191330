import React, {useEffect, useState, useRef} from 'react';
import { XIcon } from '../../appearence/icons/x_icon';
import useWindowDimensions from '../../hooks/useWindowDimension';
import { checkIsOverflowed } from '../../hooks/useOverflowed';
import HintItem from './HintItem';

const SelectWithTokensActiveItem = ({ selectItem, removeCallback }) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef();
    const width = useWindowDimensions();

    useEffect(() => {
        setIsOverflowed(checkIsOverflowed(ref.current));
    }, [width]);

    return (
        <li key={selectItem.itemId + selectItem.included} onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)}
            className={`product__forms-item ${selectItem.included === false ? 'product__forms-item_excluded' : ''}`} 
            onClick={(e) => e.preventDefault()} >
            <span className="block__bullet product__forms-text" ref={ref}>{selectItem.itemTitle}</span>
            <span onClick={() => selectItem.isLastItem ? null : removeCallback.deleteItem(selectItem.itemId)}>
                <XIcon nameOfClass={`product__forms-item-icon ${selectItem.isLastItem ? 'product__forms-item_disabled' : ''}`} />
            </span>
            {isOverflowed && isHovered ? <HintItem message={selectItem.itemTitle} /> : null}
        </li>
    );
};

export default SelectWithTokensActiveItem;
