import { ADD_PRODUCT_MACRO_ELEMENTS, ADD_PRODUCT_MICRO_ELEMENTS, ADD_PRODUCT_PHYSICAL_PROPERTIES } from '../components/helper';
import { ITEM_TAB_NAME, LOCALE_RU, PRODUCT_TAB, TABLET_WIDTH } from '../constant';
import { i18n } from '../i18n';
import { productSaveService } from './productSaveService';

const checkActiveRoute = (activeRoute, pageUrl) => {
    return activeRoute === pageUrl;
};

const createPhysicalTabName = (width) => {
    let result = '';
    if(width > TABLET_WIDTH) {
        result = i18n.get("app.page.product.productFormTab.physical");
    } else {
        result = i18n.APP_LOCALE === LOCALE_RU ? 'Физ.свойства' : 'Physycal';
    }
    return result;
};

const checkFilledProperty = (property, tabName) => {
    return property.categoryName === tabName &&
        (
            property.value !== null ||
            property.minValue !== null ||
            property.maxValue !== null ||
            property.productForms.length ||
            property.sources.length
        );
};

const getTabCount = (tabName) => {
    return Object.values(productSaveService.getProduct().properties).filter(it => checkFilledProperty(it, tabName)).length;
};

const createProductPropertiesTabs = (id, width, activeRoute) => {
    return [
        {
            pageUrl: `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${id}/?productTab=${ADD_PRODUCT_MACRO_ELEMENTS}`,
            title: `${i18n.get('app.page.product.productFormTab.macronutrient')} (${getTabCount(ADD_PRODUCT_MACRO_ELEMENTS)})`,
            isActive: checkActiveRoute(activeRoute, `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${id}/?productTab=${ADD_PRODUCT_MACRO_ELEMENTS}`),
        },
        {
            pageUrl: `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${id}/?productTab=${ADD_PRODUCT_MICRO_ELEMENTS}`,
            title: `${i18n.get('app.page.product.productFormTab.micronutrient')} (${getTabCount(ADD_PRODUCT_MICRO_ELEMENTS)})`,
            isActive: checkActiveRoute(activeRoute, `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${id}/?productTab=${ADD_PRODUCT_MICRO_ELEMENTS}`),
        },
        {
            pageUrl: `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${id}/?productTab=${ADD_PRODUCT_PHYSICAL_PROPERTIES}`,
            title: `${createPhysicalTabName(width)} (${getTabCount(ADD_PRODUCT_PHYSICAL_PROPERTIES)})`,
            isActive: checkActiveRoute(activeRoute, `/${PRODUCT_TAB}/${ITEM_TAB_NAME}/${id}/?productTab=${ADD_PRODUCT_PHYSICAL_PROPERTIES}`),
        },
    ];
};

export const productTabNavigationService = {
    observersList: [],
    getTabs(activeRoute, id, width) {
        return createProductPropertiesTabs(id, width, activeRoute);
    },
    getTabName() {
        return PRODUCT_TAB;
    },
    registerTabsUpdateObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterTabsUpdateObserver(observer) {
        this.observersList = this.observersList.filter(observerFunction => observerFunction !== observer);
    },
    notifyObservers() {
        this.observersList.forEach(observer => observer());
    },
};
