import { FILTER_GROUP_NAME_STATUS, FILTER_NAME_IS_DELETED, FILTER_NAME_IS_NOT_VALID, FILTER_TYPE_CHECKBOX, FILTER_TYPE_MAX_AND_MIN, PRODUCT_COUNT_FULL_WIDTH, PRODUCT_COUNT_SMALL_WIDTH, errorCallback, makeUrl } from "../constant";
import { PRODUCT_URL, getFetchRequest } from "../store/requests";
import { i18n } from '../i18n';
import { productsService } from "./productsService";
import { createFiltersListProductCategories, isSmallWebScreen, sortByDate } from '../components/helper';
import { formsStorage } from './formsStorage';
import { formsService } from './formsService';

const createListOfFilters = () => {
    return [ 
        ...createFiltersListProductCategories(false, 'productCategory'),
        {
            filterTitle: i18n.get('app.page.product.filters.fat'),
            filterName: 'fat',
            filterType: FILTER_TYPE_MAX_AND_MIN,
            variants: [],
            value: false,
            filterGroupName: 'fat',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.proteins'),
            filterName: 'protein',
            filterType: FILTER_TYPE_MAX_AND_MIN,
            variants: [],
            value: false,
            filterGroupName: 'proteins',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.carbohydrates'),
            filterName: 'carbohydrate',
            filterType: FILTER_TYPE_MAX_AND_MIN,
            variants: [],
            value: false,
            filterGroupName: 'carbohydrates',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.mineralsValue'),
            filterName: 'mineral',
            filterType: FILTER_TYPE_MAX_AND_MIN,
            variants: [],
            value: false,
            filterGroupName: 'mineralsValue',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.vitaminsValue'),
            filterName: 'vitamin',
            filterType: FILTER_TYPE_MAX_AND_MIN,
            variants: [],
            value: false,
            filterGroupName: 'vitaminsValue',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.vegetable'),
            filterName: 'vegetable',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'type',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.fruit'),
            filterName: 'fruit',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'type',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.berry'),
            filterName: 'berry',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'type',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.meat'),
            filterName: 'meat',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'type',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.fish'),
            filterName: 'fish',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'type',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.yellow'),
            filterName: 'yellow',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'color',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.red'),
            filterName: 'red',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'color',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.green'),
            filterName: 'green',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'color',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.black'),
            filterName: 'black',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'color',
        },
        {
            filterTitle: i18n.get('app.page.product.filters.white'),
            filterName: 'white',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'color',
        },
        {
            filterName: i18n.get('app.page.product.filters.energy'),
            filterType: FILTER_TYPE_MAX_AND_MIN,
            variants: [],
            value: false,
            filterGroupName: 'energy',
        },
        {
            filterTitle: i18n.get('product.scheme.filter.isDeleted'),
            filterName: 'isDeleted',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: FILTER_GROUP_NAME_STATUS,
        },
        {
            filterTitle: i18n.get('product.scheme.filter.isNotValid'),
            filterName: 'isNotValid',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: FILTER_GROUP_NAME_STATUS,
        },
    ];
};

const prepareListOfForms = () => {
    const formsList = formsStorage.getItems(true);
    const result = [];
    for(let form of formsList) {
        result.push({
            filterTitle: formsService.getLocalization(i18n.APP_LOCALE, form.productForm, true),
            filterName: form.productForm,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'form',
        });
    }
    return result;
};

const findFilterValueIndex = (product, filtersList) => {
    let result = false;
    for(let filter of filtersList) {
        if(filter.filterType === FILTER_TYPE_CHECKBOX && filter.filterGroupName !== FILTER_GROUP_NAME_STATUS) {
            result = Object.values(product).includes(filter.filterName) || product.productForms.find(it => it.productForm === filter.filterName);
        } else {
            result = true;
        }
        if(filter.filterType === FILTER_TYPE_MAX_AND_MIN) {
            const productProperty = Object.values(product.properties).find(it => it.propertyName === filter.filterName);
            if(productProperty) {
                if(filter.min !== undefined && filter.max !== undefined) {
                    result = +productProperty.minValue >= +filter.min && +productProperty.maxValue <= +filter.max;
                } else if(filter.min !== undefined) {
                    result = +productProperty.minValue >= +filter.min;
                } else if(filter.max !== undefined) {
                    result = +productProperty.maxValue <= +filter.max;
                } else {
                    result = false;
                }
            }
        }
    }
    return result;
};

const filterBySearchValue = (filteredList, searchValue) => {
    return filteredList.filter(it => 
        i18n.get(productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true)).toLowerCase().includes(searchValue));
};

const createSearchParams = (isDeleted, isNotValid) => {
    return `?${isDeleted ? 'withDeleted=true&' : ''}${isNotValid ? 'valid=false&' : ''}${isDeleted || isNotValid ? 'withStatus=true&' : ''}withForms=true`;
};

export const productsStorage = {
    productsList: [],
    filtersList: [],
    searchValue: '',
    possibleFilters: [],
    offset: 10,
    maxPages: 1,
    page: 1,
    observersList: [],
    showDeletedProducts: false,
    showIsNotValid: false,
    loadItems(callback, width) {
        try {
            formsStorage.loadItems(() => {
                getFetchRequest(makeUrl([PRODUCT_URL, createSearchParams(this.showDeletedProducts, this.showIsNotValid)]), (response) => {
                    this.productsList = response.sort(sortByDate);
                    this.offset = isSmallWebScreen(width) ? PRODUCT_COUNT_SMALL_WIDTH : PRODUCT_COUNT_FULL_WIDTH;
                    this.reloadPagesNumber();
                    callback && callback();
                    this.notifyFiltersChangingObservers();
                }, errorCallback);
            }, width, '?valid=true');
        } catch(err) {
            console.log(err);
        }
    },

    getItems(loadAllData) {
        let filteredList = [...this.productsList];
        if(this.searchValue) {
            filteredList = filterBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => findFilterValueIndex(it, this.filtersList));
        }
        if(this.showDeletedProducts) {
            filteredList = filteredList.filter(it => it.isDeleted);
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
        return loadAllData ? filteredList : filteredList.slice((this.page - 1) * this.offset, this.page * this.offset);
    },

    getMaxCount() {
        return this.maxPages;
    },

    getCurrentPage() {
        return this.page;
    },

    setPageNumber(value) {
        this.page = value;
        this.notifyFiltersChangingObservers();
    },

    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },

    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value, key) => {
            const currentFilter = this.possibleFilters.find(it => it.filterName === key.split('.')[0]);
            if(currentFilter) {
                if(currentFilter.filterType === FILTER_TYPE_CHECKBOX && value === 'true') {
                    currentFilter.value = value;
                    filtersList.push(currentFilter);
                }
                if(currentFilter.filterType === FILTER_TYPE_MAX_AND_MIN) {
                    currentFilter[key.split('.')[1]] = value;
                    filtersList.push(currentFilter);
                }
                if(currentFilter.filterName === FILTER_NAME_IS_DELETED && value === 'true') {
                    this.changeDeletedItemsView(true);
                }
                if(currentFilter.filterName === FILTER_NAME_IS_NOT_VALID && value === 'true') {
                    this.changeValidItemsView(true);
                }
            }
        });
        this.filtersList = filtersList;
    },
    getPossibleFilters() {
        this.possibleFilters = [...createListOfFilters(), ...prepareListOfForms()];
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    getFilter(filterName) {
        return this.possibleFilters.find(it => it.filterName === filterName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.reloadPagesNumber();
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedKeys) {
        const currentFilter = this.filtersList.find(it => it.filterName === filterName);
        if(currentFilter) {
            currentFilter.value = selectedKeys;
        } else {
            const possibleFilter = this.possibleFilters.find(it => it.filterName === filterName);
            possibleFilter.value = selectedKeys;
            this.filtersList.push(possibleFilter);
        }
        this.filtersList = this.filtersList.filter(it => 
            it.filterType !== FILTER_TYPE_CHECKBOX || (it.filterType === FILTER_TYPE_CHECKBOX && it.value == true));
        this.page = 1;
    },

    setNumberFilterValue(filterName, filterKey, filterValue) {
        const currentFilter = this.filtersList.find(it => it.filterName === filterName);
        if(currentFilter) {
            currentFilter[filterKey] = filterValue;
        } else {
            const possibleFilter = this.possibleFilters.find(it => it.filterName === filterName);
            possibleFilter[filterKey] = filterValue;
            this.filtersList.push(possibleFilter);
        }
        this.page = 1;
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.showDeletedProducts = false;
        this.showIsNotValid = false;
        this.possibleFilters.forEach((it, index) => {
            if(it.filterType === FILTER_TYPE_CHECKBOX) {
                this.possibleFilters[index].value = false;
            }
            if(it.filterType === FILTER_TYPE_MAX_AND_MIN) {
                this.possibleFilters[index].max = '';
                this.possibleFilters[index].min = '';
            }
        });
        this.loadItems();
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },

    changeDeletedItemsView(deletedIsVisible) {
        this.showDeletedProducts = deletedIsVisible;
        this.loadItems();
    },

    changeValidItemsView(isValid) {
        this.showIsNotValid = isValid;
        this.loadItems();
    },

    getById(itemId) {
        return this.productsList.find(it => it.id === itemId);
    },
    reloadPagesNumber() {
        this.page = 1;
        let filteredList = [...this.productsList];
        if(this.searchValue) {
            filteredList = filterBySearchValue(filteredList, this.searchValue);
        }
        if(this.filtersList.length) {
            filteredList = filteredList.filter(it => findFilterValueIndex(it, this.filtersList));
        }
        if(this.showDeletedProducts) {
            filteredList = filteredList.filter(it => it.isDeleted);
        }
        this.maxPages = Math.ceil(filteredList.length / this.offset);
    },
    cleanStorage() {
        this.productsList = [];
        this.filtersList = [];
        this.maxPages = 1;
        this.page = 1;
        this.possibleFilters = [];
        this.showDeletedProducts = false;
        this.showIsNotValid = false;
        this.searchValue = '';
        this.offset = 10;
    },
};
