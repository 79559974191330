import { i18n } from "../i18n";
import { productSaveService } from "./productSaveService";
import { propertiesStorage } from "./propertiesStorage";

const transformSelectValuesList = (selectValues) => {
    let resultArray = [];
    if(selectValues[0] !== null) {
        selectValues.forEach(it => resultArray.push({itemId: it, itemTitle: i18n.get(`product.scheme.property.value.${it}`, i18n.APP_LOCALE), 
            included: true}));
    }
    return resultArray;
};

const createJSONString = (listOfData) => {
    const preparedList = [];
    listOfData.forEach(it => preparedList.push(it.itemId));
    return preparedList.length ? JSON.stringify(preparedList) : [];
};

export class productSelectService {
    observersList = [];
    filter = '';
    possibleItems = [];
    selectedItems = [];
    propertyId = '';

    constructor (propertyId) {
        this.propertyId = propertyId;
    }

    getPossibleItems() {
        const currentProperty = propertiesStorage.getItems(true)
            .find(it => it.propertyName === productSaveService.getProperty(this.propertyId).propertyName);
        if(currentProperty && currentProperty.selectValues) {
            this.possibleItems = transformSelectValuesList(JSON.parse(currentProperty.selectValues))
                .filter(it => it.itemTitle.toLowerCase().toLowerCase().includes(this.filter));
            return this.possibleItems;
        } else {
            return [];
        }
    }

    getCurrentItems() {
        try {
            const currentValue = productSaveService.getProperty(this.propertyId).value;
            if(currentValue) {
                this.selectedItems = transformSelectValuesList(Array.isArray(currentValue) ? currentValue : JSON.parse(currentValue));
            }
            return this.selectedItems;
        } catch (err) {
            return [];
        }
    }

    deleteItem(itemId) { 
        this.selectedItems = this.selectedItems.filter(it => it.itemId !== itemId);
        productSaveService.updateProductProperty(this.propertyId, 'value', createJSONString(this.selectedItems));
        this.notifyItemsChangingObservers();
    }

    addItem(itemId) {
        this.selectedItems.push({itemId, itemTitle: i18n.get(`product.scheme.property.value.${itemId}`, i18n.APP_LOCALE), included: true});
        productSaveService.updateProductProperty(this.propertyId, 'value', createJSONString(this.selectedItems));
        this.notifyItemsChangingObservers();
    }

    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    }

    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    }

    hasExcludedItemsBlock() {
        return false;
    }

    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    }

    getSearchString() {
        return this.filter;
    }

    getPopupKey() {
        return '';
    }

    getButtonTitle() {
        return '';
    }
}

export const createSelectPropertyService = (propertyId) => {
    return new productSelectService(propertyId);
};
