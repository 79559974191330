import React from 'react';
import { i18n } from '../../../i18n';
import { formsService } from '../../../services/formsService';
import ListItemTitle from '../../sharedComponents/ListItemTitle';
import DeleteItemIcon from '../../sharedComponents/DeleteItemIcon';
import { formDeleteService } from '../../../services/formDeleteService';
import { POPUP_KEY_DELETE_FORM, POPUP_KEY_RESTORE_FORM } from '../../../constant';
import RestoreItemIcon from '../../sharedComponents/RestoreItemIcon';
import EditItemIcon from '../../sharedComponents/EditItemIcon';

const FormsListItem = ({ form }) => {
    return (
        <>
            <ListItemTitle itemCode={form.productForm} itemService={formsService}/>
            <h3 className="main__subtitle product__list-code">{form.productForm}</h3>
            <h2 className="block__content product__list-title">{i18n.get('app.page.product.addFormItem.formGroup')}</h2>
            <p className="block__content product__list-content">
                {form.formGroup ?
                    i18n.get(`product.scheme.formGroup.${form.formGroup}`)
                    :
                    <span className="product__list-content_warning">{i18n.get('app.page.product.notAssigned')}</span>
                }
            </p>
            <h2 className="block__content product__list-title">{i18n.get('app.page.product.addFormItem.formType')} </h2>
            <p className="block__content product__list-content">
                {form.formType ?
                    i18n.get(`product.scheme.formType.${form.formType}`)
                    :
                    <span className="product__list-content_warning">{i18n.get('app.page.product.notAssigned')}</span>
                }
            </p>
            <div className="product__list-icons">
                {form.isDeleted ?
                    <RestoreItemIcon itemId={form.id} popupKey={POPUP_KEY_RESTORE_FORM} />
                    :
                    <EditItemIcon pageUrl={`/product/form/${form.id}/`}/>}
                <DeleteItemIcon 
                    itemId={form.id} 
                    itemDeleteService={formDeleteService} 
                    popupKey={POPUP_KEY_DELETE_FORM} 
                />
            </div>
        </>
    );
};

export default FormsListItem;
