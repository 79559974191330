import React, { useState, useEffect } from 'react';
import { SearchIcon } from '../../appearence/icons/search_icon';
import { CloseIcon } from '../../appearence/icons/close_icon';

const SearchInput = ({ placeholder, itemsStorageService }) => {
    const [searchValue, setSearchValue] = useState('');

    const changeSearchInput = (e) => {
        setSearchValue(e.target.value);
        itemsStorageService.setSearchString(e.target.value);
    };

    const changeInputValue = () => {
        setSearchValue(itemsStorageService.getSearchString());
    };

    useEffect(() => {
        itemsStorageService.registerFiltersChangingObserver(changeInputValue);
        return () => itemsStorageService.unRegisterFiltersChangingObserver(changeInputValue);
    }, []);

    return (
        <label className="block__content product__label product__search-label">
            <SearchIcon nameOfClass="product__input-icon" />
            <input 
                className="product__input product__search-input" 
                placeholder={placeholder} 
                value={searchValue} 
                onChange={changeSearchInput} 
            />
            {searchValue ? 
                <span onClick={() => changeSearchInput({target: {value: ''}})}>
                    <CloseIcon nameOfClass="product__input-icon_search" />
                </span>
                :
                null}
        </label>
    );
};

export default SearchInput;
