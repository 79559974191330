import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import { CloseIcon } from '../../appearence/icons/close_icon';
import { i18n } from '../../i18n/index';
import { MOUSE_DOWN_EVENT } from '../../constant';

const SelectWithoutTokens = ({listOfOptions, handleChangeValue, titleValue, withCleanIcon}) => {
    const wrapperRef = useRef(null);
    const [optionListIsOpen, setOptionListIsOpen] = useState(false);
    const [activeOption, setActiveOption] = useState(titleValue);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOptionListIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        };
    }, []);

    const changeActiveValue = (e, it) => {
        setOptionListIsOpen(false);
        e.stopPropagation();
        handleChangeValue(it);
        setActiveOption(it.name);
    };

    const openList = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOptionListIsOpen(true);
    };

    const handleChangeListShown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(prev => !prev);
        if(!optionListIsOpen) {
            openList(e);
        }
    };

    const clearItemValue = () => {
        setActiveOption('');
        handleChangeValue({value: ''});
    };
    
    return (
        <div className={`block__content select__choice select__choice_full-width ${optionListIsOpen ? 'select__choice_active' : ''}`}
            onClick={openList} ref={wrapperRef}>
            <p className={activeOption ? '' : 'select__choice-placeholder'}>
                {activeOption ? activeOption : i18n.get("app.page.preference.selectFromList")}
            </p>
            {optionListIsOpen && listOfOptions.length ? 
                <ul className="scroll select__choice-dropdown select__choice-dropdown_full-width">
                    {listOfOptions.map((it, index) => (
                        <li key={it.id + index} className="block__content select__choice-option" onClick={(e) => changeActiveValue(e, it)}>
                            {it.name}
                        </li>
                    ))}
                </ul>
                :
                null}
            {withCleanIcon && activeOption ? <span onClick={clearItemValue} ><CloseIcon nameOfClass="select__choice-cleaning" /></span> : ''}
            <ChevronIcon nameOfClass={`select__choice-icon select__choice-icon_full-width ${optionListIsOpen ? 'select__choice-icon_active' : ''}`} 
                handleClick={handleChangeListShown}/>
        </div>
    );
};

export default SelectWithoutTokens;
