import React from 'react';
import { i18n } from '../../i18n/index';

const SelectedItem = ({ selectedValue }) => (
    <p className={selectedValue ? '' : 'select__choice-placeholder'}>
        {selectedValue ? selectedValue : i18n.get("app.page.preference.selectFromList")}
    </p>
);

export default SelectedItem;
