import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { recipeIngredientService } from '../../services/recipeIngredientsService';
import IngredientHandler from '../recipes/IngredientHandler';

const FiltersListItemSelect = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        recipeIngredientService.createSearchParams(searchParams, setSearchParams);
        recipeIngredientService.loadItems(searchParams);
        return () => {
            recipeIngredientService.deleteItem('', true);
        };
    }, []);
    return (
        <IngredientHandler selectWithTokensManager={recipeIngredientService} />
    );
};

export default FiltersListItemSelect;
