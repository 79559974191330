import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formsStorage } from '../../../services/formsStorage';
import FormsListItem from './FormsListItem';
import { i18n } from '../../../i18n';
import { ErrorHandler } from '../../ErrorHandler';
import useWindowDimensions from '../../../hooks/useWindowDimension';

const FormsList = () => {
    const [formsListIsChanged, setFormsListIsChanged] = useState([]);
    const { width } = useWindowDimensions();
    const [searchParams] = useSearchParams();

    const changeFormsList = () => {
        setFormsListIsChanged(prev => !prev);
    };

    const initializeFormsStorage = () => {
        formsStorage.loadItems(changeFormsList, width);
    };

    useEffect(() => {
        initializeFormsStorage();
        formsStorage.getInitialFilters(searchParams);
        formsStorage.registerFiltersChangingObserver(changeFormsList);
        return () => {
            formsStorage.unRegisterFiltersChangingObserver(changeFormsList);
            formsStorage.changeDeletedItemsView(false);
        };
    }, []);
    return (
        <article className="product__article-item">
            {formsStorage.getItems().length ?
                <ul className="product__list product__list_form">
                    {formsStorage.getItems().map(it => (
                        <li key={it.id + formsListIsChanged} 
                            className={`product__list-item ${it.isDeleted ? 'product__list-item_deleted' : ''}`}>
                            <FormsListItem form={it} />
                        </li>
                    ))}
                </ul>
                :
                <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>}
        </article>
    );
};

export default FormsList;
