import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import { I18N_CODE_VALIDATION_ERROR } from '../../constant';

const RestrictedCodeInput = ({ itemCreateService }) => {
    const [formCode, setFormCode] = useState('');
    const [error, setError] = useState('');

    const fillTheCode = (e) => {
        setError(false);
        setFormCode(e.target.value);
        itemCreateService.changeItemCode(e.target.value);
        setError(itemCreateService.checkCodeValidation());
    };

    const changeInput = () => {
        setError(itemCreateService.checkCodeValidation());
    };

    useEffect(() => {
        itemCreateService.registerObserver(changeInput);
        return () => itemCreateService.unRegisterObserver(changeInput);
    }, []);

    return (
        <>
            <input 
                className={`product__form-input product__add-input product__modal-input ${error ? 'product__modal-input_error' : ''}`} 
                value={formCode} 
                onChange={fillTheCode}/>
            <p className={`block__bullet ${error ? 'alert' : ''} alert__product`}>{error ? error : i18n.get(I18N_CODE_VALIDATION_ERROR)}</p>
        </>
    );
};

export default RestrictedCodeInput;
