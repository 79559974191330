import React from "react";

export const LogoIcon = () => {
    return (
        <svg className="menu__logo navigation-link" viewBox="0 0 268 62">
            <g clipPath="url(#clip0_456_6556)">
                <path d="M80.6457 42.6694L81.2121 42.8857C81.6976 43.071 81.8594 43.7043 81.5284 44.1213C81.2195 44.5075 80.8884 44.8782 80.5353 45.2412C77.8209 48.0369 74.084 49.566 70.2808 49.4888C66.4998 49.4116 63.2557 47.9596 60.556 45.1253C57.8857 42.3219 56.3777 38.4836 56.3924 34.514C56.4071 30.5443 57.79 26.93 60.556 24.0262C63.3955 21.0451 66.8381 19.5855 70.8767 19.655C74.6798 19.7245 78.3211 21.4467 80.9399 24.3351C81.1385 24.5513 81.3298 24.7753 81.5137 24.9993C81.8521 25.4086 81.6976 26.0496 81.2121 26.2349L80.6604 26.4512C80.1013 26.6674 79.476 26.5052 79.0714 26.0419C79.0126 25.9723 78.9538 25.9028 78.8875 25.8333C76.5924 23.4469 73.8265 22.2499 70.5824 22.2499C67.3384 22.2499 64.6019 23.4469 62.3067 25.8488C60.1072 28.1425 58.8714 31.2703 58.864 34.5294C58.864 37.943 60.0043 40.8545 62.3067 43.2718C64.6019 45.6814 67.3604 46.8861 70.5824 46.8861C73.7235 46.8861 76.7395 45.5578 78.9464 43.21C78.9979 43.156 79.0567 43.0942 79.1082 43.0401C79.5055 42.6076 80.1087 42.4686 80.6457 42.6694Z" fill="#264948" />
                <path d="M88.3623 5.29024H90.6869V19.4851C90.6869 20.1029 90.4515 20.6898 90.0322 21.13C89.7305 21.4467 89.3701 21.8715 89.0317 22.3734C88.8184 22.6824 88.3623 22.5434 88.3623 22.1572V5.29024Z" fill="#264948" />
                <path d="M105.098 23.4238C103.516 21.7633 101.111 20.4813 98.2196 20.4813C95.3286 20.4813 92.9158 21.7633 91.3416 23.4238C89.4657 25.3931 88.4947 27.8877 88.4432 30.8919V48.7319H90.7678V31.1081C90.7678 28.8453 91.5475 26.9146 93.107 25.3082C94.5562 23.8176 96.3217 22.9295 98.2196 22.9295C100.117 22.9295 101.876 23.8176 103.332 25.3082C104.892 26.9068 105.671 28.8453 105.671 31.1081V48.7319H107.996V30.8919C107.944 27.8877 106.981 25.4009 105.098 23.4238Z" fill="#264948" />
                <path d="M143.166 48.7319H140.841V43.3027C140.392 43.936 139.9 44.5461 139.37 45.1253C136.597 48.0369 133.242 49.4965 129.307 49.4965C125.371 49.4965 122.046 48.0369 119.273 45.1253C116.5 42.2138 115.109 38.6998 115.109 34.5912C115.109 30.4826 116.5 26.9455 119.273 24.0262C122.046 21.1146 125.393 19.655 129.307 19.655C138.723 19.655 143.166 27.849 143.166 32.2589V48.7319ZM140.841 37.0008V32.1662C140.407 29.7798 139.333 27.6791 137.612 25.8642C135.317 23.4547 132.551 22.2499 129.307 22.2499C126.063 22.2499 123.297 23.4547 121.002 25.8642C118.706 28.2738 117.559 31.1776 117.559 34.5835C117.559 37.9893 118.706 40.8854 121.002 43.2873C123.297 45.6891 126.063 46.8862 129.307 46.8862C132.551 46.8862 135.317 45.6814 137.612 43.2718C139.333 41.4646 140.415 39.3717 140.841 37.0008Z" fill="#264948" />
                <path d="M151.441 32.1121C151.441 28.9148 153.766 25.8179 153.766 25.8179C154.2 25.2001 154.686 24.6054 155.237 24.0262C158.011 21.13 161.358 19.6859 165.271 19.6859C169.185 19.6859 172.554 21.1378 175.32 24.0416C178.086 26.9454 179.468 30.4594 179.468 34.5912C179.468 38.723 178.086 42.2138 175.32 45.1253C172.554 48.0369 169.207 49.4965 165.271 49.4965C161.336 49.4965 158.011 48.0369 155.237 45.1253C154.686 44.5461 154.193 43.9514 153.766 43.3336V58.9185H151.441V32.1121ZM153.766 32.0349V37.1398C154.215 39.4721 155.281 41.5264 156.973 43.3027C159.268 45.7123 162.034 46.917 165.278 46.917C168.522 46.917 171.288 45.7123 173.584 43.3027C175.879 40.8931 177.026 37.9893 177.026 34.5835C177.026 31.1776 175.879 28.2738 173.584 25.8642C171.288 23.4547 168.522 22.2499 165.278 22.2499C162.034 22.2499 159.268 23.4547 156.973 25.8642C155.281 27.6405 154.215 29.6948 153.766 32.0272V32.0349Z" fill="#264948" />
                <path d="M208.643 42.6694L209.209 42.8857C209.695 43.071 209.857 43.7043 209.526 44.1213C209.217 44.5075 208.886 44.8782 208.533 45.2412C205.818 48.0369 202.081 49.566 198.278 49.4888C194.497 49.4116 191.253 47.9596 188.553 45.1253C185.883 42.3219 184.375 38.4836 184.39 34.514C184.404 30.5443 185.787 26.93 188.553 24.0262C191.393 21.0451 194.836 19.5855 198.874 19.655C202.677 19.7245 206.318 21.4467 208.937 24.3351C209.136 24.5513 209.327 24.7753 209.511 24.9993C209.849 25.4086 209.695 26.0496 209.209 26.2349L208.658 26.4512C208.099 26.6674 207.473 26.5052 207.069 26.0419C207.01 25.9723 206.951 25.9028 206.885 25.8333C204.59 23.4469 201.824 22.2499 198.58 22.2499C195.336 22.2499 192.599 23.4469 190.304 25.8488C188.105 28.1425 186.869 31.2703 186.861 34.5294C186.861 37.943 188.002 40.8545 190.304 43.2718C192.599 45.6814 195.358 46.8861 198.58 46.8861C201.721 46.8861 204.737 45.5578 206.944 43.21C206.995 43.156 207.054 43.0942 207.106 43.0401C207.503 42.6076 208.106 42.4686 208.643 42.6694Z" fill="#264948" />
                <path d="M216.36 5.29024H218.684V19.4851C218.684 20.1029 218.449 20.6898 218.03 21.13C217.728 21.4467 217.367 21.8715 217.029 22.3734C216.816 22.6824 216.36 22.5434 216.36 22.1572V5.29024Z" fill="#264948" />
                <path d="M233.095 23.4238C231.513 21.7633 229.108 20.4813 226.217 20.4813C223.326 20.4813 220.913 21.7633 219.339 23.4238C217.463 25.3931 216.492 27.8877 216.441 30.8919V48.7319H218.765V31.1081C218.765 28.8453 219.545 26.9146 221.104 25.3082C222.554 23.8176 224.319 22.9295 226.217 22.9295C228.115 22.9295 229.873 23.8176 231.329 25.3082C232.889 26.9068 233.669 28.8453 233.669 31.1081V48.7319H235.993V30.8919C235.942 27.8877 234.978 25.4009 233.095 23.4238Z" fill="#264948" />
                <path d="M248.602 43.7352C250.794 45.8358 253.391 46.8862 256.407 46.8862C259.459 46.8862 262.372 45.6582 264.565 43.4263C264.91 43.071 265.234 42.708 265.536 42.3219L267.993 43.2486C267.514 43.9437 266.97 44.6079 266.352 45.2412C263.638 48.0369 259.901 49.566 256.098 49.4888C252.317 49.4116 249.08 47.9597 246.373 45.1253C243.695 42.3219 242.195 38.4836 242.209 34.514C242.224 30.5444 243.607 26.93 246.373 24.0262C249.146 21.1146 252.493 19.655 256.407 19.655C259.768 19.655 262.711 20.713 265.227 22.8291L265.241 22.8446C266.198 23.6864 266.234 25.2232 265.359 26.1423L265.153 26.3585L248.602 43.7352ZM261.401 26.8451C262.542 25.648 262.115 23.6246 260.607 23.0299C259.312 22.5125 257.915 22.2576 256.414 22.2576C253.207 22.2576 250.463 23.4469 248.175 25.8179C245.954 28.1271 244.696 31.278 244.696 34.5603C244.696 37.3637 245.475 39.8274 247.02 41.9435L261.401 26.8451Z" fill="#264948" />
                <path d="M46.8588 18.4193C46.8588 16.2028 46.8588 17.0987 46.8588 14.1562C46.8588 8.12456 43.9384 3.48306 39.51 1.8149C36.391 0.641006 33.0366 0 29.5351 0C25.2538 0 21.1858 0.957648 17.5151 2.67987C13.4912 4.56428 14.4402 10.511 24.1503 17.0137C25.8717 18.1644 27.7769 19.5391 30.1603 21.0837C37.9652 26.1577 46.8662 27.2853 46.8662 18.4193H46.8588Z" fill="#FFBCB8" />
                <path d="M1.83165 20.2342C0.573745 23.8485 -0.0147485 27.0149 -3.61676e-05 31.0927C0.051457 48.2454 13.1896 62 29.5276 62C34.6034 62 39.3775 60.6562 43.5485 58.2853C45.5935 57.1268 46.8588 54.8717 46.8588 52.4312C46.8588 47.0715 38.995 35.7342 26.0187 25.7715C23.9811 24.2038 20.7738 21.6784 19.994 21.1223C13.4103 16.4422 3.93551 14.2103 1.83901 20.2265L1.83165 20.2342Z" fill="#264948" />
            </g>
            <defs>
                <clipPath id="clip0_456_6556">
                    <rect width="268" height="62" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
