import React, { useState, useEffect } from 'react';
import { popupOpenService } from '../../services/popupOpenService';
import { POPUP_KEY_DELETE_RECIPE, errorCallback, makeUrl } from '../../constant';
import { RECIPES_URL, deleteFetchRequest } from '../../store/requests';
import { MODAL_SMALL_SIZE } from '../helper';
import Modal from '../Modal';
import { i18n } from '../../i18n';

const DeleteModalContent = ({handleDelete, handleClose}) => (
    <>
        <h2 className="modal__title modal__title_align block__title-h3">{i18n.get('app.page.recipe.modal.title')}</h2>
        <p className="block__content modal__text modal__text_align">{i18n.get('app.page.recipe.modal.content')}</p>
        <div className="block__button modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>
                <span className="button__content">
                    {i18n.get('app.page.recipe.modal.cancel')}
                </span>
            </button>
            <button className="block__button button" onClick={handleDelete}>
                <span className="button__content">
                    {i18n.get('app.page.recipe.modal.delete')}
                </span>
            </button>
        </div>
    </>
);

const DeleteRecipePopup = ({ notifyRecipesList }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentRecipeId, setCurrentRecipeId] = useState('');

    const handleOpenModal = (recipeId) => {
        setIsOpen(true);
        setCurrentRecipeId(recipeId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_RECIPE, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_RECIPE, handleOpenModal);
    },[]);

    const deleteRecipe = () => {        
        setIsOpen(null);
        setCurrentRecipeId('');
        deleteFetchRequest(makeUrl([RECIPES_URL, currentRecipeId, '/']), () => notifyRecipesList(), errorCallback);
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleDelete={deleteRecipe} handleClose={setIsOpen} />} 
                handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE} />
            :
            null
    );
};

export default DeleteRecipePopup;
