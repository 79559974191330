import { i18n } from "../i18n";

export const productsService = {
    getLocalization(localeCode, code, withCodeReplace) {
        if(withCodeReplace) {
            return i18n.get(`product.item.code.${code}`, localeCode);
        } else {
            return i18n.is_exists(`product.item.code.${code}`, localeCode) ? i18n.get(`product.item.code.${code}`, localeCode) : '';
        }
    },
    getPlaceholder() {
        return i18n.get("app.page.product.addProductItem.productName");
    },
};
