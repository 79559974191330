import { productSaveService } from "./productSaveService";

const transformDataForIdsList = (listOfData) => {
    const result = [];
    listOfData.forEach(it => result.push(it.id));
    return result;
};

const checkFormIsExistsInProperty = (property, form) => {
    return productSaveService.getProperty(property).productForms.find(productForm => productForm.id === form);
};

export const updateSeveralPropertiesService = {
    propertyId: '',
    formsSelectedList: [],
    propertiesSelectedList: [],
    updateDataObservers: [],
    addFormInFormsList(formId) {
        if(formId) {
            this.formsSelectedList.push(formId);
        } else {
            this.formsSelectedList = transformDataForIdsList(this.getItems());
        }
        this.notifyObserversAboutDataChanges();
    },
    deleteFormFromFormsList(formId) {
        if(formId) {
            this.formsSelectedList = this.formsSelectedList.filter(it => it !== formId);
        } else {
            this.formsSelectedList = [];
        }
        this.notifyObserversAboutDataChanges();
    },
    addPropertyInPropertiesList(propertyId) {
        if(propertyId) {
            this.propertiesSelectedList.push(propertyId);
        } else {
            this.propertiesSelectedList = transformDataForIdsList(this.getProperties());
        }
        this.notifyObserversAboutDataChanges();
    },
    deletePropertyFromPropertiesList(propertyId) {
        if(propertyId) {
            this.propertiesSelectedList = this.propertiesSelectedList.filter(it => it !== propertyId);
        } else {
            this.propertiesSelectedList = [];
        }
        this.notifyObserversAboutDataChanges();
    },
    saveChanges() {
        for(let property of this.propertiesSelectedList) {
            for(let form of this.formsSelectedList) {
                checkFormIsExistsInProperty(property, form) ?
                    null
                    :
                    productSaveService.addProductPropertyForm(property, form, true);
            }
        }
    },
    registerObserverDataListsChanged(processListsChangedEvent) {
        this.updateDataObservers.push(processListsChangedEvent);
    },
    unRegisterObserverDataListsChanged(processListsChangedEvent) {
        this.updateDataObservers = this.updateDataObservers.filter(updateDataFunction => updateDataFunction !== processListsChangedEvent);
    },
    notifyObserversAboutDataChanges() {
        this.updateDataObservers.forEach(updateDataFunction => updateDataFunction());
    },
    areDataListsValid() {
        return this.propertiesSelectedList && this.propertiesSelectedList.length && this.formsSelectedList && this.formsSelectedList.length;
    },
    getItems() {
        const productItem = productSaveService.getProduct();
        if(this.propertyId) {
            return productItem.properties[this.propertyId].productForms;
        } else {
            return productItem.productForms;
        }
    },
    getSelectedForms() {
        return this.formsSelectedList;
    },
    getProperties() {
        return Object.values(productSaveService.getProduct().properties);
    },
    getSelectedProperties() {
        return this.propertiesSelectedList;
    },
};
