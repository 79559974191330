import React, { useEffect } from 'react';
import { Pagination } from '../../Pagination';
import DeleteProductPopup from './DeleteProductPopup';
import CreateNewProductButton from './CreateNewProductButton';
import SearchItemBlock from '../../sharedComponents/SearchItemBlock';
import CreateNewProductPopup from './CreateNewProductPopup';
import { productsStorage } from '../../../services/productsStorage';
import ProductList from './ProductList';
import { i18n } from '../../../i18n';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import { productRestoreService } from '../../../services/productRestoreService';
import RestoreItemPopup from '../../sharedComponents/RestoreItemPopup';

const ProductListTab = () => {
    const { width } = useWindowDimensions();
    const notifyProductsList = () => {
        productsStorage.loadItems(() => {}, width);
    };

    useEffect(() => {
        return () => {
            productsStorage.setPageNumber(1);
        };
    }, []);

    return (
        <>
            <SearchItemBlock itemsStorageService={productsStorage} placeholder={i18n.get('app.page.product.productSearch')} />
            <CreateNewProductButton />
            <ProductList />
            <Pagination itemsStorageService={productsStorage} />
            <DeleteProductPopup notifyProductsList={notifyProductsList}/>
            <CreateNewProductPopup />
            <RestoreItemPopup itemRestoreService={productRestoreService} notifyItemsList={notifyProductsList}/>
        </>
    );
};

export default ProductListTab;
