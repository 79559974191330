import React, { useState, useEffect } from 'react';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_DELETE_INGREDIENT } from '../../constant';
import { MODAL_SMALL_SIZE } from '../helper';
import Modal from '../Modal';
import { i18n } from '../../i18n';
import { recipeSaveService } from '../../services/recipeSaveService';

const DeleteIngredientModalContent = ({handleDelete, handleClose, ingredientCode}) => (
    <>
        <h2 className="modal__title modal__title_align block__title-h3">{i18n.get('app.page.recipe.modal.deleteModal.title')}</h2>
        <p className="block__content modal__text modal__text_align">
            {i18n.get('app.page.recipe.modal.deleteModal.content')}
            {' ' + ingredientCode}?
        </p>
        <div className="block__button modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(false)}>
                <span className="button__content">{i18n.get('app.page.recipe.modal.cancel')}</span>
            </button>
            <button className="block__button button" onClick={handleDelete}>
                <span className="button__content">{i18n.get('app.page.recipe.modal.delete')}</span>
            </button>
        </div>
    </>
);

const getIngredientCode = (ingredientId) => {
    const result = [];
    recipeSaveService.getRecipe().ingredient.find(it => it.id === ingredientId)
        .ingredientParts.forEach(ingredientPart => result.push(i18n.get(`product.item.code.${ingredientPart.productCode}`)));
    return result.join(', ');
};

const DeleteIngredientPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentIngredientId, setCurrentIngredientId] = useState('');

    const handleOpenModal = (ingredientId) => {
        setIsOpen(true);
        setCurrentIngredientId(ingredientId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_INGREDIENT, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_INGREDIENT, handleOpenModal);
    },[]);

    const deleteIngredient = () => {        
        setIsOpen(null);
        setCurrentIngredientId('');
        recipeSaveService.deleteIngredient(currentIngredientId);
    };

    return (
        isOpen ?
            <Modal content={<DeleteIngredientModalContent handleDelete={deleteIngredient} handleClose={setIsOpen} ingredientCode={getIngredientCode(currentIngredientId)} />} 
                handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE} />
            :
            null
    );
};

export default DeleteIngredientPopup;
