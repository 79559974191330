import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { propertiesStorage } from '../../../services/propertiesStorage';
import { i18n } from '../../../i18n';
import PropertyListItem from './PropertyListItem';
import { ErrorHandler } from '../../ErrorHandler';
import useWindowDimensions from '../../../hooks/useWindowDimension';

const PropertiesList = () => {
    const [propertiesListIsChanged, setPropertiesListIsChanged] = useState([]);
    const { width } = useWindowDimensions();
    const [searchParams] = useSearchParams();

    const changeFormsList = () => {
        setPropertiesListIsChanged(prev => !prev);
    };

    const initializeFormsStorage = () => {
        propertiesStorage.loadItems(changeFormsList, width);
    };

    useEffect(() => {
        initializeFormsStorage();
        propertiesStorage.getInitialFilters(searchParams);
        propertiesStorage.registerFiltersChangingObserver(changeFormsList);
        return () => {
            propertiesStorage.unRegisterFiltersChangingObserver(changeFormsList);
            propertiesStorage.changeDeletedItemsView(false);
        };
    }, []);
    
    return (
        <article className="product__article-item">
            {propertiesStorage.getItems().length ?
                <ul className="product__list product__list_form">
                    {propertiesStorage.getItems().map(it => (
                        <li key={it.id + propertiesListIsChanged} 
                            className={`product__list-item ${it.isDeleted ? 'product__list-item_deleted' : ''}`}>
                            <PropertyListItem property={it} />
                        </li>
                    ))}
                </ul>
                :
                <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>}
        </article>
    );
};

export default PropertiesList;
