import React, { useState, useEffect } from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';
import { PREFERENCE_TYPE_CEREAL_MIX, PREFERENCE_TYPE_PROPERTY, PREFERENCE_TYPE_RECIPE } from '../../constant';
import IngredientHandler from '../recipes/IngredientHandler';
import { preferenceUpdateCerealMixService } from '../../services/preferenceUpdateCerealMixService';
import { productsService } from '../../services/productsService';
import { productsStorage } from '../../services/productsStorage';
import PreferenceStructureValue from './PreferenceStructureValue';
import { propertiesService } from '../../services/propertiesService';

const findPreferenceTitle = (itemSaveService) => {
    if(itemSaveService.getPreference().preferenceItemCodes && itemSaveService.getPreference().preferenceItemCodes.length) {
        if(itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_RECIPE) {
            return i18n.get(`recipe.item.type.${itemSaveService.getPreference().preferenceItemCodes[0].itemCode}`);
        } else if(itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_PROPERTY) {
            return propertiesService.getLocalization(i18n.APP_LOCALE, itemSaveService.getProperty().itemCode, true);
        } else {
            return productsService.getLocalization(i18n.APP_LOCALE, itemSaveService.getProperty().itemCode, true);
        }
    } else {
        return '';
    }
};

const prepareListOfItems = (listOfOptions) => {
    const result = [];
    listOfOptions.forEach(it => result.push(
        it.productCode ? {id: it.id, name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true), code: it.productCode} : it,
    ));
    return result;
};

const getValueOfItemCode = (itemId) => {
    const product = productsStorage.getById(itemId);
    if(product) {
        return product.productCode;
    } else {
        return itemId;
    }
};

const checkNeedToAddValueField = (itemSaveService) => itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_PROPERTY && 
    itemSaveService.getPreference().preferenceItemCodes &&
    itemSaveService.getPreference().preferenceItemCodes[0];

const PreferenceStructureItemCode = ({ itemSaveService }) => {
    const [preferenceTypeIsChanged, setPreferenceTypeIsChanged] = useState(null);

    const updatePreferenceValue = (value) => {
        itemSaveService.updatePreferenceProperty('preferenceItemCodes', [{'itemCode': getValueOfItemCode(value)}]);
    };

    const changePreferenceType = () => {
        setPreferenceTypeIsChanged(prev => !prev);
    };

    useEffect(() => {
        itemSaveService.registerItemUpdateObserver(changePreferenceType);
        return () => itemSaveService.unRegisterItemUpdateObserver(changePreferenceType);
    }, []);

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.preferenceItemCode')}</span>
            {itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX && preferenceTypeIsChanged !== null ?
                <IngredientHandler selectWithTokensManager={preferenceUpdateCerealMixService} />
                :
                <SelectHandler 
                    selectedValue={findPreferenceTitle(itemSaveService)} 
                    optionsList={prepareListOfItems(itemSaveService.getPossibleCodeValues())} 
                    sendSelectedValue={updatePreferenceValue}
                /> }
            {checkNeedToAddValueField(itemSaveService) && preferenceTypeIsChanged !== null ?
                <PreferenceStructureValue />
                :
                null}
        </label> 
    );
};

export default PreferenceStructureItemCode;
