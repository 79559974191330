import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../i18n';
import PreferencePhraseSwitcher from './PreferencePhraseSwitcher';
import PhrasesActions from './PhrasesActions';
import PhrasesList from './PhrasesList';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';
import SearchItemBlock from '../sharedComponents/SearchItemBlock';
import DownloadCSVFileButton from './DownloadCSVFileButton';

const PreferencePhraseTab = () => {
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            preferencesPhrasesStorage.cleanStorage();
        };
    }, []);

    return (
        <>
            <DownloadCSVFileButton />
            <SearchItemBlock itemsStorageService={preferencesPhrasesStorage} placeholder={i18n.get('app.page.preference.preferenceSearch')} />
            <button className='block__button button button_outline preference__button'
                onClick={() => navigate('/preference/item/new')}>
                <span className="button__content">{i18n.get('app.page.preference.addPreference')}</span>
            </button>
            <article className="preference__article preference__article_full-width preference__phrases-article">
                <div className='preference__phrases-wrapper'>
                    <PreferencePhraseSwitcher pageUrl={'/preference/item/list'} itemTitle={i18n.get('app.page.preference.phrase')} />
                    <PhrasesActions />
                </div>
                <PhrasesList />
            </article>
        </>
    );
};

export default PreferencePhraseTab;
