import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { popupOpenService } from '../../../services/popupOpenService';
import Modal from '../../Modal';
import { FORMS_URL, deleteFetchRequest } from '../../../store/requests';
import { POPUP_KEY_DELETE_FORM, errorCallback, makeUrl } from '../../../constant';
import { MODAL_SMALL_SIZE } from '../../helper';
import DeleteModalContent from '../DeleteModalContent';
import { formsStorage } from '../../../services/formsStorage';
import { formsService } from '../../../services/formsService';
import { i18n } from '../../../i18n';

const checkIsDeleted = (formId) => formsStorage.getById(formId).isDeleted;

const DeleteFormPopup = ({notifyFormsList}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentFormId, setCurrentFormId] = useState('');
    const { tab } = useParams();

    const handleOpenModal = (formId) => {
        setIsOpen(true);
        setCurrentFormId(formId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_FORM, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_FORM, handleOpenModal);
    },[]);

    const deleteForm = () => {        
        setIsOpen(null);
        setCurrentFormId('');
        if(formsStorage.getById(currentFormId).isDeleted) {
            deleteFetchRequest(makeUrl([FORMS_URL, currentFormId, '/?forceDelete=true']), () => notifyFormsList(), errorCallback);
        } else {
            deleteFetchRequest(makeUrl([FORMS_URL, currentFormId, '/']), () => notifyFormsList(), errorCallback);
        }
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deleteForm} tab={tab}
                isForceDelete={checkIsDeleted(currentFormId)}
                itemTitle={checkIsDeleted(currentFormId) ? formsService.getLocalization(i18n.APP_LOCALE, formsStorage.getById(currentFormId).productForm) : ''} />}  
            handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeleteFormPopup;
