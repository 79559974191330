import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PenIcon } from './PenIcon';

const EditItemIcon = ({ pageUrl }) => {
    const navigate = useNavigate();

    const navigateOnEditPage = () => {
        navigate(pageUrl);
    };

    return (
        <span onClick={navigateOnEditPage}>
            <PenIcon nameOfClass="product__list-icon" />
        </span>
        
    );
};

export default EditItemIcon;
