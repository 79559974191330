import { i18n } from "../i18n";
import { productSaveService } from "./productSaveService";
import { propertyValuesSourcesStorage } from "./propertyValuesSourcesStorage";

const transformSourcesList = (sourcesList) => {
    let resultArray = [];
    sourcesList.forEach(it => resultArray.push({itemId: it.sourceId, itemTitle: it.sourceName, included: true}));
    return resultArray;
};

export class sourceSelectService {
    observersList = [];
    filter = '';
    possibleItems = transformSourcesList(propertyValuesSourcesStorage.getItems());
    selectedItems = [];
    propertyId = '';

    constructor (propertyId) {
        this.propertyId = propertyId;
    }

    getPossibleItems() {
        this.possibleItems = transformSourcesList(propertyValuesSourcesStorage.getItems());
        return this.possibleItems.filter(it => it.itemTitle.toLowerCase().toLowerCase().includes(this.filter));
    }

    loadItems() {
        this.selectedItems = transformSourcesList(productSaveService.getProperty(this.propertyId).sources);
    }

    getCurrentItems() {
        return this.selectedItems;
    }

    deleteItem(itemId) { 
        this.selectedItems = this.selectedItems.filter(it => it.itemId !== itemId);
        this.notifyItemsChangingObservers();
        productSaveService.deleteProductPropertySource(this.propertyId, itemId);
    }

    addItem(itemId) {
        this.selectedItems.push({itemId, itemTitle: propertyValuesSourcesStorage.getById(itemId).sourceName, included: true});
        this.notifyItemsChangingObservers();
        productSaveService.addProductPropertySource(this.propertyId, itemId, propertyValuesSourcesStorage.getById(itemId).sourceName);
    }

    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    }

    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    }

    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    }

    hasExcludedItemsBlock() {
        return false;
    }

    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    }

    getSearchString() {
        return this.filter;
    }

    getPopupKey() {
        return '';
    }

    getButtonTitle() {
        return i18n.get("app.page.product.addOneSource");
    }

    getPropertyId() {
        return this.propertyId;
    }
}

export const createSourceSelectService = (propertyId) => {
    const newSourceSelectService = new sourceSelectService(propertyId);
    newSourceSelectService.loadItems();
    return newSourceSelectService;
};
