import React from 'react';
import { createUserPropertyType, propertySaveService } from '../../../services/propertySaveService';
import RadioLabel from '../../RadioLabel';

const PropertyItemUserDisplayGroup = ({ userDisplayValue }) => {
    const changeUserTypeValue = () => {
        propertySaveService.updatePropertyValue('userDisplayGroup', userDisplayValue);
    };

    return (
        <RadioLabel 
            value={propertySaveService.getProperty().userDisplayGroup === userDisplayValue} 
            type={userDisplayValue}
            title={createUserPropertyType().find(it => it.id === userDisplayValue).name} 
            changeValue={changeUserTypeValue}
        />
    );
};

export default PropertyItemUserDisplayGroup;
