import React from 'react';
import { preferencesGroupsService } from '../../services/preferencesGroupsService';
import { i18n } from '../../i18n';

const formatDateValue = (date) => {
    if(date) {
        return date.split('T')[0].split('-').slice(1).reverse().join('.');
    } 
    return '';
};

const PreferenceGroupsListItemContent = ({ group }) => {
    return (
        <div className='preference__groups'>
            <p className='preference__groups-name'>{preferencesGroupsService.getLocalization(i18n.APP_LOCALE, group.name, false)}</p>
            <ul className='preference__groups-list'>
                {group.activityPeriods && group.activityPeriods.length ?
                    group.activityPeriods.map((it, index) => (
                        <li className='preference__groups-period' key={it.dateStart + it.dateStop + index}>
                            {formatDateValue(it.dateStart)} - {formatDateValue(it.dateStop)}
                        </li> 
                    ))
                   
                    :
                    <li className='preference__groups-period'>
                        {i18n.get('app.page.preference.groups.always')}
                    </li>}
            </ul>
        </div>
    );
};

export default PreferenceGroupsListItemContent;
