import React from 'react';
import { GroupIcon } from '../../appearence/icons/group_icon';
import { i18n } from '../../i18n';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_ASSIGN_GROUP } from '../../constant';

const PreferencesListSelectAssignGroup = () => {
    return (
        <button className="button preference__button_outline" onClick={() => popupOpenService.callToOpen(POPUP_KEY_ASSIGN_GROUP)}>
            <GroupIcon nameOfClass="preference__button-icon"/>
            <span className="block__content preference__button-content">{i18n.get('app.page.preference.assignGroup')}</span>
        </button>
    );
};

export default PreferencesListSelectAssignGroup;
