import React from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { preferencesGroupsService } from '../../services/preferencesGroupsService';
import { i18n } from '../../i18n';

const AssignPreferenceGroupsListItem = ({ groupId }) => {
    const chnageSelectedGroups = () => {
        preferencesGroupsStorage.changeSelectedPreferenceGroups(groupId);
    };

    return (
        <tr className="preference__modal-row">
            <td className="preference__users-item preference__modal-item_small" onClick={chnageSelectedGroups}>
                <CustomCheckbox 
                    checked={preferencesGroupsStorage.getSelectedList().find(it => it.id === groupId) !== undefined} 
                    handleChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }} />
            </td>
            <td className="preference__modal-item">
                {preferencesGroupsService.getLocalization(i18n.APP_LOCALE, preferencesGroupsStorage.getById(groupId).name, false)}
            </td>
        </tr>
    );
};

export default AssignPreferenceGroupsListItem;
