import React from "react";

export const SortIcon = ({ nameOfClass }) => (
    <svg className={nameOfClass} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="16" fill="#F5F5F5"/>
        <rect width="1528" height="1024" transform="translate(-1397 -37)" fill="white"/>
        <rect x="-1368.5" y="-17.5" width="1438" height="118.002" rx="4.5" stroke="#9747FF" strokeDasharray="10 5"/>
        <path d="M2 2H20" stroke="#264948" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 8H14" stroke="#264948" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 14H8" stroke="#264948" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
