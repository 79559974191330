import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import NavigationTabItem from './NavigationTabItem';
import useWindowDimensions from '../../hooks/useWindowDimension';

const NavigationTabs = ({ navigationService }) => {
    const [activeRoute, setActiveRoute] = useState('');
    const [tabsList, setTabsList] = useState([]);
    const {tab, id} = useParams();
    const { width } = useWindowDimensions();
    const location = useLocation();

    useEffect(() => {
        setActiveRoute(location.pathname + location.search);
    }, [tab, id, location]);

    const changeActiveTabs = () => {
        setTabsList(navigationService.getTabs(activeRoute, id, width));
    };

    useEffect(() => {
        setTabsList(navigationService.getTabs(activeRoute, id, width));
        navigationService.registerTabsUpdateObserver(changeActiveTabs);
        return () => navigationService.unRegisterTabsUpdateObserver(changeActiveTabs);
    }, [activeRoute]);
    
    return (
        <nav className="navigation">
            {tabsList.map(tab => (
                <React.Fragment key={tab.pageUrl}>
                    <NavigationTabItem pageUrl={tab.pageUrl} title={tab.title} isActive={tab.isActive}/>
                </React.Fragment>
            ))}
        </nav>
    );
};

export default NavigationTabs;
