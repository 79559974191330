import React, { useState } from 'react';
import { RECIPE_INGREDIENT_TYPE_CEREAL_MIX, RECIPE_INGREDIENT_TYPE_PRODUCT } from '../../constant';
import { i18n } from '../../i18n';
import RadioLabel from '../RadioLabel';

const IngredientTypeSwitcher = ({ changeIngredientType }) => {
    const [currentIngredientType, setCurrentIngredientType] = useState(RECIPE_INGREDIENT_TYPE_PRODUCT);

    const selectIngredientType = (value) => {
        setCurrentIngredientType(value && currentIngredientType === RECIPE_INGREDIENT_TYPE_PRODUCT ? RECIPE_INGREDIENT_TYPE_CEREAL_MIX : RECIPE_INGREDIENT_TYPE_PRODUCT);
        changeIngredientType(value && currentIngredientType === RECIPE_INGREDIENT_TYPE_PRODUCT ? RECIPE_INGREDIENT_TYPE_CEREAL_MIX : RECIPE_INGREDIENT_TYPE_PRODUCT);
    };

    return (
        <label className="block__text product__form-label product__form-item" key={currentIngredientType}>
            <span className="product__form-label_text">{i18n.get('app.page.recipe.modal.addIngredient.selectType')}</span>
            <RadioLabel 
                value={currentIngredientType === RECIPE_INGREDIENT_TYPE_PRODUCT} 
                type={RECIPE_INGREDIENT_TYPE_PRODUCT}
                title={i18n.get('preference.scheme.preferenceType.product')} 
                changeValue={selectIngredientType}
            />
            <RadioLabel
                value={currentIngredientType === RECIPE_INGREDIENT_TYPE_CEREAL_MIX} 
                type={RECIPE_INGREDIENT_TYPE_CEREAL_MIX}
                title={i18n.get('preference.scheme.preferenceType.cereal-mix')} 
                changeValue={selectIngredientType}
            />
        </label>
    );
};

export default IngredientTypeSwitcher;
