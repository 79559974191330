import React, { useState, useEffect } from 'react';
import { updateSeveralPropertiesService } from '../../../services/updateSeveralPropertiesService';
import { i18n } from '../../../i18n';

const UpdateSeveralPropertiesSaveButton = ({ closeCallback }) => {
    const [dataListIsValid, setDataListIsValid] = useState(false);

    const updateSeveralProperties = () => {
        if(dataListIsValid) {
            updateSeveralPropertiesService.saveChanges();
            closeCallback(null);
        }
    };

    const changeIsValidValue = () => {
        setDataListIsValid(updateSeveralPropertiesService.areDataListsValid());
    };

    useEffect(() => {
        updateSeveralPropertiesService.registerObserverDataListsChanged(changeIsValidValue);
        return () => updateSeveralPropertiesService.unRegisterObserverDataListsChanged(changeIsValidValue);
    }, []);

    return (
        <button className={`block__button button property__button ${dataListIsValid ? '' : 'button_disabled'}`} onClick={updateSeveralProperties}>
            <span className="button__content">{i18n.get('app.page.product.modal.updateProperties.save')}</span>
        </button>
    );
};

export default UpdateSeveralPropertiesSaveButton;
