import React, { useEffect, useState } from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { StarIcon } from '../../appearence/icons/star_icon';
import PhrasesListItemNumberField from './PhrasesListItemNumberField';
import { preferencePhraseItemService } from '../../services/preferencePhraseService';

const PreferencePhrasesListContent = ({ phrase }) => {
    const [isChecked, setIsChecked] = useState(false);

    const changeSelectedList = () => {
        preferencePhraseItemService.changeSelectedPreferencePhrases(phrase.id);
        setIsChecked(!isChecked);
    };

    const changePhrasesList = () => {
        setIsChecked(preferencePhraseItemService.getSelectedList().findIndex(it => it.id === phrase.id) >= 0);
    };

    useEffect(() => {
        preferencePhraseItemService.registerFiltersChangingObserver(changePhrasesList);
        return () => {
            preferencePhraseItemService.unRegisterFiltersChangingObserver(changePhrasesList);
        };
    }, []);

    return (
        <>
            <CustomCheckbox checked={isChecked} handleChange={changeSelectedList} />
            <p className="preference__phrases-text">{phrase.content}</p>
            <PhrasesListItemNumberField preferencePhraseId={phrase.id} ratingValue={phrase.vote} phrasesListManager={preferencePhraseItemService} />
            {phrase.isWelcome ? <StarIcon nameOfClass="preference__button-icon preference__button-icon_star" /> : null}
        </>
    );
};

export default PreferencePhrasesListContent;

