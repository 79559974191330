import React from 'react';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import SelectWithTokensActiveItem from '../sharedComponents/SelectWithTokensActiveItem';
import SelectWithTokensSelectList from '../sharedComponents/SelectWithTokensSelectList';
import SelectWithTokensInput from '../sharedComponents/SelectWithTokensInput';

const checkSelectedForms = (selectWithTokensManager) => {
    return selectWithTokensManager.getCurrentItems() && selectWithTokensManager.getCurrentItems().length;
};

const ExcludeFormListHandler = ({ selectWithTokensManager, isOpened }) => {
    if (!isOpened) return null;
    return (
        <label className="block__text product__form-label product__form-label_inner">
            <div className="product__forms">
                {checkSelectedForms(selectWithTokensManager) ? 
                    <ul className={`product__forms-list ${selectWithTokensManager.getCurrentItems().length === 0 ? 'product__forms-list_full-width' : ''}`}>
                        {selectWithTokensManager.getCurrentItems().map(it => (
                            it.included !== undefined && it.included === false ? 
                                <React.Fragment key={it.itemId + 'exclude'}>
                                    <SelectWithTokensActiveItem
                                        selectItem={{...it, included: false}} 
                                        removeCallback={selectWithTokensManager}
                                    />
                                </React.Fragment>
                                :
                                null
                        ))}
                    </ul>
                    :
                    null}
                <SelectWithTokensInput selectWithTokensManager={selectWithTokensManager} />
                <ChevronIcon
                    nameOfClass="product__forms-icon product__forms-icon_active" />
            </div>
            <div className="product__forms-dropdown product__select-dropdown">
                <SelectWithTokensSelectList selectWithTokensManager={selectWithTokensManager} included={false}/>
            </div>
        </label>
    );
};

export default ExcludeFormListHandler;
