import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../../i18n/index';
import { v4 as uuid } from 'uuid';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import LastUpdateTime from '../sharedComponents/LastUpdateTime';
import RecipeIngredientGroupsTabs from './RecipeIngredientGroupsTabs';
import AddNewIngredientPopup from './AddNewIngredientPopup';
import { recipeSaveService } from '../../services/recipeSaveService';
import { productsStorage } from '../../services/productsStorage';
import { recipesService } from '../../services/recipesService';
import ItemName from '../sharedComponents/ItemName';
import SelectWithoutTokens from '../sharedComponents/SelectWithoutTokens';
import DeleteIngredientPopup from './DeleteIngredientPopup';

const LIST_OF_RECIPE_TYPE_ENUM = [
    {
        id: uuid(),
        recipeType: 'snack',
    },
    {
        id: uuid(),
        recipeType: 'kasha',
    },
    {
        id: uuid(),
        recipeType: 'bowl',
    },
    {
        id: uuid(),
        recipeType: 'soup',
    },
    {
        id: uuid(),
        recipeType: 'bread',
    },
    {
        id: uuid(),
        recipeType: 'smoothies',
    },
    {
        id: uuid(),
        recipeType: 'granola',
    },
];

const createFullList = () => {
    const result = [];
    LIST_OF_RECIPE_TYPE_ENUM.forEach(it => {
        result.push({id: it.id, code: it.recipeType, name: i18n.get(`recipe.item.type.${it.recipeType}`)});
    });
    return result;
};

const RecipeEditTab = () => {
    const [recipeIsChanged, setRecipeIsChanged] = useState(null);
    const { id } = useParams();    
    
    const changeRecipe = () => {
        setRecipeIsChanged(prev => !prev);
    };

    useEffect(() => {
        recipeSaveService.loadRecipe(id, () => {});
        productsStorage.loadItems(() => changeRecipe());
    }, [id]);

    useEffect(() => {
        recipeSaveService.registerItemUpdateObserver(changeRecipe);
        return () => {
            recipeSaveService.unRegisterItemUpdateObserver(changeRecipe);
            recipeSaveService.cleanService();
        };
    }, []);

    const changeRecipeCode = (recipe) => {
        recipeSaveService.updateRecipeType(recipe.code);
    };

    return (
        <section className="workspace workspace__recipe">
            <AddNewIngredientPopup />
            <DeleteIngredientPopup />
            <PageTitleAsBreadCrumbs />
            {recipeIsChanged !== null ?
                <article className="recipe__article recipe__article-add">
                    <LastUpdateTime itemSaveService={recipeSaveService} />
                    <form className="recipe__form" key={recipeIsChanged + 'recipeForm'}>
                        <ItemName itemCode={recipeSaveService.getRecipe().recipeType} itemService={recipesService} itemSaveService={recipeSaveService}/>
                        <label className="block__text recipe__form-label recipe__form_lowercase recipe__form-modal">
                            {i18n.get('app.page.recipe.recipeType')}
                            <SelectWithoutTokens listOfOptions={createFullList()} handleChangeValue={changeRecipeCode} 
                                titleValue={recipesService.getLocalization(i18n.APP_LOCALE, recipeSaveService.getRecipe().recipeType, true)} />
                        </label>
                    </form>
                    <RecipeIngredientGroupsTabs />
                </article>
                :
                null}
        </section>
    );
};

export default RecipeEditTab;
