import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';
import PreferenceGroupActivityPeriodsCalendarIcon from './PreferenceGroupActivityPeriodsCalendarIcon';
import { preferenceGroupSaveService, transformLabelTypeToKey } from '../../services/preferenceGroupSaveService';
import { MinusIcon } from '../../appearence/icons/minus_icon';
import { DATE_REG_EXP, DATE_STOP_LABEL } from '../../constant';

const findCurrentValue = (labelType, index) => {
    if(preferenceGroupSaveService.getPreferenceGroup().activityPeriods[index]) {
        return preferenceGroupSaveService.getPreferenceGroup().activityPeriods[index][transformLabelTypeToKey(labelType)];
    }
    return '';
};

const PreferenceGroupActivityPeriodsItemLabel = ({ currentPeriodIndex, labelType }) => {
    const [inputValue, setInputValue] = useState(findCurrentValue(labelType, currentPeriodIndex));

    const changeActivePeriodValue = (e) => {
        if(e.target.value.match(DATE_REG_EXP) || e.target.value === '') {
            let value = e.target.value;
            if((value.length === 2 || value.length === 5) && value.length > inputValue.length) {
                value += '.';
            }
            setInputValue(value);
            value.length === 6 && preferenceGroupSaveService.updatePreferenceGroupActivityPeriod(labelType, value, currentPeriodIndex);
        }
    };

    const changeInputValue = () => {
        if(preferenceGroupSaveService.getPreferenceGroup().activityPeriods[currentPeriodIndex]) {
            setInputValue(findCurrentValue(labelType, currentPeriodIndex));
        }
    };

    useEffect(() => {
        preferenceGroupSaveService.registerPreferenceGroupUpdateObserver(changeInputValue);
        return () => preferenceGroupSaveService.unRegisterPreferenceGroupUpdateObserver(changeInputValue);
    }, []);

    return (
        <label key={labelType} className="block__text product__form-label product__add-label">
            <span className="product__form-label_text">{i18n.get(`app.page.preference.groups.${labelType}`)}</span>
            <input className="product__form-input product__add-input"
                value={inputValue}
                onChange={changeActivePeriodValue} 
                placeholder={i18n.get('app.page.preference.groups.placeholder')}
            />
            <PreferenceGroupActivityPeriodsCalendarIcon currentPeriodIndex={currentPeriodIndex} labelType={labelType} />
            {currentPeriodIndex !== 0 && labelType === DATE_STOP_LABEL ? 
                <span onClick={() => preferenceGroupSaveService.deleteActivityPeriod(currentPeriodIndex)}>
                    <MinusIcon nameOfClass="product__value-icon"/>
                </span> 
                : 
                null}
        </label>
    );
};

export default PreferenceGroupActivityPeriodsItemLabel;
