import React from 'react';
import { popupOpenService } from '../../sharedComponents/popupOpenService';
import { TrashIcon } from '../../../appearence/icons/trash_icon';
import { POPUP_KEY_DELETE_PRODUCT_PROPERTY } from '../../../constant';

const DeleteProductPropertyIcon = ({ propertyId }) => {
    const openDeleteProductPropertyPopup = () => {
        popupOpenService.callToOpen(POPUP_KEY_DELETE_PRODUCT_PROPERTY, propertyId);
    };

    return (
        <span onClick={openDeleteProductPropertyPopup}>
            <TrashIcon nameOfClass="property__update-icon" />
        </span>
    );
};

export default DeleteProductPropertyIcon;
