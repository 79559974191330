import React from 'react';

const PreferenceMealTab = () => {
    return (
        <section className="workspace workspace__preference">
        </section>
    );
};

export default PreferenceMealTab;
