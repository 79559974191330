import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { popupOpenService } from '../../../services/popupOpenService';
import Modal from '../../Modal';
import { DELETE_PRODUCT, deleteFetchRequest } from '../../../store/requests';
import { POPUP_KEY_DELETE_PRODUCT, errorCallback, makeUrl } from '../../../constant';
import { MODAL_SMALL_SIZE } from '../../helper';
import DeleteModalContent from '../DeleteModalContent';
import { productsStorage } from '../../../services/productsStorage';
import { productsService } from '../../../services/productsService';
import { i18n } from '../../../i18n';

const checkIsDeleted = (productId) => productsStorage.getById(productId).isDeleted;

const DeleteProductPopup = ({notifyProductsList}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState('');
    const { tab } = useParams();

    const handleOpenModal = (productId) => {
        setIsOpen(true);
        setCurrentProductId(productId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_PRODUCT, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_PRODUCT, handleOpenModal);
    },[]);

    const deleteProduct = () => {        
        setIsOpen(null);
        setCurrentProductId('');
        if(productsStorage.getById(currentProductId).isDeleted) {
            deleteFetchRequest(makeUrl([DELETE_PRODUCT, currentProductId, '/?forceDelete=true']), () => notifyProductsList(), errorCallback);
        } else {
            deleteFetchRequest(makeUrl([DELETE_PRODUCT, currentProductId, '/']), () => notifyProductsList(), errorCallback);
        }
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deleteProduct} tab={tab} 
                isForceDelete={checkIsDeleted(currentProductId)}
                itemTitle={checkIsDeleted(currentProductId) ? productsService.getLocalization(i18n.APP_LOCALE, productsStorage.getById(currentProductId).productCode) : ''} />} 
            handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeleteProductPopup;
