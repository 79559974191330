import { MAIN_RECIPE_ELEMENTS, SECONDARY_RECIPE_ELEMENTS, TASTE_RECIPE_PROPERTIES } from '../components/helper';
import { ITEM_TAB_NAME, RECIPE_TAB, TABLET_WIDTH } from '../constant';
import { i18n } from '../i18n';

const checkActiveRoute = (activeRoute, pageUrl) => {
    return activeRoute === pageUrl;
};

const createRecipeIngredientsTabs = (id, width, activeRoute) => {
    return [
        {
            pageUrl: `/${RECIPE_TAB}/${ITEM_TAB_NAME}/${id}/?ingredientTab=${MAIN_RECIPE_ELEMENTS}`,
            title: i18n.get('app.page.recipe.base'),
            isActive: checkActiveRoute(activeRoute, `/${RECIPE_TAB}/${ITEM_TAB_NAME}/${id}/?ingredientTab=${MAIN_RECIPE_ELEMENTS}`),
        },
        {
            pageUrl: `/${RECIPE_TAB}/${ITEM_TAB_NAME}/${id}/?ingredientTab=${SECONDARY_RECIPE_ELEMENTS}`,
            title: i18n.get(width <= TABLET_WIDTH ? 'app.page.recipe.addsShort' : 'app.page.recipe.adds'),
            isActive: checkActiveRoute(activeRoute, `/${RECIPE_TAB}/${ITEM_TAB_NAME}/${id}/?ingredientTab=${SECONDARY_RECIPE_ELEMENTS}`),
        },
        {
            pageUrl: `/${RECIPE_TAB}/${ITEM_TAB_NAME}/${id}/?ingredientTab=${TASTE_RECIPE_PROPERTIES}`,
            title: i18n.get(width <= TABLET_WIDTH ? 'app.page.recipe.tastyShort' : 'app.page.recipe.tasty'),
            isActive: checkActiveRoute(activeRoute, `/${RECIPE_TAB}/${ITEM_TAB_NAME}/${id}/?ingredientTab=${TASTE_RECIPE_PROPERTIES}`),
        },
    ];
};

export const ingredientTabNavigationService = {
    observersList: [],
    getTabs(activeRoute, id, width) {
        return createRecipeIngredientsTabs(id, width, activeRoute);
    },
    getTabName() {
        return RECIPE_TAB;
    },
    registerTabsUpdateObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterTabsUpdateObserver(observer) {
        this.observersList = this.observersList.filter(observerFunction => observerFunction !== observer);
    },
    notifyObservers() {
        this.observersList.forEach(observer => observer());
    },
};
