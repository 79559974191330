import React, { useState } from 'react';
import { COMMIT_BACKEND, COMMIT_FRONTEND } from '../../constant';
import { CommitIcon } from '../../appearence/icons/commit_icon';
import { ConeIcon } from '../../appearence/icons/cone_icon';

const CommitInfo = () => {
    const [hintIsOpen, setHintIsOpen] = useState(false);

    if(!COMMIT_BACKEND.trim() && !COMMIT_FRONTEND.trim()) {
        return null;
    }
    return (
        <div className="commit" onMouseOver={() => setHintIsOpen(true)} onMouseOut={() => setHintIsOpen(false)}>
            {hintIsOpen ? 
                <p className="block__bullet commit__hint">
                    <ConeIcon nameOfClass="commit__hint-cone"/>
                    b: {COMMIT_BACKEND}
                    <br />
                    f: {COMMIT_FRONTEND}
                </p> 
                : 
                null}
            <CommitIcon nameOfClass="commit__icon" />
        </div>
    );
};

export default CommitInfo;
