import React from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';
import { preferenceSaveService } from '../../services/preferenceSaveService';

const createListOfOptions = () => {
    return [
        {
            id: true,
            name: i18n.get('preference.scheme.isInternal.true'),
        },
        {
            id: false,
            name: i18n.get('preference.scheme.isInternal.false'),
        },
    ];
};

const findPreferenceTitle = () => {
    if(preferenceSaveService.getPreference().isInternal !== undefined) {
        return i18n.get(`preference.scheme.isInternal.${preferenceSaveService.getPreference().isInternal}`);
    } else {
        return '';
    }
};

const PreferenceStructureIsInternal = () => {
    const updatePreferenceValue = (value) => {
        preferenceSaveService.updatePreferenceProperty('isInternal', value);
    };

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.isInternal')}</span>
            <SelectHandler 
                selectedValue={findPreferenceTitle()} 
                optionsList={createListOfOptions()} 
                sendSelectedValue={updatePreferenceValue}
            /> 
        </label> 
    );
};

export default PreferenceStructureIsInternal;
