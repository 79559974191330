import React, { useState, useEffect } from 'react';
import CustomCheckbox from '../CustomCheckbox';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import PreferenceGroupsListItemContent from './PreferenceGroupsListItemContent';

const PreferenceGroupsListItem = ({ group }) => {
    const [isChecked, setIsChecked] = useState(false);

    const changeCheckedValue = () => {
        setIsChecked(preferencesGroupsStorage.getSelectedList().findIndex(it => it.id === group.id) >= 0);
    };

    const changeSelectedList = () => {
        preferencesGroupsStorage.changeSelectedPreferenceGroups(group.id);
    };

    useEffect(() => {
        preferencesGroupsStorage.registerFiltersChangingObserver(changeCheckedValue);
        return () => preferencesGroupsStorage.unRegisterFiltersChangingObserver(changeCheckedValue);
    }, []);

    return (
        <>
            <li className="preference__list-item preference__groups-item">
                <CustomCheckbox checked={isChecked} handleChange={changeSelectedList} />
                <PreferenceGroupsListItemContent group={group} />
            </li>
        </>
    );
};

export default PreferenceGroupsListItem;
