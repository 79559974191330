import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StrokeListIcon } from '../../appearence/icons/stroke_list_icon';

const PreferencePhraseSwitcher = ({ pageUrl, itemTitle }) => {
    const navigate = useNavigate();
    return (
        <p className="main__subtitle preference__navigation-button" onClick={() => navigate(pageUrl)}>
            <StrokeListIcon nameOfClass="preference__navigation-icon"/>
            {itemTitle}
        </p>
    );
};

export default PreferencePhraseSwitcher;
