import React from 'react';
import { popupOpenService } from '../../../services/popupOpenService';
import { i18n } from '../../../i18n';

const UpdateSeveralPropertiesActionsList = ({propertyId}) => {
    
    const openUpdateSeveralPropertiesPopup = (key) => {
        popupOpenService.callToOpen(key, propertyId);
    };

    return (
        <ul className="property__update-dropdown">
            <li className="property__update-item" onClick={() => openUpdateSeveralPropertiesPopup('apply-to')}>
                {i18n.get('app.pgae.product.applyTo')}
            </li>
            <li className="property__update-item">
                {i18n.get('app.pgae.product.fillAs')}
            </li>
        </ul>
    );
};

export default UpdateSeveralPropertiesActionsList;
