import React from 'react';
import { i18n } from '../../i18n';
import CreateNewGroupButton from './CreateNewGroupButton';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import DeletePreferenceGroupPopup from './DeletePreferenceGroupPopup';
import PreferenceGroupsList from './PreferenceGroupsList';
import SearchItemBlock from '../sharedComponents/SearchItemBlock';
import ContentTitle from '../sharedComponents/ContentTitle';
import PreferenceGroupsActions from './PreferenceGroupsActions';

const PreferenceGroupList = () => {
    const handleLoadPreferenceGroups = () => {
        preferencesGroupsStorage.loadItems(() => {});
    };

    return (
        <>
            <DeletePreferenceGroupPopup notifyPreferencesGroupsList={handleLoadPreferenceGroups} />
            <SearchItemBlock itemsStorageService={preferencesGroupsStorage} 
                placeholder={i18n.get('preference.scheme.groups.placeholder')} />
            <CreateNewGroupButton />
            <article className="preference__article preference__article_full-width">
                <div className="preference__groups-block">
                    <ContentTitle title={i18n.get('app.page.group.title')} />
                    <PreferenceGroupsActions />
                </div>
                <PreferenceGroupsList />
            </article>
        </>
    );
};

export default PreferenceGroupList;
