import { POPUP_KEY_CREATE_FORM, PRODUCT_CATEGORY_ANY } from "../constant";
import { i18n } from "../i18n";
import { formsService } from "./formsService";
import { formsStorage } from "./formsStorage";
import { productSaveService } from "./productSaveService";

export const filterFormsList = (form) => {
    return (productSaveService.getProduct().productCategory && form.formGroup === productSaveService.getProduct().productCategory) || 
        form.formGroup === PRODUCT_CATEGORY_ANY ||
        productSaveService.getProduct().productCategory === null;
};

const transformFormsList = (formsList, withFilter) => {
    let resultArray = [];
    withFilter ? formsList = formsList.filter(it => filterFormsList(it)) : null;
    formsList.forEach(it => resultArray.push({itemId: it.id, itemTitle: formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true), 
        included: true}));
    return resultArray;
};

export const formsProductService = {
    observersList: [],
    possibleForms: transformFormsList(formsStorage.getItems(true), true),
    selectedForms: [],
    filter: '',
    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getPossibleItems() {
        this.possibleForms = transformFormsList(formsStorage.getItems(true)
            .filter(it => formsService.getLocalization(i18n.APP_LOCALE, it.productForm, true).toLowerCase().includes(this.filter)), true);
        return this.possibleForms;
    },
    getCurrentItems() {
        this.selectedForms = transformFormsList(productSaveService.getProduct().productForms, false);
        return this.selectedForms;
    },
    deleteItem(formId) {
        productSaveService.deleteProductForm(formId);
        this.notifyItemsChangingObservers();
    },
    addItem(formId) {
        productSaveService.addProductForm(formId);
        this.notifyItemsChangingObservers();
    },
    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    },
    hasExcludedItemsBlock() {
        return false;
    },
    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    },
    getSearchString() {
        return this.filter;
    },
    getPopupKey() {
        return POPUP_KEY_CREATE_FORM;
    },
    getButtonTitle() {
        return i18n.get("app.page.product.addForm");
    },
    getLabelName() {
        return i18n.get('app.page.product.productFormName');
    },
};
