import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import { i18n } from '../../i18n/index';
import { XIcon } from '../../appearence/icons/x_icon';
import { MOUSE_DOWN_EVENT } from '../../constant';

const Select = ({listOfOptions, setListOfOptions, activeOptionList, setActiveOptionList, searchInputValue, handleChangeValue, 
    setSearchInputValue, className, withSearch, isMultiSelect, titleValue}) => {
    const wrapperRef = useRef(null);
    const [optionListIsOpen, setOptionListIsOpen] = useState(false);

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOptionListIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        };
    }, []);

    const handleOpenSearchList = (e) => {
        e.stopPropagation();
        setListOfOptions({...listOfOptions, activeList: listOfOptions.fullList});
        setOptionListIsOpen(true);
    };

    const handleAddNewActiveValue = (e, it) => {
        setOptionListIsOpen(false);
        if(isMultiSelect) {
            const data = [...activeOptionList];
            let count = 0;
            data.forEach(item => item.id === it.id ? count++ : null);
            if (count === 0) {
                data.push(it);
                setActiveOptionList(data);
                searchInputValue !== null && setSearchInputValue('');   
                searchInputValue === null & handleOpenSearchList(e);
                setListOfOptions({...listOfOptions, activeList: listOfOptions.activeList.filter(item => item.code !== it.code)});
            }
        } else if(withSearch) {
            e.stopPropagation();
            e.preventDefault();
            setListOfOptions({...listOfOptions, activeList: [], firstRender: true});
            setSearchInputValue(it.name);
        } else {
            e.stopPropagation();
            setListOfOptions({...listOfOptions, activeList: [], firstRender: true});
            handleChangeValue(it);
        }
    };

    const deleteActiveValue = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        const data = activeOptionList.filter(it => it.id !== item.id );
        setActiveOptionList([...data]);
        setListOfOptions({...listOfOptions, activeList: [...listOfOptions.activeList, item]});
    };

    const handleOpenList = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(activeOptionList.length) {
            const result = [];
            for(let i = 0; i < listOfOptions.fullList.length; i++) {
                let count = 0;
                activeOptionList.forEach(it => it.id === listOfOptions.fullList[i].id ? count++ : null);
                count === 0 && result.push(listOfOptions.fullList[i]);
            }
            setListOfOptions({...listOfOptions, activeList: result});
        } else {
            setListOfOptions({...listOfOptions, activeList: listOfOptions.fullList});
        }
        setOptionListIsOpen(true);
    };

    const handleChangeListShown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOptionListIsOpen(prev => !prev);
        if(!optionListIsOpen) {
            handleOpenList(e);
        }
    };
    
    return (
        <div className={`block__content select__choice ${listOfOptions.activeList.length ? 'select__choice_active' : ''} ${searchInputValue === null ? 'select__choice_full-width' : ''} ${className}__custom-select`}
            onClick={handleOpenList} ref={wrapperRef}>
            {isMultiSelect && activeOptionList.length ?
                <ul className={`select__choice-list ${searchInputValue === null ? 'select__choice-list_full-width' : ''} ${className}__custom-select-list`}>
                    {activeOptionList.map((it, index) => (
                        <li key={index + 'searchInput'} className={`select__choice-item ${className}__custom-select-item`} onClick={(e) => deleteActiveValue(e, it)}>
                            <span className="block__bullet select__choice-text">
                                {it.name}
                            </span>
                            <XIcon nameOfClass="select__choice-item-icon"/>
                        </li>
                    ))}
                    {optionListIsOpen && withSearch ?
                        <input className={`block__content select__choice-input ${className}__custom-select-input`} onChange={(e) => setSearchInputValue(e.target.value)} 
                            value={searchInputValue === null ? titleValue : searchInputValue}/>
                        :
                        null}
                </ul>
                :
                withSearch ? 
                    <input className={`block__content select__choice-input ${className}__custom-select-input`} onChange={(e) => setSearchInputValue(e.target.value)} 
                        value={searchInputValue === null ? titleValue : searchInputValue} placeholder={!titleValue && !searchInputValue ? i18n.get("app.page.preference.selectFromList") : ''}/>
                    :
                    null}
            {!withSearch && !isMultiSelect ? 
                <p className={titleValue ? '' : 'select__choice-placeholder'}>{titleValue ? titleValue : i18n.get("app.page.preference.selectFromList")}</p>
                :
                isMultiSelect && !withSearch && !activeOptionList.length ?
                    <p className='select__choice-placeholder'>{i18n.get("app.page.preference.selectFromList")}</p>
                    :
                    null}
            {optionListIsOpen && listOfOptions.activeList.length ? 
                <ul className={`scroll select__choice-dropdown ${activeOptionList.length ? '' : className + '__custom-select-dropdown'} 
                    ${activeOptionList.length ? `select__choice-dropdown_active  ${className}__custom-select-dropdown_active` : ''}
                    ${searchInputValue === null ? 'select__choice-dropdown_full-width' : ''} `}>
                    {listOfOptions.activeList.map((it, index) => (
                        <li key={it.id + index} className="block__content select__choice-option" onClick={(e) => handleAddNewActiveValue(e, it)}>
                            {it.name}
                        </li>
                    ))}
                </ul>
                :
                null}
            <ChevronIcon nameOfClass={`select__choice-icon ${optionListIsOpen ? 'select__choice-icon_active' : ''} ${searchInputValue === null ? 'select__choice-icon_full-width' : ''} ${className}__custom-select-icon`} 
                handleClick={handleChangeListShown}/>
        </div>
    );
};

export default Select;
