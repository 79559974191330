import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MOUSE_DOWN_EVENT } from '../../constant';
import { i18n } from '../../i18n';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import CreateSourceBlock from './CreateSourceBlock';
import { PlusIcon } from '../../appearence/icons/plus_icon';
import SelectWithTokensActiveItem from '../sharedComponents/SelectWithTokensActiveItem';
import SelectWithTokensInput from '../sharedComponents/SelectWithTokensInput';
import SelectWithTokensSelectList from '../sharedComponents/SelectWithTokensSelectList';
import { productSaveService } from '../../services/productSaveService';

const checkIsItLastSource = (sourceSelectService) => {
    return (productSaveService.getProperty(sourceSelectService.getPropertyId()).minValue || 
        productSaveService.getProperty(sourceSelectService.getPropertyId()).maxValue ||
        productSaveService.getProperty(sourceSelectService.getPropertyId()).value) && 
        sourceSelectService.getCurrentItems().length === 1;
};

const SourcesHandler = ({ sourceSelectService }) => {
    const [selectListIsOpen, setSelectListIsOpen] = useState(false);
    const [addNewSourceIsOpen, setAddNewSourceIsOpen] = useState(false);
    const [sourcesLength, setSourcesLength] = useState(0);
    const wrapperRef = useRef();

    const openSourcesList = () => {
        setSelectListIsOpen(true);
    };

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSelectListIsOpen(false);
        }
    }, []);

    const changeSourcesLength = () => {
        setSourcesLength(sourceSelectService.getCurrentItems().length);
    };

    useEffect(() => {
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        sourceSelectService.registerItemsChangingObserver(changeSourcesLength);
        changeSourcesLength();
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
            sourceSelectService.unRegisterItemsChangingObserver(changeSourcesLength);
        };
    }, []);

    const updateSourcesList = () => {
        setAddNewSourceIsOpen(false);
        changeSourcesLength();
    };

    return (
        <>
            <label className="block__text product__form-label product__source-label" ref={wrapperRef}>
                <span className="product__form-label_text">{i18n.get("app.page.product.source")}</span>
                <div ref={wrapperRef}>
                    <div className="product__forms" onClick={openSourcesList} >
                        {sourceSelectService.getCurrentItems().length > 0 ? 
                            <ul className={`product__forms-list ${sourceSelectService.getCurrentItems().length === 0 ? 'product__forms-list_full-width' : ''}`}>
                                {sourceSelectService.getCurrentItems().map((it) => (
                                    <React.Fragment key={it.itemId + sourcesLength + 'formHandler'}>
                                        <SelectWithTokensActiveItem 
                                            selectItem={{...it, isLastItem: checkIsItLastSource(sourceSelectService)}} 
                                            removeCallback={sourceSelectService}
                                        />
                                    </React.Fragment>
                                ))}
                            </ul>
                            :
                            null}
                        {selectListIsOpen || sourceSelectService.getCurrentItems().length === 0 ? 
                            <SelectWithTokensInput selectWithTokensManager={sourceSelectService} />
                            :
                            null}
                        <ChevronIcon
                            nameOfClass={`product__forms-icon ${selectListIsOpen ? 'product__forms-icon_active' : ''}`} />
                    </div>
                    {selectListIsOpen ? 
                        <div className="product__source-dropdown product__select-dropdown">
                            {!addNewSourceIsOpen ?
                                <>
                                    <SelectWithTokensSelectList selectWithTokensManager={sourceSelectService} include={true}/>
                                    <div className="block__text product__source-add" onClick={() => setAddNewSourceIsOpen(true)}>
                                        {i18n.get("app.page.product.addOneSource")}
                                        <PlusIcon nameOfClass="product__source-plus" />
                                    </div> 
                                </>

                                : 
                                <CreateSourceBlock closeCallback={updateSourcesList} />}
                        </div>
                        :
                        null}
                </div>
            </label>
        </>
    );
};

export default SourcesHandler;

