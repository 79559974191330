import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { popupOpenService } from '../../../services/popupOpenService';
import Modal from '../../Modal';
import { PROPERTIES_URL, deleteFetchRequest } from '../../../store/requests';
import { POPUP_KEY_DELETE_PROPERTY, errorCallback, makeUrl } from '../../../constant';
import { MODAL_SMALL_SIZE } from '../../helper';
import DeleteModalContent from '../DeleteModalContent';
import { propertiesStorage } from '../../../services/propertiesStorage';
import { propertiesService } from '../../../services/propertiesService';
import { i18n } from '../../../i18n';

const checkIsDeleted = (propertyId) => propertiesStorage.getById(propertyId).isDeleted;

const DeletePropertyPopup = ({notifyPropertiesList}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPropertyId, setCurrentPropertyId] = useState('');
    const { tab } = useParams();

    const handleOpenModal = (propertyId) => {
        setIsOpen(true);
        setCurrentPropertyId(propertyId);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_DELETE_PROPERTY, handleOpenModal);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_DELETE_PROPERTY, handleOpenModal);
    },[]);

    const deleteProperty = () => {        
        setIsOpen(null);
        setCurrentPropertyId('');
        if(propertiesStorage.getById(currentPropertyId).isDeleted) {
            deleteFetchRequest(makeUrl([PROPERTIES_URL, currentPropertyId, '/?forceDelete=true']), () => notifyPropertiesList(), errorCallback);
        } else {
            deleteFetchRequest(makeUrl([PROPERTIES_URL, currentPropertyId, '/']), () => notifyPropertiesList(), errorCallback, true);
        }
    };

    return (
        isOpen ?
            <Modal content={<DeleteModalContent handleClose={setIsOpen} handleDelete={deleteProperty} tab={tab}
                isForceDelete={checkIsDeleted(currentPropertyId)}
                itemTitle={checkIsDeleted(currentPropertyId) ? propertiesService.getLocalization(i18n.APP_LOCALE, propertiesStorage.getById(currentPropertyId).propertyName) : ''} />}  
            handleCloseModal={setIsOpen} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default DeletePropertyPopup;
