import { sortByDate } from "../components/helper";
import { FILTER_TYPE_CHECKBOX, SORTING_DIRECTION_ASC, errorCallback, makeUrl } from "../constant";
import { i18n } from "../i18n";
import { PREFERENCE_GROUP_URL, getFetchRequest } from "../store/requests";

const createListOfFilters = () => {
    return [ 
        {
            filterTitle: i18n.get('app.page.preference.groups.activityPeriods'),
            filterName: 'activityPeriods',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'activityPeriod',
        },
        {
            filterTitle: i18n.get('app.page.preference.groups.always'),
            filterName: 'isPermanent',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'activityPeriod',
        },
        {
            filterTitle: i18n.get('app.page.preferences.filters.true'),
            filterName: 'isAutoAssigned',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: 'isAutoAssigned',
        },
    ];
};

const findGroupFilterValue = (filter, group) => {
    if(filter.value === true) {
        return group[filter.filterName] && 
            ((Array.isArray(group[filter.filterName]) && group[filter.filterName].length > 0) || !Array.isArray(group[filter.filterName]));
    }
    return false;
};

const findSearchValueInProperty = (group, titleFilter) => {
    if(i18n.get(`preference.scheme.group.code.${group.name}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    return false;
};

const sortByDateStop = (a, b) => new Date(b.dateStop) - new Date(a.dateStop);

export const preferencesGroupsStorage = {
    groupsList: [],
    filtersList: [],
    searchValue: '',
    observersList: [],
    possibleFilters: [],
    selectedItems: [],
    sortingDirection: '',
    loadItems(callback) {
        try {
            getFetchRequest(makeUrl([PREFERENCE_GROUP_URL]), (response) => {
                this.groupsList = response.sort(sortByDate);
                callback && callback();
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    getItems() {
        let filteredList = [...this.groupsList];
        if(this.searchValue) {
            filteredList = this.groupsList.filter(it => findSearchValueInProperty(it, this.searchValue));
        }
        if(this.sortingDirection) {
            if(this.sortingDirection === SORTING_DIRECTION_ASC) {
                filteredList.sort((a, b) => a.isPermanent ? -1 : 1);
            } else {
                filteredList.sort((a, b) => {
                    new Date(b.activityPeriods.sort(sortByDateStop)[0]?.dateStop)
                    - new Date(a.activityPeriods.sort(sortByDateStop)[0]?.dateStop);
                });
            }
        }
        if(this.filtersList.length) {
            filteredList = this.groupsList.filter(it => this.filtersList.find(filter => findGroupFilterValue(filter, it)));
        }
        return filteredList;
    },
    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value, key) => {
            const currentFilter = this.possibleFilters.find(it => it.filterName === key);
            if(currentFilter.filterType === FILTER_TYPE_CHECKBOX && value === 'true') {
                currentFilter.value = true;
                filtersList.push(currentFilter);
            }
        });
        this.filtersList = filtersList;
    },
    getPossibleFilters() {
        this.possibleFilters = createListOfFilters();
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    getFilter(filterName) {
        return this.possibleFilters.find(it => it.filterName === filterName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedKeys) {
        const currentFilter = this.filtersList.find(it => it.filterName === filterName);
        if(currentFilter) {
            currentFilter.value = selectedKeys;
        } else {
            const possibleFilter = this.possibleFilters.find(it => it.filterName === filterName);
            possibleFilter.value = selectedKeys;
            this.filtersList.push(possibleFilter);
        }
        this.filtersList = this.filtersList.filter(it => 
            it.filterType !== FILTER_TYPE_CHECKBOX || (it.filterType === FILTER_TYPE_CHECKBOX && it.value == true));
        this.page = 1;
    },
    setNumberFilterValue() {
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.possibleFilters.forEach((it, index) => {
            if(it.filterType === FILTER_TYPE_CHECKBOX) {
                this.possibleFilters[index].value = false;
            }
        });
        this.notifyFiltersChangingObservers();
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getSelectedList() {
        return this.selectedItems;
    },
    changeSelectedPreferenceGroups(preferenceGroupId, cleanAll) {
        if(preferenceGroupId) {
            if(this.selectedItems.find(it => it.id === preferenceGroupId)) {
                this.selectedItems = this.selectedItems.filter(it => it.id !== preferenceGroupId);
            } else {
                this.selectedItems.push(this.groupsList.find(it => it.id === preferenceGroupId));
            }
        } else {
            if((this.groupsList.length === this.selectedItems.length && this.selectedItems.length !== 0) || cleanAll) {
                this.selectedItems = []; 
            } else {
                this.selectedItems = this.groupsList;
            }
        }
        this.notifyFiltersChangingObservers();
    },
    getSortingDirection() {
        return this.sortingDirection;
    },
    changeSortingDirection(sortingDirection) {
        this.sortingDirection = sortingDirection;
        this.notifyFiltersChangingObservers();
    },
    getById(preferenceGroupId) {
        return this.groupsList.find(it => it.id === preferenceGroupId);
    },
    changeDeletedItemsView() {
    },
    changeValidItemsView() {
    },
};
