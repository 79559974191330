import React, { useState, useEffect } from 'react';
import { LOCALE_RU } from '../../../constant';
import { useParams } from 'react-router-dom';
import { formsService } from '../../../services/formsService';
import PageTitleAsBreadCrumbs from '../../sharedComponents/PageTitleAsBreadCrumbs';
import { formSaveService } from '../../../services/formSaveService';
import ItemName from '../../sharedComponents/ItemName';
import NonEditableItemCode from '../../sharedComponents/NonEditableItemCode';
import FormItemType from './FormItemType';
import LastUpdateTime from '../../sharedComponents/LastUpdateTime';
import ItemProductCategory from '../../sharedComponents/ItemProductCategory';

const EditFormTab = () => {
    const [activeFormItem, setActiveFormItem] = useState({});
    const { id } = useParams();

    const successGetForms = (response) => {
        setActiveFormItem({...response, locales:[], locale:LOCALE_RU});
    };

    useEffect(() => {
        if(id) {
            formSaveService.loadForm(id, successGetForms);
        }
    }, [id]);

    return (
        <>
            <PageTitleAsBreadCrumbs />
            <article className="product__article product__article-add">
                <LastUpdateTime itemSaveService={formSaveService} itemCode={activeFormItem.productForm}/>
                <form className="product__form product__form_large">
                    <ItemName itemCode={activeFormItem.productForm} itemService={formsService} itemSaveService={formSaveService}/>
                    <NonEditableItemCode itemCode={activeFormItem.productForm} />
                    <ItemProductCategory itemsStorageService={formSaveService} />
                    <FormItemType />
                </form>
            </article>
        </> 
    );
};

export default EditFormTab;
