import { makeUrl, errorCallback } from "../../constant";
import { LOCALES_URL, postFetchRequest } from "../../store/requests";
import { i18n } from "../../i18n";

const UPDATE_LOCALE_TIMEOUT = 200;
const UPDATE_ITEM_TIMEOUT = 1000;

export const updateValueSevice = {
    localeTimeout: null,
    updateValueTimeout: null,
    updateLocaleValue(locale, translationCode, notifyItemsList) {
        if(this.localeTimeout) {
            clearTimeout(this.localeTimeout);
            this.localeTimeout = null;
        }
        const updatedLocale = locale;
        this.localeTimeout = setTimeout(() => {
            const localeData = {
                i18nCode: translationCode,
                localeValue: i18n.get(translationCode, updatedLocale),
            };
            postFetchRequest(makeUrl([LOCALES_URL, updatedLocale, '/']), localeData, () => {
                notifyItemsList();
                clearTimeout(this.localeTimeout);
            }, errorCallback, true);
        }, UPDATE_LOCALE_TIMEOUT);
    },
    updateItemValue(saveItemFunction) {
        if(this.updateValueTimeout) {
            clearTimeout(this.updateValueTimeout);
            this.updateValueTimeout = null;
        }
        this.updateValueTimeout = setTimeout(() => {
            saveItemFunction();
            clearTimeout(this.updateValueTimeout);
        }, UPDATE_ITEM_TIMEOUT);
    },
};
