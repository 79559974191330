import React from 'react';

const NonEditableItemCode = ({ itemCode }) => {
    return (
        <label className="main__subtitle product__form-label product__form_lowercase">
            <input className="main__subtitle product__form-input product__form-title product__form-title_disabled" 
                defaultValue={itemCode} 
                disabled={true} 
            />
        </label>
    );
};

export default NonEditableItemCode;
