import React, { useEffect, useState } from 'react';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { POPUP_KEY_DELETE_PREFERENCE } from '../../constant';
import { TrashIcon } from '../../appearence/icons/trash_icon';
import { preferencesStorage } from '../../services/preferencesStorage';

const PreferencesListActionsDeleteIcon = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const deletePreference = () => {
        if(!isDisabled) {
            popupOpenService.callToOpen(POPUP_KEY_DELETE_PREFERENCE);
        }
    };

    const changeIsDisabled = () => {
        setIsDisabled(preferencesStorage.getSelectedPreferencesList().length === 0);
    };

    useEffect(() => {
        preferencesStorage.registerFiltersChangingObserver(changeIsDisabled);
        return () => preferencesStorage.unRegisterFiltersChangingObserver(changeIsDisabled);
    }, []);

    return (
        <li onClick={deletePreference}>
            <TrashIcon nameOfClass={`preference__groups-icon ${ !isDisabled ? '' : 'preference__groups-icon_disabled'}`}/>
        </li>
    );
};

export default PreferencesListActionsDeleteIcon;
