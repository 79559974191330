import React, { useState } from 'react';
import { DropdownIcon } from '../../appearence/icons/dropdown_icon';
import FiltersListItemCheckbox from './FiltersListItemCheckbox';
import { i18n } from '../../i18n/index';
import { FILTER_GROUP_NAME_STATUS } from '../../constant';

const FiltersListStatus = ({ itemsStorageService }) => {
    const [filterGroupIsOpen, setFilterGroupIsOpen] = useState(false);

    const changeFilterItemOpenness = () => {
        setFilterGroupIsOpen(!filterGroupIsOpen);
    };

    return (
        <li key="status" className="product__dropdown-item" >
            <div className="block__content product__dropdown-title" onClick={changeFilterItemOpenness}>
                <h3 >{i18n.get('app.page.product.filters.status')}</h3>
                <DropdownIcon nameOfClass={`product__dropdown-icon ${filterGroupIsOpen ? 'product__dropdown-icon_active' : ''}`} 
                    handleClick={() => {}} value={null}/>
            </div>
            {filterGroupIsOpen ?
                <ul className="product__dropdown-content scroll">
                    {itemsStorageService.getPossibleFilters().filter(it => it.filterGroupName === FILTER_GROUP_NAME_STATUS).map(item => (
                        <React.Fragment key={item.filterName + 'key'}>
                            <FiltersListItemCheckbox 
                                itemsStorageService={itemsStorageService} 
                                filterName={item.filterName} 
                            />
                        </React.Fragment>
                    ))}
                </ul>
                : null}
        </li>
    );
};

export default FiltersListStatus;
