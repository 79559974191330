import React, { useState, useEffect } from 'react';
import { preferencesPhrasesStorage } from '../../services/preferencesPhrasesStorage';
import { StarIcon } from '../../appearence/icons/star_icon';

const PhrasesListItemWelcomeIcon = ({ preferencePhraseId }) => {
    const [isVisible, setIsVisible] = useState(false);

    const changeIconVisibility = () => {
        setIsVisible(preferencesPhrasesStorage.getById(preferencePhraseId)?.isWelcome);
    };

    useEffect(() => {
        preferencesPhrasesStorage.registerFiltersChangingObserver(changeIconVisibility);
        changeIconVisibility();
        return () => {
            preferencesPhrasesStorage.unRegisterFiltersChangingObserver(changeIconVisibility);
        };
    }, []);

    return (
        isVisible ? <StarIcon nameOfClass="preference__button-icon preference__button-icon_star" /> : null
    );
};

export default PhrasesListItemWelcomeIcon;
