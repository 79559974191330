import React, { useState } from 'react';
import { userPreferencesService } from '../../services/userPreferencesService';
import { i18n } from '../../i18n';
import { SearchIcon } from '../../appearence/icons/search_icon';

const AssignPreferenceUsersSearchInput = () => {
    const [searchValue, setSearchValue] = useState('');

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
        userPreferencesService.setSearchString(e.target.value);
    };

    return (
        <label className="block__text preference__label preference__users-label">
            <SearchIcon nameOfClass="preference__input-icon" />
            <input className="block__content preference__input" placeholder={i18n.get('app.page.preference.userSearch')}
                value={searchValue} onChange={changeSearchValue} />
        </label>
    );
};

export default AssignPreferenceUsersSearchInput;
