import { i18n } from "../i18n";

const formsService = {
    geti18nCode(formData) {
        return `product.scheme.form.code.${formData.productForm}`;
    },
    getLocalization(localeCode, code, withCodeReplace) {
        if(withCodeReplace) {
            return i18n.get(`product.scheme.form.code.${code}`, localeCode);
        } else {
            return i18n.is_exists(`product.scheme.form.code.${code}`, localeCode) ? i18n.get(`product.scheme.form.code.${code}`, localeCode) : '';
        }
    },
    getPlaceholder() {
        return i18n.get('app.page.product.addFormItem.formName');
    },
};

export {formsService};
