import React from 'react';
import SelectListItem from './SelectListItem';

const SelectList = ({ optionsList, selectAnItem }) => (
    <ul className='scroll select__choice-dropdown select__choice-dropdown_full-width'>
        {optionsList.map((it, index) => (
            <React.Fragment key={it.name + index}>
                <SelectListItem selectAnItem={selectAnItem} listItem={it}/>
            </React.Fragment>
        ))}
    </ul>
);
export default SelectList;
