import React, { useState, useEffect } from 'react';
import { LOCALE_EN, LOCALE_RU } from '../../constant';
import { WarningIcon } from '../../appearence/icons/warning_icon';
import { i18n } from '../../i18n';
import HintItem from './HintItem';

const checkIconVisibility = (itemCode, itemService) => {
    return itemService.getLocalization(LOCALE_EN, itemCode, false) === '' || itemService.getLocalization(LOCALE_RU, itemCode, false) === '';
};

const ListItemWarningIcon = ({ itemCode, itemService }) => {
    const [hintIsOpen, setHintIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if(checkIconVisibility(itemCode, itemService)) {
            setIsVisible(true);
        }
    }, []);

    const openHint = () => {
        setHintIsOpen(true);
    };

    return (
        isVisible ?
            <div className="item__hint-warning" key={itemCode + 'warning'} onMouseOver={openHint} onMouseOut={() => setHintIsOpen(false)}>
                {hintIsOpen ? <HintItem message={i18n.get('app.page.product.warningLocaleHint')} /> : null}
                <WarningIcon nameOfClass={`product__list-icon product__list-icon_warning`} />
            </div>
            :
            null
    );
};

export default ListItemWarningIcon;
