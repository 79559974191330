import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductListTab from './productsTab/products/ProductsListTab';
import FormListTab from './productsTab/forms/FormListTab';
import { i18n } from '../i18n';
import { FORM_TAB_NAME, ITEM_TAB_NAME, PAGE_TAB_NAME_LIST, PRODUCT_TAB, PROPERTY_TAB_NAME } from '../constant';
import PropertyListTab from './productsTab/properties/PropertiesListTab';
import { ErrorHandler } from './ErrorHandler';
import EditProductTab from './productsTab/products/EditProductTab';
import EditFormTab from './productsTab/forms/EditFormTab';
import EditPropertyTab from './productsTab/properties/EditPropertyTab';
import Title from './Title';
import NavigationTabs from './sharedComponents/NavigationTabs';
import { productPageNavigationService } from '../services/productPageNavigationService';

const ProductPage = () => {
    const [activeRoute, setActiveRoute] = useState('');
    const {tab, id} = useParams();

    useEffect(() => {
        setActiveRoute(`/${PRODUCT_TAB}/${tab}/${id}/`);
    }, [tab, id]);

    try {
        if(!activeRoute) return null;
        return (
            <section className="workspace workspace__product">
                {id !== PAGE_TAB_NAME_LIST ? 
                    null
                    :
                    <>
                        <Title titleValue={i18n.get('app.page.product.title')}/>
                        <NavigationTabs navigationService={productPageNavigationService} />
                    </>
                }
                {tab === ITEM_TAB_NAME ? 
                    id === PAGE_TAB_NAME_LIST ?
                        <ProductListTab />
                        :
                        <EditProductTab /> 
                    : null}
                {tab === FORM_TAB_NAME ? 
                    id === PAGE_TAB_NAME_LIST ?
                        <FormListTab /> 
                        :
                        <EditFormTab />
                    : null}
                {tab === PROPERTY_TAB_NAME ? 
                    id === PAGE_TAB_NAME_LIST ?
                        <PropertyListTab /> 
                        :
                        <EditPropertyTab />
                    : null}
            </section>
        );
    } catch (err) {
        console.log(err);
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;
    }
};

export default ProductPage;
