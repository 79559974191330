import { 
    errorCallback, 
    makeUrl,
} from "../constant";
import { RECIPES_URL, postFetchRequest } from "../store/requests";

class recipeCreateService {
    observersList = [];
    itemCode = '';
    error = '';
    registerObserver (observer) {
        this.observersList.push(observer);
    }

    unRegisterObserver (observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    }

    notifyAboutCodeChanging () {
        this.observersList.forEach(observer => observer(this.itemCode));
    }

    changeItemCode (code) {
        this.error = '';
        this.itemCode = code;
        this.notifyAboutCodeChanging();
    }
    
    saveNewItem (callback) {
        if(this.itemCode) {
            postFetchRequest(makeUrl([RECIPES_URL]), {recipeType: this.itemCode}, callback, errorCallback);
        } else {
            this.error = true;
            this.notifyAboutCodeChanging();
        }
    }
}

export let recipeCreateServiceItem;

export const initializeRecipeCreateService = () => {
    recipeCreateServiceItem = new recipeCreateService();
};
